import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { Contracts } from './_interface'
import { IContracts, IContractsStatusesForFilter } from './_types'

import { useDict } from 'store/dict/dict.state'
import { useLogin } from 'store/login/login.state'
import { useContracts } from 'store/contracts/contracts.state'
import { IContractsFilter } from 'store/contracts/contracts.types'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'

import { CardModeType } from 'components/smart/common.type'
import { ICardContractContainer } from 'components/smart/cardContract'
import { ICardListenerContainer } from 'components/smart/cardListener'

import { IModalCalendar } from 'components/dumb/modal.calendar'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'



/** Договора - Контейнер */
export const ContractsContainer = () => {
    const notify = useContext(NotificationContext)

    const dictState = useDict(useShallow((state) => state))
    const {accessLevelID} = useLogin(useShallow((state) => state.profile))
    const contractsState = useContracts(useShallow((state) => state))

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const cardListener = useInput<ICardListenerContainer>({isOpen: false})
    const cardContract = useInput<ICardContractContainer>({isOpen: false})

    const txtSearch = useInput<string>('')
    const currentPageNumber = useInput<number>(1)

    const filterIsOpen = useInput<boolean>(false)
    const filterIsActivate = useInput<boolean>(false)
    const filterContractsStatuses = useInput<IContractsStatusesForFilter[]>([])
    const filterDateStartEducationFrom = useInput<string>('')
    const filterDateStartEducationBefore = useInput<string>('')
    const filterDateCreateFrom = useInput<string>('')
    const filterDateCreateBefore = useInput<string>('')


    useEffect(() => {
        dictState.actionGetContractsStatuses().then((res) => {
            filterContractsStatuses.setValue(
                res.map((item) => ({
                    id: item.contractStatusID,
                    name: item.contractStatusName,
                    isSelected: false
                }))
            )    
        })

        return (() => {
            contractsState.clearContractsState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')
        
            filterIsOpen.setValue(false)
            filterIsActivate.setValue(false)
            filterContractsStatuses.setValue([])
            filterDateStartEducationFrom.setValue('')
            filterDateStartEducationBefore.setValue('')
            filterDateCreateFrom.setValue('')
            filterDateCreateBefore.setValue('')
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (contractsState.error) return notify && notifyOpen(contractsState.error?.message, contractsState.error?.type, 3000, notify)
    }, [contractsState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadDataContracts(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        contractsState.isReloadContracts && loadDataContracts(txtSearch.value)
    }, [contractsState.isReloadContracts]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция по получению списка договоров */
    const loadDataContracts = (search?: string) => {
        let contractStatusesListSelected: string = ''

        if (filterContractsStatuses.value.length > 0) {
            filterContractsStatuses.value.forEach((item) => {
                if (item.isSelected) {
                    contractStatusesListSelected += `'${item.name}',`
                }
            })
        }

        const filterData: IContractsFilter = {
            contractsStatuses: contractStatusesListSelected,
            dateStartEducationFrom: filterDateStartEducationFrom.value,
            dateStartEducationBefore: filterDateStartEducationBefore.value,
            dateCreateFrom: filterDateCreateFrom.value,
            dateCreateBefore: filterDateCreateBefore.value,
        }

        contractsState.actionGetContracts(currentPageNumber.value, search || '', filterData)
    }

    /** Кнопка применить (очистить) в окне - Фильтр по договорам */
    const handleApplyClearFilter = (filterType: 'apply' | 'clear') => {
        switch (filterType) {
            case 'apply': {
                filterIsActivate.setValue(true)
                loadDataContracts(txtSearch.value)
                break
            }

            case 'clear': {
                filterIsActivate.setValue(false)
                filterContractsStatuses.setValue(filterContractsStatuses.value.map(status => ({...status, isSelected: false})))
                filterDateStartEducationFrom.setValue('')
                filterDateStartEducationBefore.setValue('')
                filterDateCreateFrom.setValue('')
                filterDateCreateBefore.setValue('')

                const filterData: IContractsFilter = {
                    contractsStatuses: '',
                    dateStartEducationFrom: '', dateStartEducationBefore: '',
                    dateCreateFrom: '', dateCreateBefore: '',
                }
        
                contractsState.actionGetContracts(currentPageNumber.value, txtSearch.value, filterData)
                break
            }
        }
    }

    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string) => {
        modalCalendar.setValue({
            isOpen,
            handlerButtonCancel() { modalCalendar.setValue({ isOpen: false }) },
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'dateStartEducationFrom': filterDateStartEducationFrom.setValue(stringDate); break
                    case 'dateStartEducationBefore': filterDateStartEducationBefore.setValue(stringDate); break
                    
                    case 'dateCreateFrom': filterDateCreateFrom.setValue(stringDate); break
                    case 'dateCreateBefore': filterDateCreateBefore.setValue(stringDate); break
                }

                modalCalendar.setValue({ isOpen: false })
            }
        })
    }

    /** Открытие/закрытие анкеты по договору */
    const handleOpenCardContract = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardContract.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardContract.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardContract.setValue({isOpen: false})
                loadDataContracts(txtSearch.value)
            }
        })
    }

    /** Открытие/закрытие анкеты по слушателю */
    const handleOpenCardListener = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardListener.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardListener.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListener.setValue({isOpen: false})
                loadDataContracts(txtSearch.value)
            }
        })
    }


    const debounceSearch = useDebounce(loadDataContracts, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска
    

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IContracts = {
        accessLevelID, txtSearch, txtSearchOnChange, loadDataContracts, 

        filterIsActivate, filterIsOpen, filterContractsStatuses,
        filterDateStartEducationFrom, filterDateStartEducationBefore,
        filterDateCreateFrom, filterDateCreateBefore, handleApplyClearFilter,

        modalCalendar, handleOpenModalCalendar,
        cardContract, handleOpenCardContract,
        cardListener, handleOpenCardListener,

        contractsStatistics: contractsState.contractsStatistics,
        contractsList: contractsState.contracts.items,

        currentPageNumber, 
        isLoading: contractsState.contracts.isLoading,
        totalCount: contractsState.contracts.totalCount, 
        pageSize: contractsState.contracts.pageSize,
    }

    return <Contracts {...propsToComponent} />
}