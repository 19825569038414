import React, { memo, useEffect, useRef } from 'react'
import ReactDom from 'react-dom'

import css from './index.module.scss'
import { ModalType } from './type'

/** Компонент модального окна.
* @property {boolean} isOpen - Состояние модального окна, открытое или закрытое.
* @property {boolean} isEsc - Метка о допуске закрытия окна, при нажатии на кнопку Esc.
* @property {boolean} isWrapper - Метка о допуске закрытия окна, при клике вне области окна.
* @property {React.ReactNode} children - Дочерние элементы модального окна.
* @property {string} zIndex - Установка позиционирования модального окна.
* @property {string} overflow - Управление scroll.
* @property {string} maxWidth - Установка максимальной ширины окна.
* @property {() => void} onOk - Функция, которая будет вызываться, при нажатии на клавишу Enter.
* @property {() => void} onCancel - Функция, которая будет вызываться, при закрытии окна (необходима при использовании свойств: isEsc, isWrapper).
*/
export const Modal = memo(({ 
    isOpen, isEsc = false, isWrapper = false, children, className, zIndex = '1050', overflowY = 'hidden', maxWidth = '400px', onOk, onCancel 
}: ModalType): React.JSX.Element => {
    const modalRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.focus() 
        }

        switch (isOpen) {
            case true: document.body.style.overflow = 'hidden'; break
            case false: document.body.style.overflow = 'unset'; break
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let handleEsc: (event: KeyboardEvent) => void
        let handleEnter: (event: KeyboardEvent) => void

        if (isOpen) {
            handleEsc = (event: KeyboardEvent) => {
                if (isEsc && event.keyCode === 27) {
                    onCancel && onCancel()
                    document.body.style.overflow = 'unset'
                }
            }

            handleEnter = (event: KeyboardEvent) => {
                if (event.keyCode === 13) {
                    onOk && onOk()
                    document.body.style.overflow = 'unset'
                }
            }

            document.addEventListener('keydown', handleEsc)
            document.addEventListener('keydown', handleEnter)
        }

        return () => {
            document.removeEventListener('keydown', handleEsc)
            document.removeEventListener('keydown', handleEnter)
        }
    }, [isOpen, isEsc]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Зыкрытие окна, при клике мышкой вне области окна */
    const handlerCloseModal = () => {
        if (isWrapper) {
            onCancel && onCancel()
            document.body.style.overflow = 'unset'
        }
    }

    return ReactDom.createPortal(
        <div ref={modalRef} className={`${css.wrapper} ${isOpen ? css.open : css.close}`} style={{ width: '100%', zIndex: `${zIndex}`, overflowY: overflowY }} onClick={handlerCloseModal}>
            <div className={`${css.modal} ${className}`} style={{ width: '100%', maxWidth: `${maxWidth ? maxWidth : 'max-content'}` }} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>, document.body
    )
})