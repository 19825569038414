import React, { memo } from 'react'
import { BeatLoader } from 'react-spinners'

import { ButtonSizeType, IButton } from './type'
import css from './index.module.scss'


/** Кнопка */
export const Button = memo(({ 
    id, name, type = 'button', variant = 'primary', size, width, maxWidth, padding, isDisabled, isVisible = true, children, 
    onClick, isLoading = false, isPositionAbsolute, left, bottom, right, top, flexGrow, flexBasis
}: IButton): React.JSX.Element => {
    const elementStyle: React.CSSProperties = {
        position: isPositionAbsolute ? 'absolute' : 'relative',
        left: left,
        top: top,
        right: right,
        bottom: bottom,
        flexGrow: flexGrow,
        flexBasis: flexBasis
    }

    const getSizeClassName = (size?: ButtonSizeType) => {
        switch (size) {
            case 'lg': return css.lg
            case 'md': return css.md
            case 'sm': return css.sm
            case 'xs': return css.xs
            default: return css.md
        }
    }

    return <>
        {isVisible &&
            <div className={css.buttonWrapper} style={elementStyle}>
                <button id={id} name={name} type={type} disabled={isDisabled} onClick={onClick} className={`${css[variant]} ${getSizeClassName(size)}`} style={{ bottom: bottom, width: width, maxWidth: maxWidth, padding: padding }}>
                    {children}
                </button>
                {isLoading && <div className={css.isLoading}><BeatLoader size={10} loading={isLoading} color='#f34e2e' /></div>}
            </div>
        }
    </>
})