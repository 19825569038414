import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { ICompanies } from './_types'
import { Companies } from './_interface'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { useCompanies } from 'store/companies/companies.state'

import { CardModeType } from 'components/smart/common.type'
import { ICardCompanyContainer } from 'components/smart/cardCompany'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Контрагенты - Контейнер */
export const CompaniesContainer = () => {
    const notify = useContext(NotificationContext)

    const companiesState = useCompanies(useShallow((state) => state))
    const cardCompanies = useInput<ICardCompanyContainer>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')

    useEffect(() => {
        return (() => {
            companiesState.clearCompaniesState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')        
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (companiesState.error) return notify && notifyOpen(companiesState.error?.message, companiesState.error?.type, 3000, notify)
    }, [companiesState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadDataCompanies(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        companiesState.isReloadCompanies && loadDataCompanies(txtSearch.value)
    }, [companiesState.isReloadCompanies]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция по получению списка слушателей */
    const loadDataCompanies = async (search?: string) => {
        companiesState.actionGetCompanies(currentPageNumber.value, search || '')
    }

    /** Открытие/закрытие карточки по контрагенту */
    const handleOpenCardCompany = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardCompanies.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardCompanies.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardCompanies.setValue({isOpen: false})
                loadDataCompanies(txtSearch.value)
            }
        })
    }

    const debounceSearch = useDebounce(loadDataCompanies, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICompanies = {
        isLoading: companiesState.companies.isLoading,
        txtSearch, txtSearchOnChange, loadDataCompanies,

        currentPageNumber, 
        totalCount: companiesState.companies.totalCount, 
        pageSize: companiesState.companies.pageSize,

        companiesList: companiesState.companies.items,

        cardCompanies, handleOpenCardCompany, 
    }
    
    return <Companies {...propsToComponent} />
}