import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { CardPrintingDiplomas } from './_interface'
import { ICardPrintingDiplomas, ICardPrintingDiplomasContainer, IFieldsError } from './_types'

import { useInput } from 'hooks/useInput'
import { usePrintingsDiplomas } from 'store/printingsDiplomas/PrintingsDiplomas.state'

import { ISelectDictionaryContainer, SelectDictionaryContainer } from '../selectDictionary'

import { IModalConfirm, ModalConfirm } from 'components/dumb/modal.confirm'
import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Контейнер анкеты печати документа */
export const CardPrintingDiplomasContainer = ({isOpen, cardID, fullNameFrom, handlerButtonOk, handlerButtonCancel}: ICardPrintingDiplomasContainer) => {
    const notify = useContext(NotificationContext)

    const {isLoadingCard, getCardData, saveCardData, deleteCard} = usePrintingsDiplomas(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        getCardData: state.actionGetPrintingDiplomasCard,
        saveCardData: state.actionSetPrintingDiplomasCardSave,
        deleteCard: state.actionSetPrintingDiplomasDelete,
    })))

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalDict = useInput<ISelectDictionaryContainer>({isOpen: false})
    const modalConfirm = useInput<IModalConfirm>({isOpen: false})

    const loadingTitle = useInput<string>('Загрузка анкеты...')
    const fieldsError = useInput<IFieldsError>({})

    const txtPrintStatusID = useInput<number | ''>('')
    const txtPrintStatus = useInput<string>('')
    const txtDiplomRegNumber = useInput<string>('')
    const txtDiplomNumber = useInput<string>('')
    const txtDiplomDate = useInput<string>('')


    useEffect(() => {
        if (isOpen) {
            getCardData(Number(cardID)).then((res) => {
                if (res) {
                    txtPrintStatusID.setValue(res.printStatusID || '')
                    txtPrintStatus.setValue(res.printStatusName || '')
                    txtDiplomRegNumber.setValue(res.diplomRegNumber || '')
                    txtDiplomNumber.setValue(res.diplomNumber || '')
                    txtDiplomDate.setValue(res.diplomDateIssue || '')
                }
            })
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        fieldsError.setValue({})

        txtPrintStatusID.setValue('')
        txtPrintStatus.setValue('')
        txtDiplomRegNumber.setValue('')
        txtDiplomNumber.setValue('')
        txtDiplomDate.setValue('')
    }


    /** Открытие/закрытие окна выбора справочника */
    const handleOpenModalDict = (isOpen: boolean) => {
        modalDict.setValue({
            isOpen, dictName: 'printStatus',
            handlerButtonCancel() {modalDict.setValue({isOpen: false})}, 
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                txtPrintStatusID.setValue(Number(id))
                txtPrintStatus.setValue(name || '')

                modalDict.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                txtDiplomDate.setValue(stringDate)
                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText,
            handlerButtonCancel() { modalConfirm.setValue({ isOpen: false }) },
            handlerButtonOk() {
                loadingTitle.setValue('Возвращаю в раздел итоговой аттестации...')

                deleteCard(Number(cardID)).then((res) => {
                    if (res === 200) {
                        handlerButtonOk && handlerButtonOk()
                        return notify && notifyOpen('Анкета итоговой работы слушателя, успешно возвращена на обработку.', 'success', 1500, notify)
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })

                modalConfirm.setValue({ isOpen: false })
            }
        })
    }

    
    /** Кнопка сохранить в анкете */
    const handlerBtnSave = () => {
        saveCardData(Number(cardID), Number(txtPrintStatusID.value), txtDiplomRegNumber.value.trim(), txtDiplomNumber.value.trim(), txtDiplomDate.value.trim()).then((res) => {
            if (res === 200) {
                notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                handlerButtonOk && handlerButtonOk()
            }
        })
    }
    
    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardPrintingDiplomas = {
        isOpen, isLoadingCard, fullNameFrom, fieldsError, loadingTitle, 
        txtPrintStatus, txtDiplomRegNumber, txtDiplomNumber, txtDiplomDate,

        handlerButtonCancel, handleOpenModalDict, handleOpenModalCalendar, handlerBtnSave, handlerOpenModalConfirm,
    }
    
    return <>
        <CardPrintingDiplomas {...propsToComponent} />
        
        <ModalConfirm {...modalConfirm.value} />
        <ModalCalendar {...modalCalendar.value} />
        <SelectDictionaryContainer {...modalDict.value} />
    </>
}