import React, { memo } from 'react'

import { ICheckbox } from './type'
import css from './index.module.scss'


/** Checkbox */
export const Checkbox = memo(({
    id, key, checked, onChange, isPositionAbsolute, left, top, right, bottom
}: ICheckbox): React.JSX.Element => {
    const elementStyle: React.CSSProperties = {
        position: isPositionAbsolute ? 'absolute' : 'relative',
        left: left,
        top: top,
        right: right,
        bottom: bottom,
    }


    return <>
        <input id={id} key={key} type="checkbox" className={css.input_checkbox} style={elementStyle} checked={checked} onChange={onChange} />
    </>
})