import { Route, Routes } from 'react-router'
import { Navigate } from 'react-router-dom'

import { LoginContainer } from 'pages/login'
import { MainContainer } from 'pages/main'
import { CanceledContract, InfoContract } from 'pages/info'


/** Маршруты для стартовой страницы */
const IndexRoute = () => {
    return (
        <Routes>
            <Route key='/' path='/' element={<LoginContainer />} />
            <Route key='/lk/*' path='/lk/*' element={<MainContainer />} />
            <Route key='/infoContract/:id' path='/infoContract/:id' element={<InfoContract />} />
            <Route key='/canceledContract/:id' path='/canceledContract/:id' element={<CanceledContract />} />
            <Route key='*' path='*' element={<Navigate to='/' />} />
        </Routes>
    )
}

export default IndexRoute