import { ICardContractPayments } from './_types'

import { IconCalendar, IconClearClose } from 'images/icons'
import { Flex, ToolTip, Modal, Input, Divider, Button } from 'components/ui'


/** Анкета создания/редактирования оплат по договору */
export const CardContractPayments = ({
    isOpen, cardMode, handlerButtonCancel,
    fieldsError, txtPaymentRefund, txtPaymentNumber, txtPaymentDate, txtPaymentSum, txtPaymentPayer,
    handleOpenModalCalendar, handleBtnSave
}: ICardContractPayments) => {
    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }
    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }


    return <>
        <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} overflowY="hidden" maxWidth="350px">
            <Flex className='modal_container'>
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>{cardMode === 'new' ? 'Добавление платежа...' : 'Редактирование платежа...'}</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body'>
                    <Flex className='modal_container-content'>
                        <Flex gap='15px'>
                            <Flex gap="15px" direction="row" justifyContent="flex-start" flexWrap="wrap" className="card_style">
                                <Flex gap="10px" direction="row" alignItems="center">
                                    <b>Возврат денежных средств:</b>
                                    <Button id="tooltipBtnPaymentRefund" size="sm" padding='0 6px' onClick={() => 
                                        txtPaymentRefund.setValue(txtPaymentRefund.value === 1 ? 0 : 1)
                                    }>{txtPaymentRefund.value ? '✔' : '✘'}</Button>
                                </Flex>
                            </Flex>

                            <Divider />

                            <Flex className={!fieldsError.value.paymentNumber ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" className="input_header">Номер платежа из выписки <p>✸</p></Flex>
                                <Input placeholder="Номер платежа из выписки" paddingRight={4} {...txtPaymentNumber}
                                    suffix={txtPaymentNumber.value && getBtnClear(() => txtPaymentNumber.setValue(''))}
                                />
                            </Flex>

                            <Flex className={!fieldsError.value.paymentDate ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" className="input_header">Дата платежа из выписки <p>✸</p></Flex>
                                <Input placeholder="Дата платежа из выписки" paddingRight={4} type='mask' mask='99.99.9999' maskChar="" {...txtPaymentDate}
                                    suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'paymentDate', txtPaymentDate.value))}
                                />
                            </Flex>

                            <Flex className={!fieldsError.value.paymentSum ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" className="input_header">Сумма платежа из выписки <p>✸</p></Flex>
                                <Input placeholder="Сумма платежа из выписки" paddingRight={4} {...txtPaymentSum}
                                    suffix={txtPaymentSum.value && getBtnClear(() => txtPaymentSum.setValue(''))}
                                />
                            </Flex>

                            <Flex className={!fieldsError.value.paymentPayer ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" className="input_header">Плательщик из выписки <p>✸</p></Flex>
                                <Input placeholder="Плательщик из выписки" paddingRight={4} {...txtPaymentPayer}
                                    suffix={txtPaymentPayer.value && getBtnClear(() => txtPaymentPayer.setValue(''))}
                                />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Divider />
                    <Button width='100%' onClick={handleBtnSave}>{cardMode === 'new' ? 'Добавить платёж' : 'Изменить платёж'}</Button>
                </Flex>
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
            <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
            <ToolTip anchorSelect="#tooltipBtnPaymentRefund" content='Кликните для изменения' place="bottom" />
        </Modal>
    </>
}