import { ICardContractsProgramListener } from './_types'

import { IconClearClose, IconSelectData } from 'images/icons'
import { Flex, ToolTip, Modal, Input, Button } from 'components/ui'


/** Редактирование свойств слушателя по договору */
export const CardContractProgramListener = ({ isOpen, isAttestationTypeVisible, handlerButtonCancel,
    fieldsError, txtAttestationType, txtQualification,
    handleOpenModalDict, handleBtnSave,
}: ICardContractsProgramListener) => {
    return <>
        <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} overflowY="hidden" maxWidth="400px">
            <Flex className='modal_container'>
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>Редактирование свойств слушателя</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body'>
                    <Flex className='modal_container-content'>
                        <Flex gap='15px'>
                            <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                <Flex flexGrow={1} flexBasis={250} isVisible={isAttestationTypeVisible} className={!fieldsError.value.attestationType ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Тип аттестации <p>✸</p></Flex>
                                    <Input placeholder="Тип аттестации" isReadOnly paddingRight={4} {...txtAttestationType}
                                        suffix={<Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={() => handleOpenModalDict(true, 'attestationType')}><IconSelectData /></Button>}
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.qualification ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Квалификация <p>✸</p></Flex>
                                    <Input placeholder="Квалификация" paddingRight={4} {...txtQualification} 
                                        suffix={txtQualification.value && <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={() => txtQualification.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Button width='100%' onClick={handleBtnSave}>Применить изменения</Button>
                </Flex>
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
            <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        </Modal>
    </>
}