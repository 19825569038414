import { ICardPrintingDiplomas } from './_types'

import { IconCalendar, IconClearClose, IconSelectData } from 'images/icons'
import { Flex, Modal, Loader, Input, Divider, Button } from 'components/ui'


/** Анкета печати документа */
export const CardPrintingDiplomas = ({
    isOpen, isLoadingCard, fieldsError, loadingTitle, fullNameFrom, 
    txtPrintStatus, txtDiplomRegNumber, txtDiplomNumber, txtDiplomDate,
    handleOpenModalDict, handlerButtonCancel, handleOpenModalCalendar, handlerBtnSave, handlerOpenModalConfirm
}: ICardPrintingDiplomas) => {
    return <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} maxWidth='600px'>
        <Flex className='modal_container'>
            <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <h4>Анкета печати</h4>
                    </Flex>

                    <h5>{fullNameFrom}</h5>
                </header>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadingTitle.value} backdrop />

                <Flex gap='15px' className='modal_container-content'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={100} className={!fieldsError.value.printStatus ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Статус печати <p>✸</p></Flex>
                            <Input placeholder="Статус печати" paddingRight={4} isReadOnly {...txtPrintStatus}
                                suffix={<Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={() => handleOpenModalDict(true)}><IconSelectData /></Button>}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={100} className={!fieldsError.value.diplomNumber ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Дата выдачи документа <p>✸</p></Flex>
                            <Input placeholder="Дата выдачи документа" paddingRight={4} type='mask' mask='99.99.9999' maskChar="" {...txtDiplomDate} 
                                suffix={<Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={() => handleOpenModalCalendar(true, txtDiplomDate.value)}><IconCalendar /></Button>}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={100} className={!fieldsError.value.diplomRegNumber ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Рег. № документа <p>✸</p></Flex>
                            <Input placeholder="Рег. № документа" paddingRight={4} {...txtDiplomRegNumber} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={100} className={!fieldsError.value.diplomNumber ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Номер документа <p>✸</p></Flex>
                            <Input placeholder="Номер документа" paddingRight={4} {...txtDiplomNumber} />
                        </Flex>
                    </Flex>
                </Flex>

                <Divider />

                <Flex gap='6px' direction='row' flexWrap='wrap' justifyContent='space-between'>
                    <Button variant='primaryDark' flexGrow={1} flexBasis={100} 
                        onClick={() => handlerOpenModalConfirm(true, 'Вернуть на обработку', `Вы действительно хотите вернуть в обработку следующего слушателя:<br />👉 ${fullNameFrom}.`)}
                    >Удалить</Button>

                    <Button flexGrow={1} flexBasis={100} onClick={handlerBtnSave}>Сохранить</Button>
                </Flex>
            </Flex>
        </Flex>
    </Modal>
}