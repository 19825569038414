import { ICardApplicationTabPrimary } from "./_types"

import { IconCalendar, IconClearClose, IconSelectData } from "images/icons"
import { Flex, ToolTip, Textarea, Input, Divider, Button, Table } from "components/ui"


/** Основная вкладка в анкете заявки */
export const CardApplicationTabPrimary = ({
    cardMode, fieldsError, isFieldsDisabled, dbNameList, dbNameSelected,
    txtClientName, txtClientPhone, txtClientEmail, 
    txtProgramNameID, txtProgramName, txtProgramTypeID, txtProgramType, txtAttestationType, 
    txtEducationDateStart, txtEducationDateEnd, 
    txtDurationEducation, txtVolumeHours, txtProgramSum,
    txtApplicationStatusID, txtApplicationStatus, txtDateNextCall, txtTimeNextCall, 
    txtApplicationType, txtApplicationSource, txtApplicationComment,

    handleOpenModalDict, handleOpenModalCalendar, handleChangePhone, txtEducationDateStartOnChange, txtEducationDateEndOnChange,
}: ICardApplicationTabPrimary) => {
    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }

    const getBtnSelect = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    }

    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }

    return <>
        <Flex gap="20px">
            <Flex gap="5px" isVisible={cardMode === 'new'}>
                <Divider prefix={<h4>База данных сайта</h4>} />

                <Flex gap="8px" direction="row" flexWrap="wrap">
                    {dbNameList.length === 0 ? 'Базы данных не найдены' 
                        : dbNameList.map((item) => (
                            <Table.Row key={item.siteDbNameID} backgroundColor={dbNameSelected.value === item.siteDbName ? '#f34e2e' : ''} indexContent={dbNameSelected.value === item.siteDbName ? '✔' : '✘'} indexContentWidth="30px" paddingInset="6px 10px" onClick={() => dbNameSelected.setValue(item.siteDbName)}>
                                {item.siteDbNameDescription}
                            </Table.Row>
                        ))
                    }
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>О клиенте</h4>} />

                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.clientName ? 'input_error' : 'input_error input_error-active'}>
                        <Flex gap="5px" direction="row" className="input_header">Имя клиента <p>✸</p></Flex>
                        <Input placeholder="Имя клиента" paddingRight={4} suffix={txtClientName.value && getBtnClear(() => txtClientName.setValue(''))} {...txtClientName} />
                    </Flex>

                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.clientPhone ? 'input_error' : 'input_error input_error-active'}>
                        <Flex gap="5px" direction="row" className="input_header">Телефон клиента <p>✸</p></Flex>
                        <Input placeholder="Телефон клиента" paddingRight={4} suffix={txtClientPhone.value && getBtnClear(() => handleChangePhone(''))}
                            value={txtClientPhone.value} onChange={(e) => handleChangePhone(e.target.value)}
                        />
                    </Flex>

                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.clientEmail ? 'input_error' : 'input_error input_error-active'}>
                        <Flex direction="row" className="input_header">Email клиента</Flex>
                        <Input placeholder="Email клинета" paddingRight={4} suffix={txtClientEmail.value && getBtnClear(() => txtClientEmail.setValue(''))} {...txtClientEmail} />
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Программа обучения</h4>} />

                <Flex gap="15px">
                    <Flex flexGrow={1} className={!fieldsError.value.programName ? 'input_error' : 'input_error input_error-active'}>
                        <Flex gap="5px" direction="row" className="input_header">Наименование программы <p>✸</p></Flex>
                        <Input placeholder="Наименование программы" isReadOnly paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'courses', 'programName'))} {...txtProgramName} />
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.programType ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Тип программы <p>✸</p></Flex>
                            <Input placeholder="Тип программы" isReadOnly isDisabled={isFieldsDisabled.value} paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'programType', 'programType', txtProgramNameID.value.toString()))} {...txtProgramType} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.attestationType ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Тип аттестации <p>✸</p></Flex>
                            <Input placeholder="Тип аттестации" isReadOnly isDisabled={isFieldsDisabled.value || txtProgramTypeID.value === 2} paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'attestationType', 'attestationType'))} {...txtAttestationType} />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.durationEducation ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Продолжительность ({txtProgramTypeID.value === 1 ? 'месяцы' : 'недели'}) <p>✸</p></Flex>
                            <Input placeholder="Продолжительность" isReadOnly isDisabled={isFieldsDisabled.value} paddingRight={4} {...txtDurationEducation} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.volumeHours ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Объём программы (часы) <p>✸</p></Flex>
                            <Input placeholder="Объём программы" isReadOnly isDisabled={isFieldsDisabled.value} paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'volumeHours', 'volumeHours', txtProgramNameID.value.toString(), txtProgramTypeID.value.toString()))} {...txtVolumeHours} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.programSum ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Стоимость программы (рубли) <p>✸</p></Flex>
                            <Input placeholder="Стоимость программы" isDisabled={isFieldsDisabled.value} paddingRight={4} suffix={txtProgramSum.value && getBtnClear(() => txtProgramSum.setValue(''))}
                                value={txtProgramSum.value} onChange={(e) => txtProgramSum.setValue(e.target.value.trim())}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.educationDateStart ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Дата начала обучения <p>✸</p></Flex>
                            <Input placeholder="Дата начала обучения" isDisabled={isFieldsDisabled.value} paddingRight={4} type='mask' mask='99.99.2099' maskChar="" 
                                suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'educationDateStart', txtEducationDateStart.value))}
                                value={txtEducationDateStart.value} onChange={(e) => txtEducationDateStartOnChange(e.target.value)}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.educationDateEnd ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Дата окончания обучения <p>✸</p></Flex>
                            <Input placeholder="Дата окончания обучения" isDisabled={isFieldsDisabled.value} paddingRight={4} type='mask' mask='99.99.2099' maskChar="" 
                                suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'educationDateEnd', txtEducationDateEnd.value))} 
                                value={txtEducationDateEnd.value} onChange={(e) => txtEducationDateEndOnChange(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>О заявке</h4>} />

                <Flex gap="15px">
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.applicationStatus ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Статус заявки <p>✸</p></Flex>
                            <Input placeholder="Статус заявки" isReadOnly paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'applicationStatus', 'applicationStatus'))} {...txtApplicationStatus} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.dateNextCall ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Дата отложенного звонка {txtApplicationStatusID.value === 2 && <p>✸</p>}</Flex>
                            <Input placeholder="Дата отложенного звонка" isDisabled={txtApplicationStatusID.value !== 2} paddingRight={4} type='mask' mask='99.99.2099' maskChar="" suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'dateNextCall', txtDateNextCall.value))} {...txtDateNextCall} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.timeNextCall ? 'input_error' : 'input_error input_error-active'}>
                            <Flex direction="row" className="input_header">Время отложенного звонка</Flex>
                            <Input placeholder="Время отложенного звонка" isDisabled={txtApplicationStatusID.value !== 2} paddingRight={4} type='mask' mask='99:99:00' maskChar=""
                                suffix={txtTimeNextCall.value && getBtnClear(() => txtTimeNextCall.setValue(''))} {...txtTimeNextCall}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.applicationType ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Тип заявки <p>✸</p></Flex>
                            <Input placeholder="Тип заявки" isReadOnly paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'applicationType', 'applicationType'))} {...txtApplicationType} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.applicationSource ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Источник заявки <p>✸</p></Flex>
                            <Input placeholder="Источник заявки" isReadOnly paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'applicationSource', 'applicationSource'))} {...txtApplicationSource} />
                        </Flex>
                    </Flex>

                    <Flex flexGrow={1}>
                        <Flex direction="row" className="input_header">Комментарий к заявке</Flex>
                        <Textarea placeholder="Комментарий к заявке" rows={5} value={txtApplicationComment.value} onChange={(e) => txtApplicationComment.setValue(e.target.value)} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
        <ToolTip anchorSelect="#tooltipBtnRating" content='Рэйтинг клиента в баллах' place="left" />
    </>
}