import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseApplicationSource, IResponseApplicationStatuses, IResponseApplicationType, IResponseApplicationsNumber, IResponseAttestationType, IResponseCitizenships, IResponseContractDocuments, IResponseContractSections, IResponseContractSended, IResponseContractTypes, IResponseContractViews, IResponseContractsStatuses, IResponseCoursePrograms, IResponseCourses, IResponseCoursesContract, IResponseDocumentTypes, IResponseEducationLevels, IResponsePrintStatus, IResponseProgramType, IResponseSitesDbName, IResponseVolumeHours } from "./dict.types"


const route = '/dict'

/** Получение - Имена баз данных сайтов */
export const getSiteDbName = async () => {
    let response: IResponseSitesDbName

    try {
        const res: AxiosResponse<IResponseSitesDbName, AxiosError> = await api.get(`${route}/getSiteDbName`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseSitesDbName
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Наименований курсов */
export const getCourses = async (source: string) => {
    let response: IResponseCourses

    try {
        const res: AxiosResponse<IResponseCourses, AxiosError> = await api.get(`${route}/getCourses`, {params: {source}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCourses
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Курсов для договора */
export const getCoursesContract = async (source: string) => {
    let response: IResponseCoursesContract

    try {
        const res: AxiosResponse<IResponseCoursesContract, AxiosError> = await api.get(`${route}/getCoursesContract`, {params: {source}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCoursesContract
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Типы программ */
export const getProgramType = async (source: string, courseID: number) => {
    let response: IResponseProgramType

    try {
        const res: AxiosResponse<IResponseProgramType, AxiosError> = await api.get(`${route}/getProgramType`, {params: {source, courseID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseProgramType
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Тип аттестации */
export const getAttestationType = async () => {
    let response: IResponseAttestationType

    try {
        const res: AxiosResponse<IResponseAttestationType, AxiosError> = await api.get(`${route}/getAttestationType`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseAttestationType
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Обьем программы в часах */
export const getVolumeHours = async (source: string, courseID: number, programID: number) => {
    let response: IResponseVolumeHours

    try {
        const res: AxiosResponse<IResponseVolumeHours, AxiosError> = await api.get(`${route}/getVolumeHours`, {params: {source, courseID, programID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseVolumeHours
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Обьем программы в часах */
export const getCoursePrograms = async (source: string, courseID: number) => {
    let response: IResponseCoursePrograms

    try {
        const res: AxiosResponse<IResponseCoursePrograms, AxiosError> = await api.get(`${route}/getCoursePrograms`, {params: {source, courseID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCoursePrograms
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Статусы заявок */
export const getApplicationStatuses = async () => {
    let response: IResponseApplicationStatuses

    try {
        const res: AxiosResponse<IResponseApplicationStatuses, AxiosError> = await api.get(`${route}/getApplicationStatuses`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationStatuses
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Тип заявки */
export const getApplicationType = async () => {
    let response: IResponseApplicationType

    try {
        const res: AxiosResponse<IResponseApplicationType, AxiosError> = await api.get(`${route}/getApplicationType`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationType
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Источник заявки */
export const getApplicationSource = async () => {
    let response: IResponseApplicationSource

    try {
        const res: AxiosResponse<IResponseApplicationSource, AxiosError> = await api.get(`${route}/getApplicationSource`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationSource
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Гражданство */
export const getCitizenships = async () => {
    let response: IResponseCitizenships

    try {
        const res: AxiosResponse<IResponseCitizenships, AxiosError> = await api.get(`${route}/getCitizenships`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCitizenships
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Уровень образования */
export const getEducationLevels = async () => {
    let response: IResponseEducationLevels

    try {
        const res: AxiosResponse<IResponseEducationLevels, AxiosError> = await api.get(`${route}/getEducationLevels`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseEducationLevels
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Статусы договоров */
export const getContractsStatuses = async () => {
    let response: IResponseContractsStatuses

    try {
        const res: AxiosResponse<IResponseContractsStatuses, AxiosError> = await api.get(`${route}/getContractsStatuses`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractsStatuses
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Номера заявок */
export const getApplicationsNumber = async () => {
    let response: IResponseApplicationsNumber

    try {
        const res: AxiosResponse<IResponseApplicationsNumber, AxiosError> = await api.get(`${route}/getApplicationsNumber`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationsNumber
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Тип договора */
export const getContractTypes = async () => {
    let response: IResponseContractTypes

    try {
        const res: AxiosResponse<IResponseContractTypes, AxiosError> = await api.get(`${route}/getContractTypes`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractTypes
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Вид договора */
export const getContractViews = async () => {
    let response: IResponseContractViews

    try {
        const res: AxiosResponse<IResponseContractViews, AxiosError> = await api.get(`${route}/getContractViews`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractViews
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Тип программы */
export const getDocumentTypes = async () => {
    let response: IResponseDocumentTypes

    try {
        const res: AxiosResponse<IResponseDocumentTypes, AxiosError> = await api.get(`${route}/getDocumentTypes`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseDocumentTypes
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Документы по договору */
export const getContractDocuments = async () => {
    let response: IResponseContractDocuments

    try {
        const res: AxiosResponse<IResponseContractDocuments, AxiosError> = await api.get(`${route}/getContractDocuments`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractDocuments
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Секции файлов по договору */
export const getContractSections = async () => {
    let response: IResponseContractSections

    try {
        const res: AxiosResponse<IResponseContractSections, AxiosError> = await api.get(`${route}/getContractSections`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractSections
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Наименования отправок по договору */
export const getContractSended = async () => {
    let response: IResponseContractSended

    try {
        const res: AxiosResponse<IResponseContractSended, AxiosError> = await api.get(`${route}/getContractSended`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractSended
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Получение - Статус печати */
export const getPrintStatus = async () => {
    let response: IResponsePrintStatus

    try {
        const res: AxiosResponse<IResponsePrintStatus, AxiosError> = await api.get(`${route}/getPrintStatus`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponsePrintStatus
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
