import { initialStateLogin, useLogin } from "./login/login.state"
import { initialStateApplications, useApplications } from "./applications/applications.state"
import { initialStateDict, useDict } from "./dict/dict.state"
import { initialStateCommon, useCommon } from "./common/common.state"
import { initialStateListeners, useListeners } from "./listeners/listeners.state"
import { initialStateCompanies, useCompanies } from "./companies/companies.state"
import { initialStateContracts, useContracts } from "./contracts/contracts.state"
import { initialStateFinalExamination, useFinalExamination } from "./finalExamination/finalExamination.state"
import { initialStatePrintingDiplomas, usePrintingsDiplomas } from "./printingsDiplomas/PrintingsDiplomas.state"
import { initialStateDelivery, useDelivery } from "./delivery/delivery.state"


/** Сброс всех состояний */
export const resetStore = () => {
    useLogin.setState(initialStateLogin)
    useDict.setState(initialStateDict)
    useCommon.setState(initialStateCommon)
    
    useApplications.setState(initialStateApplications)
    useListeners.setState(initialStateListeners)
    useCompanies.setState(initialStateCompanies)
    useContracts.setState(initialStateContracts)
    useFinalExamination.setState(initialStateFinalExamination)
    usePrintingsDiplomas.setState(initialStatePrintingDiplomas)
    useDelivery.setState(initialStateDelivery)
}