import { useEffect, useRef } from "react"
import moment from "moment"

import { IApplicationsPromo } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconClearClose, IconInfo, IconSearch, IconTelegram, IconTemp, IconUpdate } from "images/icons"
import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Drawer, Divider, Button, Table } from "components/ui"



/** Промо заявки с МАКПП */
export const ApplicationsPromo = ({
    isOpen, isLoading, currentPageNumber, pageSize, totalCount, applicationsList, txtSearch, 
    txtSearchOnChange, loadDataListeners, handlerSendToTelegram, handlerButtonCancel
}: IApplicationsPromo) => {
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth;
            tableHeadNavRef.current.style.width = `${width}px`;
        }
    }, [applicationsList, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconTemp color='#fff' width={20} height={20} />
                        <h4>Список промо ({totalCount})</h4>
                    </Flex>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Flex gap='6px' className='drawer_container-content' overflow='hidden' padding='2px 0px 0px 0px'>
                    <Flex gap="3px" className="page_container">
                        <Loader isOpen={isLoading} backdrop color="#f34e2e" title='Загрузка списка...' />

                        <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                            <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                                <IconTemp width={28} height={28} />
                                <h4>Промо ({totalCount})</h4>
                            </Grid>

                            <Flex gap="6px" direction="row" className="page_header_for_mobile">
                                <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                                    value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                                    paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                                    suffix={<>
                                        {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                                    </>}
                                />

                                <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataListeners()}><IconUpdate /></Button>
                            </Flex>
                        </Flex>

                        <Divider variant="dark" />

                        <Table.Container loadingTitle="Загрузка...">
                            <Table.Header id='hiddenForMobile' classGridTemplateColumns="applicationsPromo_grid_columns" marginLeft="76px" marginRight="10px">
                                <span>ФИО слушателя<br />Телефон слушателя</span>
                                <span className='hiddenFor550'>Комментарий<br />Часы / Программа</span>
                                <span className="table_column_data_center hiddenFor750">Статус заявки</span>
                                <span className="hiddenFor950 table_column_data_center">Дата создания</span>
                                <span ref={tableHeadNavRef}></span>
                            </Table.Header>

                            <Table.Body>
                                {applicationsList.length === 0
                                    ? <Empty image={imgEmty} title="В архиве работы отсутствуют" />
                                    : applicationsList.map((item, index) => (
                                        <Table.Row key={index} indexContent={<span>{item.appTmpID}</span>} indexContentWidth="68px" paddingInset="0px 0 0px 8px" classGridTemplateColumns="applicationsPromo_grid_columns">
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.clientName}</p>
                                                <p style={{ opacity: '.7' }}>{item.clientPhone}</p>
                                            </div>

                                            <div className="hiddenFor550" style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.comment || '--------'}</p>

                                                <div style={{ opacity: '.7' }}>
                                                    <span>{item.volumeHours || '--'}</span>
                                                    <span> / </span>
                                                    <span>{item.courseName || '--------'}</span>
                                                </div>
                                            </div>


                                            <span className='table_column_data_center hiddenFor750' style={{ color: item.applicationStatusColor }}>{item.applicationStatusName}</span>

                                            <span id='hiddenForMobile' className='table_column_data_center hiddenFor950'>
                                                {moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}
                                            </span>

                                            <nav ref={tableNavRef}>
                                                <Button id='tooltipBtnSendedTelegram' variant="icon" size="xs" onClick={() => handlerSendToTelegram(item.appTmpID)}><IconTelegram /></Button>
                                            </nav>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table.Container>

                        <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                            <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br />🗸 🗸 Имени<br />🗸 Телефону<br />🗸 Email<br />🗸 Идентификатору заявки<br />🗸 Наименованию курса<br />🗸 Комментарию</ToolTip>

        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />

        <ToolTip anchorSelect="#tooltipBtnSendedTelegram" content='Отправить в telegram канал 👉 ИКТ - Промо МАКПП' place="bottom-end" />
    </Drawer>
}