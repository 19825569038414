import moment from "moment"
import Dropzone from "react-dropzone"

import { ICardDeliveryTabPrimary } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconDiplom, IconDownload, IconJobs, IconLink, IconScanFile, IconTelegram } from "images/icons"

import { Empty, Flex, Toogle, ToolTip, Textarea, Input, Divider, Button, Table } from "components/ui"


/** Вкладка основная */
export const CardDeliveryTabPrimary = ({
    fieldsError,
    txtDeliveryToWhom, txtDeliveryAddress, txtIsAddressConfirmed, txtComment,
    listFiles, listListeners, listTrackNumbers,
    handlerOpenModalPreview, handlerDownloadFiles, handlerSendingTrackNumbers, 
    handlerSendingListenerFiles, handlerFileUploader, handlerOpenModalTrackNumber
}: ICardDeliveryTabPrimary) => {
    return <>
        <Flex gap="20px">
            <Flex gap="5px">
                <Divider prefix={<h4>Информация о доставке</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.deliveryToWhom ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Кому (Ф.И.О.) <p>✸</p></Flex>
                            <Input placeholder="Кому (Ф.И.О.)" {...txtDeliveryToWhom} />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.deliveryAddress ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Куда (Адресс доставки) <p>✸</p></Flex>
                            <Input placeholder="Куда (Адресс)" paddingRight={3} {...txtDeliveryAddress} 
                                suffix={<Flex gap="4px" direction="row">
                                    <Toogle checked={txtIsAddressConfirmed.value} onChange={() => txtIsAddressConfirmed.setValue(!txtIsAddressConfirmed.value)} />
                                </Flex>}
                            />
                            <p style={{color: 'red'}}><b>Внимание!</b> В начале должен быть <b>ИНДЕКС</b> места назначения.</p>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px" className="card_style">
                <Dropzone onDropAccepted={(files) => handlerFileUploader(files)} multiple={true}>
                    {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                        <div {...getRootProps({ className: isDragAccept ? `dropZone_container dropZone_dragAccept` : `dropZone_container`, onClick: event => event.stopPropagation() })} style={{ gap: '4px', display: 'flex', flexDirection: 'column' }}>
                            <input {...getInputProps()} />

                            <Divider prefix={<h4>Сканы файлов</h4>}
                                suffix={
                                    <Dropzone onDropAccepted={(files) => handlerFileUploader(files)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button size='sm' padding={'0 10px'}>Загрузить</Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                }
                            />

                            <Table.Body overflowY="hidden">
                                {listFiles.value.length === 0
                                    ? <Empty image={imgEmty} size='60px' title='Файлы отсутствуют' />
                                    : listFiles.value.map((item, index) => (
                                        <Table.Row key={index} indexContent={index + 1} indexContentWidth="30px" classGridTemplateColumns="deliveryCardFiles_grid_columns" onClick={() => handlerOpenModalPreview(true, item.filePath, item.fileName)}>
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.fileName}</p>
                                                <p style={{ opacity: '.8' }}>Размер файла: {item.fileSize}</p>
                                            </div>

                                            <span className="hiddenForMobile table_column_data_center">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>

                                            <nav>
                                                <Button id='tooltipBtnDawnload' variant="icon" size="xs" onClick={(e) => { handlerDownloadFiles(item.fileName, item.filePath); e.stopPropagation() }}><IconDownload /></Button>
                                            </nav>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </div>
                    )}
                </Dropzone>
            </Flex>

            <Flex gap="5px" className="card_style">
                <Divider prefix={<h4>Трек-номера</h4>} suffix={<Button size="sm" padding='0 10px' onClick={() => handlerOpenModalTrackNumber(true, 'new')}>Создать</Button>} />

                <Table.Body overflowY="hidden">
                    {listTrackNumbers.value.length === 0
                        ? <Empty image={imgEmty} size='60px' title='Трек-номера отсутствуют' />
                        : listTrackNumbers.value.map((item, index) => (
                            <Table.Row key={index} indexContent={index + 1} indexContentWidth="30px" classGridTemplateColumns="deliveryCardFiles_grid_columns" 
                                onClick={() => handlerOpenModalTrackNumber(true, 'edit', item.deliveryID, item.trackNumber, item.comment)}>
                                <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.trackNumber}</p>
                                    <p style={{ opacity: '.8' }}>{item.comment}</p>
                                </div>

                                <span className="hiddenForMobile table_column_data_center">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>

                                <nav>
                                    <a href={`https://www.pochta.ru/tracking#${item.trackNumber}`} target='_blank' rel="noreferrer">
                                        <Button id='tooltipBtnTrackNumberLink' variant="icon" size="xs"><IconLink /></Button>
                                    </a>

                                    <Button id='tooltipBtnTrackNumberSend' variant="icon" size="xs" 
                                        onClick={(e) => { handlerSendingTrackNumbers(item.trackNumber); e.stopPropagation() }}
                                    ><IconTelegram /></Button>
                                </nav>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Flex>

            <Flex gap="5px" className="card_style">
                <Divider prefix={<h4>Слушатели по договору</h4>} />

                <Table.Body overflowY="hidden">
                    {listListeners.value.length === 0
                        ? <Empty image={imgEmty} size='60px' title='Слушатели отсутствуют' />
                        : listListeners.value.map((item, index) => (
                            <Table.Row key={index} indexContent={<Flex gap="6px" direction="row" alignItems="center" justifyContent="center">
                                <span id='tooltipBtnJob' style={{ cursor: 'help' }}><IconJobs width={18} height={18} color={item.isFinalExamination === 1 ? '#0edd1e' : 'red'} /></span>
                                <span id='tooltipBtnPrint' style={{ cursor: 'help' }}><IconDiplom width={18} height={18} color={item.isPrint === 1 ? '#0edd1e' : 'red'} /></span>
                                <span id='tooltipBtnScan' style={{ cursor: 'help' }}><IconScanFile width={16} color={item.isScan === 1 ? '#0edd1e' : 'red'} /></span>
                            </Flex>} indexContentWidth="84px" classGridTemplateColumns="deliveryCardListeners_grid_columns">
                                <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.lastName} {item.firstName} {item.secondName}</p>
                                    <p style={{ opacity: '.8' }}>{item.phoneNumber} / {item.email || '----'}</p>
                                </div>

                                <div className="hiddenForMobile" style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.educationLevelName}</p>
                                    <p style={{ opacity: '.8' }} className='tRow-item-name-secondary'>{item.contractTypeNameShort} / {item.documentTypeNameShort} / {item.courseName}</p>
                                </div>

                                <span className="hiddenFor750 table_column_data_center">{item.dateEducationStart}<br />{item.dateEducationEnd}</span>

                                <nav>
                                    <Button id='tooltipBtnListenerSend' variant="icon" size="xs" 
                                        onClick={(e) => { handlerSendingListenerFiles(item.contractListenerID); e.stopPropagation() }}
                                    ><IconTelegram /></Button>
                                </nav>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Комментарий</h4>} />
                <Textarea placeholder="Комментарий" rows={5} value={txtComment.value} onChange={(e) => txtComment.setValue(e.target.value)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnAddressOK" content='Адресс подтверждён' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnAddressNot" content='Адресс не подтверждён' place="bottom-end" />
        
        <ToolTip anchorSelect="#tooltipBtnDawnload" content='Скачать файл' place="left" />
        
        <ToolTip anchorSelect="#tooltipBtnTrackNumberLink" content='Проверить статус доставки' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnTrackNumberSend" content='Отправить в telegram 👉 ИКТ - Доставка' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnListenerSend" content='Отправить в telegram 👉 ИКТ - Сканы дипломов' place="bottom-end" />

        <ToolTip anchorSelect="#tooltipBtnJob" content='Итоговая работа' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPrint" content='Печать' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnScan" content='Скан' place="bottom-start" />
    </>
}