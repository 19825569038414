import imgEmty from 'images/empty.svg'
import { Empty, Flex } from 'components/ui'


/** Страница не найдена */
export const Page404 = () => {
    return <>
        <Flex alignItems='center' justifyContent='center' flexGrow={1} height='100%'>
            <Empty image={imgEmty} />
        </Flex>
    </>
}