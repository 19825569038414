import { ICardContractProgram } from "./_types"
import CommonService from "services/common.service"

import imgEmty from 'images/empty.svg'
import { IconCalendar, IconClearClose, IconSelectData } from "images/icons"

import { Empty, Flex, ToolTip, Modal, Loader, Input, Divider, Button, Table } from "components/ui"


/** Окно добавления или редактирования программы по договору */
export const CardContractProgram = ({
    loadingTitle,
    isOpen, cardMode, handlerButtonCancel,
    handleOpenModalCalendar, handleOpenModalDict, txtEducationDateStartOnChange, 
    handleClickProgramList, txtDurationEducationOnChange, handleBtnSave,
    isLoadingDict, courseProgramList,
    isLoadingCard, fieldsError,
    txtCourseName, txtProgramType,
    txtDurationEducation, txtVolumeHours, txtSumm,
    txtEducationDateStart, txtEducationDateEnd,
    txtSphere, txtQualification, txtRequirementName,
}: ICardContractProgram) => {
    const formatter = new Intl.NumberFormat('ru-RU')

    const getBtnSelect = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    }
    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }


    return <>
        <Modal isOpen={isOpen} isEsc maxWidth="640px" className="modalMobile_content" overflowY="auto" onCancel={handlerButtonCancel}>
            <Flex className='modal_container' width="100%">
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>{cardMode === 'new' ? 'Добавление программы' : 'Редактирование программы'}</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body maxHeight'>
                    <Flex gap="10px" className='modal_container-content' overflow="auto">
                        <div style={{color: 'red', fontSize: '14px', lineHeight: '150%', letterSpacing: '1px'}}>
                            <p>1. Выбери <b>наименование курса</b></p>
                            <p>2. Выбери программу из <b>справочная информация</b> 👇</p>
                            <p>3. После можешь изменить <b>тип программы, сумму, начало и конец обучения</b></p>
                            <p>~~~~</p>
                            <p><b>Тип программы</b> желательно менять только на <b>сертификат</b>, в остальных случаях, пользуйся разделом <b>справочная информация</b></p>
                        </div>

                        <Flex gap="5px">
                            <Divider prefix={<h4>Основные данные</h4>} />

                            <Flex gap='10px'>
                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.courseName ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Наименование курса <p>✸</p></Flex>
                                        <Input placeholder="Наименование курса" isReadOnly paddingRight={4} {...txtCourseName}
                                            suffix={getBtnSelect(() => handleOpenModalDict(true, 'coursesContract'))}
                                        />
                                    </Flex>
                                </Flex>

                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.programType ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Тип программы <p>✸</p></Flex>
                                        <Input placeholder="Тип программы" isReadOnly paddingRight={4} {...txtProgramType}
                                            suffix={getBtnSelect(() => handleOpenModalDict(true, 'documentTypes'))}
                                        />
                                    </Flex>
                                </Flex>

                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                    <Flex flexGrow={1} flexBasis={190} className={!fieldsError.value.durationEducation ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Продолжительность <p>✸</p></Flex>
                                        <Input placeholder="Продолжительность" paddingRight={4} isReadOnly
                                            value={txtDurationEducation.value} onChange={(e) => txtDurationEducationOnChange(e.target.value)}
                                        />
                                    </Flex>

                                    <Flex flexGrow={1} flexBasis={190} className={!fieldsError.value.volumeHours ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Объём <p>✸</p></Flex>
                                        <Input placeholder="Объём" paddingRight={4} isReadOnly {...txtVolumeHours} />
                                    </Flex>

                                    <Flex flexGrow={1} flexBasis={190} className={!fieldsError.value.summ ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Сумма <p>✸</p></Flex>
                                        <Input placeholder="Сумма" paddingRight={4} {...txtSumm} />
                                    </Flex>
                                </Flex>

                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                    <Flex flexGrow={1} flexBasis={190} className={!fieldsError.value.educationDateStart ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Начало обучения <p>✸</p></Flex>
                                        <Input placeholder="Начало обучения" paddingRight={4} type='mask' mask='99.99.2099' maskChar=""
                                            suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'educationDateStart', txtEducationDateStart.value))}
                                            value={txtEducationDateStart.value} onChange={(e) => txtEducationDateStartOnChange(e.target.value)}
                                        />
                                    </Flex>

                                    <Flex flexGrow={1} flexBasis={200} className={!fieldsError.value.educationDateEnd ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex direction="row" className="input_header">Конец обучения <p>✸</p></Flex>
                                        <Input placeholder="Конец обучения" paddingRight={4} type='mask' mask='99.99.2099' maskChar=""
                                            suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'educationDateEnd', txtEducationDateEnd.value))}
                                            value={txtEducationDateEnd.value} onChange={(e) => txtEducationDateEnd.setValue(e.target.value)}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex gap="5px">
                            <Divider prefix={<h4>Справочная информация</h4>} />

                            <Table.Body overflowY="hidden">
                                <Loader isOpen={isLoadingDict} color='#f34e2e' title='Загрузка информации...' backdrop />

                                {!courseProgramList.value.length 
                                    ? <Empty image={imgEmty} size='60px' title='Информация отсутствуют' />
                                    : courseProgramList.value.map((item, index) => (
                                        <Table.Row key={item.courseProgramID} indexContent={index + 1} indexContentWidth="30px" paddingInset="2px 8px" onClick={() => handleClickProgramList(item.courseProgramID, item.durationEducation, item.volumeHours, item.sum, item.programID, item.programName)}>
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.programID === 1 ? 'Диплом' : 'Удостоверение'} - {item.programName}</p>
                                                <p style={{ opacity: '.8' }}>{CommonService.formatDuration(Number(item.durationEducation), item.programID === 2)} 👉 {CommonService.formatHours(Number(item.volumeHours))} 👉 {formatter.format(Number(item.sum))} ₽</p>
                                            </div>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Flex>

                        <Flex gap="5px">
                            <Divider prefix={<h4>Прочее</h4>} />

                            <Flex gap='10px'>
                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.sphere ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Сфера <p>✸</p></Flex>
                                        <Input placeholder="Сфера" paddingRight={4} {...txtSphere} />
                                    </Flex>
                                </Flex>

                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.qualification ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Квалификация <p>✸</p></Flex>
                                        <Input placeholder="Квалификация" paddingRight={4} {...txtQualification} />
                                    </Flex>
                                </Flex>

                                <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap" isVisible={false}>
                                    <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.requirementName ? 'input_error' : 'input_error input_error-active'}>
                                        <Flex gap="5px" direction="row" className="input_header">Требование к образованию <p>✸</p></Flex>
                                        <Input placeholder="Требование к образованию" isReadOnly paddingRight={4} {...txtRequirementName} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Divider />
                    <Button width='100%' onClick={handleBtnSave}>
                        {cardMode === 'new' ? 'Добавить программу' : 'Применить изменения'}
                    </Button>

                    <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadingTitle.value} backdrop />
                </Flex>
            </Flex>
        </Modal>

        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
    </>
}