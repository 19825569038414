import React, { memo } from 'react'

import { ICard } from './type'
import css from './index.module.scss'


/** Карточка */
export const Card = memo(({id, mainPadding, header, children, headerPosition = 'top', headerWidth, headerPadding, headerAlignItems, onClick, isVisible}: ICard): React.JSX.Element => {

    const getHeaderPosition = () => {
        switch (headerPosition) {
            case 'top': return 'column'
            case 'right': return 'row-reverse'
            case 'bottom': return 'column-reverse'
            case 'left': return 'row'
        }
    }

    const elementStyle: React.CSSProperties = {
        display: 'flex',
        padding: mainPadding,
        alignItems: headerAlignItems,
        flexDirection: getHeaderPosition()
    }


    return <div id={id} className={css.card} style={elementStyle} onClick={onClick}>
        <header className={css.header} style={{width: headerWidth, padding: header ? headerPadding || '4px 6px' : headerPadding}}>{header}</header>
        
        <div className={css.content}>{children}</div>
    </div>
})