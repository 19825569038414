import { ICardApplicationTabHelp } from "./_types"
import CommonService from "services/common.service"

import { IconCopy } from "images/icons"
import { Flex, ToolTip, Divider, Card, Button } from "components/ui"



/** Вкладка инфармации и помощи по курсу для оператора */
export const CardApplicationTabHelp = ({
    programsList, txtClientName, txtClientPhone, txtClientEmail, txtAttestationType, txtProgramTypeID, 
    txtDurationEducation, txtVolumeHours, txtEducationDateStart, txtEducationDateEnd, txtProgramSum,
    handlerCopyToClipBoard
}: ICardApplicationTabHelp) => {
    const programPK = programsList.value.filter((fil) => fil.programID === 1)
    const programPP = programsList.value.filter((fil) => fil.programID === 2)

    const pageCanonical = programsList.value[0]?.pageCanonical || ''
    const requirementName = programsList.value[0]?.requirementName || ''
    const qualification = programsList.value[0]?.qualification || ''
    const sphere = programsList.value[0]?.sphere || ''

    return <>
        <Flex gap="20px">
            <Flex gap="5px">
                <Divider prefix={<h4>Ссылка на курс</h4>} />

                <Card>
                    <a href={pageCanonical} target="_blank" rel="noreferrer" className="link_style">{pageCanonical}</a>
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Информация о клиенте</h4>} />

                <Card header='Имя' headerWidth="100px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">{txtClientName.value}</Card>
                <Card header='Телефон' headerWidth="100px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center"><a href={`tel:${txtClientPhone.value.replaceAll(' ', '')}`} className="link_style">{txtClientPhone.value}</a></Card>
                <Card header='Email' headerWidth="100px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">{txtClientEmail.value ? <a href={`mailto:${txtClientEmail.value}`} className="link_style">{txtClientEmail.value}</a> : '----'}</Card>
                <Card header='Написать в' headerWidth="100px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">
                    <a target="_blank" href={`https://wa.me/${txtClientPhone.value.replaceAll(' ', '')}`} rel="noreferrer" className="link_style">WhatsApp</a>
                </Card>
                <Card header='Написать в' headerWidth="100px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">
                    <a href={`viber://chat?number=${txtClientPhone.value.replace('+', '%2B').replaceAll(' ', '')}`} rel="noreferrer" className="link_style">Viber</a>
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Условия обучения</h4>} />

                <Card header='Обучение' mainPadding="2px">Дистанционное на нашей образовательной платформе в системе Мудл (Moodle)</Card>
                <Card header='Требования к образованию' mainPadding="2px">{requirementName}</Card>
                <Card header='Форма контроля' mainPadding="2px" >Защита выпускной работы</Card>
                <Card header='Тип аттестации' mainPadding="2px">{txtAttestationType.value}</Card>
                <Card header='Длительность' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">
                    {CommonService.formatDuration(Number(txtDurationEducation.value), txtProgramTypeID.value === 2)}
                </Card>
                <Card header='Объём' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">
                    {CommonService.formatHours(Number(txtVolumeHours.value))}
                </Card>
                <Card header='Начало' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">{txtEducationDateStart.value}</Card>
                <Card header='Окончание' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">{txtEducationDateEnd.value}</Card>
                <Card header='Стоимость' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">{txtProgramSum.value} ₽</Card>
                <Card header='Помесячная' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">
                    {Math.round(Number(txtProgramSum.value) / Number(txtDurationEducation.value))} ₽/мес.
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Документы</h4>} />

                <Card header='Преподаватели' mainPadding="2px">Курс подготовлен ведущими преподавателями ВУЗов</Card>
                <Card header='Квалификация' mainPadding="2px">
                    {txtProgramTypeID.value === 1 && `Диплом о профессиональной переподготовке с присвоением квалификации ${qualification}, в сфере ${sphere}`}
                    {txtProgramTypeID.value === 2 && `Удостоверение о повышении квалификации с присвоением квалификации ${qualification}, в сфере ${sphere}`}
                </Card>
                <Card header='Прочее' mainPadding="2px">
                    <Flex gap="6px">
                        <p>Документы установленного образца, с 2013 года с принятием нового закона об образовании в РФ для профессиональной переподготовки и повышения квалификации.</p>
                        <p>Документы установленного образца, поэтому никому не верьте, что диплом о проф. переподготовке будет гос. образца.</p>
                    </Flex>
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Материалы курса</h4>} />

                <Card header='Для куратора' headerWidth="120px" mainPadding="0 0 0 2px" headerPosition="left" headerAlignItems="center">Видео пока нет</Card>
                <Card header='Прочее' mainPadding="2px">
                    Обучение дистанционное, на нашей образовательной платформе, материал представлен в виде лекций и заданий по дисциплинам учебного плана.
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Пакет документов для налогового вычета</h4>} />

                <Card header='Желательно говорить это вместе с информацией об отправке диплома по почте' mainPadding="2px">
                    <Flex gap="6px">
                        <p>Кроме того вы имеете возможность оформить налоговый вычет на наши образовательные Услуги.</p>
                        <p>Наша академия имеет официальную лицензию на осуществление образовательной деятельности.</p>
                        <p>Весь необходимый пакет документов для оформления вычета направляется Вам вместе с дипломом.</p>
                        <p>Максимальная сумма выплат по налоговому вычету на образование составляет 15 600 рублей.</p>
                    </Flex>
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>После заключения договора</h4>} />

                <Card header='Информация' mainPadding="2px">
                    <Flex gap="6px">
                        <p>После заключения договора мы предоставляем Вам доступ в личный кабинет.</p>
                        <p>В личном кабинете находится лекционный материал, задания и тесты по дисциплинам.</p>
                        <p>Вы проходите курс и пишите итоговую атестанционную работу по данному курсу (аналог дипломной работы) которая загружается в информационную образовательную среду.</p>
                    </Flex>
                </Card>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Доступные программы</h4>} />

                {programPK.length > 0 &&
                    <Card mainPadding="2px"
                        header={
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <div>Профессиональная переподготовка</div>
                                <Button id='tooltipBtnCopy' size="xs" variant="icon"
                                    onClick={() => handlerCopyToClipBoard(`
                                        ${programPK.map((item) => (
                                            `${CommonService.formatHours(Number(item.volumeHours))}; ${item.sum} рублей; ${CommonService.formatDuration(Number(item.durationEducation), false)}; ${item.price} рублей в месяц;`
                                        )).join('\n')}
                                    `)}
                                ><IconCopy /></Button>
                            </Flex>
                        }
                    >
                        <Flex gap="6px">
                            {programPK.map((item) => (
                                <Card key={item.volumeHours}>
                                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                                        <div>{CommonService.formatHours(Number(item.volumeHours))}; {item.sum} рублей; {CommonService.formatDuration(Number(item.durationEducation), false)}; {item.price} рублей в месяц;</div>
                                        <Button id='tooltipBtnCopy' size="xs" variant="icon" onClick={() => handlerCopyToClipBoard(`${CommonService.formatHours(Number(item.volumeHours))}; ${item.sum} рублей; ${CommonService.formatDuration(Number(item.durationEducation), false)}; ${item.price} рублей в месяц;`)}><IconCopy /></Button>
                                    </Flex>
                                </Card>
                            ))}
                        </Flex>
                    </Card>
                }

                {programPP.length > 0 &&
                    <Card mainPadding="2px" 
                        header={
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <div>Повышение квалификации</div>
                                <Button id='tooltipBtnCopy' size="xs" variant="icon" 
                                    onClick={() => handlerCopyToClipBoard(`
                                        ${programPP.map((item) => (`${CommonService.formatHours(Number(item.volumeHours))}; ${item.sum} рублей; ${CommonService.formatDuration(Number(item.durationEducation), true)}`)).join('\n')}
                                    `)}
                                ><IconCopy /></Button>
                            </Flex>
                        }
                    >
                        <Flex gap="6px">
                            {programPP.map((item) => (
                                <Card key={item.volumeHours}>
                                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                                        <div>{CommonService.formatHours(Number(item.volumeHours))}; {item.sum} рублей; {CommonService.formatDuration(Number(item.durationEducation), true)};</div>
                                        <Button id='tooltipBtnCopy' size="xs" variant="icon" onClick={() => handlerCopyToClipBoard(`${CommonService.formatHours(Number(item.volumeHours))}; ${item.sum} рублей; ${CommonService.formatDuration(Number(item.durationEducation), true)};`)}><IconCopy /></Button>
                                    </Flex>
                                </Card>
                            ))}
                        </Flex>
                    </Card>
                }
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnCopy" content='Копировать в буфер' place="left" />
    </>
}