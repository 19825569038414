import { useContext, useEffect } from 'react'

import { CardContractProgramListener } from './_interface'
import { FieldsErrorType, ICardContractsProgramListener, ICardContractProgramListenerContainer, IFieldsError } from './_types'

import { useInput } from 'hooks/useInput'
import { validFormData } from 'hooks/useForm'

import { DictNameType, ISelectDictionaryContainer, SelectDictionaryContainer } from '../selectDictionary'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Редактирование свойств слушателя по договору - Контейнер */
export const CardContractProgramListenerContainer = ({
    isOpen, isAttestationTypeVisible, handlerButtonOk, handlerButtonCancel,
    attestationTypeID, attestationTypeName, qualification
}: ICardContractProgramListenerContainer) => {
    const notify = useContext(NotificationContext)

    const modalDict = useInput<ISelectDictionaryContainer>({isOpen: false})

    const fieldsError = useInput<IFieldsError>({})

    const txtAttestationTypeID = useInput<number | ''>('')
    const txtAttestationType = useInput<string>('')

    const txtQualification = useInput<string>('')

    useEffect(() => {
        if (isOpen) {
            txtAttestationTypeID.setValue(attestationTypeID || '')
            txtAttestationType.setValue(attestationTypeName || '')
            txtQualification.setValue(qualification || '')
        }

        !isOpen && clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const clearData = () => {
        fieldsError.setValue({})

        txtAttestationTypeID.setValue('')
        txtAttestationType.setValue('')
        txtQualification.setValue('')
    }


    /** Открытие/закрытие окна выбора справочника */
    const handleOpenModalDict = (isOpen: boolean, dictName: DictNameType) => {
        modalDict.setValue({
            isOpen, dictName,
            handlerButtonCancel() {modalDict.setValue({isOpen: false})}, 
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (dictName) {
                    case 'attestationType': {
                        txtAttestationTypeID.setValue(Number(id))
                        txtAttestationType.setValue(name)
                        break
                    }
                }

                modalDict.setValue({isOpen: false})
            }
        })
    }    


    /** Кнопка сохранить все данные */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'attestationType', value: txtAttestationTypeID.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Тип аттестации не выбрано.'},
            {fieldName: 'qualification', value: txtQualification.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Квалификация не указана.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnimation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            handlerButtonOk && handlerButtonOk(Number(txtAttestationTypeID.value), txtQualification.value)
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnimation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }


    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardContractsProgramListener = {
        isOpen, isAttestationTypeVisible, handlerButtonCancel,
        
        fieldsError, txtAttestationType, txtQualification,
        handleOpenModalDict, handleBtnSave
    }
    
    return <>
        <CardContractProgramListener {...propsToComponent} />

        <SelectDictionaryContainer {...modalDict.value} />
    </>
}