import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { ICompanyFormSendedData, IResponseCompanyCheckField, IResponseListenerFiles, IResponseCompanies, IResponseCompaniesCard } from "./companies.types"


const route = '/companies'

/** Проверка по ИНН, существование контрагента */
export const getCompanyCheckINN = async (companyID: number, inn: string) => {
    let response: IResponseCompanyCheckField

    try {
        const res: AxiosResponse<IResponseCompanyCheckField, AxiosError> = await api.get(`${route}/getCompanyCheckINN`, {params: {companyID, inn}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCompanyCheckField
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Проверка по КПП, существование контрагента */
export const getCompanyCheckKPP = async (companyID: number, kpp: string) => {
    let response: IResponseCompanyCheckField

    try {
        const res: AxiosResponse<IResponseCompanyCheckField, AxiosError> = await api.get(`${route}/getCompanyCheckKPP`, {params: {companyID, kpp}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCompanyCheckField
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение списка контрагентов */
export const getCompanies = async (pageNumber: number, search?: string) => {
    let response: IResponseCompanies

    try {
        const res: AxiosResponse<IResponseCompanies, AxiosError> = await api.get(`${route}/getCompanies`, {params: {pageNumber, search}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCompanies
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка файлов контрагента */
export const getCompanyFiles = async (companyID: number) => {
    let response: IResponseListenerFiles

    try {
        const res: AxiosResponse<IResponseListenerFiles, AxiosError> = await api.get(`${route}/getCompanyFiles?companyID=${companyID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseListenerFiles
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение данных по анкете контрагента */
export const getCompanyCard = async (companyID: number) => {
    let response: IResponseCompaniesCard

    try {
        const res: AxiosResponse<IResponseCompaniesCard, AxiosError> = await api.get(`${route}/getCompanyCard`, {params: {companyID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCompaniesCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете контрагента */
export const setCompanyCardSave = async (companyID: number, data: ICompanyFormSendedData, files?: File[]) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(
            `${route}/setCompanyCardSave?companyID=${companyID}`, 
            { companyData: JSON.stringify(data), docs: files }, 
            { headers: { "Content-Type": `multipart/form-data` } }
        )

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Удаление файлов контрагента */
export const setCompanyDeleteFiles = async (companyID: number, fileID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.delete(`${route}/setCompanyDeleteFiles?companyID=${companyID}&fileID=${fileID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Загрузка файлов контрагента */
export const setCompanyUploadFiles = async (companyID: number, files: File[]) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(
            `${route}/setCompanyUploadFiles?companyID=${companyID}`, { docs: files }, { headers: { "Content-Type": `multipart/form-data` } }
        )

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
