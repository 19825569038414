import React, { memo, useEffect } from 'react'

import { IDrawerType } from './type'
import css from './index.module.scss'

/** Компонент Выдвижной панели (Drawer).
* @property {boolean} isOpen - Состояние панели, , открытое или закрытое.
* @property {boolean} isEsc - Метка о допуске закрытия окна, при нажатии на кнопку Esc.
* @property {boolean} isWrapper - Метка о допуске закрытия окна, при клике вне области окна.
* @property {React.ReactNode} children - Дочерние элементы панели.
* @property {string} size - Размер панели.
* @property {string} placement - Расположение панели (справа, слева, сверху, снизу).
* @property {number} zIndex - Значение z-индекса панели.
* @property {() => void} onOk - Функция, которая будет вызываться, при нажатии на клавишу Enter.
* @property {() => void} onCancel - Функция, которая будет вызываться, при закрытии окна (необходима при использовании свойств: isEsc, isWrapper).
*/
export const Drawer = memo(({ 
    isOpen, isEsc = false, isWrapper = false, children, size = '400px', placement = 'right', zIndex = 1000, maxWidth, maxHeight, onOk, onCancel 
}: IDrawerType): React.JSX.Element => {
    useEffect(() => {
        let handleEsc: (event: KeyboardEvent) => void
        let handleEnter: (event: KeyboardEvent) => void

        if (isOpen) {
            handleEsc = (event: KeyboardEvent) => {
                if (isEsc && event.keyCode === 27) {
                    onCancel && onCancel()
                    document.body.style.overflow = 'unset'
                }
            }

            handleEnter = (event: KeyboardEvent) => {
                if (event.keyCode === 13) {
                    onOk && onOk()
                    document.body.style.overflow = 'unset'
                }
            }

            document.addEventListener('keydown', handleEsc)
            document.addEventListener('keydown', handleEnter)
        }

        return () => {
            document.removeEventListener('keydown', handleEsc)
            document.removeEventListener('keydown', handleEnter)
        }
    }, [isOpen, isEsc]) // eslint-disable-line react-hooks/exhaustive-deps

    const handlerCloseModal = () => {
        if (isWrapper) {
            onCancel && onCancel()
            document.body.style.overflow = 'unset'
        }
    }

    const transformValues = {
        left: { open: 'translateX(0)', closed: 'translateX(-101%)' },
        right: { open: 'translateX(0)', closed: 'translateX(101%)' },
        top: { open: 'translateY(0)', closed: 'translateY(-101%)' },
        bottom: { open: 'translateY(0)', closed: 'translateY(101%)' }
    }
    
    const drawerWrapperStyle: React.CSSProperties = {
        zIndex: zIndex,
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'auto' : 'none',
    }

    const drawerWrapperContentStyle: React.CSSProperties = {
        width: ['left', 'right'].includes(placement) ? size : '',
        height: ['top', 'bottom'].includes(placement) ? size : '',
        transform: isOpen ? transformValues[placement].open : transformValues[placement].closed,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        zIndex: zIndex,
    }

    return (
        <div className={css['drawer-wrapper']} style={drawerWrapperStyle} onClick={handlerCloseModal}>
            <div className={`${css['drawer-wrapper-content']} ${css[`drawer-${placement}`]}`} style={drawerWrapperContentStyle} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
})