import { memo, useEffect, useState } from "react"
import moment from "moment"
import { Calendar } from '@natscale/react-calendar'

import { IconClearClose } from "images/icons"
import { Flex, Modal, Button } from "components/ui"


export interface IModalCalendar {
    isOpen: boolean
    currentDate?: string

    handlerButtonOk?: (stringDate: string) => void
    handlerButtonCancel?: () => void
}


/** Окно выбора даты из календаря */
export const ModalCalendar = memo(({isOpen, currentDate, handlerButtonCancel, handlerButtonOk}: IModalCalendar): JSX.Element => {
    const [selectedDateToCalendar, setSelectedDateToCalendar] = useState<Date | undefined>(undefined)

    useEffect(() => {
        let defaultDate: Date = new Date()

        if (isOpen) {
            if (currentDate) {
                const [day, month, year] = currentDate.split('.')
                defaultDate = new Date(Number(year), Number(month) - 1, Number(day))
            }
            setSelectedDateToCalendar(defaultDate)
        }

        return () => {
            setSelectedDateToCalendar(undefined)
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const monthsLabel = {
        0: 'Январь',
        1: 'Февраль',
        2: 'Март',
        3: 'Апрель',
        4: 'Май',
        5: 'Июнь',
        6: 'Июль',
        7: 'Август',
        8: 'Сентябрь',
        9: 'Октябрь',
        10: 'Ноябрь',
        11: 'Декабрь',
    }

    const weekDaysLabel = {
        0: 'ВС',
        1: 'ПН',
        2: 'ВТ',
        3: 'СР',
        4: 'ЧТ',
        5: 'ПТ',
        6: 'СБ',
    }

    return <Modal isOpen={isOpen} isEsc isWrapper onCancel={handlerButtonCancel} overflowY="hidden" maxWidth="min-content">
        <Flex className='modal_container'>
            <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                <h4>Выбор даты...</h4>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Flex className='modal_container-content'>
                    <Calendar className='rrc_root'
                        weekDaysLabel={weekDaysLabel} monthsLabel={monthsLabel}
                        value={selectedDateToCalendar}
                        onChange={(e: any) => handlerButtonOk && handlerButtonOk(moment(e).format('DD.MM.YYYY'))} 
                    />
                </Flex>
            </Flex>
        </Flex>
    </Modal>
})