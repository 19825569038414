import { useEffect, useRef } from "react"

import { IFinalExaminationArchive } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconClearClose, IconContracts, IconInfo, IconJobs, IconListeners, IconSearch, IconUpdate } from "images/icons"

import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Drawer, Divider, Button, Table } from "components/ui"


/** Архив итоговых работ слушателей */
export const FinalExaminationArchive = ({
    isOpen, isLoading, totalCount, listenersList, loadingTitle, txtSearch, archiveCount, countUnverifiedWorks, currentPageNumber, loadDataListeners, pageSize, txtSearchOnChange,
    handlerButtonCancel, handleOpenCardContract, handleOpenCardListener, handlerOpenModalConfirm
}: IFinalExaminationArchive) => {
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth
            tableHeadNavRef.current.style.width = `${width}px`
        }
    }, [listenersList, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconJobs color='#fff' width={20} height={20} />
                        <h4>Архив итоговых работ ({totalCount})</h4>
                    </Flex>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Flex gap='6px' className='drawer_container-content' overflow='hidden' padding='2px 0px 0px 0px'>
                    <Flex gap="3px" className="page_container">
                        <Loader isOpen={isLoading} backdrop color="#f34e2e" title={loadingTitle.value} />

                        <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                            <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                                <IconJobs width={28} height={28} />
                                <h4>Итоговые работы ({totalCount})</h4>
                            </Grid>

                            <Flex gap="6px" direction="row" className="page_header_for_mobile">
                                <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                                    value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                                    paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                                    suffix={<>
                                        {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                                    </>}
                                />

                                <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataListeners()}><IconUpdate /></Button>
                            </Flex>
                        </Flex>

                        <Divider variant="dark" />

                        <Table.Container loadingTitle="Загрузка...">
                            <Table.Header id='hiddenForMobile' classGridTemplateColumns="finalExamination_grid_columns" marginLeft="76px" marginRight="10px">
                                <span>Тип договора / ФИО слушателя<br />Документ / Аттестация / Долг / ИАР / Тест</span>
                                <span className=''>Программа<br />Тема итоговой работы</span>
                                <span className="hiddenFor950 table_column_data_center">№ и дата договора</span>
                                <span className="hiddenFor950 table_column_data_center">Начало обуч.<br />Конец обуч.</span>
                                <span ref={tableHeadNavRef} className="hiddenFor750"></span>
                            </Table.Header>

                            <Table.Body>
                                {listenersList.length === 0
                                    ? <Empty image={imgEmty} title="В архиве работы отсутствуют" />
                                    : listenersList.map((item) => (
                                        <Table.Row key={item.finalExaminationID} indexContent={<span>{item.applicationID}</span>} indexContentWidth="68px" paddingInset="0px 0 0px 8px"
                                            backgroundColor={`${item.defaulColorStatus}`} backgroundColorInset={`${item.contractStatusID === 6 && 'linear-gradient(0deg, #f9a696 0%, #fef6f4 100%)'}`} classGridTemplateColumns="finalExamination_grid_columns">
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.contractTypeName} 👉 {item.lastName} {item.firstName} {item.secondName}</p>
                                                <Flex gap='5px' direction='row'>
                                                    <div style={{ opacity: '.7' }}>
                                                        <span>{item.documentTypeName}</span> / <span>{item.attestationTypeName}</span> / <b style={{ color: `${item.dutyPayment > 0 && 'red'}` }}>{item.dutyPayment}</b> / <span style={{ color: `${(item.attestationTypeName === 'ВР' && !item.scoreIAR) && 'red'}` }}>{item.scoreIAR || 0}</span> / <span style={{ color: `${(item.attestationTypeName === 'ИТ' && !item.scoreTest) && 'red'}` }}>{item.scoreTest || 0}</span>
                                                    </div>
                                                </Flex>
                                            </div>

                                            <div className='hiddenForMobile' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.courseName}</p>
                                                <p style={{ opacity: '.7' }}>{item.themeIAR || '----'}</p>
                                            </div>

                                            <div className='hiddenFor950 table_column_data_center' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.contractNumber}</p>
                                                <p style={{ opacity: '.7' }}>{item.contractDate}</p>
                                            </div>

                                            <div className="hiddenFor950 table_column_data_center">
                                                <p>{item.dateEducationStart}</p>
                                                <p style={{ color: `${item.warningEndEducationDate === 1 && 'red'}`, fontWeight: `${item.warningEndEducationDate === 1 && '700'}` }}>{item.dateEducationEnd}</p>
                                            </div>

                                            <nav id='hiddenFor750' ref={tableNavRef}>
                                                <Button id='tooltipBtnCardContract' variant="icon" size="xs" onClick={(e) => { handleOpenCardContract(true, item.contractID); e.stopPropagation() }}><IconContracts /></Button>
                                                <Button id='tooltipBtnCardListener' variant="icon" size="xs" onClick={(e) => { handleOpenCardListener(true, item.listenerID); e.stopPropagation() }}><IconListeners /></Button>
                                                <Divider type="vertical" size="20px" />
                                                <Button id='tooltipBtnWithArchive' variant="icon" size="xs" onClick={(e) => { handlerOpenModalConfirm(true, 'Вернуть на обработку', `Вы действительно хотите вернуть в обработку следующего слушателя:<br />👉 ${item.lastName} ${item.firstName} ${item.secondName};<br />👉 Договор № ${item.contractNumber}.`, 'inArchive', item.finalExaminationID); e.stopPropagation() }}><IconJobs /></Button>
                                            </nav>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table.Container>

                        <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                            <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br />🗸 Фамилии<br />🗸 Имени<br />🗸 Отчеству<br />🗸 Телефону<br />🗸 Email<br />🗸 СНИЛС<br />🗸 Номеру договора<br />🗸 Идентификатору заявки<br />🗸 Наименованию курса</ToolTip>

        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />

        <ToolTip anchorSelect="#tooltipBtnCardContract" content='Анкета договора' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCardListener" content='Анкета слушателя' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnWithArchive" content='Врнуть на обработку' place="bottom-end" />
    </Drawer>
}