import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { CardContractGenerateDoc } from './_interface'
import { ICardContractGenerateDoc, ICardContractGenerateDocContainer, IContractDocumentsList } from './_types'

import { useInput } from 'hooks/useInput'
import { useDict } from 'store/dict/dict.state'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Контейнер окна по выбору документов для формирования */
export const CardContractGenerateDocContainer = ({isOpen, isCompany, handlerButtonOk, handlerButtonCancel}: ICardContractGenerateDocContainer) => {
    const notify = useContext(NotificationContext)

    const {isLoading, actionGetContractDocuments} = useDict(useShallow((state) => state))
    const documentsList = useInput<IContractDocumentsList[]>([])
    const txtSum = useInput<string>('')

    useEffect(() => {
        if (isOpen) {
            actionGetContractDocuments().then((res) => {
                if (res) {
                    documentsList.setValue(res.map((item) => ({
                        id: item.contractDocumentID,
                        name: item.contractDocumentName,
                        category: item.documentCategory,

                        isSelect: item.documentCategory === 'Основное' ? true : false
                    })))
                }
            })
        }

        !isOpen && clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Очистка формы */
    const clearData = () => {
        documentsList.setValue([])
    }

    /** Функция по выбору документов */
    const handlerSelectedDocuments = (docID: number) => {
        const newList = documentsList.value.map(item => {
            if (item.id === docID) {
                return { ...item, isSelect: !item.isSelect }
            }

            return item
        })
        
        documentsList.setValue(newList)
    }
    
    /** Кнопка сформировать */
    const handlerBtnSave = () => {
        const newList = documentsList.value.filter((item) => item.isSelect === true)
        if (newList.length === 0) return notify && notifyOpen('Необходимо выбрать документы для формирования.', 'warning', 2000, notify)
        if (isCompany && newList.filter((item) => item.id === 4).length === 1 && !txtSum.value) return notify && notifyOpen('Необходимо ввести сумму, на какую Вы хотите создать счёт.', 'warning', 2000, notify)

        handlerButtonOk && handlerButtonOk(newList, txtSum.value)
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardContractGenerateDoc = {
        isOpen, isCompany, handlerButtonCancel,
        isLoading, documentsList, txtSum,
        handlerSelectedDocuments, handlerBtnSave,
    }

    return <>
        <CardContractGenerateDoc {...propsToComponent} />
    </>
}