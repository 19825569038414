import { memo } from 'react'
import { NavLink } from 'react-router-dom'

import { Flex, Drawer, Divider, Button } from 'components/ui'
import { IconApplications, IconClearClose, IconContracts, IconDelivery, IconEntity, IconExitApp, IconJobs, IconKP, IconListeners, IconMessage, IconProfile, IconSettings } from 'images/icons'


/** Свойства главного мобильного меню */
export interface IDrawerMenu {
    isOpen: boolean
    
    handlerButtonCancel?: () => void
    handlerOpenModalExitProgram?: () => void
}


/** Главное мобильное меню */
export const DrawerMenu = memo(({isOpen, handlerButtonCancel, handlerOpenModalExitProgram}: IDrawerMenu): JSX.Element => {
    return <Drawer isOpen={isOpen} isEsc isWrapper onCancel={handlerButtonCancel} maxWidth='300px' placement='right'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <h4>Основное меню</h4>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Flex gap='6px' className='drawer_container-content'>
                    <NavLink to='applications' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconApplications />
                        <p>Заявки</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <NavLink to='commercialOffers' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconKP />
                        <p>Ком. предложения</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <NavLink to='companies' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconEntity />
                        <p>Контрагенты</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px' />

                    <NavLink to='listeners' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconListeners />
                        <p>Слушатели</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <NavLink to='contracts' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconContracts />
                        <p>Договора</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <NavLink to='finalExamination' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconJobs />
                        <p>Итоговые работы</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <NavLink to='delivery' className={({ isActive }) => isActive ? 'menu_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconDelivery />
                        <p>Доставка</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <NavLink to='settings' className={({ isActive }) => isActive ? 'navmenu_button_button menu_button-active' : 'menu_button'} onClick={handlerButtonCancel}>
                        <IconSettings />
                        <p>Администрирование</p>
                    </NavLink>

                    <Divider variant='dark' margin='8px'/>

                    <Button variant='icon' size='md' onClick={handlerButtonCancel}>
                        <IconMessage />
                        <p>Сообщения</p>
                    </Button>
                    
                    <Divider variant='dark' margin='8px'/>

                    <Button variant='icon' size='md' onClick={handlerButtonCancel}>
                        <IconProfile />
                        <p>Мой профиль</p>
                    </Button>
                    
                    <Divider variant='dark' margin='8px'/>
                    
                    <Button variant='icon' size='md' onClick={() => {handlerOpenModalExitProgram && handlerOpenModalExitProgram(); handlerButtonCancel && handlerButtonCancel()}}>
                        <IconExitApp />
                        <p>Выход</p>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    </Drawer>
})