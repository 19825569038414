import { NavLink } from 'react-router-dom'

import { IMain } from './_types'
import MainRoutes from '../../routes/main.routes'

import { DrawerMenu } from 'components/dumb/drawer.menu'
import { ModalConfirm } from 'components/dumb/modal.confirm'

import logo from 'images/logo.png'
import { IconApplications, IconContracts, IconDelivery, IconEntity, IconExitApp, IconJobs, IconKP, IconListeners, IconMenu, IconMessage, IconProfile, IconSettings } from 'images/icons'

import { Flex, ToolTip, Divider, Button, Badge } from 'components/ui'


/** Главная навигационная страница */
export const Main = ({
    modalConfirm, handlerOpenModalConfirm, drawerMenu, handlerOpenDrawerMenu,
    countNewApplications, countNewContracts, countAccessContracts, countUnverifiedWorks,
    countNewPrintDiplom, countDeliveryAddress, countDeliveryFinished,
}: IMain) => {
    return <>
        <DrawerMenu {...drawerMenu.value} />
        <ModalConfirm {...modalConfirm.value} />

        <Flex gap='2px' direction='row' className='main_container'>
            <Flex id='mainMenu' gap='20px' alignItems='center' className='main_container-nav'>
                <img src={logo} alt="Логотип организации" />

                <Flex gap='6px' alignItems='center' className='main_container-nav_main'>
                    <NavLink to='applications' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Заявки">
                        <IconApplications />
                        <Badge isVisible={Number(countNewApplications.value) > 0}>{countNewApplications.value}</Badge>
                    </NavLink>

                    <NavLink to='commercialOffers' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Ком. предложения">
                        <IconKP />
                    </NavLink>

                    <Divider variant='light' size='1.5em' />

                    <NavLink to='companies' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Контрагенты">
                        <IconEntity />
                    </NavLink>

                    <NavLink to='listeners' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Слушатели">
                        <IconListeners />
                    </NavLink>

                    <NavLink id='tooltipBtnContracts' to='contracts' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="">
                        <IconContracts />
                        <Badge isVisible={Number(countNewContracts.value) > 0}>{countNewContracts.value}</Badge>
                        <Badge position={Number(countNewContracts.value) > 0 ? 'bottomLeft' : 'bottomRight'} background='#3b3bff' isVisible={Number(countAccessContracts.value) > 0}>{countAccessContracts.value}</Badge>
                    </NavLink>

                    <Divider variant='light' size='1.5em' />

                    <NavLink to='finalExamination' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Итоговые работы">
                        <IconJobs />
                        <Badge isVisible={Number(countUnverifiedWorks.value) > 0}>{countUnverifiedWorks.value}</Badge>
                    </NavLink>

                    <NavLink id='tooltipBtnDelivery' to='delivery' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="">
                        <IconDelivery />
                        <Badge isVisible={Number(countDeliveryAddress.value) > 0}>{countDeliveryAddress.value}</Badge>
                        <Badge position={Number(countDeliveryAddress.value) > 0 ? 'bottomLeft' : 'bottomRight'} background='#3b3bff' isVisible={Number(countDeliveryFinished.value) > 0}>{countDeliveryFinished.value}</Badge>
                    </NavLink>

                    <Divider variant='light' size='1.5em' />

                    <NavLink to='settings' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Администрирование">
                        <IconSettings />
                    </NavLink>
                </Flex>

                <Flex gap='6px' alignItems='center'>

                    <Button id="tooltipBtnMessage" variant='icon' size='md'>
                        <IconMessage />
                    </Button>

                    <Button id="tooltipBtnProfile" variant='icon' size='md'>
                        <IconProfile />
                    </Button>

                    <Divider variant='light' size='1.5em' />
                    
                    <Button id="tooltipBtnExit" variant='icon' size='md' onClick={() => handlerOpenModalConfirm(true, 'Выход из системы...', 'Вы действительно хотите выйти из приложения?')}>
                        <IconExitApp />
                    </Button>
                </Flex>
            </Flex>

            <main className='main_container-body'>
                <MainRoutes />
            </main>
        </Flex>

        <div id='mobileMenu' className='button_menu_for_mobile'>
            <Button size='sm' onClick={() => handlerOpenDrawerMenu(true)}>
                <IconMenu width={20} height={20} />
            </Button>
        </div>

        <ToolTip id="navTooltip" place="right" />
        <ToolTip anchorSelect="#tooltipBtnMessage" content='Сообщения' place="right" />
        <ToolTip anchorSelect="#tooltipBtnProfile" content='Мой профиль' place="right" />
        <ToolTip anchorSelect="#tooltipBtnExit" content='Выход' place="right" />

        <ToolTip anchorSelect="#tooltipBtnContracts" place="right">
            <p>Договора</p>
            {Number(countNewContracts.value) > 0 && <p>Новые: {countNewContracts.value}</p>}
            {Number(countAccessContracts.value) > 0 && <p>Ждут доступ: {countAccessContracts.value}</p>}
        </ToolTip>

        <ToolTip anchorSelect="#tooltipBtnDelivery" place="right">
            <p>Доставка</p>
            {Number(countDeliveryAddress.value) > 0 && <p>Подтверждение адреса: {countDeliveryAddress.value}</p>}
            {Number(countDeliveryFinished.value) > 0 && <p>Ожидают отправку: {countDeliveryFinished.value}</p>}
        </ToolTip>
    </>
}