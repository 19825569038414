import { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { Login } from './_interface'
import { useInput } from 'hooks/useInput'
import { resetStore } from 'store/reset.store'
import { useLogin } from 'store/login/login.state'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Авторизация пользователя - Контейнер */
export const LoginContainer = () => {
    const notify = useContext(NotificationContext)
    const {isAuthorized, isLoading, error, actionLogin, actionCheckAuth} = useLogin(useShallow((state) => ({
        isAuthorized: state.isAuthorized,
        isLoading: state.isLoading,
        error: state.error,
        actionLogin: state.actionLogin,
        actionCheckAuth: state.actionCheckAuth
    })))

    const isPassword = useInput<boolean>(false)
    const txtLogin = useInput<string>('')
    const txtPassword = useInput<string>('')

    useEffect(() => {
        if (error) return notify && notifyOpen(error?.message, error?.type, 2000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (localStorage.getItem('us_id') && localStorage.getItem('us_exp')) {
            actionCheckAuth()
        } else { resetStore() }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    /** Нажатие на кнопку войти в форме авторизации */
    const handleButtonClickLogin = () => {
        if (!txtLogin.value || !txtPassword.value) return notify && notifyOpen('В досутпе отказано.<br/>Необходимо ввести имя пользователя и его пароль.', 'info', 2000, notify)
        actionLogin(txtLogin.value, txtPassword.value)
    }

    /** Обработчик при нажатии клавиши Enter на полях имени пользователя и пароля */
    const handleFormKeyEnterLogin = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') handleButtonClickLogin()
    }

    /** Свойства передаваемые в компонену */
    const propsToComponent = {
        isLoading, isPassword, txtLogin, txtPassword,
        handleButtonClickLogin, handleFormKeyEnterLogin
    }

    // return <Login {...propsToComponent} />
    return <>{ !isAuthorized ? <Login {...propsToComponent} /> : <Navigate to="/lk" /> }</>
}