import { memo, useEffect, useState } from 'react'
import { Image } from 'antd'

import { useInput } from 'hooks/useInput'
import { IconClearClose } from 'images/icons'
import { Flex, Modal, Button } from 'components/ui'


export interface IModalPreview {
    isOpen: boolean
    handlerButtonCancel?: () => void

    fileUrl?: string
    fileName?: string
}


/** Предварительный просмотр документов */
export const ModalPreview = memo(({isOpen, fileUrl, fileName, handlerButtonCancel}: IModalPreview) => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
    
    const point = useInput<number>(100)
    const filePath = useInput<string>('')
    const isImg = useInput<boolean>(false)

    useEffect(() => {
        // Устанавливаем начальное значение ширины окна при монтировании компонента
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)

        // Добавляем слушателя события изменения размера окна
        window.addEventListener('resize', handleResize)

        if (window.innerWidth <= 400) {
            point.setValue(10)
        } else {
            point.setValue(100)
        }

        // Убираем слушателя при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [window.innerWidth, window.innerHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && fileName && fileUrl) {
            const fileParts = fileName.split('.')
            const fileExtension = fileParts.pop()

            switch (fileExtension) {
                case 'txt':
                case 'pdf': filePath.setValue(fileUrl); break
                
                case 'doc':
                case 'docx': filePath.setValue(`https://view.officeapps.live.com/op/embed.aspx?src=https://crm.iktbox.ru${fileUrl}`); break
                
                default: isImg.setValue(true); filePath.setValue(fileUrl); break
            }
        }

        return () => {
            filePath.setValue('')
            isImg.setValue(false)
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleResize = () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
    }

    return <>
        {isImg.value && <Image
            style={{ display: 'none' }}
            // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
            preview={{
                visible: isImg.value,
                src: filePath.value,
                onVisibleChange: (value) => {
                    isImg.setValue(value)
                    handlerButtonCancel && handlerButtonCancel()
                },
                zIndex: 1003
            }}
        />}

        <Modal isOpen={(isOpen && !isImg.value)} zIndex='1060' isEsc isWrapper onCancel={handlerButtonCancel} maxWidth={`${windowWidth - point.value}px`}>
            <Flex className='modal_container' width={`${windowWidth - point.value}px`} height={`${windowHeight - point.value}px`}>
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>Предпросмотр документа</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body' height='100%' width='100%' flexGrow={1}>
                    <Flex className='modal_container-content' flexGrow={1}>
                        {/* {isOpen && <iframe style={{ position: 'relative' }} src='/assets/contracts/1-d43744a8.pdf' width='100%' height='100%' allowFullScreen={false}></iframe>} */}
                        {(isOpen && !isImg.value) && <iframe key='document' src={filePath.value} width='99.5%' height='100%' title='Просмотр документа'></iframe>}
                    </Flex>

                    {/* <iframe style={{ border: '0' }} width="700px" height="800" src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`} loading="lazy" referrerPolicy="no-referrer-when-downgrade" sandbox="allow-scripts allow-same-origin" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                </Flex>
            </Flex>
        </Modal>
    </>
})