import React, { memo } from 'react'

import { IDivider } from './type'
import css from './index.module.scss'


/** Разделительная линия разделяет различный контент. */
export const Divider = memo(({
    id, className, variant, type, size, margin, prefix, suffix
}: IDivider): React.JSX.Element => {
    const getVariant = () => {
        switch (variant) {
            case 'dark': return css.divider_line_dark
            case 'light': return css.divider_line_light
            default: return css.divider_line_dark
        }
    }

    const getType = () => {
        const marginHorizontalStyle: React.CSSProperties = {
            paddingLeft: margin || '0px',
            paddingRight: margin || '0px'
        }
        const marginVerticalStyle: React.CSSProperties = {
            gap: 0,
            paddingTop: margin || '0px',
            paddingBottom: margin || '0px'
        }

        const sizeHorizontalStyle: React.CSSProperties = {width: size || '100%'}
        const sizeVerticalStyle: React.CSSProperties = {height: size || '100%', width: '0px'}
    
        switch (type) {
            case 'horizontal': return { size: sizeHorizontalStyle, margin: marginHorizontalStyle }
            case 'vertical': return { size: sizeVerticalStyle, margin: marginVerticalStyle }
            default: return { size: sizeHorizontalStyle, margin: marginHorizontalStyle }
        }
    }



    return <div id={id} className={`${css.divider_container}`} style={getType().margin}>
        <div style={{ marginLeft: `${prefix && '4px'}`, textWrap: 'nowrap'}}>{prefix}</div>
        <span className={`${getVariant()} ${className}`} style={getType().size}></span>
        <div style={{ textWrap: 'nowrap'}}>{suffix}</div>
    </div>
})