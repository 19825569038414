import { ICardContractDetailing, ICardContractFiles, ICardContractTabPrimary, ICardContract } from './_types'

import { IconClearClose, IconContracts, IconFiles, IconHelp, IconLogs, IconMainGrid } from 'images/icons'

import { CardContractTabDetailing } from './_tab.detailing'
import { CardContractTabPrimary } from './_tab.primary'
import { CardContractTabFiles } from './_tab.files'
import { CardContractTabLogs } from './_tab.logs'

import { Flex, ToolTip, Loader, Drawer, Divider, Button, Tab } from 'components/ui'


/** Анкета по договору */
export const CardContract = ({ loadingTitle,
    isOpen, cardMode, handlerButtonCancel, handleBtnSave,
    isLoading, tabActive, fieldsError, contractID, contractsDateCreate, summDuty, txtPostPayment, txtOutsideMoodle,
    paymentsList, programsList, filesList,
    handleOpenModalCalendar,
    handlerOpenModalConfirm,
    handleOpenModalDict, handlerOpenModalContractUpload, handlerOpenModalContractSended,
    handlerOpenModalProgram, handlerOpenModalCompanies, handlerOpenModalListeners, handlerGenerateDocuments, handlerOpenModalPayments,
    handlerDeleteFiles, handlerDownloadFiles, handlerSelectedFiles, handlerSelectedFilesAll, handlerOpenModalPreview, handlerOpenModalListenerProperty,
    txtApplicationID, txtContractDate, txtContractNumber, txtContractTypeID, txtContractType, txtContractView, txtContractStatus,
    txtCompanyName, txtCompanyContactFIO, txtCompanyContactPhone, txtCompanyContactEmail, txtComment,
}: ICardContract) => {

    /** Свойства основной вклдаки */
    const propsToTabPrimary: ICardContractTabPrimary = {
        cardMode, fieldsError, summDuty, paymentsList, programsList, txtPostPayment, txtOutsideMoodle,
        txtApplicationID, txtContractDate, txtContractNumber, txtContractTypeID, txtContractType, txtContractView, txtContractStatus,
        txtCompanyName, txtCompanyContactFIO, txtCompanyContactPhone, txtCompanyContactEmail, txtComment,
        handlerOpenModalConfirm, handleOpenModalDict, handleOpenModalCalendar, handlerOpenModalProgram, handlerOpenModalCompanies, handlerOpenModalListeners,
        handlerOpenModalListenerProperty, handlerOpenModalPayments,
    }
    /** Свойства вкладки файлы */
    const propsToTabFiles: ICardContractFiles = {
        filesList, handlerDeleteFiles, handlerDownloadFiles, handlerSelectedFiles, handlerSelectedFilesAll, handlerOpenModalPreview,
    }
    /** Свойства вкладки детализация по договору */
    const propsToTabDetailing: ICardContractDetailing = {
        
    }
    

    /** Получение сообщения при нажатии на кнопку - Допуск к обучению */
    const getMessageToCreateLogin = () => {
        if (txtOutsideMoodle.value === 1) { // Если обучение вне moodle
            return 'Внимание! Все слушатели по данному договору будут обучаться самостоятельно, вне учебной платформы MOODLE.<br /><br />Вы подтверждаете это?'
        }

        return 'Вы действительно хотите предоставить доступ в личный кабинет, нашей учебной платформы?'
    }

    /** Функция по открытию страниц в зависимости от выбора активной вкладки */
    const getCardPage = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': return <CardContractTabPrimary {...propsToTabPrimary} />
            case 'files': return <CardContractTabFiles {...propsToTabFiles} />
            case 'detailing': return <CardContractTabDetailing {...propsToTabDetailing} />
            case 'logs': return <CardContractTabLogs />
        }
    }
    
    /** Функция по получению кнопочной панели в низу анкеты, в зависимости от выбранной вкладки */
    const getCardPageBtnPanel = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end'>
                        <Flex direction='row' gap='4px' isVisible={cardMode === 'edit'}>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handlerOpenModalConfirm(true, 'Допус к обучению', getMessageToCreateLogin(), 'createLogin')}>
                                Допуск к обучению
                            </Button>

                            <Divider type='vertical' margin='8px' />
                        </Flex>

                        <Button padding='0 10px' isVisible={cardMode === 'edit'} onClick={() => handleBtnSave(true)}>Сохранить и закрыть</Button>
                        <Button padding='0 10px' onClick={() => handleBtnSave(false)}>
                            {cardMode === 'edit' ? 'Сохранить' : 'Создать договор'}
                        </Button>
                    </Flex>
                </Flex>
            }

            case 'files': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end' flexWrap='wrap'>
                        <Flex direction='row' gap='4px' isVisible={filesList.value.filter((item) => item.isSelected).length > 0}>
                            <Button variant='iconBr' padding='0 10px' onClick={() => handlerDeleteFiles()}>Удалить</Button>
                            <Button variant='iconBr' padding='0 10px' onClick={() => handlerDownloadFiles()}>Скачать</Button>
                            <Divider type='vertical' margin='8px' />
                        </Flex>

                        <Flex direction='row' gap='4px'>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handlerOpenModalContractSended(true)}>Отправить</Button>
                            <Button variant='primaryDark' padding='0 10px' onClick={handlerGenerateDocuments}>Сформировать</Button>
                        </Flex>

                        <Divider type='vertical' margin='8px' />
                        <Button padding='0 10px' onClick={() => handlerOpenModalContractUpload(true)}>Загрузка</Button>
                    </Flex>
                </Flex>
            }

            case 'detailing': {
                return ''
            }

            case 'logs': return ''
        }
    }

    return <>
        <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
            <Flex className='drawer_container'>
                <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                    <header>
                        <Flex gap='4px' direction='row' alignItems='center'>
                            <IconContracts color='#fff' width={20} height={20} />
                            <h4>{cardMode === 'edit' ? 'Анкета договора' : 'Создание договора'}</h4>
                        </Flex>

                        {cardMode === 'edit' && <h5>№ {contractID} от {contractsDateCreate.value} (👉 {txtApplicationID.value})</h5>}
                    </header>

                    <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='drawer_container-body_bottom_panel'>
                    <Flex gap='6px' className='drawer_container-content' overflow={['files', 'listeners'].includes(tabActive.value) ? 'hidden' : 'scroll'} padding={['files', 'listeners'].includes(tabActive.value) ? '2px 0px 0px 0px' : '4px 8px 4px 4px'}>
                        {getCardPage(tabActive.value)}
                    </Flex>

                    {getCardPageBtnPanel(tabActive.value)}
                </Flex>

                <Tab.Container isVisible={cardMode === 'edit'}>
                    <Tab.Item tabName='primary' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Основные данные' onClick={tabActive.setValue}><IconMainGrid /></Tab.Item>
                    <Tab.Item tabName='files' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Файлы' onClick={tabActive.setValue}><IconFiles /></Tab.Item>
                    <Tab.Item tabName='detailing' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Детализация' onClick={tabActive.setValue}><IconHelp /></Tab.Item>
                    <Tab.Item tabName='logs' tabNameActive={tabActive.value} tabToolTipPlace='top-end' tabToolTipTitle='Журнал изменений' onClick={tabActive.setValue}><IconLogs /></Tab.Item>
                </Tab.Container>

                <Loader isOpen={isLoading} color='#f34e2e' title={loadingTitle.value} backdrop />
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        </Drawer>
    </>
}