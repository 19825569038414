import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CardModeType } from "../common.type"
import { CardDelivery } from "./_interface"
import { FieldsErrorType, ICardDelivery, ICardDeliveryContainer, IFieldsError } from "./_types"

import { CardDeliveryTrackNumberContainer, ICardDeliveryTrackNumberContainer } from "../cardDeliveryTrackNumber"

import { useInput } from "hooks/useInput"
import { validFormData } from "hooks/useForm"

import { useDelivery } from "store/delivery/delivery.state"
import { useContracts } from "store/contracts/contracts.state"
import { IDeliveryCardFilesList, IDeliveryCardListenersList, IDeliveryCardTrackNumbersList } from "store/delivery/delivery.type"

import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"
import { IModalPreview, ModalPreview } from "components/dumb/modal.preview"
import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"



/** Контейнер анкеты доставки */
export const CardDeliveryContainer = ({isOpen, cardID, handlerButtonOk, handlerButtonCancel}: ICardDeliveryContainer) => {
    const notify = useContext(NotificationContext)

    const {isUploadingFile, uploadContractFiles} = useContracts(useShallow((state) => ({
        isUploadingFile: state.isLoadingCard,
        uploadContractFiles: state.actionSetContractUploadFiles
    })))
    const {
        isLoadingCard,
        getCardData, sendingOrCloseContract, sendingListenerFiles, getFiles, trackNumberSave, getTrackNumbers, saveCard
    } = useDelivery(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        getCardData: state.actionGetDeliveryCardData,
        sendingOrCloseContract: state.actionSetDeliverySendingEndCloseContract,
        sendingListenerFiles: state.actionSetDeliverySendingFiles,
        getFiles: state.actionGetDeliveryFilesList,
        trackNumberSave: state.actionSetDeliveryTrackNumbersSave,
        getTrackNumbers: state.actionGetDeliveryTrackNumbersList,
        saveCard: state.actionSetDeliveryCardDataSave
    })))

    const tabActive = useInput<string>('primary')
    const fieldsError = useInput<IFieldsError>({})
    const loadingTitle = useInput<string>('Загрузка анкеты...')

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalPreview = useInput<IModalPreview>({isOpen: false})
    const modalTrackNumber = useInput<ICardDeliveryTrackNumberContainer>({isOpen: false})

    const contractNumber = useInput<string>('')
    const contractDate = useInput<string>('')
    const applicationID = useInput<number | ''>('')
    
    const txtDeliveryToWhom = useInput<string>('')
    const txtDeliveryAddress = useInput<string>('')
    const txtIsAddressConfirmed = useInput<boolean>(false)
    const txtComment = useInput<string>('')
    
    const listFiles = useInput<IDeliveryCardFilesList[]>([])
    const listListeners = useInput<IDeliveryCardListenersList[]>([])
    const listTrackNumbers = useInput<IDeliveryCardTrackNumbersList[]>([])


    useEffect(() => {
        if (isOpen) {
            getCardData(Number(cardID)).then((res) => {
                if (res) {
                    contractNumber.setValue(res.contractNumber || '')
                    contractDate.setValue(res.contractDate || '')
                    applicationID.setValue(res.applicationID || '')
                    
                    txtDeliveryToWhom.setValue(res.deliveryToWhom || '')
                    txtDeliveryAddress.setValue(res.deliveryAddress || '')
                    txtIsAddressConfirmed.setValue(res.isAddressConfirmed === 1 ? true : false)
                    txtComment.setValue(res.comment || '')

                    listFiles.setValue(res.filesList)
                    listListeners.setValue(res.listenersList)
                    listTrackNumbers.setValue(res.trackNumbersList)
                }
            })
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        tabActive.setValue('primary')
        fieldsError.setValue({})

        contractNumber.setValue('')
        contractDate.setValue('')
        applicationID.setValue('')
        
        txtDeliveryToWhom.setValue('')
        txtDeliveryAddress.setValue('')
        txtIsAddressConfirmed.setValue(false)
        txtComment.setValue('')
        
        listFiles.setValue([])
        listListeners.setValue([])
        listTrackNumbers.setValue([])
    }


    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, actionName?: string, recordID?: number) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                switch (actionName) {
                    case 'sendingOrCloseContract': {
                        loadingTitle.setValue('Закрываю договор, ожидайте...')

                        sendingOrCloseContract(Number(cardID), '').then((res) => {
                            if (res === 200) notify && notifyOpen('Договор успешно завершён, вся информация отправлена в telegram канал 👉 ИКТ - Доставка', 'success', 1500, notify)
                        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })
                        break
                    } // Закрытие договора
                }

                modalConfirm.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна предпросмотра документа */
    const handlerOpenModalPreview = (isOpen: boolean, fileUrl: string, fileName: string) => {
        modalPreview.setValue({
            isOpen, fileUrl, fileName, 
            handlerButtonCancel() {modalPreview.setValue({isOpen: false})}, 
        })
    }
    /** Открытие/закрытие окна создания/редактирования трек-номера */
    const handlerOpenModalTrackNumber = (isOpen: boolean, cardMode: CardModeType, deliveryID?: number, trackNumber?: string, comment?: string) => {
        modalTrackNumber.setValue({
            isOpen, cardMode, trackNumber, comment,
            handlerButtonCancel() { modalTrackNumber.setValue({ isOpen: false }) },
            handlerButtonOk(trackNumber, comment) {
                trackNumberSave(Number(cardID), Number(deliveryID), cardMode, trackNumber, comment).then((res) => {
                    if (res === 200) notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                }).finally(() => {
                    getTrackNumbers(Number(cardID)).then((res) => {
                        listTrackNumbers.setValue(res)
                    })
                })

                modalTrackNumber.setValue({ isOpen: false })
            }
        })
    }


    /** Скачивание файлов */
    const handlerDownloadFiles = (fileName: string, filePath: string) => {
        const link = document.createElement('a') // Создание ссылки и программное скачивание файла

        link.href = filePath
        link.download = fileName
        document.body.appendChild(link)

        link.click()
        document.body.removeChild(link)
    }
    /** Отправка информации о трек-номере */
    const handlerSendingTrackNumbers = (trackNumber: string) => {
        loadingTitle.setValue('Отправляю информацию, ожидайте...')

        sendingOrCloseContract(Number(cardID), trackNumber).then((res) => {
            if (res === 200) notify && notifyOpen('Вся информация успешно отправлена в telegram канал 👉 ИКТ - Доставка', 'success', 1500, notify)
        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })

    }
    /** Отправка информации и файлов слушателя */
    const handlerSendingListenerFiles = (contractListenerID: number) => {
        loadingTitle.setValue('Отправляю информацию, ожидайте...')

        sendingListenerFiles(Number(contractListenerID)).then((res) => {
            if (res === 200) notify && notifyOpen('Вся информация успешно отправлена в telegram канал 👉 ИКТ - Сканы дипломов', 'success', 1500, notify)
        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })
    }
    // Загрузка файлов на сервер
    const handlerFileUploader = (fileList: any) => {
        loadingTitle.setValue('Отправляю файлы, ожидайте...')

        uploadContractFiles(Number(cardID), 4, fileList).then(res => {
            if (res === 200) notify && notifyOpen('Файлы успешно загружены!', 'success', 1500, notify)
        }).finally(() => {
            getFiles(Number(cardID)).then((res) => {
                listFiles.setValue(res)
            })
        })
    }


    /** Кнопка сохранить все данные во вкладке primary */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'deliveryToWhom', value: txtDeliveryToWhom.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Необходимо заполнить поле 👉 Кому (Ф.И.О.).'},
            {fieldName: 'deliveryAddress', value: txtDeliveryAddress.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Необходимо заполнить поле 👉 Куда (Адресс доставки).'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnimation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }
        
        if (validateData.length === 0) { // Сохраняем данные
            loadingTitle.setValue('Сохранение данных...')

            saveCard(Number(cardID), txtDeliveryToWhom.value, txtDeliveryAddress.value, txtComment.value, Number(txtIsAddressConfirmed.value)).then((res) => {
                if (res === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            }).finally(() => {
                loadingTitle.setValue('Загрузка анкеты...')
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnimation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }


    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardDelivery = {
        isOpen, isLoadingCard: isLoadingCard || isUploadingFile, 
        tabActive, loadingTitle, fieldsError,
        contractNumber, contractDate, applicationID,

        txtDeliveryToWhom, txtDeliveryAddress, txtIsAddressConfirmed, txtComment,
        listFiles, listListeners, listTrackNumbers,

        handlerOpenModalConfirm, handlerOpenModalPreview, handlerDownloadFiles, 
        handlerSendingTrackNumbers, handlerSendingListenerFiles, handlerFileUploader,
        handlerOpenModalTrackNumber, handleBtnSave, handlerButtonCancel
    }
    
    return <>
        <CardDelivery {...propsToComponent} />
        
        <ModalConfirm {...modalConfirm.value} />
        <ModalPreview {...modalPreview.value} />
        <CardDeliveryTrackNumberContainer {...modalTrackNumber.value} />
    </>
}