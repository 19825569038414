import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { IApplicationsPromo, IApplicationsPromoContainer } from "./_types"
import { ApplicationsPromo } from "./_interface"

import { useInput } from "hooks/useInput"
import { useDebounce } from "hooks/useDebounce"
import { useApplications } from "store/applications/applications.state"




/** Контейнер промо заявки с МКПП */
export const ApplicationsPromoContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: IApplicationsPromoContainer) => {
    const {isLoading, totalCount, pageSize, applicationsList, clearPromo, getApplications, sendTelegramIngo} = useApplications(useShallow((state) => ({
        isLoading: state.applicationsPromo.isLoading,
        totalCount: state.applicationsPromo.totalCount,
        pageSize: state.applicationsPromo.pageSize,
        applicationsList: state.applicationsPromo.items,
        clearPromo: state.clearApplicationsPromo,
        getApplications: state.actionGetApplicationsPromo,
        sendTelegramIngo: state.actionSendTelegramAppPromo
    })))
    
    const txtSearch = useInput<string>('')
    const currentPageNumber = useInput<number>(1)

    useEffect(() => {
        isOpen && loadDataListeners(txtSearch.value)

        if (!isOpen) {
            clearPromo()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isOpen && loadDataListeners(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    

    /** Функция по получению списка слушателей */
    const loadDataListeners = (search?: string) => {
        getApplications(currentPageNumber.value, search || '')
    }

    const debounceSearch = useDebounce(loadDataListeners, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска

    /** Отправка информации в telegram канал (ИКТ - Промо (МАКПП)) */
    const handlerSendToTelegram = (applicationID: number) => {
        sendTelegramIngo(Number(applicationID))
    }

    /** Свойства передаваемые в компонену */
    const propsToComponent: IApplicationsPromo = {
        isOpen, isLoading, currentPageNumber, totalCount, pageSize, applicationsList,
        txtSearch, txtSearchOnChange, loadDataListeners, handlerSendToTelegram, handlerButtonCancel
    }

    return <ApplicationsPromo {...propsToComponent} />
}