import Dropzone from 'react-dropzone'

import { ICardCompanyTabFiles, ICardCompanyTabPrimary, ICardCompany } from './_types'

import { CardListenerTabPrimary } from './_tab.primary'
import { CardListenerTabFiles } from './_tab.files'
import { CardListenerTabLogs } from './_tab.logs'

import { Flex, ToolTip, Loader, Drawer, Divider, Button, Tab } from 'components/ui'
import { IconClearClose, IconEntity, IconFiles, IconLogs, IconMainGrid } from 'images/icons'


/** Карточка по контрагенту */
export const CardCompany = ({
    isOpen, cardMode, handlerButtonCancel, isLoading, tabActive, fieldsError, 
    filesList, companyNumber, companyDateCreate, commpanyINNAuto, txtINN, isReadOnlyKPP, txtKPP, txtCompanyName, txtCompanyNameShort,
    txtManagerPosition, txtActingOnBasis, txtLastName, txtFirstName, txtSecondName, txtInPersonWhom, txtAddress, txtRequisites, txtComment,

    fileUploader, handlerSelectedFiles, handlerSelectedFilesAll, handlerDeleteFiles, handlerDownloadFiles, 
    handlerOpenModalPreview, handlerINNAuto, handleChangeINN, handleChangeKPP, handleBtnSave,
}: ICardCompany) => {
    const propsToTabPrimary: ICardCompanyTabPrimary = { // Свойства передеваемые во вкладку primary
        fieldsError,
        commpanyINNAuto, txtINN, txtKPP, isReadOnlyKPP, txtCompanyName, txtCompanyNameShort, txtManagerPosition, txtActingOnBasis,
        txtLastName, txtFirstName, txtSecondName, txtInPersonWhom, txtAddress, txtRequisites, txtComment,
        handlerINNAuto, handleChangeINN, handleChangeKPP,
    }

    const propsToTabFiles: ICardCompanyTabFiles = {
        filesList, handlerSelectedFiles, handlerSelectedFilesAll, fileUploader, handlerDownloadFiles, handlerDeleteFiles, handlerOpenModalPreview,
    }

    const getCardPage = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': return <CardListenerTabPrimary {...propsToTabPrimary} />
            case 'files': return <CardListenerTabFiles {...propsToTabFiles} />
            case 'logs': return <CardListenerTabLogs />
        }
    }

    const getCardPageBtnPanel = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end'>
                        {/* <Flex direction='row' gap='4px'>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handlerOpenModalConfirm(true, 'Демо-доступ...', 'Вы действительно хотите создать демо-доступ, для данного клиента?')}>Демо-доступ</Button>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handleOpenModalMailings(true)}>Рассылки</Button>
                        </Flex> */}

                        {/* <Divider type='vertical' margin='8px' /> */}
                        <Button padding='0 10px' onClick={handleBtnSave}>
                            {cardMode === 'edit' ? 'Сохранить' : 'Создать контрагента'}
                        </Button>
                    </Flex>
                </Flex>
            }

            case 'files': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end'>
                        <Flex direction='row' gap='4px' isVisible={filesList.value.filter((item) => item.isSelected).length > 0}>
                            <Button padding='0 10px' variant='primaryDark' onClick={() => handlerDeleteFiles()}>Удалить</Button>
                            <Button padding='0 10px' variant='primaryDark' onClick={() => handlerDownloadFiles()}>Скачать</Button>
                            <Divider type='vertical' margin='8px' />
                        </Flex>

                        <Dropzone onDropAccepted={(files) => fileUploader(files)}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button padding={'0 10px'}>Загрузить</Button>
                                </div>
                            )}
                        </Dropzone>
                    </Flex>
                </Flex>
            }
            case 'logs': return ''
        }
    }

    return <>
        <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
            <Flex className='drawer_container'>
                <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                    <header>
                        <Flex gap='4px' direction='row' alignItems='center'>
                            <IconEntity color='#fff' width={20} height={20} />
                            <h4>{cardMode === 'edit' ? 'Анкета контрагента' : 'Создание контрагента'}</h4>
                        </Flex>

                        {cardMode === 'edit' && <h5>№ {companyNumber.value} от {companyDateCreate.value}</h5>}
                    </header>

                    <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='drawer_container-body_bottom_panel'>
                    <Flex gap='6px' className='drawer_container-content' overflow={tabActive.value === 'files' ? 'hidden' : 'scroll'} padding={tabActive.value === 'files' ? '2px 0px 0px 0px' : '4px 8px 4px 4px'}>
                        {getCardPage(tabActive.value)}
                    </Flex>

                    {getCardPageBtnPanel(tabActive.value)}
                </Flex>

                <Tab.Container>
                    <Tab.Item tabName='primary' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Основные данные' onClick={tabActive.setValue}><IconMainGrid /></Tab.Item>
                    <Tab.Item tabName='files' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Файлы слушателя' onClick={tabActive.setValue}><IconFiles /></Tab.Item>
                    <Tab.Item tabName='logs' tabNameActive={tabActive.value} tabToolTipPlace='top-end' tabToolTipTitle='Журнал изменений' onClick={tabActive.setValue}><IconLogs /></Tab.Item>
                </Tab.Container>

                {<Loader isOpen={isLoading} color='#f34e2e' title='Загрузка анкеты...' backdrop />}
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        </Drawer>
    </>
}