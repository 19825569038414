import React, { memo } from 'react'
import { BeatLoader } from 'react-spinners'
import ReactInputMask from 'react-input-mask'

import css from './index.module.scss'
import { IInput, InputSizeType } from './type'


/** Текстовое поле */
export const Input = memo(({ 
    id, name, type = 'text', placeholder, autoComplete = 'off', prefix, suffix, variant = 'primary', 
    mask, maskChar, size, width, maxWidth, minWidth, isReadOnly = false, isDisabled = false, isLoading = false, 
    paddingLeft, paddingRight, value, onChange, textAlign = 'left', isVisible = true
}: IInput): React.JSX.Element => {
    const getSizeClassName = (size?: InputSizeType) => {
        switch (size) {
            case 'lg': return css.lg
            case 'md': return css.md
            case 'sm': return css.sm
            case 'xs': return css.xs
            default: return css.md
        }
    }

    const inputType = (type: React.HTMLInputTypeAttribute | 'mask') => {
        switch (type) {
            case 'mask': return (<ReactInputMask maskChar={maskChar} mask={mask || ''} id={id} name={name} placeholder={placeholder} value={value?.toString()} onChange={onChange} autoComplete={autoComplete} readOnly={isReadOnly} disabled={isDisabled} className={`${getSizeClassName(size)}`} />)
            default: return (<input id={id} name={name} type={type} placeholder={placeholder} value={value?.toString()} onChange={onChange} autoComplete={autoComplete} readOnly={isReadOnly} disabled={isDisabled} className={`${getSizeClassName(size)}`} style={{textAlign: textAlign}} />)
        }
    }


    return <>
        {isVisible && <div className={`${css[variant]} ${getSizeClassName(size)} ${isDisabled && css['disabled']}`} onClick={(e) => e.stopPropagation()} style={{ width: width, maxWidth: maxWidth, minWidth, paddingRight: paddingRight, paddingLeft: paddingLeft }}>
            {prefix}
            {inputType(type)}
            {suffix}

            {isLoading && <div className={css.isLoading}><BeatLoader size={10} loading={isLoading} color='#f34e2e' /></div>}
        </div>}
    </>
})