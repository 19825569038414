import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { FinalExaminationPrintings } from './_interface'
import { IFinalExaminationPrintings, IFinalExaminationPrintingsContainer } from './_types'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'

import { usePrintingsDiplomas } from 'store/printingsDiplomas/PrintingsDiplomas.state'

import { CardContractContainer, ICardContractContainer } from '../cardContract'
import { CardListenerContainer, ICardListenerContainer } from '../cardListener'
import { CardPrintingDiplomasContainer, ICardPrintingDiplomasContainer } from '../cardPrintingDiplomas'

import { IModalConfirm, ModalConfirm } from 'components/dumb/modal.confirm'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Контейнер работ на печать */
export const FinalExaminationPrintingsContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: IFinalExaminationPrintingsContainer) => {
    const notify = useContext(NotificationContext)

    const {error, isLoading, isReloadListeners, printingsList, pageSize, totalCount, getPrintingDiplomasListeners, deletePrintDiplomas, clearPrintingDiplomasState} = usePrintingsDiplomas(useShallow((state) => ({
        error: state.error,
        isLoading: state.listeners.isLoading,
        isReloadListeners: state.isReloadListeners,
        printingsList: state.listeners.items,
        pageSize: state.listeners.pageSize,
        totalCount: state.listeners.totalCount,
        getPrintingDiplomasListeners: state.actionGetPrintingDiplomasListeners,
        deletePrintDiplomas: state.actionSetPrintingDiplomasDelete,
        clearPrintingDiplomasState: state.clearPrintingDiplomasState,
    })))
    

    const cardContract = useInput<ICardContractContainer>({isOpen: false})
    const cardListener = useInput<ICardListenerContainer>({isOpen: false})
    const cardPrinting = useInput<ICardPrintingDiplomasContainer>({isOpen: false})

    const loadingTitle = useInput<string>('Загрузка документов...')
    const modalConfirm = useInput<IModalConfirm>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')

    useEffect(() => {
        isOpen && loadDataListeners(txtSearch.value)

        if (!isOpen) {
            clearPrintingDiplomasState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isOpen && loadDataListeners(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (error) return notify && notifyOpen(error?.message, error?.type, 2000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isReloadListeners && loadDataListeners(txtSearch.value)
    }, [isReloadListeners]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция по получению списка слушателей */
    const loadDataListeners = (search?: string) => {
        getPrintingDiplomasListeners(currentPageNumber.value, search || '')
    }



    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, actionName?: string, recordID?: number) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                loadingTitle.setValue('Возвращаю в раздел итоговой аттестации...')

                deletePrintDiplomas(Number(recordID)).then((res) => {
                    if (res === 200) {
                        loadDataListeners(txtSearch.value)
                        handlerButtonOk && handlerButtonOk()
                        return notify && notifyOpen('Анкета итоговой работы слушателя, успешно возвращена на обработку.', 'success', 1500, notify)
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка документов...') })

                modalConfirm.setValue({ isOpen: false })
            }
        })
    }
    /** Открытие/закрытие анкеты по договору */
    const handleOpenCardContract = (isOpen: boolean, contractID?: number) => {
        cardContract.setValue({
            isOpen, cardMode: 'edit', cardID: contractID,
            handlerButtonCancel() {cardContract.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardContract.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие анкеты по слушателю */
    const handleOpenCardListener = (isOpen: boolean, ListenerID?: number) => {
        cardListener.setValue({
            isOpen, cardMode: 'edit', cardID: ListenerID,
            handlerButtonCancel() {cardListener.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListener.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие анкеты по печати документа */
    const handleOpenCardPrinting = (isOpen: boolean, printID?: number, fullNameFrom?: string) => {
        cardPrinting.setValue({
            isOpen, cardID: printID, fullNameFrom,
            handlerButtonCancel() {cardPrinting.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardPrinting.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }


    const debounceSearch = useDebounce(loadDataListeners, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска


    /** Свойства передаваемые в компоненту */
    const propsToComponent: IFinalExaminationPrintings = {
        isOpen, isLoading, loadingTitle, printingsList, 

        txtSearch, currentPageNumber, pageSize, totalCount,

        handlerOpenModalConfirm, handleOpenCardContract, handleOpenCardListener,
        txtSearchOnChange, loadDataListeners, handlerButtonCancel, handleOpenCardPrinting,
    }
    
    return <>
        <FinalExaminationPrintings {...propsToComponent} />

        <ModalConfirm {...modalConfirm.value} />
        <CardListenerContainer {...cardListener.value} />
        <CardContractContainer {...cardContract.value} />
        <CardPrintingDiplomasContainer {...cardPrinting.value} />
    </>
}