import { ISelectListener } from './_types'
import CommonService from '../../../services/common.service'

import imgEmty from './../../../styles/images/empty.svg'
import { IconClearClose, IconInfo, IconListeners, IconSearch } from '../../../styles/images/icons'

import { TableBody, TableRow } from '../../ui/table'
import { Empty, Flex, ToolTip, Pagination, Loader, Input, Drawer, Divider, Button } from '../../ui'


/** Выбор слушателя */
export const SelectListener = ({
    isOpen, handlerButtonOk, handlerButtonCancel,
    isLoading, totalCount, pageSize, listenersList, 
    txtSearch, txtSearchOnChange, currentPageNumber,
    handleOpenCardListener,
}: ISelectListener) => {
    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconListeners color='#fff' width={20} height={20} />
                        <h4>Выбор слушателя</h4>
                    </Flex>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Loader isOpen={isLoading} backdrop color="#f34e2e" title="Загрузка слушателей..." />

                <Flex gap='6px' className='drawer_container-content' overflow='hidden' padding='2px 0px 0px 0px'>
                    <Flex gap='4px' direction='row' justifyContent='space-between'>
                        <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                            value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                            paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                            suffix={<>
                                {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                                <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                            </>}
                        />

                        <Button id='tooltipBtnCreate' size="sm" variant="primary" padding='0 10px' onClick={() => handleOpenCardListener(true, 'new')}>СОЗДАТЬ</Button>
                    </Flex>

                    <Divider />

                    <TableBody>
                        {!listenersList.length 
                            ? <Empty image={imgEmty} title='Контрагенты отсутствуют' /> 
                            : listenersList.map((item) => (
                                <TableRow key={item.listenerID} indexContent={item.listenerID} indexContentWidth='60px' paddingInset='2px 6px' onClick={() => handlerButtonOk && handlerButtonOk(item.listenerID, CommonService.declensionFullName(item.lastName, item.firstName, item.secondName, 'кому/чему'), item.registration)}>
                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.lastName} {item.firstName} {item.secondName}</p>
                                        <Flex gap='5px' direction='row'><p style={{ opacity: '.7' }}>{item.phoneNumber}</p> / <p style={{opacity: '.7', color: `${item.snils.length > 14 && 'red'}`}}>{item.snils}</p></Flex>
                                    </div>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                    <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                        <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Создать нового слушателя' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Фамилии<br/>🗸 Имени<br/>🗸 Отчеству<br/>🗸 Телефону<br/>🗸 Email<br/>🗸 СНИЛС<br/>🗸 Паспорту<br/>🗸 Адресу<br/>🗸 Диплому</ToolTip>
    </Drawer>
}