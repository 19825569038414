import { ISelectMailing } from './_types'

import imgEmpty from 'images/empty.svg'
import { IconClearClose } from 'images/icons'

import { Empty, Flex, Grid, ToolTip, Modal, Button, Table } from 'components/ui'


/** Рассылки */
export const SelectMailing = ({isOpen, handlerButtonCancel, selectedMailingID, actionMailingsList, onSelectString, handleButtonSended}: ISelectMailing) => {
    return <Modal isOpen={isOpen} isEsc isWrapper onCancel={handlerButtonCancel}>
        <Flex className='modal_container'>
            <Flex className='modal_container-header' direction='row' alignItems='center' justifyContent='space-between'>
                <h4>Рассылки...</h4>
                <Button id='tooltipClose' size='sm' variant='icon' onClick={handlerButtonCancel}><IconClearClose /></Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Flex gap='6px' className='modal_container-content' height='300px'>
                    <Table.Container className='dict_container'>
                        <Table.Body >
                            {actionMailingsList.value.length === 0
                                ? <Empty image={imgEmpty} />
                                : actionMailingsList.value.map((item, index) => (
                                    <Table.Row key={index + 1} indexContent={<span>{item.isCheck ? '✔' : index + 1}</span>}
                                        paddingInset='4px 8px' indexContentWidth="40px"
                                        classGridTemplateColumns="files_grid_columns"
                                        onClick={() => onSelectString(item.id)}
                                        backgroundColor={item.isCheck ? '#F34E2E' : ''}
                                    >
                                        <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                            <p>{item.name}</p>
                                        </div>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table.Container>
                </Flex>

                <Grid gap='6px' gridTemplate='columns' gridTemplateColumns='1fr 1fr'>
                    <Button variant='primaryDark' width='100%' onClick={handlerButtonCancel}>ОТМЕНА</Button>
                    <Button width='100%' onClick={handleButtonSended}>ОТПРАВИТЬ</Button>
                </Grid>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#toolTipClear" place="left" content='Очистить' />
        <ToolTip anchorSelect="#tooltipClose" place="left" content='Закрыть' />
    </Modal>
}