import moment from "moment"
import { useEffect, useRef } from "react"

import { ICardContractFiles } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconCheck, IconDelete, IconDownload, IconUnCheck } from "images/icons"

import { Empty, Flex, ToolTip, Divider, Button, Table } from "components/ui"


/** Вкладка файлы */
export const CardContractTabFiles = ({
    filesList, handlerDeleteFiles, handlerDownloadFiles, handlerSelectedFiles, handlerSelectedFilesAll, handlerOpenModalPreview
}: ICardContractFiles) => {
    let oldSectionName: string = ''
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    const getDivider = (sectionName: string) => {
        if (oldSectionName !== sectionName) {
            oldSectionName = sectionName
            return <Divider prefix={<h4>{sectionName}</h4>} />
        }
    }

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth;
            tableHeadNavRef.current.style.width = `${width}px`
        }
    }, [filesList, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <Table.Container>
            <Table.Header id='hiddenForMobile' classGridTemplateColumns="listeners_files_header_grid_columns" marginLeft="0px" marginRight="10px">
                <Button id='tooltipCheckAll' isDisabled={filesList.value.length === 0} onClick={handlerSelectedFilesAll}>
                    {(filesList.value.length > 0 && filesList.value.filter(item => item.isSelected).length === filesList.value.length) ? <IconCheck /> : <IconUnCheck />}
                </Button>

                <span>Наименование файла<br />Размер файла</span>
                <span className="hiddenFor750 table_column_data_center">Дата создания</span>
                <span ref={tableHeadNavRef}></span>
            </Table.Header>

            <Table.Body>
                {filesList.value.length === 0
                    ? <Empty image={imgEmty} title="Файлы отсутствуют" />
                    : filesList.value.map((item, index) => {
                        return (
                            <Flex key={index}>
                                {getDivider(item.sectionName)}

                                <Table.Row indexContent={index + 1} indexContentWidth='60px' paddingInset="0 0 0 12px" badge={
                                    <Button id='tooltipCheck' size='xs' onClick={(e) => { handlerSelectedFiles(item.fileID); e.stopPropagation() }}>{item.isSelected ? <IconCheck width={20} height={20} /> : <IconUnCheck width={20} height={20} />}</Button>
                                } classGridTemplateColumns='listeners_files_grid_columns' onClick={() => handlerOpenModalPreview(true, item.filePath, item.fileName)}>
                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.fileName}</p>
                                        <p style={{ opacity: '.8' }}>Размер файла: {item.fileSize}</p>
                                    </div>

                                    <span className="hiddenFor750 table_column_data_center">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>

                                    <nav ref={tableNavRef}>
                                        <Button id='tooltipBtnDawnload' variant="icon" size="xs" onClick={(e) => { handlerDownloadFiles(item.fileID); e.stopPropagation() }}><IconDownload /></Button>
                                        <Button id='tooltipBtnDelete' variant="icon" size="xs" onClick={(e) => { handlerDeleteFiles(item.fileID); e.stopPropagation() }}><IconDelete /></Button>
                                    </nav>
                                </Table.Row>
                            </Flex>
                        )
                    })
                }
            </Table.Body>
        </Table.Container>

        <ToolTip anchorSelect="#tooltipCheck" content='Выделить / Снять' place="left" />
        <ToolTip anchorSelect="#tooltipCheckAll" content='Выделить / Снять всё' place="left" />
        <ToolTip anchorSelect="#tooltipBtnDelete" content='Удалить файл' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnDawnload" content='Скачать файл' place="left" />
    </>
}