import { memo } from 'react'
import parse from 'html-react-parser'

import { Flex, Grid, Modal, Button } from 'components/ui'


/** Свойства окна извещения */
export interface IModalNotice {
    isOpen: boolean
    
    headerText?: string
    contentText?: string

    handlerButtonOk?: () => void
}


/** Окно извещения */
export const ModalNotice = memo(({isOpen, headerText, contentText, handlerButtonOk}: IModalNotice): JSX.Element => {
    return <Modal isOpen={isOpen} isEsc isWrapper onCancel={handlerButtonOk} onOk={handlerButtonOk}>
        <Flex className='modal_container'>
            <Flex className='modal_container-header'>
                <h4>{headerText || 'Извещение...'}</h4>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Flex className='modal_container-content'>
                    <span style={{fontSize: '15px', letterSpacing: '1.5px', lineHeight: '140%'}}>
                        {parse(contentText || 'Привет:<br/>Вы не указали текст!')}
                    </span>
                </Flex>

                <Grid gap='6px' gridTemplate='columns' gridTemplateColumns='1fr'>
                    <Button width='100%' onClick={handlerButtonOk}>ХОРОШО</Button>
                </Grid>
            </Flex>
        </Flex>
    </Modal>
})