import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { CardContractProgram } from './_interface'
import { FieldsErrorType, ICardContractProgram, ICardContractProgramContainer, IFieldsError } from './_types'

import { useInput } from 'hooks/useInput'
import { validFormData } from 'hooks/useForm'
import { useDebounce } from 'hooks/useDebounce'
import { NotificationContext, notifyOpen } from '../../ui/notification/notification.provider'


import { useDict } from 'store/dict/dict.state'
import { useCommon } from 'store/common/common.state'
import { ICourseProgramsList } from 'store/dict/dict.types'
import { useContracts } from 'store/contracts/contracts.state'

import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'
import { DictNameType, ISelectDictionaryContainer, SelectDictionaryContainer } from '../selectDictionary'



/** Добавление или редактирование программы по договору - Контейнер */
export const CardContractProgramContainer = ({isOpen, cardMode, contractID, contractProgramID, dbName, handlerButtonOk, handlerButtonCancel}: ICardContractProgramContainer) => {
    const notify = useContext(NotificationContext)
    const loadingTitle = useInput<string>('Загрузка данных...')

    const {isLoading, actionGetCoursePrograms} = useDict(useShallow((state) => state))
    const {actionGetEduDateStartEnd} = useCommon(useShallow((state) => state))

    const {isLoadingCard, getProgramData, saveCard} = useContracts(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        getProgramData: state.actionGetContractCardPrograms,
        saveCard: state.actionSetContractCardProgramsSave,
    })))

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalDict = useInput<ISelectDictionaryContainer>({isOpen: false})

    const fieldsError = useInput<IFieldsError>({})
    const courseProgramList = useInput<ICourseProgramsList[]>([])
    
    const txtCourseProgramID = useInput<number | ''>('')

    const txtCourseNameID = useInput<number | ''>('')
    const txtCourseName = useInput<string>('')
    const txtProgramTypeID = useInput<number | ''>('')
    const txtProgramType = useInput<string>('')

    const txtDurationEducation = useInput<number | string>('')
    const txtVolumeHours = useInput<number | ''>('')
    const txtSumm = useInput<number | ''>('')

    const txtEducationDateStart = useInput<string>('')
    const txtEducationDateEnd = useInput<string>('')

    const txtSphere = useInput<string>('')
    const txtQualification = useInput<string>('')
    const txtRequirementName = useInput<string>('')


    useEffect(() => {
        if (isOpen) {
            getProgramData(Number(cardMode === 'new' ? contractID : contractProgramID), cardMode === 'new' ? 'new' : 'edit').then((res) => {
                if (res) {
                    txtCourseProgramID.setValue(res.courseProgramID)

                    txtCourseNameID.setValue(res.courseID || '')
                    txtCourseName.setValue(res.courseName || '')

                    txtProgramTypeID.setValue(res.documentTypeID || '')
                    txtProgramType.setValue(res.documentTypeName || '')
                
                    txtDurationEducation.setValue(res.durationEducation || '')
                    txtVolumeHours.setValue(res.volumeHours || '')
                    txtSumm.setValue(res.price || '')
                
                    txtEducationDateStart.setValue(res.startEducationDate || '')
                    txtEducationDateEnd.setValue(res.endEducationDate || '')
                
                    txtSphere.setValue(res.sphere || '')
                    txtQualification.setValue(res.qualification || '')
                    txtRequirementName.setValue(res.requirementName || '')
                }
            })
        }

        !isOpen && clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && txtCourseNameID.value) {
            actionGetCoursePrograms(dbName || '', txtCourseNameID.value || 0).then((res) => {
                courseProgramList.setValue(res)
            })
        }
    }, [isOpen, txtCourseNameID.value]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Очистка формы */
    const clearData = () => {
        courseProgramList.setValue([])

        txtCourseProgramID.setValue('')
        
        txtCourseNameID.setValue('')
        txtCourseName.setValue('')
        txtProgramTypeID.setValue('')
        txtProgramType.setValue('')
    
        txtDurationEducation.setValue('')
        txtVolumeHours.setValue('')
        txtSumm.setValue('')
    
        txtEducationDateStart.setValue('')
        txtEducationDateEnd.setValue('')
    
        txtSphere.setValue('')
        txtQualification.setValue('')
        txtRequirementName.setValue('')
    }

    /** Получение дат, начала обучения и окончание обучения */
    const getEducationDate = (eduDateStart: string) => {
        actionGetEduDateStartEnd(eduDateStart, Number(txtDurationEducation.value), txtProgramTypeID.value === 2 ? 1 : 0).then((res) => {
            // res.eduStartDate && txtEducationDateStart.setValue(res.eduStartDate)
            res.eduEndDate && txtEducationDateEnd.setValue(res.eduEndDate)
        })
    }
    const getEducationDateEnd = (durationEducation: number) => {
        actionGetEduDateStartEnd(txtEducationDateStart.value, durationEducation, txtProgramTypeID.value === 2 ? 1 : 0).then((res) => {
            // res.eduStartDate && txtEducationDateStart.setValue(res.eduStartDate)
            res.eduEndDate && txtEducationDateEnd.setValue(res.eduEndDate)
        })
    }
    

    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'educationDateStart': txtEducationDateStartOnChange(stringDate); break
                    case 'educationDateEnd': txtEducationDateEnd.setValue(stringDate); break
                }

                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handleOpenModalDict = (isOpen: boolean, dictName: DictNameType, whereParam1?: string, whereParam2?: string) => {
        modalDict.setValue({
            isOpen, dictName, dbName, whereParam1, whereParam2,
            handlerButtonCancel() {modalDict.setValue({isOpen: false})}, 
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (dictName) {
                    case 'coursesContract': {
                        txtCourseNameID.setValue(Number(id))
                        txtCourseName.setValue(name)

                        txtSphere.setValue(param1 || '')
                        txtQualification.setValue(param2 || '')
                        txtRequirementName.setValue(param3 || '')
                        break
                    }
                    
                    case 'documentTypes': {
                        txtProgramTypeID.setValue(Number(id))
                        txtProgramType.setValue(name)
                        break
                    }
                }

                modalDict.setValue({isOpen: false})
            }
        })
    }


    /** Обработка клика по справочной информации, с последующей перезаписи продолжительности, обьема и суммы */
    const handleClickProgramList = (courseProgramID: number, durationEducation: number, volumeHours: number, price: number, programID: number, programName: string) => {
        txtCourseProgramID.setValue(courseProgramID)
        txtProgramTypeID.setValue(programID)
        txtProgramType.setValue(programName)

        txtDurationEducation.setValue(durationEducation)
        txtVolumeHours.setValue(volumeHours)
        txtSumm.setValue(price)

        actionGetEduDateStartEnd(txtEducationDateStart.value, Number(durationEducation), programID === 2 ? 1 : 0).then((res) => {
            // res.eduStartDate && txtEducationDateStart.setValue(res.eduStartDate)
            res.eduEndDate && txtEducationDateEnd.setValue(res.eduEndDate)
        })
    }

    const debounceEduDate = useDebounce(getEducationDate, 1500) // Запуск получения дат начала обучения и окончания обучения
    const debounceEduDateEnd = useDebounce(getEducationDateEnd, 1500) // Запуск получения дат начала обучения и окончания обучения
    const txtEducationDateStartOnChange = (value: string) => {txtEducationDateStart.setValue(value); debounceEduDate(value)} // Поле дата начала обучения
    const txtDurationEducationOnChange = (value: string) => {
        txtDurationEducation.setValue(value.replace(',', '.'))

        const validateData = validFormData([{fieldName: 'durationEducation', value: value.replace(',', '.'), validType: 'numberDecimal', isRequired: true, errorMessage: ''}])
        if (validateData.length === 0) debounceEduDateEnd(value.replace(',', '.'))
    } // Поле продолжительность


    /** Кнопка сохранить все данные */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'courseName', value: txtCourseNameID.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Наименование курса не выбрано.'},
            {fieldName: 'programType', value: txtProgramTypeID.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Тип программы не выбран.'},
            
            {fieldName: 'durationEducation', value: txtDurationEducation.value.toString(), validType: 'numberDecimal', isRequired: true, errorMessage: 'Продолжительность не корректное значение.'},
            {fieldName: 'volumeHours', value: txtVolumeHours.value.toString(), validType: 'number_1-9', isRequired: true, errorMessage: 'Объём не корректное значение.'},
            {fieldName: 'summ', value: txtSumm.value.toString(), validType: 'number_1-9', isRequired: true, errorMessage: 'Сумма не корректное значение.'},
            
            {fieldName: 'educationDateStart', value: txtEducationDateStart.value, validType: 'date', isRequired: true, errorMessage: 'Начало обучения, не корректная дата.'},
            {fieldName: 'educationDateEnd', value: txtEducationDateEnd.value, validType: 'date', isRequired: true, errorMessage: 'Конец обучения, не корректная дата.'},
            
            {fieldName: 'sphere', value: txtSphere.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Сфера не указано.'},
            {fieldName: 'qualification', value: txtQualification.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Квалификация не указано.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnimation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            loadingTitle.setValue(cardMode === 'new' ? 'Добавление программы...' : 'Сохранение данных...')

            saveCard(contractID || 0, contractProgramID || 0, {
                actionForm: cardMode || 'new',

                courseProgramID: Number(txtCourseProgramID.value),
                courseID: Number(txtCourseNameID.value),
                courseName: txtCourseName.value,
                documentTypeID: Number(txtProgramTypeID.value),
                durationEducation: txtDurationEducation.value.toString(),
                volumeHours: Number(txtVolumeHours.value),
                price: Number(txtSumm.value),
                sphere: txtSphere.value,
                qualification: txtQualification.value,
                requirementName: txtRequirementName.value,
                dateEducationStart: txtEducationDateStart.value,
                dateEducationEnd: txtEducationDateEnd.value
            }).then((res) => {
                if (res === 200) {
                    notify && notifyOpen('Программа успешно добавлена.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            }).finally(() => {
                loadingTitle.setValue('Загрузка данных...')
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnimation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }


    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardContractProgram = {
        loadingTitle,
        isOpen, cardMode, handlerButtonCancel,
        handleOpenModalCalendar, handleOpenModalDict, txtEducationDateStartOnChange, 
        handleClickProgramList, txtDurationEducationOnChange, handleBtnSave,

        isLoadingDict: isLoading, courseProgramList,
        isLoadingCard, fieldsError,
        txtCourseName, txtProgramType,
        txtDurationEducation, txtVolumeHours, txtSumm,
        txtEducationDateStart, txtEducationDateEnd,
        txtSphere, txtQualification, txtRequirementName,
    }
    
    return <>   
        <CardContractProgram {...propsToComponent} />

        <ModalCalendar {...modalCalendar.value} />
        <SelectDictionaryContainer {...modalDict.value} />
    </>
}