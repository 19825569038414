import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { getApplicationSource, getApplicationStatuses, getApplicationType, getApplicationsNumber, getAttestationType, getCitizenships, getContractDocuments, getContractSections, getContractSended, getContractTypes, getContractViews, getContractsStatuses, getCoursePrograms, getCourses, getCoursesContract, getDocumentTypes, getEducationLevels, getPrintStatus, getProgramType, getSiteDbName, getVolumeHours } from './dict.api'
import { IDictState, IDictActions, IApplicationStatusesList, ICoursesList, ISitesDbNameList, IProgramTypeList, IAttestationTypeList, IVolumeHoursList, IApplicationTypeList, IApplicationSourceList, ICitizenshipsList, IEducationLevelsList, IContractsStatusesList, IApplicationsNumberList, IContractTypesList, IContractViewsList, ICourseProgramsList, ICoursesContractList, IDocumentTypesList, IContractDocumentsList, IContractSectionsList, IContractSendedList, IPrintStatusList } from './dict.types'


export const initialStateDict: IDictState = {
    error: null,
    isLoading: false,
}

/** Хранилище - Справочники */
export const useDict = create<IDictState & IDictActions>()(devtools((set) => ({
    ...initialStateDict,

    clearDictState: () => {set(initialStateDict)},

    /** Имена баз данных сайтов */
    actionGetSitesDbName: async () => {
        let returnData: ISitesDbNameList[] = []
        set({isLoading: true})

        const res = await getSiteDbName()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Курсы */
    actionGetCourses: async (source: string) => {
        let returnData: ICoursesList[] = []
        set({isLoading: true})

        const res = await getCourses(source)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Курсы по договору */
    actionGetCoursesContract: async (source: string) => {
        let returnData: ICoursesContractList[] = []
        set({isLoading: true})

        const res = await getCoursesContract(source)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Типы программ */
    actionGetProgramType: async (source: string, courseID: number) => {
        let returnData: IProgramTypeList[] = []
        set({isLoading: true})

        const res = await getProgramType(source, courseID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Типы аттестации */
    actionGetAttestationType: async () => {
        let returnData: IAttestationTypeList[] = []
        set({isLoading: true})

        const res = await getAttestationType()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Обьем программы в часах */
    actionGetVolumeHours: async (source: string, courseID: number, programID: number) => {
        let returnData: IVolumeHoursList[] = []
        set({ isLoading: true })

        const res = await getVolumeHours(source, courseID, programID)
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            set({ error: null })
            returnData = res.data || []
        }

        set({ isLoading: false })
        return returnData
    },

    /** Получение списка программ по курсу */
    actionGetCoursePrograms: async (source: string, courseID: number) => {
        let returnData: ICourseProgramsList[] = []
        set({ isLoading: true })

        const res = await getCoursePrograms(source, courseID)
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            set({ error: null })
            returnData = res.data || []
        }

        set({ isLoading: false })
        return returnData
    },
    
    /** Статусы заявок */
    actionGetApplicationStatuses: async () => {
        let returnData: IApplicationStatusesList[] = []
        set({isLoading: true})

        const res = await getApplicationStatuses()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Тип заявки */
    actionGetApplicationType: async () => {
        let returnData: IApplicationTypeList[] = []
        set({isLoading: true})

        const res = await getApplicationType()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Источник заявки */
    actionGetApplicationSource: async () => {
        let returnData: IApplicationSourceList[] = []
        set({ isLoading: true })

        const res = await getApplicationSource()
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            set({ error: null })
            returnData = res.data || []
        }

        set({ isLoading: false })
        return returnData
    },

    /** Гражданство */
    actionGetCitizenships: async () => {
        let returnData: ICitizenshipsList[] = []
        set({ isLoading: true })

        const res = await getCitizenships()
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            set({ error: null })
            returnData = res.data || []
        }

        set({ isLoading: false })
        return returnData
    },

    /** Уровень образования */
    actionGetEducationLevels: async () => {
        let returnData: IEducationLevelsList[] = []
        set({ isLoading: true })

        const res = await getEducationLevels()
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            set({ error: null })
            returnData = res.data || []
        }

        set({ isLoading: false })
        return returnData
    },
    
    /** Статусы договоров */
    actionGetContractsStatuses: async () => {
        let returnData: IContractsStatusesList[] = []
        set({isLoading: true})

        const res = await getContractsStatuses()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Номера заявок */
    actionGetApplicationsNumber: async () => {
        let returnData: IApplicationsNumberList[] = []
        set({isLoading: true})

        const res = await getApplicationsNumber()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Тип договора */
    actionGetContractTypes: async () => {
        let returnData: IContractTypesList[] = []
        set({isLoading: true})

        const res = await getContractTypes()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Вид договора */
    actionGetContractViews: async () => {
        let returnData: IContractViewsList[] = []
        set({isLoading: true})

        const res = await getContractViews()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Тип программы */
    actionGetDocumentTypes: async () => {
        let returnData: IDocumentTypesList[] = []
        set({isLoading: true})

        const res = await getDocumentTypes()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Документы по договору */
    actionGetContractDocuments: async () => {
        let returnData: IContractDocumentsList[] = []
        set({isLoading: true})

        const res = await getContractDocuments()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Секции файлов по договору */
    actionGetContractSections: async () => {
        let returnData: IContractSectionsList[] = []
        set({isLoading: true})

        const res = await getContractSections()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Секции файлов по договору */
    actionGetContractSended: async () => {
        let returnData: IContractSendedList[] = []
        set({isLoading: true})

        const res = await getContractSended()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

    /** Статус печати */
    actionGetPrintStatus: async () => {
        let returnData: IPrintStatusList[] = []
        set({isLoading: true})

        const res = await getPrintStatus()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            returnData = res.data || []
        }

        set({isLoading: false})
        return returnData
    },

}), {name: 'dictState'}))