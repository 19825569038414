import Dropzone from 'react-dropzone'

import { ICardListenerTabFiles, ICardListenerTabPrimary, ICardListener } from './_types'

import { CardListenerTabPrimary } from './_tab.primary'
import { CardListenerTabFiles } from './_tab.files'
import { CardListenerTabLogs } from './_tab.logs'

import { Flex, ToolTip, Loader, Drawer, Divider, Button, Tab } from 'components/ui'
import { IconClearClose, IconFiles, IconListeners, IconLogs, IconMainGrid } from 'images/icons'


/** Карточка по слушателю */
export const CardListener = ({
    isOpen, cardMode, handlerButtonCancel, filesList,
    isLoading, tabActive, fieldsError, listenerNumber, listenerDateCreate, clientSnilsAuto,
    txtCitizenshipID, txtCitizenship, txtSnils, txtClientPhone, txtClientEmail,
    txtLastName, txtFirstName, txtSecondName, txtBirthDate, txtFullNameFrom,
    txtPassport, txtAddress, txtEducationLevelID, txtEducationLevel, txtLastNameDiplom, txtDiplom,
    txtComment, txtLogin, txtPassword, txtSex,
    handleOpenModalCalendar, handleOpenModalDict, handleChangePhone, fileUploader,
    handleBtnSave, handlerSnilsAuto, handleChangeSnils, handlerPhoneGenerate, handlerSelectedFiles, handlerSelectedFilesAll,
    handlerDownloadFiles, handlerDeleteFiles, handlerOpenModalPreview,
}: ICardListener) => {
    const propsToTabPrimary: ICardListenerTabPrimary = { // Свойства передеваемые во вкладку primary
        cardMode, fieldsError, clientSnilsAuto,
        txtCitizenshipID, txtCitizenship, txtSnils, txtClientPhone, txtClientEmail,
        txtLastName, txtFirstName, txtSecondName, txtBirthDate, txtFullNameFrom,
        txtPassport, txtAddress, txtEducationLevelID, txtEducationLevel, txtLastNameDiplom, txtDiplom,
        txtComment, txtLogin, txtPassword, txtSex,
        handleOpenModalDict, handleOpenModalCalendar, handleChangePhone, handlerSnilsAuto, handleChangeSnils, handlerPhoneGenerate,
    }

    const propsToTabFiles: ICardListenerTabFiles = {
        filesList, handlerSelectedFiles, handlerSelectedFilesAll, fileUploader, handlerDownloadFiles, handlerDeleteFiles, handlerOpenModalPreview,
    }

    const getCardPage = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': return <CardListenerTabPrimary {...propsToTabPrimary} />
            case 'files': return <CardListenerTabFiles {...propsToTabFiles} />
            case 'logs': return <CardListenerTabLogs />
        }
    }

    const getCardPageBtnPanel = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end'>
                        {/* <Flex direction='row' gap='4px'>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handlerOpenModalConfirm(true, 'Демо-доступ...', 'Вы действительно хотите создать демо-доступ, для данного клиента?')}>Демо-доступ</Button>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handleOpenModalMailings(true)}>Рассылки</Button>
                        </Flex> */}

                        {/* <Divider type='vertical' margin='8px' /> */}
                        <Button padding='0 10px' onClick={handleBtnSave}>
                            {cardMode === 'edit' ? 'Сохранить' : 'Создать слушателя'}
                        </Button>
                    </Flex>
                </Flex>
            }

            case 'files': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end'>
                        <Flex direction='row' gap='4px' isVisible={filesList.value.filter((item) => item.isSelected).length > 0}>
                            <Button padding='0 10px' variant='primaryDark' onClick={() => handlerDeleteFiles()}>Удалить</Button>
                            <Button padding='0 10px' variant='primaryDark' onClick={() => handlerDownloadFiles()}>Скачать</Button>
                            <Divider type='vertical' margin='8px' />
                        </Flex>

                        <Dropzone onDropAccepted={(files) => fileUploader(files)}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button padding={'0 10px'}>Загрузить</Button>
                                </div>
                            )}
                        </Dropzone>
                    </Flex>
                </Flex>
            }
            case 'logs': return ''
        }
    }

    return <>
        <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
            <Flex className='drawer_container'>
                <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                    <header>
                        <Flex gap='4px' direction='row' alignItems='center'>
                            <IconListeners color='#fff' width={20} height={20} />
                            <h4>{cardMode === 'edit' ? 'Анкета слушателя' : 'Создание слушателя'}</h4>
                        </Flex>

                        {cardMode === 'edit' && <h5>№ {listenerNumber.value} от {listenerDateCreate.value}</h5>}
                    </header>

                    <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='drawer_container-body_bottom_panel'>
                    <Flex gap='6px' className='drawer_container-content' overflow={tabActive.value === 'files' ? 'hidden' : 'scroll'} padding={tabActive.value === 'files' ? '2px 0px 0px 0px' : '4px 8px 4px 4px'}>
                        {getCardPage(tabActive.value)}
                    </Flex>

                    {getCardPageBtnPanel(tabActive.value)}
                </Flex>

                <Tab.Container>
                    <Tab.Item tabName='primary' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Основные данные' onClick={tabActive.setValue}><IconMainGrid /></Tab.Item>
                    <Tab.Item tabName='files' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Файлы слушателя' onClick={tabActive.setValue}><IconFiles /></Tab.Item>
                    <Tab.Item tabName='logs' tabNameActive={tabActive.value} tabToolTipPlace='top-end' tabToolTipTitle='Журнал изменений' onClick={tabActive.setValue}><IconLogs /></Tab.Item>
                </Tab.Container>

                {<Loader isOpen={isLoading} color='#f34e2e' title='Загрузка анкеты...' backdrop />}
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        </Drawer>
    </>
}