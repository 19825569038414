import React, { memo } from 'react'

import { IBadge } from './type'
import css from './index.module.scss'


/** Небольшое числовое значение или дескриптор состояния для элементов пользовательского интерфейса. */
export const Badge = memo(({
    children, position = 'bottomRight', background = '#28ac28', color = 'white', isVisible = true
}: IBadge): React.JSX.Element => {
    const getPositionClassName = () => {
        switch (position) {
            case 'topLeft': return css.topLeft
            case 'topRight': return css.topRight
            case 'bottomLeft': return css.bottomLeft
            case 'bottomRight': return css.bottomRight

            default: return css.bottomRight
        }
    }
    
    const elementStyle: React.CSSProperties = {
        color: color,
        background: background,
        border: '1px solid #fff',

        display: isVisible ? 'flex' : 'none'
    }
    
    return <div className={`${css.container} ${getPositionClassName()}`} style={elementStyle}>
        <p>{children}</p>
    </div>
})