import { ICardContractSended } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconClearClose } from "images/icons"

import { Empty, Flex, ToolTip, Modal, Loader, Divider, Button, Table } from "components/ui"


/** Окно по выбору что отправлять в телегу */
export const CardContractSended = ({isOpen, isLoading, sendedList, handlerBtnSave, handlerButtonCancel, handlerSelectedDocuments}: ICardContractSended) => {
    return <>
        <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} overflowY="hidden" maxWidth="350px">
            <Flex className='modal_container'>
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>Отправка документов</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body'>
                    <Flex className='modal_container-content'>
                        <Loader isOpen={isLoading} backdrop color='#f34e2e' title='Загрузка документов' />

                        <Table.Body overflowY='hidden' minHeight='200px'>
                            {!sendedList.value
                                ? <Empty image={imgEmty} title="Документы отсутствуют" />
                                : sendedList.value.map((item, index) => (
                                    <Table.Row key={index} indexContent={`${item.isSelect ? '✔' : '✘'}`} indexContentWidth='30px' paddingInset='2px 8px' backgroundColor={`${item.isSelect ? '#32d732' : '#f34e2e'}`} onClick={() => handlerSelectedDocuments(item.id)}>
                                        <p>{item.name}</p>
                                        <p style={{ opacity: '.7' }}>{item.telegram}</p>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Flex>

                    <Divider />
                    <Button width='100%' onClick={handlerBtnSave}>Отправить</Button>
                </Flex>
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
            <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
            <ToolTip anchorSelect="#tooltipBtnPaymentRefund" content='Кликните для изменения' place="bottom" />
        </Modal>
    </>
}