import React, { memo } from 'react'
import parse from 'html-react-parser'

import imgSuccess from './img/success.png'
import imgInfo from './img/info.png'
import imgWarning from './img/warning.png'
import imgError from './img/error.png'

import css from './notification.module.scss'
import { Button } from '../button'


/** Всплывающее сообщение */
export default memo((props: any): JSX.Element => {
    const [exit, setExit] = React.useState(false)
    const [width, setWidth] = React.useState(0)
    const [intervalID, setIntervalID] = React.useState<any | null>(null)

    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth((prev: any) => {
                if (prev < 100) {
                    return prev + 0.5
                }

                clearInterval(id)
                return prev
            })
        }, props.duration / 100)

        setIntervalID(id)
    }

    const handlePauseTimer = () => {
        clearInterval(intervalID)
    }

    const handleCloseNotification = () => {
        handlePauseTimer()
        setExit(true)

        setTimeout(() => {
            props.dispatch({
                type: 'REMOVE_NOTIFICATION',
                id: props.id
            })
        }, 400)
    }

    React.useEffect(() => {
        if (width === 100) {
            handleCloseNotification()
        }
    }, [width]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        handleStartTimer()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer}
            className={`${css.notificationSuccess} ${exit ? css.exit : ''}`}
            style={{ background: `${props.type === 'success' ? '#00CC99' : props.type === 'info' ? '#5458F7' : props.type === 'warning' ? '#F2C94C' : props.type === 'error' ? '#EB5757' : '#fff'}` }}>

            <div className={css.notificationContent}>
                <Button variant='iconBg' size='xs' onClick={handleCloseNotification}>
                    <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_239_32)">
                            <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                        </g>
                        <defs>
                            <clipPath id="clip0_239_32">
                                <rect width="26" height="26" fill="currentColor" />
                            </clipPath>
                        </defs>
                    </svg>
                </Button>

                <img src={
                    props.type === 'success' ? imgSuccess :
                    props.type === 'info' ? imgInfo :
                    props.type === 'warning' ? imgWarning :
                    props.type === 'error' ? imgError :
                    ''} alt="Тип сообщения"
                />

                <div className={css.message}>{parse(props.message || '')}</div>
                <div className={css.barContainer}>
                    <div className={css.bar} style={{ width: `${width}%` }}></div>
                </div>
            </div>
        </div>
    )
})