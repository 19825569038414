import { IContractsFilter } from './_types'

import { IconClearClose, IconDateEdit } from 'images/icons'
import { Flex, Grid, ToolTip, Input, Drawer, Divider, Button, Table } from 'components/ui'



/** Фильтр договоров */
export const ContractsFilter = ({
    isOpen = false, handlerButtonCancel,
    filterContractStatuses, handleApplyClearFilter,
    filterDateStartEducationFrom, filterDateStartEducationBefore,
    filterDateCreateFrom, filterDateCreateBefore,
    handleOpenModalCalendar
}: IContractsFilter) => {
    const handleSelectStatus = (id: number) => {
        const updatedStatuses = filterContractStatuses.value.map(status => {
            if (status.id === id) {
                return { ...status, isSelected: !status.isSelected }
            }

            return status // Возвращаем неизмененный элемент для остальных элементов массива
        })

        filterContractStatuses.setValue(updatedStatuses)
    }

    const handleClearSelection = () => {
        const updatedStatuses = filterContractStatuses.value.map(status => ({ ...status, isSelected: false }))
        filterContractStatuses.setValue(updatedStatuses)
    }

    return <>
        <Drawer isOpen={isOpen} zIndex={1001} isEsc isWrapper onCancel={handlerButtonCancel} maxWidth='370px' placement='right'>
            <Flex className='drawer_container'>
                <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                    <h4>Фильтр по договорам</h4>
                    <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='drawer_container-body'>
                    <Flex gap='20px' className='drawer_container-content'>
                        <Flex gap="2px">
                            <Divider prefix='Дата начала обучения' suffix={(filterDateStartEducationFrom.value || filterDateStartEducationBefore.value) && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => { filterDateStartEducationFrom.setValue(''); filterDateStartEducationBefore.setValue('') }}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px" direction="row">
                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='от' width='100%' {...filterDateStartEducationFrom}
                                    suffix={<Flex direction="row">
                                        {filterDateStartEducationFrom.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateStartEducationFrom.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateStartEducationFrom')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />

                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='по' width='100%' {...filterDateStartEducationBefore}
                                    suffix={<Flex direction="row">
                                        {filterDateStartEducationBefore.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateStartEducationBefore.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateStartEducationBefore')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Дата создания договора' suffix={(filterDateCreateFrom.value || filterDateCreateBefore.value) && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => { filterDateCreateFrom.setValue(''); filterDateCreateBefore.setValue('') }}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px" direction="row">
                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='от' width='100%' {...filterDateCreateFrom}
                                    suffix={<Flex direction="row">
                                        {filterDateCreateFrom.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateCreateFrom.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateCreateFrom')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />

                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='по' width='100%' {...filterDateCreateBefore}
                                    suffix={<Flex direction="row">
                                        {filterDateCreateBefore.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateCreateBefore.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateCreateBefore')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Статус договора' suffix={filterContractStatuses.value.filter((item) => item.isSelected === true).length > 0 && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={handleClearSelection}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px">
                                {filterContractStatuses.value.length === 0
                                    ? ''
                                    : filterContractStatuses.value.map((item) => (
                                        <Table.Row key={item.id} indexContent={`${item.isSelected ? '✔' : item.id}`} backgroundColor={`${item.isSelected ? '#f34e2e' : ''}`} indexContentWidth="30px" paddingInset="4px 8px" onClick={() => handleSelectStatus(item.id)}>
                                            <p>{item.name}</p>
                                        </Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>
                    </Flex>

                    <Grid gap="5px" gridTemplate="columns" gridTemplateColumns="1fr 1fr">
                        <Button onClick={() => handleApplyClearFilter('apply')}>Применить</Button>
                        <Button variant="primaryDark" onClick={() => handleApplyClearFilter('clear')}>Очистить</Button>
                    </Grid>
                </Flex>
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="bottom-end" />
            <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="right" />
        </Drawer>
    </>
}