import React, { memo } from 'react'
import { Tooltip } from 'react-tooltip'
import { ITooltip } from './type'


/** Подсказка */
export const ToolTip = memo(({
    place, content, anchorSelect, id, children
}: ITooltip): React.JSX.Element => {
    return <Tooltip 
        id={id}
        anchorSelect={anchorSelect}
        place={place}
        content={content} 
        style={{ borderRadius: '10px' }} border='2px solid #f34e2e'
    >{children}</Tooltip>
})