// import { useEffect, useState } from 'react'
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { ICardCompanyTabPrimary } from './_types'
import { IconClearClose } from 'images/icons'

import { Flex, ToolTip, Textarea, Input, Divider, Checkbox, Button } from 'components/ui'


/** Основная вкладка в анкете контрагента */
export const CardListenerTabPrimary = ({
    fieldsError, commpanyINNAuto, txtINN, txtKPP, isReadOnlyKPP, txtCompanyName, txtCompanyNameShort,
    txtManagerPosition, txtActingOnBasis, txtLastName, txtFirstName, txtSecondName, txtInPersonWhom,
    txtAddress, txtRequisites, txtComment,
    handlerINNAuto, handleChangeINN, handleChangeKPP,
}: ICardCompanyTabPrimary) => {
    // const {transcript, resetTranscript} = useSpeechRecognition()
    // const [isListening, setIsListening] = useState<boolean>(false)

    // useEffect(() => {
    //     // txtPassport.setValue(transcript)
    // }, [transcript])

    // const startListening = () => {
    //     setIsListening(true)
    //     SpeechRecognition.startListening({ continuous: true })
    // }

    // const stopListening = () => {
    //     setIsListening(false)
    //     SpeechRecognition.stopListening()
    // }

    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }

    // const getBtnSelect = (handleBtnClick: () => void) => {
    //     return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    // }

    // const getBtnSelectCalendar = (handleBtnClick: () => void) => {
    //     return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    // }

    return <>
        <Flex gap="20px">
            <Flex gap="5px">
                <Divider prefix={<h4>Основная информация</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.inn ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">ИНН контрагента <p>✸</p></Flex>
                            <Input placeholder="ИНН контрагента" paddingRight={4} mask='999999999999' maskChar=""
                                type={!commpanyINNAuto.value ? 'mask' : 'text'} isReadOnly={commpanyINNAuto.value}
                                value={txtINN.value} onChange={(e) => handleChangeINN(e.target.value)}
                            />
                            <Checkbox id='toolTipINN' isPositionAbsolute right='18px' top='8px' checked={commpanyINNAuto.value} onChange={(e) => handlerINNAuto(e.target.checked)} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.kpp ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">КПП контрагента {!isReadOnlyKPP.value && <p>✸</p>}</Flex>
                            <Input placeholder="КПП контрагента" paddingRight={4} mask='999999999' type='mask' maskChar="" 
                                suffix={txtKPP.value && getBtnClear(() => txtKPP.setValue(''))} isDisabled={isReadOnlyKPP.value}
                                value={txtKPP.value} onChange={(e) => handleChangeKPP(e.target.value)}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Полное наименование контрагента <p>✸</p></Flex>
                            <Input placeholder="Полное наименование контрагента" paddingRight={4} 
                                suffix={txtCompanyName.value && getBtnClear(() => txtCompanyName.setValue(''))} {...txtCompanyName} 
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyNameShort ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Краткое наименование контрагента <p>✸</p></Flex>
                            <Input placeholder="Краткое наименование контрагента" paddingRight={4} 
                                suffix={txtCompanyNameShort.value && getBtnClear(() => txtCompanyNameShort.setValue(''))} {...txtCompanyNameShort}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Руководитель</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.managerPosition ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Должность руководителя {(isReadOnlyKPP.value && !commpanyINNAuto.value) ? '' : <p>✸</p>}</Flex>
                            <Input placeholder="Должность руководителя" paddingRight={4} {...txtManagerPosition}
                                isDisabled={(isReadOnlyKPP.value && !commpanyINNAuto.value) ? true : false}
                                suffix={txtManagerPosition.value && getBtnClear(() => txtManagerPosition.setValue(''))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.actingOnBasis ? 'input_error' : 'input_error input_error-active'}>
                            <Flex direction="row" className="input_header">Действующего на основании {(isReadOnlyKPP.value && !commpanyINNAuto.value) ? '' : <p>✸</p>}</Flex>
                            <Input placeholder="Действующего на основании" paddingRight={4} {...txtActingOnBasis}
                                isDisabled={(isReadOnlyKPP.value && !commpanyINNAuto.value) ? true : false}
                                suffix={txtActingOnBasis.value && getBtnClear(() => txtActingOnBasis.setValue(''))}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.lastName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Фамилия руководителя <p>✸</p></Flex>
                            <Input placeholder="Фамилия руководителя" paddingRight={4} {...txtLastName}
                                suffix={txtLastName.value && getBtnClear(() => txtLastName.setValue(''))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.firstName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Имя руководителя <p>✸</p></Flex>
                            <Input placeholder="Имя руководителя" paddingRight={4} 
                                suffix={txtFirstName.value && getBtnClear(() => txtFirstName.setValue(''))} {...txtFirstName}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex direction="row" className="input_header">Отчество руководителя</Flex>
                            <Input placeholder="Отчество руководителя" paddingRight={4} 
                                suffix={txtSecondName.value && getBtnClear(() => txtSecondName.setValue(''))} {...txtSecondName}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.inPersonWhom ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">В лице кого <p>✸</p></Flex>
                            <Input placeholder="Должность + Ф.И.О. руководителя" paddingRight={4} 
                                suffix={txtInPersonWhom.value && getBtnClear(() => txtInPersonWhom.setValue(''))} {...txtInPersonWhom}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Прочее</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.address ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Юридический адрес <p>✸</p></Flex>
                            <Input placeholder="Юридический адрес" paddingRight={4} 
                                suffix={txtAddress.value && getBtnClear(() => txtAddress.setValue(''))} {...txtAddress}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.requisites ? 'input_error' : 'input_error input_error-active'}>
                            <Flex direction="row" className="input_header">Платёжные реквизиты <p>✸</p></Flex>
                            <Textarea placeholder="Платёжные реквизиты" rows={7} 
                                value={txtRequisites.value} onChange={(e) => txtRequisites.setValue(e.target.value)}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex direction="row" className="input_header">Комментарий</Flex>
                            <Textarea placeholder="Комментарий" rows={5} 
                                value={txtComment.value} onChange={(e) => txtComment.setValue(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
        <ToolTip anchorSelect="#toolTipINN" place="right">При установке галочки, ИНН сгенерируется автоматически.<br/>При снятии галочки, ИНН надо будет ввести вручную.</ToolTip>
        <ToolTip anchorSelect="#toolTipSexM" content='Мужской пол' place="top-end" />
        <ToolTip anchorSelect="#toolTipSexJ" content='Женский пол' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPhone" content='Сгенерировать телефон' place="bottom-end" />
    </>
}