import { ISelectCompanies } from './_types'

import imgEmty from 'images/empty.svg'
import { IconClearClose, IconEntity, IconInfo, IconSearch } from 'images/icons'

import { Empty, Flex, ToolTip, Pagination, Loader, Input, Drawer, Divider, Button, Table } from 'components/ui'


/** Выбор контрагента */
export const SelectCompanies = ({
    isOpen, handlerButtonOk, handlerButtonCancel,
    isLoading, totalCount, pageSize, companiesList, 
    txtSearch, txtSearchOnChange, currentPageNumber,
    handleOpenCardCompany,
}: ISelectCompanies) => {
    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconEntity color='#fff' width={20} height={20} />
                        <h4>Выбор контрагента</h4>
                    </Flex>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Loader isOpen={isLoading} backdrop color="#f34e2e" title="Загрузка контрагентов..." />

                <Flex gap='6px' className='drawer_container-content' overflow='hidden' padding='2px 0px 0px 0px'>
                    <Flex gap='4px' direction='row' justifyContent='space-between'>
                        <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                            value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                            paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                            suffix={<>
                                {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                                <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                            </>}
                        />

                        <Button id='tooltipBtnCreate' size="sm" variant="primary" padding='0 10px' onClick={() => handleOpenCardCompany(true, 'new')}>СОЗДАТЬ</Button>
                    </Flex>

                    <Divider />

                    <Table.Body>
                        {!companiesList.length 
                            ? <Empty image={imgEmty} title='Контрагенты отсутствуют' /> 
                            : companiesList.map((item) => (
                                <Table.Row key={item.companyID} indexContent={item.companyID} indexContentWidth='60px' paddingInset='2px 6px' onClick={() => handlerButtonOk && handlerButtonOk(item.companyID, item.companyNameShort, item.address)}>
                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.companyName}</p>
                                        <Flex gap='5px' direction='row'><p style={{opacity: '.8', color: `${item.companyINN.length > 12 && 'red'}`}}>{item.companyINN}</p> / <p style={{ opacity: '.8' }}>{item.companyKPP || '----'}</p></Flex>
                                    </div>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>

                    <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                        <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Создать нового контрагента' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Фамилии<br/>🗸 Имени<br/>🗸 Отчеству<br/>🗸 ИНН<br/>🗸 КПП<br/>🗸 Наименованию полному<br/>🗸 Наименованию короткому<br/>🗸 Адресу<br/>🗸 Реквизитам</ToolTip>
    </Drawer>
}