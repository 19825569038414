import { useEffect, useRef } from 'react'
import Dropzone from 'react-dropzone'
import moment from 'moment'
    
import { ICardListenerTabFiles } from './_types'

import imgEmty from 'images/empty.svg'
import { IconCheck, IconDelete, IconDownload, IconUnCheck } from 'images/icons'

import { Empty, ToolTip, Button, Table } from 'components/ui'


/** Вкладка файлы в анкете слушателя */
export const CardListenerTabFiles = ({
    filesList, handlerSelectedFiles, handlerSelectedFilesAll, fileUploader, 
    handlerDownloadFiles, handlerDeleteFiles, handlerOpenModalPreview
}: ICardListenerTabFiles) => {
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth;
            tableHeadNavRef.current.style.width = `${width}px`;
        }
    }, [filesList, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <Table.Container>
            <Table.Header id='hiddenForMobile' classGridTemplateColumns="listeners_files_header_grid_columns" marginLeft="0px" marginRight="10px">
                <Button id='tooltipCheckAll' isDisabled={filesList.value.length === 0} onClick={handlerSelectedFilesAll}>
                    {(filesList.value.length > 0 && filesList.value.filter(item => item.isSelected).length === filesList.value.length) ? <IconCheck /> : <IconUnCheck />}
                </Button>

                <span>Наименование файла<br />Размер файла</span>
                <span className="hiddenFor750 table_column_data_center">Дата создания</span>
                <span ref={tableHeadNavRef}></span>
            </Table.Header>

            <Dropzone onDropAccepted={(files) => fileUploader(files)} multiple={true}>
                {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                    <div {...getRootProps({ className: isDragAccept ? `dropZone_container dropZone_dragAccept` : `dropZone_container`, onClick: event => event.stopPropagation() })}>
                        <Table.Body>
                            {filesList.value.length === 0
                                ? <Empty image={imgEmty} />
                                : filesList.value.map((item, index) => (
                                    <Table.Row key={item.fileID} indexContent={index + 1} indexContentWidth='60px' paddingInset="0 0 0 12px" badge={
                                        <Button id='tooltipCheck' size='xs' onClick={(e) => { handlerSelectedFiles(item.fileID); e.stopPropagation() }}>{item.isSelected ? <IconCheck width={20} height={20} /> : <IconUnCheck width={20} height={20} />}</Button>
                                    } classGridTemplateColumns='listeners_files_grid_columns' onClick={() => handlerOpenModalPreview(true, item.filePath, item.fileName)}>
                                        <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                            <p>{item.fileName}</p>
                                            <p style={{ opacity: '.8' }}>Размер файла: {item.fileSize}</p>
                                        </div>

                                        <span className="hiddenFor750 table_column_data_center">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>

                                        <nav ref={tableNavRef}>
                                            <Button id='tooltipBtnDawnload' variant="icon" size="xs" onClick={(e) => {handlerDownloadFiles(item.fileID); e.stopPropagation()}}><IconDownload /></Button>
                                            <Button id='tooltipBtnDelete' variant="icon" size="xs" onClick={(e) => { handlerDeleteFiles(item.fileID); e.stopPropagation() }}><IconDelete /></Button>
                                        </nav>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </div>
                )}
            </Dropzone>
        </Table.Container>

        <ToolTip anchorSelect="#tooltipCheck" content='Выделить / Снять' place="left" />
        <ToolTip anchorSelect="#tooltipCheckAll" content='Выделить / Снять всё' place="left" />
        <ToolTip anchorSelect="#tooltipBtnDelete" content='Удалить файл' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnDawnload" content='Скачать файл' place="left" />
    </>
}