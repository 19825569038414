import { useEffect, useRef } from "react"
import moment from "moment"

import { ICardContractTabPrimary } from "./_types"
import CommonService from "services/common.service"

import imgEmty from 'images/empty.svg'
import { IconAdd, IconCalendar, IconClearClose, IconDelete, IconListeners, IconSelectData, IconUpdate } from "images/icons"

import { Empty, Flex, ToolTip, Textarea, Input, Divider, Button } from "components/ui"
import { TableBody, TableContainer, TableHeaders, TableRow, TableRowCard } from "components/ui/table"


/** Вкладка основная */
export const CardContractTabPrimary = ({
    cardMode, fieldsError, summDuty, paymentsList, programsList, txtPostPayment, txtOutsideMoodle, 
    txtApplicationID, txtContractDate, txtContractNumber, txtContractTypeID, txtContractType, txtContractView, txtContractStatus,
    txtCompanyName, txtCompanyContactFIO, txtCompanyContactPhone, txtCompanyContactEmail, txtComment,
    handlerOpenModalConfirm, handleOpenModalDict, handleOpenModalCalendar, handlerOpenModalProgram, handlerOpenModalCompanies, handlerOpenModalListeners,
    handlerOpenModalListenerProperty, handlerOpenModalPayments,
}: ICardContractTabPrimary) => {
    const formatter = new Intl.NumberFormat('ru-RU')
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth;
            tableHeadNavRef.current.style.width = `${width}px`;
        }
    }, [paymentsList.value, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }

    const getBtnSelect = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    }

    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }

    return <>
        <Flex gap="20px">
            <Flex gap="5px">
                <Divider prefix={<h4>Основная информация</h4>} />

                <Flex gap='15px'>
                    <Flex gap="15px" direction="row" justifyContent="flex-start" flexWrap="wrap" className="card_style">
                        <Flex gap="10px" direction="row" alignItems="center">
                            <b>Постоплата:</b><Button id="tooltipBtnPostPayment" onClick={() => txtPostPayment.setValue(txtPostPayment.value === 1 ? 0 : 1)} size="sm" padding='0 6px'>{txtPostPayment.value === 1 ? '✔' : '✘'}</Button>
                        </Flex>

                        <Divider type="vertical" margin="2px" />

                        <Flex gap="10px" direction="row" alignItems="center">
                            <b>Вне Moodle:</b><Button id="tooltipBtnPostPayment" onClick={() => txtOutsideMoodle.setValue(txtOutsideMoodle.value === 1 ? 0 : 1)} size="sm" padding='0 6px'>{txtOutsideMoodle.value === 1 ? '✔' : '✘'}</Button>
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} isVisible={cardMode === 'new'} className={!fieldsError.value.applicationID ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Номер заявки <p>✸</p></Flex>
                            <Input placeholder="Номер заявки" isReadOnly paddingRight={4} {...txtApplicationID}
                                suffix={getBtnSelect(() => handleOpenModalDict(true, 'applicationsNumber'))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.contractDate ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Дата договора <p>✸</p></Flex>
                            <Input placeholder="Дата договора" paddingRight={4} type='mask' mask='99.99.9999' maskChar="" {...txtContractDate}
                                suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'contractDate', txtContractDate.value))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} isVisible={cardMode === 'edit'} className={!fieldsError.value.contractNumber ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Номер договора <p>✸</p></Flex>
                            <Input placeholder="Номер договора" paddingRight={4} isReadOnly {...txtContractNumber} 
                                suffix={<Button id="tooltipBtnGenContractNumber" size="sm" variant="icon"
                                    onClick={() => handlerOpenModalConfirm(true, 'Номер договора', 'Вы действительно хотите сгененировать новый номер, для данного договора?', 'contractNumber')}><IconUpdate />
                                </Button>}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.contractType ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Тип договора <p>✸</p></Flex>
                            <Input placeholder="Тип договора" isReadOnly paddingRight={4} {...txtContractType}
                                suffix={getBtnSelect(() => handleOpenModalDict(true, 'contractType'))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} isVisible={txtContractTypeID.value !== 1} className={!fieldsError.value.contractView ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Вид договора <p>✸</p></Flex>
                            <Input placeholder="Вид договора" isReadOnly paddingRight={4} {...txtContractView}
                                suffix={getBtnSelect(() => handleOpenModalDict(true, 'contractView'))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.contractStatus ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Статус договора <p>✸</p></Flex>
                            <Input placeholder="Статус договора" isReadOnly paddingRight={4} {...txtContractStatus}
                                suffix={getBtnSelect(() => handleOpenModalDict(true, 'contractStatus'))}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px" isVisible={txtContractTypeID.value !== 1}>
                <Divider prefix={<h4>Юридическое лицо</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Наименование контрагента <p>✸</p></Flex>
                            <Input placeholder="Наименование контрагента" isReadOnly paddingRight={4} {...txtCompanyName}
                                suffix={getBtnSelect(() => handlerOpenModalCompanies(true))}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyContactFIO ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Ф.И.О. контактного лица <p>✸</p></Flex>
                            <Input placeholder="Ф.И.О. контактного лица" paddingRight={4} {...txtCompanyContactFIO}
                                suffix={txtCompanyContactFIO.value && getBtnClear(() => txtCompanyContactFIO.setValue(''))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyContactPhone ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Телефон контактного лица <p>✸</p></Flex>
                            <Input placeholder="Телефон контактного лица" paddingRight={4} {...txtCompanyContactPhone}
                                suffix={txtCompanyContactPhone.value && getBtnClear(() => txtCompanyContactPhone.setValue(''))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyContactEmail ? 'input_error' : 'input_error input_error-active'}>
                            <Flex direction="row" className="input_header">Email контактного лица</Flex>
                            <Input placeholder="Email контактного лица" paddingRight={4} {...txtCompanyContactEmail}
                                suffix={txtCompanyContactEmail.value && getBtnClear(() => txtCompanyContactEmail.setValue(''))}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px" isVisible={cardMode === 'edit'}>
                <Divider prefix={<h4>Программы и слушатели</h4>} suffix={<Flex direction="row" gap="10px" alignItems="center" isVisible={txtContractTypeID.value === 2 || (txtContractTypeID.value === 1 && programsList.value.length === 0)}>
                    <Button id='tooltipBtnCreate' size="sm" variant="icon" onClick={() => handlerOpenModalProgram(true, 'new')}><IconAdd /></Button>
                </Flex>} />

                <TableContainer>
                    <TableBody overflowY="hidden">
                        {programsList.value && !programsList.value.length
                            ? <Empty image={imgEmty} title='Программы отсутствуют' size='60px' />
                            : programsList.value && programsList.value.map((item) => (
                                <TableRowCard key={item.contractProgramID} classGridTemplateColumnsForHeader="contract_card_grid_columns_programs" paddingHeader="1px 1px 0 5px" childrenHeader={<>
                                    <div className='' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.courseName} 👉 {CommonService.formatDuration(item.durationEducation, item.volumeHours < 250)} 👉 {CommonService.formatHours(item.volumeHours)} 👉 {item.price} ₽ 👉 ({item.dateEducationStart} - {item.dateEducationEnd})</p>
                                        <p style={{ opacity: '.8' }}>{item.documentTypeName} 👉 {item.requirementName}</p>
                                    </div>

                                    <div style={{ gap: '4px', display: 'flex' }}>
                                        <Button id='tooltipBtnSelectListeners' variant="primaryLight" size="md" isVisible={txtContractTypeID.value === 2 || item.listenersList.length === 0} 
                                            onClick={(e) => {handlerOpenModalListeners(true, item.contractProgramID, item.qualification); e.stopPropagation()}}
                                        ><IconListeners /></Button>

                                        <Button id='tooltipBtnDeleteProgram' variant="primaryLight" size="md" isVisible={item.listenersList.length === 0} 
                                            onClick={(e) => {
                                                handlerOpenModalConfirm(true, 'Удаление программы', 'Вы действительно хотите удалить данную программу?', 'deleteProgram', item.contractProgramID); e.stopPropagation()}}
                                        ><IconDelete /></Button>
                                    </div>
                                </>} onClick={() => handlerOpenModalProgram(true, 'edit', item.contractProgramID)}>
                                    <TableBody overflowY="hidden">
                                        {!item.listenersList.length
                                            ? <Empty image={imgEmty} title="Слушатели отсутствуют" color="#fff" size='60px' />
                                            : item.listenersList.map((listener) => (
                                                <TableRow key={listener.contractListenerID} classGridTemplateColumns="contract_card_grid_columns_listeners" onClick={(e) => {handlerOpenModalListenerProperty(true, item.contractProgramID, listener.contractListenerID, item.documentTypeID, listener.attestationTypeID, listener.attestationTypeName, listener.qualification); e.stopPropagation()}}>
                                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                        <p>{`${listener.lastName} ${listener.firstName} ${listener.secondName || ''}`.trim()} 👉 {listener.attestationTypeName}</p>
                                                        <p style={{ opacity: '.8' }}>{listener.educationLevelName} 👉 {listener.qualification}</p>
                                                    </div>

                                                    <nav>
                                                        <Button id='tooltipBtnDelete' variant="icon" size="xs" onClick={(e) => {
                                                            handlerOpenModalConfirm(true, 'Удаление слушателя', `Вы действительно хотите удалить следующего слушателя?<br /><br />${listener.lastName} ${listener.firstName} ${listener.secondName}`, 'deleteListener', listener.contractListenerID); e.stopPropagation()
                                                        }}><IconDelete /></Button>
                                                    </nav>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </TableRowCard>
                            ))
                        }
                    </TableBody>
                </TableContainer>
            </Flex>

            <Flex gap="5px" isVisible={cardMode === 'edit'}>
                <Divider prefix={<h4>Оплаты</h4>} suffix={<Flex direction="row" gap="10px" alignItems="center">
                    <b>Долг: <b style={{color: 'red'}}>{formatter.format(summDuty.value)}</b> ₽</b>
                    <Divider type="vertical" size="20px" />
                    <Button id='tooltipBtnCreate' size="sm" variant="icon" onClick={() => handlerOpenModalPayments(true, 'new')}><IconAdd /></Button>
                </Flex>} />

                <Flex gap='15px' direction="row" justifyContent="space-between" flexWrap="wrap">
                    <TableContainer>
                        <TableHeaders id='hiddenFor550' classGridTemplateColumns="contract_card_grid_columns_payments" marginLeft="48px">
                            <span className='table_column_data_center hiddenFor750'>Номер<br />платежа</span>
                            <span className='table_column_data_center'>Сумма<br />платежа</span>
                            <span className='table_column_data_center'>Дата<br />платежа</span>
                            <span className='table_column_data_center hiddenFor550'>Дата<br />создания</span>
                            <span ref={tableHeadNavRef}></span>
                        </TableHeaders>

                        <TableBody overflowY="hidden">
                            {paymentsList.value && !paymentsList.value.length 
                                ? <Empty image={imgEmty} size='60px' title='Оплаты отсутствуют' />
                                : paymentsList.value && paymentsList.value.map((item, index) => (
                                    <TableRow key={item.contractsPaymentID} classGridTemplateColumns="contract_card_grid_columns_payments" indexContent={item.isReturn === 0 ? '+' : '-'} indexContentWidth="40px" onClick={() => handlerOpenModalPayments(true, 'edit', item.contractsPaymentID, item.paymentNumber, item.paymentDate, item.paymentSum, item.paymentPayer, item.isReturn)}>
                                        <span className='table_column_data_center hiddenFor750'>{item.paymentNumber}</span>
                                        <span className='table_column_data_center'>{formatter.format(item.paymentSum)} ₽</span>
                                        <span className='table_column_data_center'>{item.paymentDate}</span>
                                        <span className='table_column_data_center hiddenFor550'>{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>

                                        <nav ref={tableNavRef}>
                                            <Button id='tooltipBtnDelete' variant="icon" size="xs" onClick={(e) => {
                                                handlerOpenModalConfirm(true, 'Удаление оплаты', `Вы действительно хотите удалить оплату № ${item.paymentNumber}, сумма: ${formatter.format(item.paymentSum)}₽ ?`, 'deletePayment', item.contractsPaymentID); e.stopPropagation()
                                            }}><IconDelete /></Button>
                                        </nav>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </TableContainer>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Комментарий</h4>} />
                <Textarea placeholder="Комментарий" rows={5} value={txtComment.value} onChange={(e) => txtComment.setValue(e.target.value)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
        <ToolTip anchorSelect="#tooltipBtnDelete" content='Удалить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Добавить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnPostPayment" content='Кликните для изменения' place="bottom" />
        <ToolTip anchorSelect="#tooltipBtnGenContractNumber" content='Получить новый номер договора' place="left" />

        <ToolTip anchorSelect="#tooltipBtnDeleteProgram" content='Удалить программу' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelectListeners" content='Добавить слушателей' place="left" />
    </>
}