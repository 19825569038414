import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { IFinalExaminationActions, IFinalExaminationState, IFinalExaminationCard, IFinalExaminationList, IFinalExaminationJobsList } from './finalExamination.type'
import { getFinalExaminationArchive, getFinalExaminationCard, getFinalExaminationJobs, getFinalExaminationListeners, setFinalExaminationCardSave, setFinalExaminationInArchive, setFinalExaminationToPrint, setFinalExaminationWithArchive } from './finalExamination.api'



export const initialStateFinalExamination: IFinalExaminationState = {
    error: null,
    isReloadListeners: false,
    isReloadArchive: false,
    isLoadingCard: false,
    isLoadingJobs: false,
    countUnverifiedWorks: 0,
    countNewPrintDiplom: 0,
 
    listeners: {
        isLoading: false,

        items: [] as IFinalExaminationList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0,
        archiveCount: 0
    },
    archive: {
        isLoading: false,

        items: [] as IFinalExaminationList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0,
        archiveCount: 0
    },
}

/** Хранилище страницы - Итоговые работы */
export const useFinalExamination = create<IFinalExaminationState & IFinalExaminationActions>()(devtools((set) => ({
    ...initialStateFinalExamination,

    /** Очистка хранилища итоговых работ */
    clearFinalExaminationState: () => {set(initialStateFinalExamination)},
    
    /** Очистка хранилиа архива итоговых работ */
    clearFinalExaminationArchive: () => {
        set((state) => ({
            archive: {
                ...state.archive, 
                items: [],
                totalCount: 0,
                pageSize: 0,
                currentPage: 0,
                archiveCount: 0
            }
        }))
    },

    /** Количество работ на проверку */
    actionSetCountUnverifiedWorks: (count: number) => {set({countUnverifiedWorks: count})},
    /** Количество работ на печать */
    actionSetCountNewPrintDiplom: (count: number) => {set({countNewPrintDiplom: count})},


    /** Получение списка анкет итоговых работ */
    actionGetFinalExaminationListeners: async (pageNumber: number, search: string) => {
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await getFinalExaminationListeners(pageNumber, search)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadListeners: false})

            set((state) => ({
                listeners: {
                    ...state.listeners, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage,
                    archiveCount: res.data!.archiveCount
                }
            }))
        }

        set((state) => ({listeners: {...state.listeners, isLoading: false}}))
    },
    /** Получение списка анкет итоговых работ из архива */
    actionGetFinalExaminationArchive: async (pageNumber: number, search: string) => {
        set((state) => ({archive: {...state.archive, isLoading: !state.isReloadArchive}}))

        const res = await getFinalExaminationArchive(pageNumber, search)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadArchive: false})

            set((state) => ({
                archive: {
                    ...state.archive, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage,
                    archiveCount: res.data!.archiveCount
                }
            }))
        }

        set((state) => ({archive: {...state.archive, isLoading: false}}))
    },
    /** Получение списка итоговых работ */
    actionGetFinalExaminationJobs: async () => {
        let returnData: IFinalExaminationJobsList[] | null = null
        set({isLoadingJobs: true})

        const res = await getFinalExaminationJobs()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            if (res.data) returnData = res.data.data
        }

        set({isLoadingJobs: false})
        return returnData
    },



    /** Получение данных по анкете */
    actionGetFinalExaminationCard: async (finalExaminationID: number) => {
        let returnData: IFinalExaminationCard | null = null
        set({isLoadingCard: true})

        const res = await getFinalExaminationCard(finalExaminationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по анкете */
    actionSetFinalExaminationCardSave: async (finalExaminationID: number, themeIAR: string, iarScore: string, testScore: string) => {
        set({isLoadingCard: true})

        const res = await setFinalExaminationCardSave(finalExaminationID, themeIAR, iarScore, testScore)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },



    /** Отправка итоговой работы слушателя на печать */
    actionSetFinalExaminationToPrint: async (finalExaminationID: number) => {
        set({isLoadingCard: true})
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await setFinalExaminationToPrint(finalExaminationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

            if (res.message === 'OK') set({ error: null })

            set({isLoadingCard: false})
            set({isReloadListeners: false})
            set((state) => ({listeners: {...state.listeners, isLoading: false}}))
        return res.status
    },
    /** Перемещение итоговой работы слушателя в архив */
    actionSetFinalExaminationInArchive: async (finalExaminationID: number) => {
        set({isLoadingCard: true})
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await setFinalExaminationInArchive(finalExaminationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set({isLoadingCard: false})
        set({isReloadListeners: false})
        set((state) => ({listeners: {...state.listeners, isLoading: false}}))
        return res.status
    },
    /** Перемещение анкеты итоговой работы слушателя обратно на обработку */
    actionSetFinalExaminationWithArchive: async (finalExaminationID: number) => {
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadArchive}}))
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await setFinalExaminationWithArchive(finalExaminationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set({isReloadArchive: false})
        set({isReloadListeners: false})
        set((state) => ({listeners: {...state.listeners, isLoading: false}}))
        return res.status
    },

}), {name: 'finalExaminationState'}))