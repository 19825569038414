import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { IContractGenerateDocuments, IContractsFilter, IContractsFormSendedData, IContractsPaymentFormSendedData, IContractsProgramFormSendedData, IContractsProgramListenerFormSendedData, IResponseContractCard, IResponseContractCardPrograms, IResponseContractFiles, IResponseContractInfo, IResponseContractPayments, IResponseContractPrograms, IResponseContracts, IResponseContractsStatistics, IResponseNewContractNumber } from "./contracts.types"


const route = '/contracts'

/** Получение общей статистики */
export const getContractsStatistics = async () => {
    let response: IResponseContractsStatistics

    try {
        const res: AxiosResponse<IResponseContractsStatistics, AxiosError> = await api.get(`${route}/getContractsStatistics`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractsStatistics
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение списка договоров */
export const getContracts = async (pageNumber: number, search?: string, filter?: IContractsFilter) => {
    let response: IResponseContracts

    try {
        const res: AxiosResponse<IResponseContracts, AxiosError> = await api.get(`${route}/getContracts`, {params: {pageNumber, search, filter}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContracts
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка оплат по договору */
export const getContractPayments = async (contractID: number) => {
    let response: IResponseContractPayments

    try {
        const res: AxiosResponse<IResponseContractPayments, AxiosError> = await api.get(`${route}/getContractPayments?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractPayments
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка програм и слушателей по договору */
export const getContractProgramsListeners = async (contractID: number) => {
    let response: IResponseContractPrograms

    try {
        const res: AxiosResponse<IResponseContractPrograms, AxiosError> = await api.get(`${route}/getContractProgramsListeners?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractPrograms
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка файлов по договору */
export const getContractFiles = async (contractID: number) => {
    let response: IResponseContractFiles

    try {
        const res: AxiosResponse<IResponseContractFiles, AxiosError> = await api.get(`${route}/getContractFiles?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractFiles
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}




/** Получение данных по анкете договора */
export const getContractCard = async (contractID: number) => {
    let response: IResponseContractCard

    try {
        const res: AxiosResponse<IResponseContractCard, AxiosError> = await api.get(`${route}/getContractCard`, {params: {contractID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Изменение номера договора */
export const getNewContractNumber = async (contractID: number, contractDate: string) => {
    let response: IResponseNewContractNumber

    try {
        const res: AxiosResponse<IResponseNewContractNumber, AxiosError> = await api.get(`${route}/getNewContractNumber`, {params: {contractID, contractDate}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseNewContractNumber
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Данные по анкете программы (Добавление / Редактирование программы по договору) */
export const getContractCardPrograms = async (recordID: number, action: string) => {
    let response: IResponseContractCardPrograms

    try {
        const res: AxiosResponse<IResponseContractCardPrograms, AxiosError> = await api.get(`${route}/getContractCardPrograms`, {params: {recordID, action}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractCardPrograms
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете программы договора */
export const setContractCardProgramsSave = async (contractID: number, contractProgramID: number, data: IContractsProgramFormSendedData) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setContractCardProgramsSave?contractID=${contractID}&contractProgramID=${contractProgramID}`, {contractProgramData: data})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете программа-слушатель договора */
export const setContractCardProgramListenerSave = async (contractListenerID: number, data: IContractsProgramListenerFormSendedData) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setContractCardProgramListenerSave?contractListenerID=${contractListenerID}`, {contractProgramListenerData: data})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете программа-слушатель договора */
export const setContractCardPaymentSave = async (contractID: number, contractPaymentID: number, data: IContractsPaymentFormSendedData) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setContractCardPaymentSave?contractID=${contractID}&contractPaymentID=${contractPaymentID}`, {contractPaymentData: data})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Удаление файлов по договору */
export const setContractFilesDelete = async (contractID: number, fileID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.delete(`${route}/setContractFilesDelete?contractID=${contractID}&fileID=${fileID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Загрузка файлов по договору */
export const setContractUploadFiles = async (contractID: number, contractSectionID: number, files: File[]) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(
            `${route}/setContractUploadFiles?contractID=${contractID}&contractSectionID=${contractSectionID}`, { docs: files }, { headers: { "Content-Type": `multipart/form-data` } }
        )

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете договора */
export const setContractCardSave = async (contractID: number, data: IContractsFormSendedData) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setContractCardSave?contractID=${contractID}`, {contractData: data})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}


/** Допуск к обучению и отправка информации в Telegram канал (ИКТ - Доступы в ЛК) */
export const setCreatePrimaryAccess = async (contractID: number, isOutsideMoodle: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setCreatePrimaryAccess?contractID=${contractID}&isOutsideMoodle=${isOutsideMoodle}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Формирование документов по договору */
export const setContractGenerateDocument = async (contractID: number, documents: IContractGenerateDocuments[], paymentSum: string) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setContractGenerateDocument?contractID=${contractID}&paymentSum=${paymentSum}`, {documents})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Отправка → Документы на подпись (Договор, Счёт, Заявление) → Telegram канал (ИКТ - Оформление договоров) */
export const setSendedContractDocuments = async (contractID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setSendedContractDocuments?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Отправка → Заявление на доставку + Счёт → Telegram канал (ИКТ - Заявление на доставку) */
export const setSendedContractStatementDelivery = async (contractID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setSendedContractStatementDelivery?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}


/** Получение краткой информации для МИША ДЖЯН (WARNING GLOBAL) */
export const getContractInfo = async (contractID: string) => {
    let response: IResponseContractInfo

    try {
        const res: AxiosResponse<IResponseContractInfo, AxiosError> = await api.get(`${route}/getContractInfo?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseContractInfo
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Отказ от договора для МИША ДЖЯН (WARNING GLOBAL) */
export const setContractCanceled = async (contractID: string, comment: string) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setContractCanceled?contractID=${contractID}&comment=${comment}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
