import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { CardModeType } from '../common.type'
import { SelectCompanies } from './_interface'
import { ISelectCompanies, ISelectCompanyContainer } from './_types'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { useCompanies } from 'store/companies/companies.state'

import { CardCompanyContainer, ICardCompanyContainer } from '../cardCompany'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Выбор контрагента - Контейнер */
export const SelectCompaniesContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: ISelectCompanyContainer) => {
    const notify = useContext(NotificationContext)

    const { error, isReloadCompanies, isLoading, totalCount, pageSize, companiesList, getCompanies, clearCompanies } = useCompanies(useShallow((state) => ({
        error: state.error,
        isReloadCompanies: state.isReloadCompanies,
        
        isLoading: state.companies.isLoading,
        totalCount: state.companies.totalCount,
        pageSize: state.companies.pageSize,

        companiesList: state.companies.items,
        getCompanies: state.actionGetCompanies,
        clearCompanies: state.clearCompaniesState,
    })))

    const cardCompanies = useInput<ICardCompanyContainer>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')


    useEffect(() => {
        isOpen && loadData()

        return (() => {
            if (!isOpen) {
                clearCompanies()

                txtSearch.setValue('')
                currentPageNumber.setValue(1)
            }
        })
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => { if (error) return notify && notifyOpen(error?.message, error?.type, 3000, notify) }, [error]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { isOpen && loadData(txtSearch.value) }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { isReloadCompanies && loadData(txtSearch.value) }, [isReloadCompanies]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция по получению списка контрагентов */
    const loadData = (search?: string) => {
        getCompanies(currentPageNumber.value, search || '')
    }

    /** Открытие/закрытие карточки по контрагенту */
    const handleOpenCardCompany = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardCompanies.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardCompanies.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardCompanies.setValue({isOpen: false})
                loadData(txtSearch.value)
            }
        })
    }

    const debounceSearch = useDebounce(loadData, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска    

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ISelectCompanies = {
        isOpen, handlerButtonOk, handlerButtonCancel,

        isLoading, totalCount, pageSize, companiesList, 
        txtSearch, txtSearchOnChange, currentPageNumber,
        handleOpenCardCompany,
    }
    
    return <>
        <SelectCompanies {...propsToComponent} />

        <CardCompanyContainer {...cardCompanies.value} />
    </>
}