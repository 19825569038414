import { useContext, useEffect } from 'react'

import { SelectDictionary } from './_interface'
import { CommonListType, ISelectDictionary, ISelectDictionaryContainer } from './_types'

import { useInput } from 'hooks/useInput'
import { useDict } from 'store/dict/dict.state'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Выбор из справочника - Контейнер */
export const SelectDictionaryContainer = ({isOpen, dictName, dbName, whereParam1, whereParam2, handlerButtonCancel, returnSelectedData}: ISelectDictionaryContainer) => {
    const notify = useContext(NotificationContext)

    const { 
        isLoading, error, clearDictState,
        actionGetCoursesContract, actionGetCourses, actionGetProgramType, actionGetAttestationType, actionGetVolumeHours,  
        actionGetApplicationStatuses, actionGetApplicationType, actionGetApplicationSource, actionGetCitizenships,
        actionGetEducationLevels, actionGetApplicationsNumber, actionGetContractsStatuses, actionGetContractTypes, 
        actionGetContractViews, actionGetDocumentTypes, actionGetPrintStatus
    } = useDict()
    
    const headerText = useInput<string>('')
    const txtSearch = useInput<string>('')

    const selectedID = useInput<number>(0)
    const commonList = useInput<CommonListType[]>([])


    useEffect(() => {
        if (isOpen) {
            switch (dictName) {
                case 'courses': { // Наименование программы (Курсы)
                    actionGetCourses(dbName || '').then((res) => {
                        commonList.setValue(res.filter((fil) => fil.courseProgramID).map((item) => {
                            return ({
                                id: item.courseID,
                                name: item.courseName,
                                param1: item.courseProgramID.toString(),
                                param2: item.programID.toString(),
                                param3: item.programName,
                                param4: item.durationEducation,
                                param5: item.volumeHours,
                                param6: item.price
                            })
                        }))
                    })
                    headerText.setValue('Выбор курса')
                    break
                }

                case 'coursesContract': { // Наименование программы (Курсы) по договору
                    actionGetCoursesContract(dbName || '').then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.courseID,
                                name: item.courseName,
                                param1: item.sphere,
                                param2: item.qualification,
                                param3: item.requirementName,
                            })
                        }))
                    })
                    headerText.setValue('Выбор курса')
                    break
                }

                case 'programType': { // Тип программы
                    actionGetProgramType(dbName || '', Number(whereParam1)).then((res) => {
                        commonList.setValue(res.filter((fil) => fil.courseProgramID).map((item) => {
                            return ({
                                id: item.programID,
                                name: item.programName,
                                param1: item.courseProgramID.toString(),
                                param2: item.attestationTypeID.toString(),
                                param3: item.attestationTypeName,
                                param4: item.durationEducation,
                                param5: item.volumeHours,
                                param6: item.price
                            })
                        }))
                    })
                    headerText.setValue('Выбор типа программы')
                    break
                }

                case 'attestationType': { // Тип аттестации
                    actionGetAttestationType().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.attestationTypeID,
                                name: item.attestationTypeName,
                            })
                        }))
                    })
                    headerText.setValue('Выбор типа аттестации')
                    break
                }

                case 'volumeHours': { // Обьемы часов по программе
                    actionGetVolumeHours(dbName || '', Number(whereParam1), Number(whereParam2)).then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.courseProgramID,
                                name: item.volumeHours,
                                param1: item.durationEducation,
                                param2: item.price,
                            })
                        }))    
                    })
                    headerText.setValue('Выбор объёма часов')
                    break
                }

                case 'applicationStatus': { // Статусы заявки
                    actionGetApplicationStatuses().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.applicationStatusID,
                            name: item.applicationStatusName,
                            param1: item.applicationStatusColor,
                        })))    
                    })
                    headerText.setValue('Выбор статуса заявки')
                    break
                }

                case 'applicationType': { // Тип заявки
                    actionGetApplicationType().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.applicationTypeID,
                            name: item.applicationTypeName,
                        })))
                    })
                    headerText.setValue('Выбор типа заявки')
                    break
                }

                case 'applicationSource': { // Источник заявки
                    actionGetApplicationSource().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.applicationSourceID,
                            name: item.applicationSourceName,
                        })))
                    })
                    headerText.setValue('Выбор источника заявки')
                    break
                }

                case 'applicationsNumber': { // Номера заявок
                    actionGetApplicationsNumber().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.applicationID,
                            name: `${item.applicationID.toString()} - ${item.siteName}`,
                            param1: item.dataBaseName,
                            param2: item.siteName
                        })))
                    })
                    headerText.setValue('Выбор номера заявки')
                    break
                }

                case 'citizenship': { // Гражданство
                    actionGetCitizenships().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.citizenshipID,
                            name: `${item.citizenshipCode} - ${item.citizenshipName}`,
                        })))    
                    })
                    headerText.setValue('Выбор гражданства')
                    break
                }

                case 'educationLevel': { // Уровень образования
                    actionGetEducationLevels().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.educationLevelID,
                            name: item.educationLevelName,
                        })))
                    })
                    headerText.setValue('Выбор уровень образования')
                    break
                }

                case 'contractStatus': { // Статус договора
                    actionGetContractsStatuses().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.contractStatusID,
                            name: item.contractStatusName,
                        })))
                    })
                    headerText.setValue('Выбор статуса договора')
                    break
                }

                case 'contractType': { // Тип договора
                    actionGetContractTypes().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.contractTypeID,
                            name: item.contractTypeName,
                        })))
                    })
                    headerText.setValue('Выбор тип договора')
                    break
                }

                case 'contractView': { // Вид договора
                    actionGetContractViews().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.contractViewID,
                            name: item.contractViewName,
                        })))
                    })
                    headerText.setValue('Выбор вид договора')
                    break
                }

                case 'documentTypes': { // Тип программы
                    actionGetDocumentTypes().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.documentTypeID,
                            name: item.documentTypeName,
                        })))
                    })
                    headerText.setValue('Тип программы')
                    break
                }

                case 'printStatus': { // Статус печати
                    actionGetPrintStatus().then((res) => {
                        commonList.setValue(res.map((item) => ({
                            id: item.printStatusID,
                            name: item.printStatusName,
                        })))
                    })
                    headerText.setValue('Статус печати')
                    break
                }
            }
        }
        
        !isOpen && clearDict()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error) return notify && notifyOpen(error?.message, error?.type, 3000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция обнуления данных по справочнику */
    const clearDict = () => {
        clearDictState()

        headerText.setValue('')
        txtSearch.setValue('')

        selectedID.setValue(0)
        commonList.setValue([])
    }

    /** Нажатие на кнопку выбрать */
    const handlerButtonOk = (selectedID: number) => {
        const selectedRowToList = commonList.value.filter(val => val.id === selectedID)
        if (selectedRowToList.length === 0) return notify && notifyOpen('Для продолжения необходимо выбрать значение из справочника.', 'info', 3000, notify)

        returnSelectedData && returnSelectedData(
            selectedRowToList[0].id, selectedRowToList[0].name, 
            selectedRowToList[0].param1, selectedRowToList[0].param2, 
            selectedRowToList[0].param3, selectedRowToList[0].param4, 
            selectedRowToList[0].param5, selectedRowToList[0].param6
        )
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ISelectDictionary = {
        isOpen, isLoading,
        headerText: headerText.value, txtSearch, 
        selectedID, commonList,
        handlerButtonOk, handlerButtonCancel,
    }

    return <SelectDictionary {...propsToComponent} />
}