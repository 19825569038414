import { ILogin } from "./_types"

import logo from 'images/logo.png'
import { IconEye, IconEyeCrossed, IconPassword, IconUser } from "images/icons"

import { Flex, ToolTip, Input, Button } from "components/ui"


/** Авторизация пользователя */
export const Login = ({ isLoading, isPassword, txtLogin, txtPassword, handleButtonClickLogin, handleFormKeyEnterLogin }: ILogin) => {
    return <Flex height="100%" alignItems="center" justifyContent="center">
        <Flex gap="15px" className="auth_container">
            <Flex gap="5px" alignItems="center" textAlign="center">
                <img src={logo} alt="Лого университета" />
                <h4>Группа компаний<br />Система управления данными</h4>
            </Flex>

            <form onKeyUp={handleFormKeyEnterLogin} style={{ padding: '0 10px' }}>
                <Flex gap="20px">
                    <Input size="lg"
                        placeholder="Имя пользователя"
                        prefix={<IconUser opacity={.6} />}
                        value={txtLogin.value} onChange={(e) => txtLogin.setValue(e.target.value)}
                    />
                    <Input size="lg"
                        placeholder="Пароль пользователя"
                        prefix={<IconPassword opacity={.6} />}
                        suffix={
                            <Button id="my-anchor-element" variant='icon' size='sm' onClick={() => isPassword.setValue(!isPassword.value)}>
                                {isPassword.value ? <IconEyeCrossed /> : <IconEye />}
                            </Button>
                        }
                        type={isPassword.value ? 'text' : 'password'}
                        value={txtPassword.value} onChange={(e) => txtPassword.setValue(e.target.value)}
                    />

                    <Button size="lg" isLoading={isLoading} onClick={handleButtonClickLogin}>ВОЙТИ</Button>
                </Flex>
            </form>
        </Flex>

        <ToolTip anchorSelect="#my-anchor-element" place="bottom-end" content={`${isPassword.value ? 'Скрыть пароль' : 'Показать пароль'}`} />
    </Flex>
}