import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { useInput } from 'hooks/useInput'
import { useContracts } from 'store/contracts/contracts.state'

import { Flex, Input } from 'components/ui'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


export const InfoContract = () => {
    const urlParams = useParams()
    const notify = useContext(NotificationContext)

    const {error, getInfo} = useContracts(useShallow((state) => ({
        error: state.error,
        getInfo: state.actionGetContractInfo
    })))

    const txtContractNumber = useInput<string>('')
    const txtApplicationNumber = useInput<string>('')
    const txtAvailabilityPayments = useInput<string>('')
    const txtSignedFiles = useInput<string>('')


    useEffect(() => {
        if (urlParams.id) {
            getInfo(urlParams.id).then(res => {
                if (res) {
                    txtContractNumber.setValue(res.data?.contractNumber || '')
                    txtApplicationNumber.setValue(res.data?.applicationID || '')
                    txtAvailabilityPayments.setValue(res.data?.availabilityPayments || '')
                    txtSignedFiles.setValue(res.data?.signedFiles || '')
                }
            })
        }
    }, [urlParams.id, txtContractNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error) return notify && notifyOpen(error?.message, error?.type, 3000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

    return <Flex height='100%' alignItems='center' justifyContent='center'>
        <Flex gap='15px'>
            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Номер договора</Flex>
                <Input placeholder='Номер договора' isReadOnly {...txtContractNumber} />
            </Flex>

            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Номер заявки</Flex>
                <Input placeholder='Номер заявки' isReadOnly {...txtApplicationNumber} />
            </Flex>

            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Наличие оплат</Flex>
                <Input placeholder='Имеется (2) (15.12.2023)' isReadOnly {...txtAvailabilityPayments} />
            </Flex>

            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Подписанные документы</Flex>
                <Input placeholder='Загружены/Отсутствуют' isReadOnly {...txtSignedFiles} />
            </Flex>
        </Flex>
    </Flex>
}