import { ICardContractGenerateDoc } from './_types'

import imgEmty from 'images/empty.svg'
import { IconClearClose } from 'images/icons'

import { Empty, Flex, ToolTip, Modal, Loader, Input, Divider, Button, Table } from 'components/ui'


/** Окно по выбору документов для формирования */
export const CardContractGenerateDoc = ({
    isOpen, isCompany, isLoading, txtSum, documentsList, handlerSelectedDocuments, handlerBtnSave, handlerButtonCancel
}: ICardContractGenerateDoc) => {
    let oldSectionName: string = ''
    const getDivider = (sectionName: string) => {
        if (oldSectionName !== sectionName) {
            oldSectionName = sectionName
            return <Divider prefix={<h4>{sectionName}</h4>} />
        }
    }

    return <>
        <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} overflowY="hidden" maxWidth="350px">
            <Flex className='modal_container'>
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>Выбор документов</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body'>
                    <Flex className='modal_container-content'>
                        <Loader isOpen={isLoading} backdrop color='#f34e2e' title='Загрузка документов' />

                        <Table.Body overflowY='hidden' minHeight='200px'>
                            {!documentsList.value
                                ? <Empty image={imgEmty} title="Документы отсутствуют" />
                                : documentsList.value.map((item, index) => {
                                    return (
                                        <Flex key={index}>
                                            {getDivider(item.category)}

                                            <Table.Row classGridTemplateColumns='table_grid_column' indexContent={`${item.isSelect ? '✔' : '✘'}`} indexContentWidth='30px' paddingInset='5px 8px' backgroundColor={`${item.isSelect ? '#32d732' : '#f34e2e'}`} onClick={(e) => handlerSelectedDocuments(item.id)}>
                                                <p>{item.name}</p>
                                                <Input size='sm' {...txtSum} prefix={<span style={{textWrap: 'nowrap'}}>На сумму:</span>} isVisible={(isCompany === true && item.id === 4 && item.isSelect)} />
                                            </Table.Row>
                                        </Flex>
                                    )
                                })
                            }
                        </Table.Body>
                    </Flex>

                    <Divider />
                    <Button width='100%' onClick={handlerBtnSave}>Сформировать</Button>
                </Flex>
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
            <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
            <ToolTip anchorSelect="#tooltipBtnPaymentRefund" content='Кликните для изменения' place="bottom" />
        </Modal>
    </>
}