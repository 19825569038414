import { ToolTip } from "../../ui"
import { ICardContractDetailing } from "./_types"


/** Вкладка Детализация по договору */
export const CardContractTabDetailing = ({title}: ICardContractDetailing) => {
    return <>
        детализация по договору {title}
        <ToolTip anchorSelect="#tooltipBtnDeleteProgram" content='Удалить программу' place="left" />
        <ToolTip anchorSelect="#tooltipBtnDelete" content='Удалить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Добавить слушателей' place="left" />
    </>
}