import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import { IDeliveryList, IDeliveryActions, IDeliveryFilter, IDeliveryState, IDeliveryCardFilesList, IDeliveryCardTrackNumbersList, IDeliveryCard } from './delivery.type'
import { getDeliveryCardData, getDeliveryFilesList, getDeliveryList, getDeliveryTrackNumbersList, setDeliveryCardDataSave, setDeliverySendingEndCloseContract, setDeliverySendingFiles, setDeliveryTrackNumbersSave } from './delivery.api'


export const initialStateDelivery: IDeliveryState = {
    error: null,

    isReloadContracts: false,
    isLoadingCard: false,
    isLoadingCardFiles: false,
    isLoadingCardTrackNumbers: false,
 
    contracts: {
        isLoading: false,

        items: [] as IDeliveryList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },
}

/** Хранилище страницы - Доставки */
export const useDelivery = create<IDeliveryState & IDeliveryActions>()(devtools((set) => ({
    ...initialStateDelivery,

    clearDeliveryState: () => {set(initialStateDelivery)},


    /** Получение основного списка */
    actionGetDeliveryList: async (pageNumber: number, search: string, filter?: IDeliveryFilter) => {
        set((state) => ({contracts: {...state.contracts, isLoading: !state.isReloadContracts}}))

        const res = await getDeliveryList(pageNumber, search, filter)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadContracts: false})

            set((state) => ({
                contracts: {
                    ...state.contracts, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        set((state) => ({contracts: {...state.contracts, isLoading: false}}))
    },



    /** Получение списка файлов по анкете */
    actionGetDeliveryFilesList: async (contractID: number) => {
        let returnData: IDeliveryCardFilesList[] = []
        set({isLoadingCardFiles: true})

        const res = await getDeliveryFilesList(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data?.files
        }

        set({isLoadingCardFiles: false})
        return returnData
    },
    /** Получение списка трек-номеров по анкете */
    actionGetDeliveryTrackNumbersList: async (contractID: number) => {
        let returnData: IDeliveryCardTrackNumbersList[] = []
        set({isLoadingCardTrackNumbers: true})

        const res = await getDeliveryTrackNumbersList(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data?.trackNumbers
        }

        set({isLoadingCardTrackNumbers: false})
        return returnData
    },



    /** Получение данных по анкете */
    actionGetDeliveryCardData: async (contractID: number) => {
        let returnData: IDeliveryCard | null = null
        set({isLoadingCard: true})

        const res = await getDeliveryCardData(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по трек-номеру */
    actionSetDeliveryTrackNumbersSave: async (contractID: number, deliveryID: number, action: string, trackNumber: string, trackNumberComment: string) => {
        set({isLoadingCardTrackNumbers: true})

        const res = await setDeliveryTrackNumbersSave(contractID, deliveryID, action, trackNumber, trackNumberComment)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCardTrackNumbers: false})
        return res.status
    },
    /** Сохранение данных по анкете */
    actionSetDeliveryCardDataSave: async (contractID: number, deliveryToWhom: string, deliveryAddress: string, myComment: string, isAddressConfirmed: number) => {
        set({isLoadingCard: true})

        const res = await setDeliveryCardDataSave(contractID, deliveryToWhom, deliveryAddress, myComment, isAddressConfirmed)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({ isReloadContracts: true })
        }
                
        set({isLoadingCard: false})
        return res.status
    },



    /** Отправка информации в telegram канал (ИКТ - Сканы дипломов) */
    actionSetDeliverySendingFiles: async (contractListenerID: number) => {
        set({isLoadingCard: true})

        const res = await setDeliverySendingFiles(contractListenerID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Завершение обучения и отправка информации в telegram канал (ИКТ - Доставка) */
    actionSetDeliverySendingEndCloseContract: async (contractID: number, byTrackNumber: string) => {
        set({isLoadingCard: true})

        const res = await setDeliverySendingEndCloseContract(contractID, byTrackNumber)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadContracts: true})
        }
            
        set({isLoadingCard: false})
        return res.status
    },

}), {name: 'deliveryState'}))