import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { useInput } from 'hooks/useInput'
import { useContracts } from 'store/contracts/contracts.state'

import { Flex, Textarea, Input, Button } from 'components/ui'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


export const CanceledContract = () => {
    const urlParams = useParams()
    const notify = useContext(NotificationContext)

    const {error, getInfo, contractCancel} = useContracts(useShallow((state) => ({
        error: state.error,
        getInfo: state.actionGetContractInfo,
        contractCancel: state.actionSetContractCanceled
    })))

    const txtContractNumber = useInput<string>('')
    const txtApplicationNumber = useInput<string>('')
    const txtComment = useInput<string>('')


    useEffect(() => {
        if (urlParams.id) {
            getInfo(urlParams.id).then(res => {
                if (res) {
                    txtContractNumber.setValue(res.data?.contractNumber || '')
                    txtApplicationNumber.setValue(res.data?.applicationID || '')
                }
            })
        }
    }, [urlParams.id, txtContractNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error) return notify && notifyOpen(error?.message, error?.type, 3000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

    const saveData = () => {
        if (!txtComment.value) {return notify && notifyOpen('Необходимо ввести краткий комментарий!', 'info', 2000, notify)}

        if (urlParams.id) {
            contractCancel(urlParams.id, txtComment.value).then(res => {
                if (res === 200) return notify && notifyOpen('Статус успешно изменён!', 'success', 2000, notify)
            })
        }
    }
    
    return <Flex height='100%' alignItems='center' justifyContent='center'>
        <Flex gap='15px'>
            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Номер договора</Flex>
                <Input placeholder='Номер договора' isReadOnly {...txtContractNumber} />
            </Flex>

            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Номер заявки</Flex>
                <Input placeholder='Номер заявки' isReadOnly {...txtApplicationNumber} />
            </Flex>

            <Flex>
                <Flex gap="5px" direction="row" className="input_header">Краткий комментарий</Flex>
                <Textarea cols={30} rows={5} placeholder='Краткий комментарий' value={txtComment.value} onChange={(e) => txtComment.setValue(e.target.value)} />
            </Flex>

            <Button width='100%' onClick={saveData}>Сохранить</Button>
        </Flex>
    </Flex>
}