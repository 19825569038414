import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { useInput } from "hooks/useInput"
import { useDebounce } from "hooks/useDebounce"

import { useDict } from "store/dict/dict.state"
import { useDelivery } from "store/delivery/delivery.state"
import { IDeliveryFilter } from "store/delivery/delivery.type"

import { Delivery } from "./_interface"
import { DeliveryFilter } from "./_filter"
import { IDelivery, IDeliveryStatusesForFilter, IDeliveryWinFilter } from "./_types"

import { IModalCalendar, ModalCalendar } from "components/dumb/modal.calendar"

import { CardContractContainer, ICardContractContainer } from "components/smart/cardContract"
import { CardListenerContainer, ICardListenerContainer } from "components/smart/cardListener"
import { CardDeliveryContainer, ICardDeliveryContainer } from "components/smart/cardDelivery"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Контейнер страницы доставка */
export const DeliveryContainer = () => {
    const notify = useContext(NotificationContext)

    // const {accessLevelID} = useLogin(useShallow((state) => state.profile))
    const deliveryState = useDelivery(useShallow((state) => state))
    const dictState = useDict(useShallow((state) => state))

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const cardListener = useInput<ICardListenerContainer>({isOpen: false})
    const cardContract = useInput<ICardContractContainer>({isOpen: false})
    const cardDelivery = useInput<ICardDeliveryContainer>({isOpen: false})

    const isOpenFilter = useInput<boolean>(false)
    const txtSearch = useInput<string>('')
    const currentPageNumber = useInput<number>(1)

    const filterIsActivate = useInput<boolean>(false)
    const filterContractStatuses = useInput<IDeliveryStatusesForFilter[]>([])
    const filterDateStartEducationFrom = useInput<string>('')
    const filterDateStartEducationBefore = useInput<string>('')
    const filterDateCreateFrom = useInput<string>('')
    const filterDateCreateBefore = useInput<string>('')


    useEffect(() => {
        dictState.actionGetContractsStatuses().then((res) => {
            filterContractStatuses.setValue(
                res.map((item) => ({
                    id: item.contractStatusID,
                    name: item.contractStatusName,
                    isSelected: false
                }))
            )    
        })

        return (() => {
            deliveryState.clearDeliveryState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')
        
            isOpenFilter.setValue(false)
            filterIsActivate.setValue(false)
            filterContractStatuses.setValue([])
            filterDateStartEducationFrom.setValue('')
            filterDateStartEducationBefore.setValue('')
            filterDateCreateFrom.setValue('')
            filterDateCreateBefore.setValue('')
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (deliveryState.error) return notify && notifyOpen(deliveryState.error?.message, deliveryState.error?.type, 3000, notify)
    }, [deliveryState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadDataContracts(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        deliveryState.isReloadContracts && loadDataContracts(txtSearch.value)
    }, [deliveryState.isReloadContracts]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция по получению списка договоров */
    const loadDataContracts = (search?: string) => {
        let contractStatusesListSelected: string = ''

        if (filterContractStatuses.value.length > 0) {
            filterContractStatuses.value.forEach((item) => {
                if (item.isSelected) {
                    contractStatusesListSelected += `'${item.name}',`
                }
            })
        }

        const filterData: IDeliveryFilter = {
            contractsStatuses: contractStatusesListSelected,
            dateStartEducationFrom: filterDateStartEducationFrom.value,
            dateStartEducationBefore: filterDateStartEducationBefore.value,
            dateCreateFrom: filterDateCreateFrom.value,
            dateCreateBefore: filterDateCreateBefore.value,
        }

        deliveryState.actionGetDeliveryList(currentPageNumber.value, search || '', filterData)
    }


    /** Кнопка применить (очистить) в окне - Фильтр по договорам */
    const handleApplyClearFilter = (filterType: 'apply' | 'clear') => {
        switch (filterType) {
            case 'apply': {
                filterIsActivate.setValue(true)
                loadDataContracts(txtSearch.value)
                break
            }

            case 'clear': {
                filterIsActivate.setValue(false)
                filterContractStatuses.setValue(filterContractStatuses.value.map(status => ({...status, isSelected: false})))
                filterDateStartEducationFrom.setValue('')
                filterDateStartEducationBefore.setValue('')
                filterDateCreateFrom.setValue('')
                filterDateCreateBefore.setValue('')

                const filterData: IDeliveryFilter = {
                    contractsStatuses: '',
                    dateStartEducationFrom: '', dateStartEducationBefore: '',
                    dateCreateFrom: '', dateCreateBefore: '',
                }
        
                deliveryState.actionGetDeliveryList(currentPageNumber.value, txtSearch.value, filterData)
                break
            }
        }
    }

    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string) => {
        modalCalendar.setValue({
            isOpen,
            handlerButtonCancel() { modalCalendar.setValue({ isOpen: false }) },
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'dateStartEducationFrom': filterDateStartEducationFrom.setValue(stringDate); break
                    case 'dateStartEducationBefore': filterDateStartEducationBefore.setValue(stringDate); break
                    
                    case 'dateCreateFrom': filterDateCreateFrom.setValue(stringDate); break
                    case 'dateCreateBefore': filterDateCreateBefore.setValue(stringDate); break
                }

                modalCalendar.setValue({ isOpen: false })
            }
        })
    }

    /** Открытие/закрытие анкеты по договору */
    const handleOpenCardContract = (isOpen: boolean, cardID?: number) => {
        cardContract.setValue({
            isOpen, cardMode: 'edit', cardID,
            handlerButtonCancel() {cardContract.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardContract.setValue({isOpen: false})
                loadDataContracts(txtSearch.value)
            }
        })
    }

    /** Открытие/закрытие анкеты по слушателю */
    const handleOpenCardListener = (isOpen: boolean, cardID?: number) => {
        cardListener.setValue({
            isOpen, cardMode: 'edit', cardID,
            handlerButtonCancel() {cardListener.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListener.setValue({isOpen: false})
                loadDataContracts(txtSearch.value)
            }
        })
    }

    /** Открытие/закрытие анкеты доставки */
    const handleOpenCardDelivery = (isOpen: boolean, cardID?: number) => {
        cardDelivery.setValue({
            isOpen, cardID,
            handlerButtonCancel() {cardDelivery.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardDelivery.setValue({isOpen: false})
                loadDataContracts(txtSearch.value)
            }
        })
    }


    const debounceSearch = useDebounce(loadDataContracts, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IDelivery = {
        isOpenFilter, filterIsActivate, isLoading: deliveryState.contracts.isLoading,
        currentPageNumber, totalCount: deliveryState.contracts.totalCount, pageSize: deliveryState.contracts.pageSize,

        txtSearch, deliveryList: deliveryState.contracts.items,

        loadDataContracts, txtSearchOnChange, handleApplyClearFilter, handleOpenCardListener, handleOpenCardContract, handleOpenCardDelivery
    }
    /** Свойства передаваемые в компоненту окна фильтра */
    const propsToFilter: IDeliveryWinFilter = {
        isOpen: isOpenFilter.value, filterContractStatuses,
        filterDateStartEducationFrom, filterDateStartEducationBefore,
        filterDateCreateFrom, filterDateCreateBefore,
    
        handlerButtonCancel: () => isOpenFilter.setValue(false),
        handleApplyClearFilter, handleOpenModalCalendar,
    }

    return <>
        <Delivery {...propsToComponent} />
        <DeliveryFilter {...propsToFilter} />

        <ModalCalendar {...modalCalendar.value} />
        <CardListenerContainer {...cardListener.value} />
        <CardContractContainer {...cardContract.value} />
        <CardDeliveryContainer {...cardDelivery.value} />
    </>
}