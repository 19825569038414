import moment from "moment"

import { ICardApplicationTabLogs } from "./_types"

import imgEmty from 'images/empty.svg'
import { Empty, Flex, Table } from "components/ui"


/** Функция для сравнения двух объектов, игнорируя свойство keysToIgnore */
// const areItemsEqual = (item1: any, item2: any, keysToIgnore: any[]) => {
//     // const keysToIgnore = ['logAction', 'logDate', 'logOperator'];
//     const keys1 = Object.keys(item1).filter(key => !keysToIgnore.includes(key))
//     const keys2 = Object.keys(item2).filter(key => !keysToIgnore.includes(key))

//     if (keys1.length !== keys2.length) {
//         return false
//     }

//     for (const key of keys1) {
//         if (item1[key] !== item2[key]) {
//             return false
//         }
//     }

//     return true
// }

/** Вкладка лог журнала */
export const CardApplicationTabLogs = ({logsList}: ICardApplicationTabLogs) => {
    let nextIndex = 0

    return <>
        {logsList.value.length === 0 
            ? <Empty image={imgEmty} title="Логи отсутствуют" /> 
            : logsList.value.map((item, index) => {
                if ((logsList.value.length - 1) === index) {nextIndex = index} else {nextIndex += 1}
                // if ((logsList.value.length - 1) !== index && areItemsEqual(item, logsList.value[nextIndex])) return null

                return <Flex key={index}>
                    <Table.RowCard isVisible={item.logAction !== 'INSERT'}
                        paddingHeader="4px 6px"
                        childrenHeader={
                            <Flex direction="row" justifyContent="space-between">
                                <span>Оператор 👉 {item.logOperator === '?' ? 'Клиент' : item.logOperator}</span>
                            </Flex>
                        }

                        paddingFooter="0px 6px"
                        childrenFooter={
                            <Flex direction="row" justifyContent="flex-end">
                                <span>{`${item.logAction === 'INSERT' ? 'Создан' : 'Изменён'} 👉 ${moment(item.logDate).format('DD.MM.YYYY HH:mm:ss')}`}</span>
                            </Flex>
                        }>

                        <Table.Body overflowY="hidden">
                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].clientName !== item.clientName}>
                                <Flex gap="6px" direction="row">
                                    <h5>Клиент:</h5>
                                    <p style={{ fontWeight: '700' }}>{item.clientName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].clientPhone !== item.clientPhone}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Телефон:</h5>
                                    <p style={{fontWeight: '700'}}>{item.clientPhone}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].clientEmail !== item.clientEmail}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Email:</h5>
                                    <p style={{fontWeight: '700'}}>{item.clientEmail}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].courseName !== item.courseName}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Курс:</h5>
                                    <p style={{fontWeight: '700'}}>{item.courseName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].programName !== item.programName}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Тип программы:</h5>
                                    <p style={{fontWeight: '700'}}>{item.programName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].attestationTypeName !== item.attestationTypeName}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Тип аттестации:</h5>
                                    <p style={{fontWeight: '700'}}>{item.attestationTypeName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].durationEducation !== item.durationEducation}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Продолжительность:</h5>
                                    <p style={{fontWeight: '700'}}>{item.durationEducation}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].volumeHours !== item.volumeHours}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Объём:</h5>
                                    <p style={{fontWeight: '700'}}>{item.volumeHours}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].sum !== item.sum}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Стоимость:</h5>
                                    <p style={{fontWeight: '700'}}>{item.sum}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].startEducationDate !== item.startEducationDate}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Начало обучения:</h5>
                                    <p style={{fontWeight: '700'}}>{item.startEducationDate}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].endEducationDate !== item.endEducationDate}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Окончание обучения:</h5>
                                    <p style={{fontWeight: '700'}}>{item.endEducationDate}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].applicationStatusName !== item.applicationStatusName}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Статус заявки:</h5>
                                    <p style={{fontWeight: '700'}}>{item.applicationStatusName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].dateNextCall !== item.dateNextCall}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Дата отложенного звонка:</h5>
                                    <p style={{fontWeight: '700'}}>{item.dateNextCall}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].timeNextCall !== item.timeNextCall}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Время отложенного звонка:</h5>
                                    <p style={{fontWeight: '700'}}>{item.timeNextCall}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].applicationTypeName !== item.applicationTypeName}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Тип заявки:</h5>
                                    <p style={{fontWeight: '700'}}>{item.applicationTypeName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].applicationSourceName !== item.applicationSourceName}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Истояник заявки:</h5>
                                    <p style={{fontWeight: '700'}}>{item.applicationSourceName}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].applicationComment !== item.applicationComment}>
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <h5>Коментарий:</h5>
                                    <p style={{fontWeight: '700'}}>{item.applicationComment}</p>
                                </Flex>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].isRepeat !== item.isRepeat || (item.logOperator === '?' && !item.sms)}>
                                <h5>Клиент повторно зарегистрировал заявку</h5>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].userDemoNameID !== item.userDemoNameID && item.logOperator === '?'}>
                                <h5>Клиент через сайт запросил демо-доступ</h5>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].userDemoCrm !== item.userDemoCrm || item.logOperator !== '?'}>
                                <h5>Клиенту выдан демо-доступ из ЦРМ</h5>
                            </Table.Row>

                            <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column" isVisible={logsList.value[nextIndex].sms !== item.sms}>
                                <h5>Клиенту отправлена СМС</h5>
                            </Table.Row>
                        </Table.Body>
                    </Table.RowCard>


                    <Table.RowCard isVisible={item.logAction === 'INSERT'}
                        paddingHeader="4px 6px"
                        childrenHeader={
                            <Flex direction="row" justifyContent="space-between">
                                <span>Оператор 👉 {item.logOperator === '?' ? 'Клиент' : item.logOperator}</span>
                            </Flex>
                        }

                        paddingFooter="0px 6px"
                        childrenFooter={
                            <Flex direction="row" justifyContent="flex-end">
                                <span>{`${item.logAction === 'INSERT' ? 'Создан' : 'Изменён'} 👉 ${moment(item.logDate).format('DD.MM.YYYY HH:mm:ss')}`}</span>
                            </Flex>
                        }>

                        <Table.Row paddingInset="4px 8px" classGridTemplateColumns="table_grid_column">
                            <Flex gap="4px" direction="row" alignItems="center">
                                <h5>Заявка зарегистрирована</h5>
                            </Flex>
                        </Table.Row>
                    </Table.RowCard>
                </Flex>
            })
        }
    </>
}