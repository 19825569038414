import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { nanoid } from 'nanoid'
import moment from 'moment'

import { FieldsErrorType, ICardListener, ICardListenerContainer, IFieldsError, IFilesList } from './_types'
import { CardListener } from './_interface'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { phoneMask, validFormData } from 'hooks/useForm'

import CommonService from 'services/common.service'
import { useListeners } from 'store/listeners/listeners.state'

import { DictNameType, ISelectDictionaryContainer, SelectDictionaryContainer } from '../selectDictionary'

import { IModalNotice, ModalNotice } from 'components/dumb/modal.notice'
import { IModalPreview, ModalPreview } from 'components/dumb/modal.preview'
import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Карточка по слушателю - Контейнер */
export const CardListenerContainer = ({isOpen, cardMode, cardID, handlerButtonOk, handlerButtonCancel}: ICardListenerContainer) => {
    const notify = useContext(NotificationContext)

    const {
        isLoadingCard, actionGetListenerCard, actionSetListenerCard, actionGetListenerCheckPhone, 
        actionGetListenerCheckSnils, actionSetListenerDeleteFiles, actionSetListenerUploadFiles, actionGetListenerFiles
    } = useListeners(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        actionGetListenerCard: state.actionGetListenerCard,
        actionSetListenerCard: state.actionSetListenerCard,
        actionGetListenerCheckPhone: state.actionGetListenerCheckPhone,
        actionGetListenerCheckSnils: state.actionGetListenerCheckSnils,
        actionSetListenerDeleteFiles: state.actionSetListenerDeleteFiles,
        actionSetListenerUploadFiles: state.actionSetListenerUploadFiles,
        actionGetListenerFiles: state.actionGetListenerFiles,
    })))

    const filesListSendToSrv = useInput<File[]>([])
    const filesList = useInput<IFilesList[]>([])

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalDict = useInput<ISelectDictionaryContainer>({isOpen: false})
    const modalNotice = useInput<IModalNotice>({isOpen: false})
    const modalPreview = useInput<IModalPreview>({isOpen: false})

    const tabActive = useInput<string>('primary')
    const fieldsError = useInput<IFieldsError>({})


    const listenerNumber = useInput<number | ''>('')
    const listenerDateCreate = useInput<string>('')
    const clientSnilsAuto = useInput<boolean>(false)

    const txtCitizenshipID = useInput<number | ''>(185)
    const txtCitizenship = useInput<string>('643 - РОССИЯ')
    const txtSnilsBuff = useInput<string>('') // Буффер, оригинальный СЛИЛС
    const txtSnils = useInput<string>('')
    const txtClientPhone = useInput<string>('')
    const txtClientEmail = useInput<string>('')
    
    const txtSex = useInput<string>('')
    const txtLastName = useInput<string>('')
    const txtFirstName = useInput<string>('')
    const txtSecondName = useInput<string>('')
    const txtBirthDate = useInput<string>('')
    const txtFullNameFrom = useInput<string>('')
    
    const txtPassport = useInput<string>('')
    const txtAddress = useInput<string>('')

    const txtEducationLevelID = useInput<number | ''>(3)
    const txtEducationLevel = useInput<string>('Высшее образование')
    const txtLastNameDiplom = useInput<string>('')
    const txtDiplom = useInput<string>('')
    
    const txtComment = useInput<string>('')
    const txtLogin = useInput<string>('')
    const txtPassword = useInput<string>(nanoid())



    useEffect(() => {
        if (isOpen) {
            cardMode === 'edit' && actionGetListenerCard(Number(cardID)).then((res) => {
                if (res) {
                    listenerNumber.setValue(res.listenerID)
                    listenerDateCreate.setValue(moment(res.dateCreate).format('DD.MM.YYYY'))
                    clientSnilsAuto.setValue(res.snils.length > 14 ? true : false)

                    txtCitizenshipID.setValue(res.citizenshipID || '')
                    txtCitizenship.setValue(`${res.citizenshipCode} - ${res.citizenshipName}`)
                    txtSnilsBuff.setValue(res.snils || '')
                    txtSnils.setValue(res.snils || '')
                    txtClientPhone.setValue(res.phoneNumber || '')
                    txtClientEmail.setValue(res.email || '')

                    txtSex.setValue(res.sex || '')
                    txtLastName.setValue(res.lastName || '')
                    txtFirstName.setValue(res.firstName || '')
                    txtSecondName.setValue(res.secondName || '')
                    txtBirthDate.setValue(res.birthDate || '')
                    txtFullNameFrom.setValue(res.fullNameFrom || '')

                    txtPassport.setValue(res.passport || '')
                    txtAddress.setValue(res.registration || '')

                    txtEducationLevelID.setValue(res.educationLevelID || '')
                    txtEducationLevel.setValue(res.educationLevelName || '')
                    txtLastNameDiplom.setValue(res.maidenName || '')
                    txtDiplom.setValue(res.diplom || '')

                    txtComment.setValue(res.comment || '')
                    txtLogin.setValue(res.lkLogin || '')
                    txtPassword.setValue(res.lkPassword || '')

                    filesList.setValue(res.filesList.map((item) => ({
                        fileID: item.listenerFileID,
                        fileName: item.fileName,
                        filePath: item.filePath,
                        fileSize: item.fileSize,
                        dateCreate: item.dateCreate,
                        isSelected: false
                    })))
                }
            })

            txtPassword.setValue(nanoid())
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (cardMode === 'new' && (txtLastName.value || txtFirstName.value || txtSecondName.value)) {
            const lastName = txtLastName.value?.trim().charAt(0).toUpperCase() + txtLastName.value?.trim().toLowerCase().slice(1)
            const firstName = txtFirstName.value?.trim().charAt(0).toUpperCase() + txtFirstName.value?.trim().toLowerCase().slice(1)
            const secondName = txtSecondName.value?.trim().charAt(0).toUpperCase() + txtSecondName.value?.trim().toLowerCase().slice(1)

            let newFullNameFrom = `${CommonService.declensionFullName(lastName, firstName, secondName, 'кого/чего')}`
            txtFullNameFrom.setValue(newFullNameFrom.trim())
        }
    }, [txtLastName.value, txtFirstName.value, txtSecondName.value]) // eslint-disable-line react-hooks/exhaustive-deps

    

    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        tabActive.setValue('primary')
        fieldsError.setValue({})

        listenerNumber.setValue('')
        listenerDateCreate.setValue('')
        clientSnilsAuto.setValue(false)

        txtCitizenshipID.setValue(185)
        txtCitizenship.setValue('643 - РОССИЯ')
        txtSnilsBuff.setValue('') // Буффер, оригинальный СЛИЛС
        txtSnils.setValue('')
        txtClientPhone.setValue('')
        txtClientEmail.setValue('')

        txtSex.setValue('')
        txtLastName.setValue('')
        txtFirstName.setValue('')
        txtSecondName.setValue('')
        txtBirthDate.setValue('')
        txtFullNameFrom.setValue('')

        txtPassport.setValue('')
        txtAddress.setValue('')

        txtEducationLevelID.setValue(3)
        txtEducationLevel.setValue('Высшее образование')
        txtLastNameDiplom.setValue('')
        txtDiplom.setValue('')

        txtComment.setValue('')
        txtLogin.setValue('')
        txtPassword.setValue('')

        filesList.setValue([])
        filesListSendToSrv.setValue([])
    }
    /** Функция по генерации СНИЛС */
    const handlerSnilsAuto = (checked: boolean) => {
        clientSnilsAuto.setValue(checked)

        if (checked) {handleChangeSnils(nanoid())} 
        else {
            let snilsFromBuffer: string = ''
            if (txtSnilsBuff.value.length === 14) snilsFromBuffer = txtSnilsBuff.value

            txtSnils.setValue(snilsFromBuffer || '')
        }
    }
    /** Функция по генерации номера телефона */
    const handlerPhoneGenerate = () => {
        handleChangePhone(`850${CommonService.generateDigits(17)}`)
    }



    /** Проверка по номеру телефона, существование лушателя */
    const listenerCheckPhoneNumber = async (phoneNumber: string) => {
        const res = await actionGetListenerCheckPhone(cardID || 0, phoneNumber)
        if (res?.result) handlerOpenModalNotice(true, 'Извещение...', `<b>Внимание!</b><br/>Слушатель с таким номером телефона уже зарегистрирован, идентификатор слушателя: <b>${res.appID}</b>`)
    }
    /** Проверка по СНИЛС, существование лушателя */
    const listenerCheckSnils = async (snils: string) => {
        const res = await actionGetListenerCheckSnils(cardID || 0, snils)
        if (res?.result) handlerOpenModalNotice(true, 'Извещение...', `<b>Внимание!</b><br/>Слушатель с таким СНИЛС уже зарегистрирован, идентификатор слушателя: <b>${res.appID}</b>`)
    }



    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'birthDate': txtBirthDate.setValue(stringDate); break
                }
                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handleOpenModalDict = (isOpen: boolean, dictName: DictNameType, fieldName: string, whereParam1?: string, whereParam2?: string) => {
        modalDict.setValue({
            isOpen, dictName, whereParam1, whereParam2,
            handlerButtonCancel() {modalDict.setValue({isOpen: false})}, 
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (fieldName) {
                    case 'citizenship': {
                        txtCitizenshipID.setValue(Number(id))
                        txtCitizenship.setValue(name)
                        break
                    }

                    case 'educationLevel': {
                        txtEducationLevelID.setValue(Number(id))
                        txtEducationLevel.setValue(name)
                        break
                    }
                }

                modalDict.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна извещения */
    const handlerOpenModalNotice = (isOpen: boolean, headerText: string, contentText: string) => {
        modalNotice.setValue({
            isOpen, headerText, contentText, 
            handlerButtonOk() {modalNotice.setValue({isOpen: false})}, 
        })
    }
    /** Открытие/закрытие окна предпросмотра документа */
    const handlerOpenModalPreview = (isOpen: boolean, fileUrl: string, fileName: string) => {
        modalPreview.setValue({
            isOpen, fileUrl, fileName, 
            handlerButtonCancel() {modalPreview.setValue({isOpen: false})}, 
        })
    }


    /** Функция по выделению файлов */
    const handlerSelectedFiles = (fileID: number) => {
        const newList = filesList.value.map(item => {
            if (item.fileID === fileID) {
                return { ...item, isSelected: !item.isSelected }
            }

            return item
        })

        filesList.setValue(newList)
    }
    /** Функция по выделению всех файлов */
    const handlerSelectedFilesAll = () => {
        const newList = filesList.value.map(item => {
            return { ...item, isSelected: !item.isSelected }
        })

        filesList.setValue(newList)
    }
    /** Функция по скачиванию файлов слушателя */
    const handlerDownloadFiles = (fileID?: number) => {
        let selectedFiles: IFilesList[] = []

        if (cardMode === 'new') return notify && notifyOpen(`Не возможно скачать файл или файлы, т.к. они еще не загружены на сервер.`, 'warning', 1500, notify)

        if (fileID) { selectedFiles = filesList.value.filter(file => file.fileID === fileID) } 
        else { selectedFiles = filesList.value.filter(file => file.isSelected) }

        for (const file of selectedFiles) {
            const link = document.createElement('a') // Создание ссылки и программное скачивание файла

            link.href = file.filePath
            link.download = file.fileName
            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)
        }
    }
    /** Функция по удалению файлов слушателя */
    const handlerDeleteFiles = async (fileID?: number) => {
        let selectedFiles: IFilesList[] = []

        if (fileID) { selectedFiles = filesList.value.filter(file => file.fileID === fileID) } 
        else { selectedFiles = filesList.value.filter(file => file.isSelected) }

        switch (cardMode) {
            case 'new': { // При создании слушателя               
                const fileNamesToDelete = selectedFiles.map(file => file.fileName) // Получаем имена файлов, которые нужно удалить
                
                // Обновляем filesList, удаляя выбранные файлы
                filesList.setValue(filesList.value.filter(file => !fileNamesToDelete.includes(file.fileName)))
                
                // Обновляем filesListSendToSrv, удаляя файлы, чьи имена находятся в fileNamesToDelete
                filesListSendToSrv.setValue(filesListSendToSrv.value.filter(file => !fileNamesToDelete.includes(file.name)));
                break
            }
            case 'edit': { // При редактировании слушателя
                for (const file of selectedFiles) {
                    await actionSetListenerDeleteFiles(Number(cardID) | 0, file.fileID).finally(() => {
                        actionGetListenerFiles(Number(cardID) | 0).then((files) => {
                            filesList.setValue(files.map((item) => ({
                                fileID: item.listenerFileID,
                                fileName: item.fileName,
                                filePath: item.filePath,
                                fileSize: item.fileSize,
                                dateCreate: item.dateCreate,
                                isSelected: false
                            })))
                        })
                    })
                } break
            }
        }
    }



    /** Кнопка сохранить все данные во вкладке primary */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'citizenship', value: txtCitizenship.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Гражданство слушателя, обязательно должно быть выбрано.'},
            {fieldName: 'snils', value: txtSnils.value, validType: (clientSnilsAuto.value || txtCitizenshipID.value !== 185) ? 'notEmpty' : 'snils', isRequired: true, errorMessage: 'СНИЛС слушателя не корректный.'},
            {fieldName: 'clientPhone', value: txtClientPhone.value, validType: 'phoneMask', isRequired: true, errorMessage: 'Номер телефона не корректный.'},
            {fieldName: 'clientEmail', value: txtClientEmail.value, validType: 'email', isRequired: false, errorMessage: 'Электронная почта не корректная.'},

            {fieldName: 'birthDate', value: txtBirthDate.value, validType: 'date', isRequired: true, errorMessage: 'Дата рождения не корректна или такой даты не существует.'},
            {fieldName: 'lastName', value: txtBirthDate.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Фамилию слушателя обязательно нужно заполнить.'},
            {fieldName: 'firstName', value: txtBirthDate.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Имя слушателя обязательно нужно заполнить.'},
            {fieldName: 'fullNameFrom', value: txtBirthDate.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Ф.И.О. слушателя (от кого) обязательно нужно заполнить.'},
            {fieldName: 'sex', value: txtSex.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Пол слушателя, необходимо указать.'},
            
            {fieldName: 'passport', value: txtPassport.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Паспорт слушателя, необходимо заполнить.'},
            {fieldName: 'address', value: txtAddress.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Адрес прописки слушателя, необходимо заполнить.'},

            {fieldName: 'educationLevel', value: txtEducationLevel.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Уровень образования слушателя, необходимо выбрать.'},
            {fieldName: 'diplom', value: txtDiplom.value, validType: 'notEmpty', isRequired: txtEducationLevelID.value === 1 ? false : true, errorMessage: 'Документ об образовании слушателя, необходимо заполнить.'},
            
            {fieldName: 'login', value: txtLogin.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Логин (личный кабинет), необходимо заполнить.'},
            {fieldName: 'password', value: txtPassword.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Пароль (личный кабинет), необходимо заполнить.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            actionSetListenerCard(cardID || 0, {
                actionForm: cardMode || 'new',
            
                citizenshipID: Number(txtCitizenshipID.value), 
                snils: txtSnils.value,
                phoneNumber: txtClientPhone.value,
                email: txtClientEmail.value,
            
                birthDate: txtBirthDate.value,
                lastName: txtLastName.value,
                firstName: txtFirstName.value,
                secondName: txtSecondName.value,
                fullNameFrom: txtFullNameFrom.value,
                sex: txtSex.value,
            
                passport: txtPassport.value,
                registration: txtAddress.value,
            
                educationLevelID: Number(txtEducationLevelID.value),
                maidenName: txtLastNameDiplom.value,
                diplom: txtDiplom.value,
            
                comment: txtComment.value,
                lkLogin: txtLogin.value,
                lkPassword: txtPassword.value
            }, filesListSendToSrv.value).then((res) => {
                if (res === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }



    /** Проверка номера телефона на валидность, и проверка на дубль */
    const debouncePhone = useDebounce(listenerCheckPhoneNumber, 1500)
    const handleChangePhone = (phoneNumber: string) => {
        const resultPhoneMask = phoneMask(phoneNumber)

        txtLogin.setValue(resultPhoneMask.data)
        txtClientPhone.setValue(resultPhoneMask.data)
        
        debouncePhone(resultPhoneMask.data)
    }
    /** Проверка СНИЛС на дубль */
    const debounceSnils = useDebounce(listenerCheckSnils, 1500)
    const handleChangeSnils = (value: string) => {
        txtSnils.setValue(value)
        debounceSnils(value)
    }


    // Загрузка файлов на сервер
    const fileUploader = async (files: File[]) => {
        const maxSize = 10485760 // Максимально разрешенный размер файла для загрузки (10 MB)

        switch (cardMode) {
            case 'new': { // При создании слушателя
                const newFiles: IFilesList[] = files.reduce<IFilesList[]>((acc, item) => {
                    // Проверка на максимальный размер файла
                    if (item.size > maxSize) {
                        notify && notifyOpen(`Файл ${item.name}, превышает максимально допустимый размер в 10 мегабайт.`, 'error', 1500, notify)
                        return acc
                    }

                    // Проверка на уникальность имени файла
                    const isAlreadyAdded = filesList.value.some(existingFile => existingFile.fileName === item.name)
                    if (isAlreadyAdded) {
                        notify && notifyOpen(`Файл с именем ${item.name} уже добавлен в список.`, 'error', 1500, notify)
                        return acc
                    }

                    // Добавление нового файла
                    const newFile: IFilesList = {
                        fileID: Number(CommonService.generateDigits(10)),
                        fileName: item.name,
                        filePath: '',
                        fileSize: CommonService.getDefiningFileUnit(item.size),
                        dateCreate: new Date().toISOString(),
                        isSelected: false
                    }

                    acc.push(newFile)
                    return acc
                }, [])

                // Обновление списка файлов, добавляя новые файлы
                filesList.setValue([...filesList.value, ...newFiles])

                const newFilesOnly = newFiles.map(f => files.find(file => file.name === f.fileName)).filter(f => f) as File[]
                filesListSendToSrv.setValue([...filesListSendToSrv.value, ...newFilesOnly])
                break
            }

            case 'edit': { // При редактировании слушателя
                files.forEach((file) => { // Проверка файлов

                })

                await actionSetListenerUploadFiles(Number(cardID) | 0, files).finally(() => {
                    actionGetListenerFiles(Number(cardID) | 0).then((files) => {
                        filesList.setValue(files.map((item) => ({
                            fileID: item.listenerFileID,
                            fileName: item.fileName,
                            filePath: item.filePath,
                            fileSize: item.fileSize,
                            dateCreate: item.dateCreate,
                            isSelected: false
                        })))
                    })
                })
                break
            }
        }
    }



    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardListener = {
        isOpen, cardMode, handlerButtonCancel,
        isLoading: isLoadingCard, tabActive, fieldsError,

        filesList, fileUploader,

        listenerNumber, listenerDateCreate, clientSnilsAuto, txtSex,
        txtCitizenshipID, txtCitizenship, txtSnils, txtClientPhone, txtClientEmail,
        txtLastName, txtFirstName, txtSecondName, txtBirthDate, txtFullNameFrom,
        txtPassport, txtAddress, txtEducationLevelID, txtEducationLevel, txtLastNameDiplom, txtDiplom,
        txtComment, txtLogin, txtPassword,

        handleOpenModalCalendar, handleOpenModalDict, handlerOpenModalPreview,
        handleChangePhone, handleChangeSnils, handlerSelectedFiles, handlerSelectedFilesAll,
        handleBtnSave, handlerSnilsAuto, handlerPhoneGenerate, handlerDownloadFiles, handlerDeleteFiles,
    }

    return <>
        <CardListener {...propsToComponent} />

        <ModalNotice {...modalNotice.value} />
        <ModalPreview {...modalPreview.value} />
        <ModalCalendar {...modalCalendar.value} />
        
        <SelectDictionaryContainer {...modalDict.value} />
    </>
}