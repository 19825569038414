import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { getEducationStartEndDate } from './common.api'
import { ICommonState, ICommonActions, IEduDateStartEnd } from './common.types'


export const initialStateCommon: ICommonState = {
    error: null,
    isLoading: false,
}

/** Хранилище страницы - Авторизация */
export const useCommon = create<ICommonState & ICommonActions>()(devtools((set) => ({
    ...initialStateCommon,


    clearCommonState: () => {set(initialStateCommon)},

    /** Имена баз данных сайтов */
    actionGetEduDateStartEnd: async (startDate?: string, countMonthOrWeek?: number, isWeek?: number) => {
        set({isLoading: true})

        let returnData: IEduDateStartEnd = {
            eduStartDate: '',
            eduEndDate: ''
        }

        const res = await getEducationStartEndDate(startDate, countMonthOrWeek, isWeek)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK' && res.data) {
            set({error: null})
            returnData = res.data
        }

        set({isLoading: false})
        
        return returnData
    },
}), {name: 'commonState'}))