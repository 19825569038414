import axios, { AxiosError, AxiosResponse } from "axios"

import { IResponseCommon } from "../common.type"
import api, { APP_API_URL } from "../../services/api.service"
import { IResponseLogin, IResponseUserProfile } from "./login.types"


const route = '/auth'
axios.defaults.withCredentials = true


/** Авторизация пользователя */
export const auth = async (login: string, password: string): Promise<IResponseLogin> => {
    let response: IResponseLogin

    try {
        const res: AxiosResponse<IResponseLogin, AxiosError> = await api.post(`${route}/login`, { login, password })
        
        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch (error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseLogin
        response = {status: 500, message: resError.message, errors: resError.errors}
        return response
    }
}

/** Проверка авторизации пользователя */
export const checkAuth = async (): Promise<IResponseLogin> => {
    let response: IResponseLogin

    try {
        const res: AxiosResponse<IResponseLogin, AxiosError> = await axios.get(`${APP_API_URL}${route}/refresh`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch (error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseLogin
        response = {status: 500, message: resError.message, errors: resError.errors}
        return response
    }
}

/** Выход из системы */
export const logout = async (): Promise<IResponseCommon> => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/logout`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch (error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError.message, errors: resError.errors}
        return response
    }
}

/** Получение данных по профилю текущего пользователя */
export const getUserProfile = async () => {
    let response: IResponseUserProfile

    try {
        const res: AxiosResponse<IResponseUserProfile, AxiosError> = await api.get(`${route}/getUserProfile`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseUserProfile
        response = {status: 500, message: resError.message, errors: resError.errors}
        return response
    }
}