import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CardContractSended } from "./_interface"
import { ICardContractSended, ICardContractSendedContainer, ISendedList } from "./_types"

import { useInput } from "hooks/useInput"
import { useDict } from "store/dict/dict.state"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Контейнер окна по выбору что отправлять в телегу */
export const CardContractSendedContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: ICardContractSendedContainer) => {
    const notify = useContext(NotificationContext)

    const {isLoading, actionGetContractSended} = useDict(useShallow((state) => state))
    const sendedList = useInput<ISendedList[]>([])

    useEffect(() => {
        if (isOpen) {
            actionGetContractSended().then((res) => {
                if (res) {
                    sendedList.setValue(res.map((item) => ({
                        id: item.contractSendedID,
                        name: item.contractSendedName,
                        type: item.contractSendedType,
                        telegram: item.telegramGroup,

                        isSelect: false
                    })))
                }
            })
        }

        !isOpen && clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Очистка формы */
    const clearData = () => {
        sendedList.setValue([])
    }

    /** Функция по выбору документов */
    const handlerSelectedDocuments = (docID: number) => {
        const newList = sendedList.value.map(item => {
            if (item.id === docID) {
                return { ...item, isSelect: !item.isSelect }
            }

            return item
        })
        
        sendedList.setValue(newList)
    }
    
    /** Кнопка отправить */
    const handlerBtnSave = () => {
        const newList = sendedList.value.filter((item) => item.isSelect === true)
        if (newList.length === 0) return notify && notifyOpen('Необходимо выбрать что вы хотите отправить.', 'warning', 2000, notify)

        handlerButtonOk && handlerButtonOk(newList)
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardContractSended = {
        isOpen, handlerButtonCancel,
        isLoading, sendedList, 
        handlerSelectedDocuments, handlerBtnSave,
    }

    return <>
        <CardContractSended {...propsToComponent} />
    </>
}