import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { IResponsePrintDiplomasCard, IResponsePrintDiplomasList } from "./PrintingsDiplomas.type"


const route = '/printingDiplomas'


/** Список анкет */
export const getPrintingDiplomasList = async (pageNumber: number, search?: string) => {
    let response: IResponsePrintDiplomasList

    try {
        const res: AxiosResponse<IResponsePrintDiplomasList, AxiosError> = await api.get(`${route}/getPrintingDiplomasList`, {params: {pageNumber, search}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponsePrintDiplomasList
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Данные по анкете */
export const getPrintingDiplomasCard = async (printID: number) => {
    let response: IResponsePrintDiplomasCard

    try {
        const res: AxiosResponse<IResponsePrintDiplomasCard, AxiosError> = await api.get(`${route}/getPrintingDiplomasCard`, {params: {printID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponsePrintDiplomasCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете */
export const setPrintingDiplomasCardSave = async (printID: number, printStatusID: number, diplomRegNumber: string, diplomNumber: string, diplomDateIssue: string) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setPrintingDiplomasCardSave?printID=${printID}&printStatusID=${printStatusID}&diplomRegNumber=${diplomRegNumber}&diplomNumber=${diplomNumber}&diplomDateIssue=${diplomDateIssue}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Удаление анкеты из раздела печати */
export const setPrintingDiplomasDelete = async (printID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.delete(`${route}/setPrintingDiplomasDelete?printID=${printID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}