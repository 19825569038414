import { useContext, useEffect } from 'react'

import { CardDeliveryTrackNumber } from './_interface'
import { FieldsErrorType, ICardDeliveryTrackNumber, ICardDeliveryTrackNumberContainer, IFieldsError } from './_types'

import { useInput } from 'hooks/useInput'
import { validFormData } from 'hooks/useForm'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Контейнер окна по созданию/редактированию трек-номера */
export const CardDeliveryTrackNumberContainer = ({isOpen, cardMode, trackNumber, comment, handlerButtonOk, handlerButtonCancel}: ICardDeliveryTrackNumberContainer) => {
    const notify = useContext(NotificationContext)

    const fieldsError = useInput<IFieldsError>({})
    const txtTrackNumber = useInput<string>('')
    const txtComment = useInput<string>('')
    
    useEffect(() => {
        if (isOpen) {
            txtTrackNumber.setValue(trackNumber || '')
            txtComment.setValue(comment || '')
        }

        !isOpen && clearCard() // Обнуление данных
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Очистка компоненты */
    const clearCard = () => {
        txtTrackNumber.setValue('')
        txtComment.setValue('')
    }
    
    /** Кнопка сохранить/создать */
    const handlerBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'trackNumber', value: txtTrackNumber.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Укажите трек-номер.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnimation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }
        
        if (validateData.length === 0) { // Сохраняем данные
            handlerButtonOk && handlerButtonOk(txtTrackNumber.value.trim(), txtComment.value.trim())
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnimation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }


    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardDeliveryTrackNumber = {
        isOpen, cardMode, fieldsError,
        txtTrackNumber, txtComment,
        handlerBtnSave, handlerButtonCancel
    }
    
    return <CardDeliveryTrackNumber {...propsToComponent} />
}