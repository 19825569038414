import { ICardFinalExamination } from './_types'

import { IconClearClose } from 'images/icons'
import { Flex, Modal, Loader, Input, Divider, Button, Textarea } from 'components/ui'


/** Анкета итоговой работы */
const CardFinalExamination = ({
    isOpen, isLoadingCard, loadingTitle, isBtnToPrint, fullNameFrom, fieldsError, txtThemeIAR, txtBallIAR, txtBallTest,
    handlerOpenModalConfirm, handlerBtnSave, handlerButtonCancel
}: ICardFinalExamination) => {
    return <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} maxWidth='600px'>
        <Flex className='modal_container'>
            <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <h4>Анкета итоговой работы</h4>
                    </Flex>

                    <h5>{fullNameFrom}</h5>
                </header>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadingTitle.value} backdrop />

                <Flex gap='15px' className='modal_container-content'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.themeIAR ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Тема итоговой аттестационной работы <p>✸</p></Flex>
                            <Textarea placeholder="Тема итоговой аттестационной работы" rows={4} value={txtThemeIAR.value} onChange={(e) => txtThemeIAR.setValue(e.target.value)}
                                // suffix={getBtnSelect(() => handleOpenModalDict(true, 'documentTypes'))}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={100} className={!fieldsError.value.ballIAR ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Балл по работе <p>✸</p></Flex>
                            <Input placeholder="Балл по работе" paddingRight={4} {...txtBallIAR} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={100} className={!fieldsError.value.ballTest ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Балл по тесту <p>✸</p></Flex>
                            <Input placeholder="Балл по тесту" paddingRight={4} {...txtBallTest} />
                        </Flex>
                    </Flex>
                </Flex>

                <Divider />

                <Flex gap='6px' direction='row' flexWrap='wrap' justifyContent='space-between'>
                    <Button variant='primaryDark' flexGrow={1} flexBasis={100} isVisible={isBtnToPrint.value === 1}
                        onClick={() => handlerOpenModalConfirm(true, 'На печать', `Вы действительно хотите отправить в печать данного слушателя:<br />👉 ${fullNameFrom}.`, 'toPrint')}
                    >В печать</Button>
                    <Button variant='primaryDark' flexGrow={1} flexBasis={100} 
                        onClick={() => handlerOpenModalConfirm(true, 'Отправка в архив', `Вы действительно хотите отправить в архив данного слушателя:<br />👉 ${fullNameFrom}.`, 'inArchive')}
                    >В архив</Button>

                    <Button flexGrow={1} flexBasis={100} onClick={handlerBtnSave}>Сохранить</Button>
                </Flex>
            </Flex>
        </Flex>
    </Modal>
}

export default CardFinalExamination