import { ICardApplication } from './_types'

import { CardApplicationTabLogs } from './_tab.logs'
import { CardApplicationTabHelp } from './_tab.help'
import { CardApplicationTabPrimary } from './_tab.primary'

import { Flex, ToolTip, Loader, Drawer, Divider, Button, Tab } from 'components/ui'
import { IconApplications, IconClearClose, IconHelp, IconLogs, IconMainGrid } from 'images/icons'


/** Карточка по заявке */
export const CardApplication = ({
    isOpen, cardMode, handlerButtonCancel, tabActive, fieldsError, isFieldsDisabled,
    dbNameList, dbNameSelected, modalCalendar, modalDict, programsList, modalMailings, modalConfirm, modalNotice,
    applicationNumber, applicationDateCreate, applicationIsRepeat,
    isLoading, txtClientName, txtClientPhone, txtClientEmail,
    txtProgramNameID, txtProgramName, txtProgramTypeID, txtProgramType, txtAttestationType, 
    txtEducationDateStart, txtEducationDateEnd, 
    txtDurationEducation, txtVolumeHours, txtProgramSum,
    txtApplicationStatusID, txtApplicationStatus, txtDateNextCall, txtTimeNextCall, 
    txtApplicationType, txtApplicationSource, txtApplicationComment, logsList,
    handleOpenModalDict, handleOpenModalCalendar, handleChangePhone, handleBtnSave, handleOpenModalMailings, 
    handlerOpenModalConfirm, txtEducationDateStartOnChange, txtEducationDateEndOnChange, handlerCopyToClipBoard,
}: ICardApplication) => { 
    const propsToTabPrimary = { // Свойства передеваемые во вкладку primary
        cardMode, fieldsError, isFieldsDisabled, dbNameList, dbNameSelected,
        txtClientName, txtClientPhone, txtClientEmail,
        txtProgramNameID, txtProgramName, txtProgramTypeID, txtProgramType, txtAttestationType, 
        txtEducationDateStart, txtEducationDateEnd, 
        txtDurationEducation, txtVolumeHours, txtProgramSum,
        txtApplicationStatusID, txtApplicationStatus, txtDateNextCall, txtTimeNextCall, 
        txtApplicationType, txtApplicationSource, txtApplicationComment,
        handleOpenModalDict, handleOpenModalCalendar, handleChangePhone, 
        txtEducationDateStartOnChange, txtEducationDateEndOnChange
    }

    const propsToTabHelp = {
        programsList,
        txtClientName, txtClientPhone, txtClientEmail, 
        txtAttestationType, txtProgramTypeID, 
        txtDurationEducation, txtVolumeHours, 
        txtEducationDateStart, txtEducationDateEnd, txtProgramSum,
        handlerCopyToClipBoard
    }

    const propsToLogs = {
        logsList
    }

    const getCardPage = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': return <CardApplicationTabPrimary {...propsToTabPrimary} />
            case 'help': return <CardApplicationTabHelp {...propsToTabHelp} />
            case 'logs': return <CardApplicationTabLogs {...propsToLogs} />
        }
    }

    const getCardPageBtnPanel = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex flexWrap='wrap' direction='row' gap='4px'>
                        <Flex direction='row' gap='4px' isVisible={cardMode === 'edit'} className='mobile550'>
                            <Button flexGrow={1} variant='primaryDark' padding='0 10px' onClick={() => handlerOpenModalConfirm(true, 'Демо-доступ...', 'Вы действительно хотите создать демо-доступ, для данного клиента?')}>Демо-доступ</Button>
                            <Button flexGrow={1} variant='primaryDark' padding='0 10px' onClick={() => handleOpenModalMailings(true)}>Рассылки</Button>
                            <Divider id='hiddenFor550' type='vertical' margin='8px' />
                        </Flex>

                        <Flex direction='row' gap='4px' className='mobile550'>
                            <Button flexGrow={1} padding='0 10px' isVisible={cardMode === 'edit'} onClick={() => handleBtnSave(true)}>Сохранить и закрыть</Button>
                            <Button flexGrow={1} padding='0 10px' onClick={() => handleBtnSave(false)}>
                                {cardMode === 'edit' ? 'Сохранить' : 'Создать заявку'}
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            }

            case 'help': return ''
            case 'logs': return ''
        }
    }

    return <>
        <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
            <Flex className='drawer_container'>
                <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                    <header>
                        <Flex gap='4px' direction='row' alignItems='center'>
                            <IconApplications color='#fff' width={20} height={20} />
                            <h4>{cardMode === 'edit' ? 'Анкета заявки' : 'Создание заявки'}</h4> {applicationIsRepeat.value === 1 && <div id='tooltipBtnClientAttention' style={{cursor: 'help'}}>🔥</div>}
                        </Flex>

                        {cardMode === 'edit' && <h5>№ {applicationNumber.value} от {applicationDateCreate.value}</h5>}
                    </header>

                    <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='drawer_container-body_bottom_panel'>
                    <Flex gap='6px' className='drawer_container-content'>
                        {getCardPage(tabActive.value)}
                    </Flex>

                    {getCardPageBtnPanel(tabActive.value)}
                </Flex>

                <Tab.Container isVisible={cardMode === 'edit'}>
                    <Tab.Item tabName='primary' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Основные данные' onClick={tabActive.setValue}><IconMainGrid /></Tab.Item>
                    <Tab.Item tabName='help' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Информация для оператора' onClick={tabActive.setValue}><IconHelp /></Tab.Item>
                    <Tab.Item tabName='logs' tabNameActive={tabActive.value} tabToolTipPlace='top-end' tabToolTipTitle='Журнал изменений' onClick={tabActive.setValue}><IconLogs /></Tab.Item>
                </Tab.Container>

                {<Loader isOpen={isLoading} color='#f34e2e' title='Загрузка анкеты...' backdrop />}
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
            <ToolTip anchorSelect="#tooltipBtnClientAttention" content='Клиент требует внимания' place="bottom-end" />
        </Drawer>
    </>
}