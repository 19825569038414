import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import CardFinalExamination from "./_interface"
import { ICardFinalExamination, ICardFinalExaminationContainer, IFieldsError } from "./_types"

import { useInput } from "hooks/useInput"
import { useFinalExamination } from "store/finalExamination/finalExamination.state"

import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"
import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Контейнер анкеты итоговой работы */
export const CardFinalExaminationContainer = ({isOpen, cardID, fullNameFrom, handlerButtonOk, handlerButtonCancel}: ICardFinalExaminationContainer) => {
    const notify = useContext(NotificationContext)

    const {isLoadingCard, getCardData, saveCardData, sendInArchive, sendToPrint} = useFinalExamination(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        getCardData: state.actionGetFinalExaminationCard,
        saveCardData: state.actionSetFinalExaminationCardSave,
        sendInArchive: state.actionSetFinalExaminationInArchive,
        sendToPrint: state.actionSetFinalExaminationToPrint,
    })))

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})

    const loadingTitle = useInput<string>('Загрузка анкеты...')
    const fieldsError = useInput<IFieldsError>({})

    const txtThemeIAR = useInput<string>('')
    const txtBallIAR = useInput<string>('')
    const txtBallTest = useInput<string>('')
    
    const isBtnToPrint = useInput<number>(0)


    useEffect(() => {
        if (isOpen) {
            getCardData(Number(cardID)).then((res) => {
                if (res) {
                    txtThemeIAR.setValue(res.themeIAR || '')
                    txtBallIAR.setValue(res.iarScore || '')
                    txtBallTest.setValue(res.testScore || '')

                    isBtnToPrint.setValue(res.sendPrinting || 0)
                }
            })
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        fieldsError.setValue({})

        txtThemeIAR.setValue('')
        txtBallIAR.setValue('')
        txtBallTest.setValue('')
        
        isBtnToPrint.setValue(0)
    }

    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, actionName?: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText,
            handlerButtonCancel() { modalConfirm.setValue({ isOpen: false }) },
            handlerButtonOk() {
                switch (actionName) {
                    case 'inArchive': {
                        loadingTitle.setValue('Отправляю в архив...')
                        sendInArchive(Number(cardID)).then((res) => {
                            if (res === 200) {
                                handlerButtonOk && handlerButtonOk()
                                return notify && notifyOpen('Анкета итоговой работы слушателя, успешно отправлена в архив.', 'success', 1500, notify)
                            }
                        }).finally(() => {loadingTitle.setValue('Загрузка анкеты...')})
                        break
                    } // Создание и получение нового номера по договору

                    case 'toPrint': {
                        loadingTitle.setValue('Отправляю в печать...')

                        sendToPrint(Number(cardID)).then((res) => {
                            if (res === 200) {
                                handlerButtonOk && handlerButtonOk()
                                return notify && notifyOpen('Анкета итоговой работы слушателя, успешно отправлена в печать.', 'success', 1500, notify)
                            }
                        }).finally(() => {loadingTitle.setValue('Загрузка анкеты...')})
                        break
                    } // Допуск слушателя к обучению
                }

                modalConfirm.setValue({ isOpen: false })
            }
        })
    }
    
    /** Кнопка сохранить в анкете */
    const handlerBtnSave = () => {
        saveCardData(Number(cardID), txtThemeIAR.value.trim(), txtBallIAR.value.trim(), txtBallTest.value.trim()).then((res) => {
            if (res === 200) {
                notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                handlerButtonOk && handlerButtonOk()
            }
        })
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardFinalExamination = {
        isOpen, fullNameFrom, handlerButtonCancel,

        isLoadingCard, loadingTitle, isBtnToPrint,
        fieldsError, txtThemeIAR, txtBallIAR, txtBallTest,
        handlerOpenModalConfirm, handlerBtnSave,
    }

    return <>
        <CardFinalExamination {...propsToComponent} />

        <ModalConfirm {...modalConfirm.value} />
    </>
}