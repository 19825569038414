import { IFinalExaminationJobs } from "./_types"

import { IconClearClose } from "images/icons"

import { Flex, Modal, Loader, Button, Table } from "components/ui"


/** Проверка итоговых работ */
export const FinalExaminationJobs = ({
    isOpen, isLoading, jobsList, handlerButtonCancel, handlerOnClickTableRow
}: IFinalExaminationJobs) => {
    return <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} maxWidth="550px">
        <Flex className='modal_container'>
            <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                <h4>Итоговые работы для проверки</h4>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Flex className='modal_container-content' height="200px">
                    <Table.Body>
                        {jobsList.value.map((item, index) => (
                            <Table.Row key={index} indexContent={index + 1} indexContentWidth="40px" paddingInset="2px 8px" onClick={(e) => {handlerOnClickTableRow(item.lastName, item.linkToLK); e.stopPropagation()}}>
                                <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.lastName} {item.firstName}</p>
                                    <p style={{ opacity: '.7' }}>{item.courseName}</p>
                                </div>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    <Loader isOpen={isLoading} color='#f34e2e' title='Загрузка работ для проверки...' backdrop />
                </Flex>
            </Flex>
        </Flex>
    </Modal>
}