import moment from 'moment'

import { ModalCalendar } from 'components/dumb/modal.calendar'

import { IContracts } from './_types'
import { ContractsFilter } from './_filter'
import CommonService from 'services/common.service'

import { CardContractContainer } from 'components/smart/cardContract'
import { CardListenerContainer } from 'components/smart/cardListener'

import imgEmty from 'images/empty.svg'
import { IconAdd, IconClearClose, IconContracts, IconDelivery, IconDiplom, IconFilter, IconInfo, IconJobs, IconOutsideMoodle, IconPostPayment, IconScanFile, IconSearch, IconUpdate } from 'images/icons'

import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Divider, Button, Table } from 'components/ui'


/** Договора */
export const Contracts = ({
    accessLevelID, isLoading, txtSearch, txtSearchOnChange, loadDataContracts, handleOpenCardContract, handleOpenCardListener,
    modalCalendar, cardContract, cardListener, contractsList, contractsStatistics, totalCount, pageSize, currentPageNumber,
    
    filterIsOpen, filterIsActivate, filterContractsStatuses, 
    filterDateStartEducationFrom, filterDateStartEducationBefore, 
    filterDateCreateFrom, filterDateCreateBefore,
    handleApplyClearFilter, handleOpenModalCalendar
}: IContracts) => {
    const formatter = new Intl.NumberFormat('ru-RU')

    return <>
        <ModalCalendar {...modalCalendar.value} />
        <CardListenerContainer {...cardListener.value} />
        <CardContractContainer {...cardContract.value} />
        
        <ContractsFilter 
            isOpen={filterIsOpen.value} handlerButtonCancel={() => filterIsOpen.setValue(false)} 
            filterContractStatuses={filterContractsStatuses} handleApplyClearFilter={handleApplyClearFilter}
            filterDateStartEducationFrom={filterDateStartEducationFrom} filterDateStartEducationBefore={filterDateStartEducationBefore}
            filterDateCreateFrom={filterDateCreateFrom} filterDateCreateBefore={filterDateCreateBefore}
            handleOpenModalCalendar={handleOpenModalCalendar}
        />

        <Flex gap="3px" className="page_container">
            <Loader isOpen={isLoading} backdrop color="#f34e2e" title="Загрузка договоров..." />

            <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                    <IconContracts width={28} height={28} />
                    <h4>Договора ({totalCount})</h4>
                </Grid>

                <Flex gap="6px" direction="row" className="page_header_for_mobile">
                    <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                        value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                        paddingRight={4} prefix={<IconSearch width={16} height={16} />} 
                        suffix={<>
                            {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                            <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                        </>} 
                    />
                    <div style={{ position: 'relative' }}>
                        <Button id='tooltipBtnFilter' size="sm" variant="icon" onClick={() => filterIsOpen.setValue(true)}><IconFilter /></Button>
                        <div style={{ position: 'absolute', bottom: '-4px', right: '-4px' }}>
                            {filterIsActivate.value && <Button id='tooltipBtnClear' size="xs" variant="primary" onClick={() => handleApplyClearFilter('clear')}>
                                <IconClearClose width={16} height={16} />
                            </Button>}
                        </div>
                    </div>

                    <Divider type="vertical" margin="3px" />
                    <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataContracts(txtSearch.value)}><IconUpdate /></Button>
                    <Button id='tooltipBtnCreate' size="sm" variant="icon" onClick={() => handleOpenCardContract(true, "new")}><IconAdd /></Button>
                </Flex>
            </Flex>

            <Divider variant="dark" />

            <Table.Container loadingTitle="Загрузка...">
                <Table.Header id='hiddenForMobile' classGridTemplateColumns="contracts_grid_columns" marginLeft="3px" marginRight="14px">
                    <span className='table_column_data_center'>Номер заявки</span>
                    <span className='table_column_data_center'>Номер договора<br/>Дата договора</span>
                    <span>Тип / Вид договора<br/>Контрагент</span>
                    <span className="hiddenFor750">Статус договора<br/>Общая сумма / Долг</span>
                    <span className="hiddenFor950 table_column_data_center">Дата создания</span>
                </Table.Header>

                <Table.Body>
                    {!contractsList.length
                        ? <Empty image={imgEmty} /> 
                        : contractsList.map((item) => (
                            <Table.RowCard key={item.contractID} backgroundColor={item.contractStatusColor} 
                                classGridTemplateColumnsForHeader="contracts_grid_columns" classGridTemplateColumnsForFooter='contracts_grid_columns_footer'
                                onClick={() => handleOpenCardContract(true, "edit", item.contractID)}
                                childrenHeader={<>
                                    <p className='table_column_data_center'>{item.applicationID}</p>

                                    <div className='table_column_data_center' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.contractNumber}</p>
                                        <p style={{ opacity: '.8' }}>{item.contractDate}</p>
                                    </div>

                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.companyName && '🔥'} {item.contractTypeName} / {item.contractViewName}</p>
                                        <p style={{ opacity: '.8' }}>{item.companyName || '----'}</p>
                                    </div>

                                    <div className='hiddenFor750' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.contractStatusName}</p>
                                        <b style={{ opacity: '.8' }}>{formatter.format(Number(item.contractSumm))} ₽ 👉 {formatter.format(Number(item.contractPayment))} ₽</b>
                                    </div>

                                    <span className="table_column_data_center hiddenFor950">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>
                                </>}
                                paddingFooter='0 6px' childrenFooter={<>
                                    <p>{item.siteName}</p>
                                    <div>{item.isLearningOutsideMoodle === 1 && <span id='tooltipBtnOutsideMoodle' style={{cursor: 'help'}}><IconOutsideMoodle width={18} height={18} color='green' /></span>}</div>
                                    <div>{item.isPostPayment === 1 && <span id='tooltipBtnPostPayment' style={{cursor: 'help'}}><IconPostPayment width={18} height={18} color='green' /></span>}</div>
                                </>}>
                                
                                {item.listenersList.length === 0 
                                    ? <Empty image={imgEmty} size='60px' title='Слушатели отсутствуют' color='#fff' />
                                    : item.listenersList.map((listener, index) => (
                                        <Table.Row key={index} paddingInset='0px 0px 0px 8px'
                                            // badge={(item.contractCount > 0 || item.applicationStatusID === 3) && <p className="table_badge" style={{ background: item.contractCount === 0 ? 'red' : item.applicationStatusColor }}>{item.contractCount}</p>}
                                            onClick={(e) => {handleOpenCardListener(true, 'edit', listener.listenerID); e.stopPropagation()}}
                                            classGridTemplateColumns="contracts_grid_columns_listeners">
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{listener.lastName} {listener.firstName} {listener.secondName}</p>
                                                <p style={{ opacity: '.8' }}>{listener.documentTypeName} / {listener.attestationTypeName} / {listener.phoneNumber}</p>
                                            </div>

                                            <div className='hiddenForMobile' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{listener.courseName}</p>
                                                <p style={{ opacity: '.8' }}>{CommonService.formatHours(Number(listener.volumeHours))} / {listener.dateEducationStart} / {listener.dateEducationEnd}</p>
                                            </div>

                                            <nav id='hiddenFor750'>
                                                <span id='tooltipBtnJob' style={{cursor: 'help'}}><IconJobs width={22} height={22} color={listener.isFinalExamination === 1 ? 'green' : 'red'} /></span>
                                                <span id='tooltipBtnPrint' style={{cursor: 'help'}}><IconDiplom width={22} height={22} color={!listener.printStatusID ? 'red' : listener.printStatusID === 1 ? '#b2b200' : 'green'} /></span>
                                                <span id='tooltipBtnDeliv' style={{cursor: 'help'}}><IconDelivery width={22} height={22} color={listener.isDelivery === 1 ? 'green' : 'red'} /></span>
                                                <span id='tooltipBtnScan' style={{cursor: 'help'}}><IconScanFile width={22} color={listener.isScan === 1 ? 'green' : 'red'} /></span>
                                            </nav>
                                        </Table.Row>
                                    ))
                                }
                            </Table.RowCard>
                        ))
                    }
                </Table.Body>
            </Table.Container>

            <Flex direction="row" justifyContent="space-between" alignItems="center" className="page_container-footer">
                <Flex gap="8px" direction="row" className="page_container-footer-stat">
                    <div>Новые: <span>{contractsStatistics.new}</span></div>
                    <Divider id='hiddenForMobile' type="vertical" />
                    <div id='hiddenForMobile'>На подпись: <span>{contractsStatistics.signature}</span></div>
                    <Divider id='hiddenForMobile' type="vertical" />
                    <div id='hiddenForMobile'>Ждут доступ: <span>{contractsStatistics.waitingAccess}</span></div>
                    <Divider className='hiddenFor750' type="vertical" />
                    <div className='hiddenFor750'>Обучаются: <span>{contractsStatistics.studying}</span></div>
                    <Divider className='hiddenFor950' type="vertical" />
                    <div className='hiddenFor950'>Закончили: <span>{contractsStatistics.studyingEnd}</span></div>
                    <Divider className='hiddenFor950' type="vertical" />
                    <div className="hiddenFor950">Отказ: <span>{contractsStatistics.canceled}</span></div>
                </Flex>

                <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Номеру договора<br/>🗸 Номеру заявки<br/>🗸 Фамилии клиента<br/>🗸 Имени клиента<br/>🗸 Отчеству клиента<br/>🗸 Телефону клиента<br/>🗸 Email клиента<br/>🗸 Наименованию контрагента<br/>🗸 Адресу доставки</ToolTip>
        <ToolTip anchorSelect="#tooltipBtnFilter" content='Фильтр' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Создать договор' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
        
        <ToolTip anchorSelect="#tooltipBtnJob" content='Итоговая работа' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPrint" content='Печать' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnDeliv" content='Доставка' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnScan" content='Скан' place="top-end" />
        
        <ToolTip anchorSelect="#tooltipBtnOutsideMoodle" content='Обучение вне moodle' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPostPayment" content='Пост оплата' place="bottom-end" />
    </>
}