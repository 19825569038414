import moment from 'moment'
import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { FieldsErrorType, ICardApplication, ICardApplicationContainer, IFieldsError } from './_types'
import { CardApplication } from './_interface'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { phoneMask, validFormData } from 'hooks/useForm'

import { useDict } from 'store/dict/dict.state'
import { useCommon } from 'store/common/common.state'
import { ISitesDbNameList } from 'store/dict/dict.types'
import { useApplications } from 'store/applications/applications.state'
import { IApplicationCardLogs, IApplicationCardPrograms } from 'store/applications/applications.types'

import { IModalNotice, ModalNotice } from 'components/dumb/modal.notice'
import { IModalConfirm, ModalConfirm } from 'components/dumb/modal.confirm'
import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'

import { SelectMailingContainer, ISelectMailingContainer } from '../selectMailings'
import { SelectDictionaryContainer, ISelectDictionaryContainer, DictNameType } from '../selectDictionary'



/** Карточка по заявке - Контейнер */
export const CardApplicationContainer = ({isOpen, cardMode, cardID, handlerButtonOk, handlerButtonCancel}: ICardApplicationContainer) => {
    const notify = useContext(NotificationContext)

    const {actionGetEduDateStartEnd} = useCommon(useShallow((state) => state))
    const {isLoadingCard, actionGetApplicationCard, actionSetApplicationCard, actionCreateDemoAccess, actionGetApplicationCheckPhone} = useApplications(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        actionGetApplicationCard: state.actionGetApplicationCard,
        actionSetApplicationCard: state.actionSetApplicationCard,
        actionCreateDemoAccess: state.actionCreateDemoAccess,
        actionGetApplicationCheckPhone: state.actionGetApplicationCheckPhone,
    })))
    const {clearDictState, actionGetSitesDbName} = useDict(useShallow(state => state))

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalMailings = useInput<ISelectMailingContainer>({isOpen: false})
    const modalDict = useInput<ISelectDictionaryContainer>({isOpen: false})
    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalNotice = useInput<IModalNotice>({isOpen: false})

    const tabActive = useInput<string>('primary')
    const fieldsError = useInput<IFieldsError>({})
    const isFieldsDisabled = useInput<boolean>(false)
    const dbNameList = useInput<ISitesDbNameList[]>([])
    const dbNameSelected = useInput<string>('modern_academy')
    
    const applicationNumber = useInput<number | ''>('')
    const applicationDateCreate = useInput<string>('')
    const applicationIsRepeat = useInput<number | ''>('')
    
    const txtClientName = useInput<string>('')
    const txtClientPhone = useInput<string>('')
    const txtClientEmail = useInput<string>('')
    
    const txtProgramNameID = useInput<number | ''>('')
    const txtProgramName = useInput<string>('')
    const txtProgramTypeID = useInput<number | ''>('')
    const txtProgramType = useInput<string>('')
    const txtAttestationTypeID = useInput<number | ''>(2)
    const txtAttestationType = useInput<string>('Итоговое тестирование')
    const txtEducationDateStart = useInput<string>('')
    const txtEducationDateEnd = useInput<string>('')    
    const txtDurationEducation = useInput<string>('')
    const txtVolumeHours = useInput<string>('')
    const txtProgramSum = useInput<string>('')
    
    const txtApplicationStatusID = useInput<number | ''>(1)
    const txtApplicationStatus = useInput<string>('Новая')
    const txtDateNextCall = useInput<string>('')
    const txtTimeNextCall = useInput<string>('')
    const txtApplicationTypeID = useInput<number | ''>(1)
    const txtApplicationType = useInput<string>('Заявка')
    const txtApplicationSourceID = useInput<number | ''>(1)
    const txtApplicationSource = useInput<string>('Телефон')
    const txtApplicationComment = useInput<string>('')

    const programsList = useInput<IApplicationCardPrograms[]>([])
    const logsList = useInput<IApplicationCardLogs[]>([])


    useEffect(() => {
        if (isOpen) {
            getEducationDate('')
            actionGetSitesDbName().then((res) => {
                dbNameList.setValue(res)
                dbNameSelected.setValue(res[0].siteDbName)    
            })

            cardMode === 'edit' && actionGetApplicationCard(Number(cardID)).then((res) => {
                if (res) {
                    applicationNumber.setValue(res.applicationNumber)
                    applicationDateCreate.setValue(moment(res.applicationDateCreate).format('DD.MM.YYYY'))
                    applicationIsRepeat.setValue(res.applicationIsRepeat)

                    txtClientName.setValue(res.clientName)
                    txtClientPhone.setValue(res.clientPhone)
                    txtClientEmail.setValue(res.clientEmail || '')

                    txtProgramNameID.setValue(res.programNameID || '')
                    txtProgramName.setValue(res.programName || '')
                    txtProgramTypeID.setValue(res.programTypeID || '')
                    txtProgramType.setValue(res.programType || '')
                    txtAttestationTypeID.setValue(res.attestationTypeID || '')
                    txtAttestationType.setValue(res.attestationType || '')
                    txtEducationDateStart.setValue(res.educationDateStart || '')
                    txtEducationDateEnd.setValue(res.educationDateEnd || '')
                    txtDurationEducation.setValue(res.durationEducation || '')
                    txtVolumeHours.setValue(res.volumeHours || '')
                    txtProgramSum.setValue(res.programSum || '')
                    
                    txtApplicationStatusID.setValue(res.applicationStatusID || '')
                    txtApplicationStatus.setValue(res.applicationStatus || '')
                    txtDateNextCall.setValue(res.dateNextCall || '')
                    txtTimeNextCall.setValue(res.timeNextCall || '')
                    txtApplicationTypeID.setValue(res.applicationTypeID || '')
                    txtApplicationType.setValue(res.applicationType || '')
                    txtApplicationSourceID.setValue(res.applicationSourceID || '')
                    txtApplicationSource.setValue(res.applicationSource || '')
                    txtApplicationComment.setValue(res.applicationComment || '')

                    programsList.setValue(res.queryPrograms)
                    logsList.setValue(res.logs)
                }
            })
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isFieldsDisabled.setValue(!txtProgramNameID.value)
    }, [txtProgramNameID.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getEducationDate(txtEducationDateStart.value)
    }, [txtDurationEducation.value, txtProgramTypeID.value]) // eslint-disable-line react-hooks/exhaustive-deps



    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        clearDictState()

        tabActive.setValue('primary')
        fieldsError.setValue({})
        isFieldsDisabled.setValue(false)
        dbNameList.setValue([])
        dbNameSelected.setValue('modern_academy')
    
        applicationNumber.setValue('')
        applicationDateCreate.setValue('')
        applicationIsRepeat.setValue('')
    
        txtClientName.setValue('')
        txtClientPhone.setValue('')
        txtClientEmail.setValue('')
        
        txtProgramNameID.setValue('')
        txtProgramName.setValue('')
        txtProgramTypeID.setValue('')
        txtProgramType.setValue('')
        txtAttestationTypeID.setValue(2)
        txtAttestationType.setValue('Итоговое тестирование')
        txtEducationDateStart.setValue('')
        txtEducationDateEnd.setValue('')    
        txtDurationEducation.setValue('')
        txtVolumeHours.setValue('')
        txtProgramSum.setValue('')
        
        txtApplicationStatusID.setValue(1)
        txtApplicationStatus.setValue('Новая')
        txtDateNextCall.setValue('')
        txtTimeNextCall.setValue('')
        txtApplicationTypeID.setValue(1)
        txtApplicationType.setValue('Заявка')
        txtApplicationSourceID.setValue(1)
        txtApplicationSource.setValue('Телефон')
        txtApplicationComment.setValue('')

        programsList.setValue([])
    }


    /** Получение дат, начала обучения и окончание обучения */
    const getEducationDate = (eduDateStart: string) => {
        actionGetEduDateStartEnd(eduDateStart, Number(txtDurationEducation.value), txtProgramTypeID.value === 2 ? 1 : 0).then((res) => {
            res.eduStartDate && txtEducationDateStart.setValue(res.eduStartDate)
            res.eduEndDate && txtEducationDateEnd.setValue(res.eduEndDate)
        })
    }
    /** Проверка по номеру телефона, существование заявки */
    const applicationCheckPhoneNumber = async (phoneNumber: string) => {
        const res = await actionGetApplicationCheckPhone(cardID || 0, phoneNumber)
        if (res?.result) handlerOpenModalNotice(true, 'Извещение...', `<b>Внимание!</b><br/>Заявка с таким номером телефона уже зарегистрирована, ее номер: <b>${res.appID}</b>`)
    }



    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'educationDateStart': txtEducationDateStartOnChange(stringDate); break
                    case 'educationDateEnd': txtEducationDateEndOnChange(stringDate); break
                    case 'dateNextCall': txtDateNextCall.setValue(stringDate); break
                }

                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора рассылок в telegram */
    const handleOpenModalMailings = (isOpen: boolean) => {
        if (!cardID) return notify && notifyOpen('Идентификактор заявки еще не определён. Возможно вы ещё не создали заявку. Открытие окна рассылок отколнено.', 'warning', 2000, notify)

        modalMailings.setValue({
            isOpen, applicationID: cardID,
            handlerButtonCancel() { modalMailings.setValue({ isOpen: false }) },
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handleOpenModalDict = (isOpen: boolean, dictName: DictNameType, fieldName: string, whereParam1?: string, whereParam2?: string) => {
        if (fieldName === 'programType' && !txtProgramNameID.value) return notify && notifyOpen('Необходимо выбрать наименование программы.', 'info', 2000, notify)
        if (fieldName === 'attestationType' && !txtProgramTypeID.value) return notify && notifyOpen('Необходимо выбрать тип программы.', 'info', 2000, notify)
        if (fieldName === 'volumeHours' && !txtProgramTypeID.value) return notify && notifyOpen('Необходимо выбрать тип программы.', 'info', 2000, notify)

        modalDict.setValue({
            isOpen, dictName, dbName: dbNameSelected.value, whereParam1, whereParam2,
            handlerButtonCancel() {modalDict.setValue({isOpen: false})}, 
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (fieldName) {
                    case 'programName': {
                        txtProgramNameID.setValue(Number(id)) // Идентификатор программы
                        txtProgramName.setValue(name) // Наименование программы

                        txtProgramTypeID.setValue(Number(param2)) // Идентификатор типа программы
                        txtProgramType.setValue(param3 || '') // Наименование типа программы

                        txtDurationEducation.setValue(param4 || '') // Продолжительность
                        txtVolumeHours.setValue(param5 || '') //  Обьем программы
                        txtProgramSum.setValue(param6 || '') // Стоимость программы
                        break
                    }
                    
                    case 'programType': {
                        txtProgramTypeID.setValue(Number(id)) // Идентификатор типа программы
                        txtProgramType.setValue(name) // Наименование типа программы

                        txtAttestationTypeID.setValue(Number(param2)) // Идентификатор типа аттестации
                        txtAttestationType.setValue(param3 || '') // Идентификатор типа аттестации

                        txtDurationEducation.setValue(param4 || '') // Продолжительность
                        txtVolumeHours.setValue(param5 || '') //  Обьем программы
                        txtProgramSum.setValue(param6 || '') // Стоимость программы
                        break
                    }
                    
                    case 'attestationType': {
                        txtAttestationTypeID.setValue(Number(id)) // Идентификатор типа аттестации
                        txtAttestationType.setValue(name) // Идентификатор типа аттестации
                        break
                    }
                    
                    case 'volumeHours': {
                        txtDurationEducation.setValue(param1 || '') // Продолжительность
                        txtVolumeHours.setValue(name) //  Обьем программы
                        txtProgramSum.setValue(param2 || '') // Стоимость программы
                        break
                    }
                    
                    case 'applicationStatus': {
                        txtApplicationStatusID.setValue(Number(id))
                        txtApplicationStatus.setValue(name)
                        break
                    }
                    
                    case 'applicationType': {
                        txtApplicationTypeID.setValue(Number(id))
                        txtApplicationType.setValue(name)
                        break
                    }
                    
                    case 'applicationSource': {
                        txtApplicationSourceID.setValue(Number(id))
                        txtApplicationSource.setValue(name)
                        break
                    }
                }

                modalDict.setValue({isOpen: false})
            }
        })
    }



    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        if (!cardID) return notify && notifyOpen('Идентификактор заявки еще не определён. Возможно вы ещё не создали заявку. Создание демо-доступа отклонено.', 'warning', 2000, notify)
        
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                actionCreateDemoAccess(Number(cardID))

            }
        })
    }
    /** Открытие/закрытие окна извещения */
    const handlerOpenModalNotice = (isOpen: boolean, headerText: string, contentText: string) => {
        modalNotice.setValue({
            isOpen, headerText, contentText, 
            handlerButtonOk() {modalNotice.setValue({isOpen: false})}, 
        })
    }



    /** Кнопка сохранить все данные во вкладке primary */
    const handleBtnSave = (isClosedModal: boolean) => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'clientName', value: txtClientName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Имя клиента должно быть обязательно заполнено.'},
            {fieldName: 'clientPhone', value: txtClientPhone.value, validType: 'phoneMask', isRequired: true, errorMessage: 'Номер телефона не корректный.'},
            {fieldName: 'clientEmail', value: txtClientEmail.value, validType: 'email', isRequired: false, errorMessage: 'Электронная почта не корректная.'},

            {fieldName: 'programName', value: txtProgramName.value, validType: 'notEmpty', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Наименование программы, обязательно должно быть указано.'},
            {fieldName: 'programType', value: txtProgramType.value, validType: 'notEmpty', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Тип программы, обязательно должен быть указан.'},
            {fieldName: 'attestationType', value: txtAttestationType.value, validType: 'notEmpty', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Тип аттестации, обязательно должен быть указан.'},
            
            {fieldName: 'educationDateStart', value: txtEducationDateStart.value, validType: 'date', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Дата начала обучения не корректна или такой даты не существует.'},
            {fieldName: 'educationDateEnd', value: txtEducationDateEnd.value, validType: 'date', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Дата окончания обучения не корректна или такой даты не существует.'},
            
            {fieldName: 'durationEducation', value: txtDurationEducation.value, validType: 'numberDecimal', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Продолжительность программы, обязательно должна быть указана.'},
            {fieldName: 'volumeHours', value: txtVolumeHours.value, validType: 'number_1-9', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Объём программы, обязательно должен быть указан.'},
            {fieldName: 'programSum', value: txtProgramSum.value, validType: 'number_1-9', isRequired: [1, 4, 5, 7].includes(txtApplicationStatusID.value || 0) ? false : true, errorMessage: 'Стоимость программы не корректное значение.'},

            {fieldName: 'applicationStatus', value: txtApplicationStatus.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Статус заявки, обязательно должен быть указан.'},
            {fieldName: 'dateNextCall', value: txtDateNextCall.value, validType: 'date', isRequired: txtApplicationStatusID.value === 2 ? true : false, errorMessage: 'Дата отложенного звонка не корректна или такой даты не существует.'},
            {fieldName: 'timeNextCall', value: txtTimeNextCall.value, validType: 'time', isRequired: false, errorMessage: 'Время отложенного звонка не корректное или такого времени не сущесвует.'},
            {fieldName: 'applicationType', value: txtApplicationType.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Тип заявки, обязательно должен быть указан.'},
            {fieldName: 'applicationSource', value: txtApplicationSource.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Истояник заявки, обязательно должен быть указан.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            actionSetApplicationCard(cardID || 0, {
                actionForm: cardMode || 'new',
                dbName: dbNameSelected.value,
            
                clientName: txtClientName.value,
                clientPhone: txtClientPhone.value,
                clientEmail: txtClientEmail.value,
            
                programNameID: txtProgramNameID.value || 0,
                programName: txtProgramName.value,
                
                programTypeID: txtProgramTypeID.value || 0,
                programType: txtProgramType.value,
                
                attestationTypeID: txtAttestationTypeID.value || 0,
            
                educationDateStart: txtEducationDateStart.value,
                educationDateEnd: txtEducationDateEnd.value,
            
                durationEducation: txtDurationEducation.value,
                volumeHours: txtVolumeHours.value,
                programSum: txtProgramSum.value,
            
                applicationStatusID: txtApplicationStatusID.value || 0,
                applicationTypeID: txtApplicationTypeID.value || 0,
                applicationSourceID: txtApplicationSourceID.value || 0,
            
                dateNextCall: txtDateNextCall.value,
                timeNextCall: txtTimeNextCall.value,
            
                applicationComment: txtApplicationComment.value,
                clientRating: 10,
            }).then((res) => {
                if (res === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    if (isClosedModal || cardMode === 'new') handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }


    /** Функция по проверке номера телефона, в случае успеха даем вводить */
    const debouncePhone = useDebounce(applicationCheckPhoneNumber, 1500) // Запуск получения дат начала обучения и окончания обучения
    const handleChangePhone = (phoneNumber: string) => {
        const resultPhoneMask = phoneMask(phoneNumber)
        txtClientPhone.setValue(resultPhoneMask.data)
        debouncePhone(resultPhoneMask.data)
    }
    
    const debounceEduDate = useDebounce(getEducationDate, 1500) // Запуск получения дат начала обучения и окончания обучения
    const txtEducationDateStartOnChange = (value: string) => {txtEducationDateStart.setValue(value); debounceEduDate(value)} // Поле дата начала обучения
    const txtEducationDateEndOnChange = (value: string) => {txtEducationDateEnd.setValue(value); debounceEduDate(txtEducationDateStart.value)} // Поле дата окончания обучения

    /** Кнопка копирования текста в буфер обмена */
    const handlerCopyToClipBoard = async (textToCopy: string) => {
        const textarea = document.createElement('textarea')
        textarea.value = textToCopy
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)

        notify && notifyOpen('Текст скопирован в буфер обмена!', 'success', 1500, notify)
    }


    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardApplication = {
        isOpen, cardMode, handlerButtonCancel,
        tabActive, fieldsError, isFieldsDisabled,
        dbNameList: dbNameList.value, dbNameSelected,

        logsList, programsList, modalNotice,

        modalCalendar, handleOpenModalCalendar,
        modalMailings, handleOpenModalMailings,
        modalDict, handleOpenModalDict,
        modalConfirm, handlerOpenModalConfirm,

        applicationNumber, applicationDateCreate, applicationIsRepeat,

        isLoading: isLoadingCard, txtClientName, txtClientPhone, txtClientEmail,
        txtProgramNameID, txtProgramName, txtProgramTypeID, txtProgramType, txtAttestationType,
        txtEducationDateStart, txtEducationDateEnd, 
        txtDurationEducation, txtVolumeHours, txtProgramSum,

        txtApplicationStatusID, txtApplicationStatus, txtDateNextCall, txtTimeNextCall, 
        txtApplicationType, txtApplicationSource, txtApplicationComment,

        handleChangePhone, handleBtnSave, handlerCopyToClipBoard,
        txtEducationDateStartOnChange, txtEducationDateEndOnChange,
    }

    return <>
        <CardApplication {...propsToComponent} />
        
        <ModalNotice {...modalNotice.value} />
        <ModalConfirm {...modalConfirm.value} />
        <ModalCalendar {...modalCalendar.value} />

        <SelectMailingContainer {...modalMailings.value} />
        <SelectDictionaryContainer {...modalDict.value} />
    </> 
}