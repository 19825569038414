import { useEffect, useRef } from "react"
import moment from "moment"

import { IApplications } from "./_types"
import CommonService from "services/common.service"

import imgEmty from 'images/empty.svg'
import { IconAdd, IconApplications, IconClearClose, IconContracts, IconFilter, IconInfo, IconSearch, IconTelegram, IconTemp, IconUpdate } from "images/icons"

import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Divider, Button, Table } from 'components/ui'


/** Заявки */
export const Applications = ({
    accessLevelRead,
    currentPageNumber, totalCount, pageSize,
    filterIsActivate, filterIsOpen,
    handleOpenCardApplication,
    handleOpenModalMailings,
    handleApplyClearFilter,
    isLoading, applicationsStatistics, applicationsList, accessLevelID,
    txtSearch, txtSearchOnChange, loadDataApplications, handleOpenCardApplicationPromo, handlerOpenModalConfirm
}: IApplications) => {
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth;
            tableHeadNavRef.current.style.width = `${width}px`
        }
    }, [applicationsList, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return <>
        <Loader isOpen={isLoading} backdrop color="#f34e2e" title="Загрузка заявок..." />
        <Empty image={imgEmty} isVisible={!accessLevelRead.value} /> 

        <Flex gap="3px" className="page_container" isVisible={accessLevelRead.value}>
            <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                    <IconApplications width={28} height={28} />
                    <h4>Заявки ({totalCount})</h4>
                </Grid>

                <Flex gap="6px" direction="row" className="page_header_for_mobile">
                    <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%' 
                        value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                        paddingRight={4} prefix={<IconSearch width={16} height={16} />} 
                        suffix={<>
                            {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                            <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                        </>} 
                    />
                    <div style={{ position: 'relative' }}>
                        <Button id='tooltipBtnFilter' size="sm" variant="icon" onClick={() => filterIsOpen.setValue(true)}><IconFilter /></Button>
                        <div style={{ position: 'absolute', bottom: '-4px', right: '-4px' }}>
                            {filterIsActivate.value && <Button id='tooltipBtnClear' size="xs" variant="primary" onClick={() => handleApplyClearFilter('clear')}>
                                <IconClearClose width={16} height={16} />
                            </Button>}
                        </div>
                    </div>
                    <Divider type="vertical" margin="3px" />
                    <Button id='tooltipBtnPromo' size="sm" variant="icon" onClick={() => handleOpenCardApplicationPromo(true)}><IconTemp /></Button>
                    <Divider type="vertical" margin="3px" />
                    <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataApplications(txtSearch.value)}><IconUpdate /></Button>
                    <Button id='tooltipBtnCreate' size="sm" variant="icon" onClick={() => handleOpenCardApplication(true, "new")}><IconAdd /></Button>
                </Flex>
            </Flex>

            <Divider variant="dark" />

            <Table.Container loadingTitle="Загрузка...">
                <Table.Header id='hiddenForMobile' classGridTemplateColumns="applications_grid_columns" marginLeft="78px" marginRight="10px">
                    <span>Имя клиента<br/>Телефон клиента</span>
                    <span>Комментарий<br/>Часы / Программа</span>
                    <span className="hiddenFor750 table_column_data_center">Отложенный звонок</span>
                    <span className="hiddenFor950 table_column_data_center">Статус заявки</span>
                    <span className="hiddenFor750 table_column_data_center">Дата создания</span>
                    <span ref={tableHeadNavRef}></span>
                </Table.Header>

                <Table.Body>
                    {applicationsList.length === 0 
                        ? <Empty image={imgEmty} /> 
                        : applicationsList.map((item) => (
                            <Table.Row key={item.applicationID} indexContent={<span>{item.applicationID}</span>} indexContentWidth="68px" paddingInset="0 0 0 10px"
                                badge={(item.contractCount > 0 || item.applicationStatusID === 3) && <p className="table_badge" style={{ background: item.contractCount === 0 ? 'red' : item.applicationStatusColor }}>{item.contractCount}</p>}
                                backgroundColor={item.applicationStatusColor}
                                classGridTemplateColumns="applications_grid_columns" 
                                onClick={() => handleOpenCardApplication(true, "edit", item.applicationID)}>

                                <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.clientName}</p>
                                    <p style={{ opacity: '.7' }}>{item.clientPhone} {item.isRepeat === 1 ? '🔥' : ''}</p>
                                </div>
                                
                                <div className='hiddenForMobile' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.applicationComment || '----'}</p>
                                    <p style={{ opacity: '.7' }}>{item.volumeHours ? CommonService.formatHours(item.volumeHours) : '----'} / {item.courseName || '----'}</p>
                                </div>

                                <div className="hiddenFor750 hiddenForMobile table_column_data_center">
                                    <p>{item.dateNextCall || '----'}</p>
                                    <p>{item.timeNextCall || '----'}</p>
                                </div>

                                <span className="hiddenForMobile hiddenFor950 table_column_data_center" style={{ color: item.applicationStatusColor }}>{item.applicationStatus}</span>
                                <span className="hiddenFor750 hiddenForMobile table_column_data_center">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>

                                <nav ref={tableNavRef}>
                                    <Button id='tooltipBtnCreateContract' variant="icon" size="xs" isDisabled={![1, 2].includes(accessLevelID) || item.applicationStatusID !== 3} onClick={(e) => {handlerOpenModalConfirm(true, 'Создание договора', `Вы действительно хотите создать договор по заявке № ${item.applicationID}`, item.applicationID, item.dataBase); e.stopPropagation()}}><IconContracts /></Button>
                                    <Button id='tooltipBtnMailings' variant="icon" size="xs" isDisabled={![1, 3, 4].includes(accessLevelID)} onClick={(e) => {handleOpenModalMailings(true, item.applicationID); e.stopPropagation()}}><IconTelegram /></Button>
                                </nav>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table.Container>

            <Flex direction="row" justifyContent="space-between" alignItems="center" className="page_container-footer">
                <Flex gap="8px" direction="row" className="page_container-footer-stat">
                    <div>Новые: <span>{applicationsStatistics.new}</span></div>
                    <Divider id='hiddenForMobile' type="vertical" />
                    <div id='hiddenForMobile'>Отложенные: <span>{applicationsStatistics.delayedCall}</span></div>
                    <Divider id='hiddenForMobile' type="vertical" />
                    <div id='hiddenForMobile'>Договор: <span>{applicationsStatistics.executionContract}</span></div>
                    <Divider className='hiddenFor750 hiddenForMobile' type="vertical" />
                    <div className='hiddenFor750 hiddenForMobile'>Отказ: <span>{applicationsStatistics.canceled}</span></div>
                    <Divider className='hiddenFor750 hiddenForMobile' type="vertical" />
                    <div className='hiddenFor750 hiddenForMobile'>Не клиент: <span>{applicationsStatistics.notClient}</span></div>
                    <Divider className='hiddenFor950 hiddenForMobile' type="vertical" />
                    <div className="hiddenFor950 hiddenForMobile">Игнор: <span>{applicationsStatistics.clientIgnore}</span></div>
                </Flex>

                <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Номеру заявки<br/>🗸 Имени клиента<br/>🗸 Телефону клиента<br/>🗸 Email клиента<br/>🗸 Имени курса<br/>🗸 Комментарию</ToolTip>

        <ToolTip anchorSelect="#tooltipBtnMailings" content='Рассылки' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreateContract" content='Создать договор' place="bottom-end" />

        <ToolTip anchorSelect="#tooltipBtnFilter" content='Фильтр' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPromo" content='Промо' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Создать заявку' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
    </>
}