import React, { memo } from 'react'
import { BeatLoader } from 'react-spinners'

import { ITextarea } from './type'
import css from './index.module.scss'


/** Многострочное текстовое поле  */
export const Textarea = memo(({ 
    id, name, cols, rows, placeholder, autoComplete = 'off', prefix, suffix, variant = 'primary', width, maxWidth, isReadOnly = false, isDisabled = false, isLoading = false, paddingRight, value, onChange 
}: ITextarea): React.JSX.Element => {
    return <section className={`${css[variant]} ${isDisabled && css['disabled']}`} style={{ width: width, maxWidth: maxWidth, paddingRight: paddingRight }}>
        {isLoading && <div className={css.isLoading}><BeatLoader size={10} loading={isLoading} color='#f34e2e' /></div>}
        {prefix}
        <textarea id={id} name={name} className={css.textarea} cols={cols} rows={rows} placeholder={placeholder} value={value} onChange={onChange} autoComplete={autoComplete} readOnly={isReadOnly} disabled={isDisabled} style={{ resize: 'none' }} />
        {suffix}
    </section>
})