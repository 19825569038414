import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { SelectMailing } from './_interface'
import { IActionMailing, ISelectMailing, ISelectMailingContainer } from './_types'

import { useInput } from 'hooks/useInput'
import { useApplications } from 'store/applications/applications.state'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Рассылки - Контейнер */
export const SelectMailingContainer = ({isOpen, applicationID, handlerButtonCancel}: ISelectMailingContainer) => {
    const notify = useContext(NotificationContext)

    const {actionSendTelegramClientInfo, actionSendTelegramClientDecisionLearn, actionSendTelegramClientDemoInfo, actionSendTelegramClientRepeat,
        actionSendTelegramClientTrainingBegun, actionSendTelegramClientNewYear, actionSendEmailClientInfo, actionCreateDemoAccess
    } = useApplications(useShallow(state => ({
        error: state.error,
        actionSendTelegramClientInfo: state.actionSendTelegramClientInfo,
        actionSendTelegramClientDemoInfo: state.actionSendTelegramClientDemoInfo,
        actionSendTelegramClientRepeat: state.actionSendTelegramClientRepeat,
        actionSendTelegramClientDecisionLearn: state.actionSendTelegramClientDecisionLearn,
        actionSendTelegramClientTrainingBegun: state.actionSendTelegramClientTrainingBegun,
        actionSendTelegramClientNewYear: state.actionSendTelegramClientNewYear,
        actionSendEmailClientInfo: state.actionSendEmailClientInfo,
        actionCreateDemoAccess: state.actionCreateDemoAccess,
    })))

    const selectedMailingID = useInput<number>(0)
    const actionMailingsList = useInput<IActionMailing[]>([
        {id: 7, name: 'Демо + Инфо', isCheck: false},
        {id: 8, name: 'Повторный клиент', isCheck: false},
        {id: 6, name: 'Демо-доступ', isCheck: false},
        {id: 1, name: 'Информация для клиента', isCheck: false},
        {id: 2, name: 'Решение клиента обучаться', isCheck: false},
        {id: 3, name: 'Обучение уже началось', isCheck: false},
        {id: 4, name: 'Промо-акция', isCheck: false},
        {id: 5, name: 'На почту клиенту', isCheck: false},
    ])

    useEffect(() => {
        if (!isOpen) {
            const updatedActionMailings: IActionMailing[] = actionMailingsList.value.map(item => {
                return { ...item, isCheck: false }
            })
    
            actionMailingsList.setValue(updatedActionMailings)
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (error) return notify && notifyOpen(error?.message, error?.type, 3000, notify)
    // }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция по выбору рассылок */
    const onSelectString = (id: number) => {
        const updatedActionMailings: IActionMailing[] = actionMailingsList.value.map(item => {
            if (item.id === id) {
                return { ...item, isCheck: !item.isCheck }
            }
            return item
        })

        actionMailingsList.setValue(updatedActionMailings)
    }

    /** Функция по откравке рассылок */
    const handleButtonSended = () => {
        let sendOK: boolean = false

        // Проверяем, есть ли выбранные рассылки
        for (const item of actionMailingsList.value) {
            if (item.isCheck) sendOK = true
        }

        if (sendOK) {
            for (const item of actionMailingsList.value) {
                if (item.isCheck) {
                    switch (item.id) {
                        case 1: actionSendTelegramClientInfo(Number(applicationID)); break
                        case 2: actionSendTelegramClientDecisionLearn(Number(applicationID)); break
                        case 3: actionSendTelegramClientTrainingBegun(Number(applicationID)); break
                        case 4: actionSendTelegramClientNewYear(Number(applicationID)); break
                        case 5: actionSendEmailClientInfo(Number(applicationID)); break
                        case 6: actionCreateDemoAccess(Number(applicationID)); break
                        case 7: actionSendTelegramClientDemoInfo(Number(applicationID)); break
                        case 8: actionSendTelegramClientRepeat(Number(applicationID)); break
                    }
                }
            }

            handlerButtonCancel && handlerButtonCancel()
        } else {
            notify && notifyOpen('Необходимо выбрать хотя бы одну рассылку.', 'warning', 2000, notify)
        }
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ISelectMailing = {
        isOpen, handlerButtonCancel,
        selectedMailingID, actionMailingsList, 
        onSelectString, handleButtonSended
    }

    return <SelectMailing {...propsToComponent} />
}