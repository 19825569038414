import { Modal } from './modal'
import { Drawer } from './drawer'
import { Collapse } from './collapse'

import { TabContainer, TabItem } from './tab'
import { TableContainer, TableHeaders, TableBody, TableRow, TableRowCard } from './table'

import { Badge } from './badge'
import { Empty } from './empty'
import { Loader } from './loader'

import { Input } from './input'
import { Button } from './button'
import { Toogle } from './toogle'
import { Checkbox } from './checkbox'
import { Textarea } from './textarea'

import { Flex } from './flex'
import { Grid } from './grid'
import { Card } from './card'
import { ToolTip } from './tooltip'
import { Divider } from './divider'
import { Container } from './container'
import { Pagination } from './pagination'

const Tab = {
    Container: TabContainer,
    Item: TabItem
}

const Table = {
    Container: TableContainer,
    Header: TableHeaders,
    Body: TableBody,
    Row: TableRow,
    RowCard: TableRowCard
}

export {
    Modal, Drawer, Collapse, Tab, Table,
    Badge, Empty, Loader,
    Flex, Grid, Card, ToolTip, Divider, Container, Pagination,
    Input, Button, Toogle, Checkbox, Textarea    
}