import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import { 
    IContractsActions, IContractsState, 
    IContractsStatistics, IContractsList, IContractsFilter,
    IContractFilesList,
    IProgramsList,
    IContractCard,
    IContractCardPrograms,
    IContractsFormSendedData,
    IContractsProgramFormSendedData,
    IContractsProgramListenerFormSendedData,
    IContractCardPayments,
    IContractsPaymentFormSendedData,
    IContractGenerateDocuments,
} from './contracts.types'
import { getContractCard, getContractCardPrograms, getContractFiles, getContractInfo, getContractPayments, getContractProgramsListeners, getContracts, getContractsStatistics, getNewContractNumber, setContractCanceled, setContractCardPaymentSave, setContractCardProgramListenerSave, setContractCardProgramsSave, setContractCardSave, setContractFilesDelete, setContractGenerateDocument, setContractUploadFiles, setCreatePrimaryAccess, setSendedContractDocuments, setSendedContractStatementDelivery } from './contracts.api'


export const initialStateContracts: IContractsState = {
    error: null,
    contractsStatistics: {} as IContractsStatistics,

    isReloadContracts: false,
    isLoadingCard: false,
 
    contracts: {
        isLoading: false,

        items: [] as IContractsList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },
}

/** Хранилище страницы - Заявки */
export const useContracts = create<IContractsState & IContractsActions>()(devtools((set) => ({
    ...initialStateContracts,


    clearContractsState: () => {set(initialStateContracts)},

    /** Получение списка договоров */
    actionGetContracts: async (pageNumber: number, search: string, filter?: IContractsFilter) => {
        set((state) => ({contracts: {...state.contracts, isLoading: !state.isReloadContracts}}))

        const resStat = await getContractsStatistics()
        if (resStat.status !== 200) set({ error: {type: resStat.status === 500 ? 'error' : 'warning', message: resStat.message} })

        const res = await getContracts(pageNumber, search, filter)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadContracts: false})

            set((state) => ({
                contractsStatistics: {
                    ...state.contractsStatistics,
                    ...resStat.data
                },
                contracts: {
                    ...state.contracts, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        set((state) => ({contracts: {...state.contracts, isLoading: false}}))
    },
    /** Получение списка оплат */
    actionGetgetContractPayments: async (contractID: number) => {
        let returnData: IContractCardPayments | null = null
        set({isLoadingCard: true})

        const res = await getContractPayments(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})
        return returnData
    },
    /** Получение списка програм и слушателей по ним */
    actionGetgetContractProgramsListeners: async (contractID: number) => {
        let returnData: IProgramsList[] = []
        set({isLoadingCard: true})

        const res = await getContractProgramsListeners(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data?.programs
        }

        set({isLoadingCard: false})
        return returnData
    },
    /** Получение списка файлов */
    actionGetContractFiles: async (contractID: number) => {
        let returnData: IContractFilesList[] = []
        set({isLoadingCard: true})

        const res = await getContractFiles(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data?.files
        }

        set({isLoadingCard: false})
        return returnData
    },



    /** Получение данных по анкете договора */
    actionGetContractCard: async (contractID: number) => {
        let returnData: IContractCard | null = null
        set({isLoadingCard: true})

        const res = await getContractCard(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Изменение номера договора */
    actionGetNewContractNumber: async (contractID: number, contractDate: string) => {
        let returnData: string = ''
        set({isLoadingCard: true})

        const res = await getNewContractNumber(contractID, contractDate)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            if (res.data) returnData = res.data.contractNumber
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Получение данных по анкете договора */
    actionGetContractCardPrograms: async (recordID: number, action: string) => {
        let returnData: IContractCardPrograms | null = null
        set({isLoadingCard: true})

        const res = await getContractCardPrograms(recordID, action)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по анкете программы договора */
    actionSetContractCardProgramsSave: async (contractID: number, contractProgramID: number, data: IContractsProgramFormSendedData) => {
        set({isLoadingCard: true})

        const res = await setContractCardProgramsSave(contractID, contractProgramID, data)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadContracts: true})
        }
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Сохранение данных по анкете программа-слушатель договора */
    actionSetContractCardProgramListenerSave: async (contractListenerID: number, data: IContractsProgramListenerFormSendedData) => {
        set({isLoadingCard: true})

        const res = await setContractCardProgramListenerSave(contractListenerID, data)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadContracts: true})
        }
            
        set({isLoadingCard: false})
        
        return res.status
    },
    /** Сохранение данных по анкете оплаты договора */
    actionSetContractCardPaymentSave: async (contractID: number, contractPaymentID: number, data: IContractsPaymentFormSendedData) => {
        set({isLoadingCard: true})

        const res = await setContractCardPaymentSave(contractID, contractPaymentID, data)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadContracts: true})
        }
            
        set({isLoadingCard: false})
        
        return res.status
    },
    /** Удаление файлов по договору */
    actionSetContractFilesDelete: async (contractID: number, fileID: number) => {
        set({isLoadingCard: true})

        const res = await setContractFilesDelete(contractID, fileID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Загрузка файлов по договору */
    actionSetContractUploadFiles: async (contractID: number, contractSectionID: number, files: File[]) => {
        set({isLoadingCard: true})

        const res = await setContractUploadFiles(contractID, contractSectionID, files)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Сохранение данных по анкете договора */
    actionSetContractCard: async (contractID: number, data: IContractsFormSendedData) => {
        set({isLoadingCard: true})

        const res = await setContractCardSave(contractID, data)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },



    /** Допуск к обучению и отправка информации в Telegram канал (ИКТ - Доступы в ЛК) */
    actionSetCreatePrimaryAccess: async (contractID: number, isOutsideMoodle: number) => {
        set({isLoadingCard: true})

        const res = await setCreatePrimaryAccess(contractID, isOutsideMoodle)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadContracts: true})
        }
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Формирование документов по договору */
    actionSetContractGenerateDocument: async (contractID: number, documents: IContractGenerateDocuments[], paymentSum: string) => {
        set({isLoadingCard: true})

        const res = await setContractGenerateDocument(contractID, documents, paymentSum)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Отправка → Документы на подпись (Договор, Счёт, Заявление) → Telegram канал (ИКТ - Оформление договоров) */
    actionSetSendedContractDocuments: async (contractID: number) => {
        set({isLoadingCard: true})

        const res = await setSendedContractDocuments(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadContracts: true})
        }
            
        set({isLoadingCard: false})
        return res.status
    },
    /** Отправка → Заявление на доставку + Счёт → Telegram канал (ИКТ - Заявление на доставку) */
    actionSetSendedContractStatementDelivery: async (contractID: number) => {
        set({isLoadingCard: true})

        const res = await setSendedContractStatementDelivery(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },



    /** Получение краткой информации для МИША ДЖЯН (WARNING GLOBAL) */
    actionGetContractInfo: async (contractID: string) => {
        const res = await getContractInfo(contractID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({error: null})

        return res
    },
    /** Отказ от договора для МИША ДЖЯН (WARNING GLOBAL) */
    actionSetContractCanceled: async (contractID: string, comment: string) => {
        const res = await setContractCanceled(contractID, comment)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({error: null})

        return res.status
    },

}), {name: 'contractsState'}))