import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { CardContractUpload } from './_interface'
import { ICardContractUpload, ICardContractUploadContainer } from './_types'

import { useInput } from 'hooks/useInput'
import { useDict } from 'store/dict/dict.state'
import { IContractSectionsList } from 'store/dict/dict.types'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Контейнер окна загрзки файлов по договору */
export const CardContractUploadContainer = ({isOpen, fileUploader, handlerButtonCancel}: ICardContractUploadContainer) => {
    const notify = useContext(NotificationContext)

    const {isLoading, actionGetContractSections} = useDict(useShallow((state) => state))

    const sectionList = useInput<IContractSectionsList[]>([])
    const selectedSectionID = useInput<number | ''>('')


    useEffect(() => {
        if (isOpen) {
            actionGetContractSections().then((res) => {
                if (res) sectionList.setValue(res)
            })
        }

        !isOpen && clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Очистка формы */
    const clearData = () => {
        sectionList.setValue([])
        selectedSectionID.setValue('')
    }

    /** Загрузка файлов */
    const thisFileUploader = (files: File[]) => {
        if (!selectedSectionID.value) return notify && notifyOpen('Вы не выбрали раздел из списка.', 'info', 1500, notify)

        fileUploader && fileUploader(files, selectedSectionID.value)
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardContractUpload = {
        isOpen, handlerButtonCancel, thisFileUploader,
        isLoading, sectionList, selectedSectionID,
    }

    return <>
        <CardContractUpload {...propsToComponent} />
    </>
}