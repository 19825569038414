import Dropzone from 'react-dropzone'

import { ICardContractUpload } from './_types'

import imgEmty from 'images/empty.svg'
import { IconClearClose } from 'images/icons'

import { Empty, Flex, Modal, Loader, Divider, Button, Table } from 'components/ui'


/** Окно загрзки файлов по договору */
export const CardContractUpload = ({isOpen, isLoading, sectionList, selectedSectionID, handlerButtonCancel, thisFileUploader}: ICardContractUpload) => {
    return <>
        <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} overflowY="hidden" maxWidth="330px">
            <Flex className='modal_container'>
                <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                    <h4>Загрузка файлов</h4>

                    <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='modal_container-body'>
                    <Dropzone onDropAccepted={(files) => thisFileUploader(files)} multiple={true}>
                        {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                            <div {...getRootProps({ className: isDragAccept ? `dropZone_container dropZone_dragAccept` : `dropZone_container`, onClick: event => event.stopPropagation() })}>
                                <input {...getInputProps()} />

                                <Flex gap='6px' className='modal_container-content' width='100%'>
                                    <Loader isOpen={isLoading} backdrop color='#f34e2e' title='Загрузка разделов...' />

                                    <p style={{ color: 'red', fontSize: '15px' }}>✔ Выберите один раздел из списка.<br />✔ Перетащите файлы в данное окно.</p>

                                    <Divider />

                                    <Table.Body overflowY='hidden' minHeight='160px'>
                                        {!sectionList.value
                                            ? <Empty image={imgEmty} title="Секции отсутствуют" />
                                            : sectionList.value.map((item, index) => (
                                                <Table.Row key={index} indexContent={`${selectedSectionID.value === item.contractSectionID ? '✔' : '✘'}`} indexContentWidth='30px' paddingInset='5px 8px' backgroundColor={`${selectedSectionID.value === item.contractSectionID ? '#32d732' : '#f34e2e'}`} onClick={() => selectedSectionID.setValue(item.contractSectionID)}>
                                                    <p>{item.contractSectionName}</p>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>
                                </Flex>
                                
                            </div>
                        )}
                    </Dropzone>

                    <Divider />
                    <Dropzone onDropAccepted={(files) => thisFileUploader(files)}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button width='100%'>Выбрать из каталога</Button>
                            </div>
                        )}
                    </Dropzone>

                </Flex>
            </Flex>
        </Modal>
    </>
}