import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { CardModeType } from '../common.type'
import { SelectListener } from './_interface'
import { ISelectListener, ISelectListenerContainer } from './_types'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { useListeners } from 'store/listeners/listeners.state'

import { CardListenerContainer, ICardListenerContainer } from '../cardListener'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Выбор слушателя - Контейнер */
export const SelectListenerContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: ISelectListenerContainer) => {
    const notify = useContext(NotificationContext)

    const { error, isReloadListeners, isLoading, totalCount, pageSize, listenersList, getListeners, clearListeners } = useListeners(useShallow((state) => ({
        error: state.error,
        isReloadListeners: state.isReloadListeners,
        
        isLoading: state.listeners.isLoading,
        totalCount: state.listeners.totalCount,
        pageSize: state.listeners.pageSize,

        listenersList: state.listeners.items,
        getListeners: state.actionGetListeners,
        clearListeners: state.clearListenersState,
    })))

    const cardListeners = useInput<ICardListenerContainer>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')


    useEffect(() => {
        isOpen && loadData()

        return (() => {
            if (!isOpen) {
                clearListeners()

                txtSearch.setValue('')
                currentPageNumber.setValue(1)
            }
        })
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => { if (error) return notify && notifyOpen(error?.message, error?.type, 3000, notify) }, [error]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { isOpen && loadData(txtSearch.value) }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { isReloadListeners && loadData(txtSearch.value) }, [isReloadListeners]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция по получению списка слушателей */
    const loadData = (search?: string) => {
        getListeners(currentPageNumber.value, search || '')
    }


    /** Открытие/закрытие карточки по слушателю */
    const handleOpenCardListener = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardListeners.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardListeners.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListeners.setValue({isOpen: false})
                loadData(txtSearch.value)
            }
        })
    }

    const debounceSearch = useDebounce(loadData, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска    

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ISelectListener = {
        isOpen, handlerButtonOk, handlerButtonCancel,

        isLoading, totalCount, pageSize, listenersList, 
        txtSearch, txtSearchOnChange, currentPageNumber,
        handleOpenCardListener,
    }    

    return <>
        <SelectListener {...propsToComponent} />

        <CardListenerContainer {...cardListeners.value} />
    </>
}