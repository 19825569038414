import { memo } from 'react'
import { style } from 'typestyle'
import { Tooltip } from 'react-tooltip'

import { ITab, ITabContainer } from './type'
import css from './index.module.scss'


/** Контейнер под вкладки */
export const TabContainer = memo(({children, isVisible = true}: ITabContainer): React.JSX.Element => {
    return <>
        {!isVisible ? '' 
            : <div style={{ gap: '27px', display: 'flex', marginLeft: '40px' }}>
                {children}
            </div>
        }
    </>
})

export const TabItem = memo(({tabName, tabNameActive, children, tabToolTipTitle, tabToolTipPlace, onClick, tabDefaultColor = '#303B44', tabActiveColor = '#f0f0f0'}: ITab): React.JSX.Element => {
    const tab = style({
        background: tabDefaultColor,
        $nest: {
            '&::after, &::before': {
                width: '15px',
                height: '15px',
                background: tabDefaultColor,
            },
            '&::after': {right: '-15px'},
            '&::before': {left: '-15px'},
        },
    })

    const tabContent = style({
        color: '#f34e2e',
        $nest: {
            '&::after, &::before': {
                width: '15px',
                height: '15px',
                background: tabDefaultColor,
            },
            '&::after': {right: '-15px'},
            '&::before': {left: '-15px'},
        },
    })
    
    const tabActive = style({
        padding: '4px 10px 8px 10px',
        background: tabActiveColor,
    })

    const tabContentActive = style({
        color: tabDefaultColor,
        $nest: {
            '&::after, &::before': {
                background: tabActiveColor,
            },
        },
    })
    
    return <>
        <div id={`toolTipTab_${tabName}`} className={tabNameActive === tabName ? `${css.tab} ${tab} ${css.tab_active} ${tabActive}` : `${css.tab} ${tab}`} onClick={() => onClick && onClick(tabName)}>
            <div className={`${css.tab_content} ${tabNameActive === tabName ? tabContentActive : '' } ${tabContent}`}>
                {children}
            </div>
        </div>

        <Tooltip anchorSelect={`#toolTipTab_${tabName}`} place={tabToolTipPlace} content={tabToolTipTitle} style={{ borderRadius: '10px' }} border='2px solid #f34e2e' />
    </>
})