import { ICardDeliveryTrackNumber } from "./_types"

import { IconClearClose } from "images/icons"
import { Flex, Modal, Input, Button } from "components/ui"


/** Окно создания/редактирования трек-номера */
export const CardDeliveryTrackNumber = ({isOpen, cardMode, fieldsError, txtTrackNumber, txtComment, handlerBtnSave, handlerButtonCancel}: ICardDeliveryTrackNumber) => {
    return <Modal isOpen={isOpen} isEsc onCancel={handlerButtonCancel} maxWidth="350px">
        <Flex className='modal_container'>
            <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                <h4>{cardMode === 'new' ? 'Создание трек-номера' : 'Редактирование трек-номера'}</h4>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Flex className='modal_container-content'>
                    <Flex gap='15px'>
                        <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                            <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.trackNumber ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" className="input_header">Трек-номер <p>✸</p></Flex>
                                <Input placeholder="Трек-номер" {...txtTrackNumber} />
                            </Flex>
                        </Flex>

                        <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                            <Flex flexGrow={1} flexBasis={250}>
                                <Flex gap="5px" direction="row" className="input_header">Комментарий</Flex>
                                <Input placeholder="Комментарий" paddingRight={3} {...txtComment}/>
                            </Flex>
                        </Flex>

                        <Button onClick={handlerBtnSave}>
                            {cardMode === 'new' ? 'Создать трек-номер' : 'Сохранить изменения'}
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    </Modal>
}