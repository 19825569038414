import { useContext, useEffect } from 'react'
import moment from 'moment'

import { CardContractPayments } from './_interface'
import { FieldsErrorType, ICardContractPayments, ICardContractPaymentsContainer, IFieldsError } from './_types'

import { useInput } from 'hooks/useInput'
import { validFormData } from 'hooks/useForm'

import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'



/** Контейнер - анкеты создания/редактирования оплат по договору */
export const CardContractPaymentsContainer = ({
    isOpen, cardMode, handlerButtonOk, handlerButtonCancel,
    paymentNumber, paymentDate, paymentSum, paymentPayer, paymentRefund,
}: ICardContractPaymentsContainer) => {
    const notify = useContext(NotificationContext)

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})

    const fieldsError = useInput<IFieldsError>({})

    const txtPaymentRefund = useInput<number>(0)
    const txtPaymentNumber = useInput<string>('')
    const txtPaymentDate = useInput<string>(moment(new Date()).format('DD.MM.YYYY'))
    const txtPaymentSum = useInput<number | ''>('')
    const txtPaymentPayer = useInput<string>('')


    useEffect(() => {
        if (isOpen) {
            txtPaymentRefund.setValue(Number(paymentRefund) || 0)
            txtPaymentNumber.setValue(paymentNumber || '')
            txtPaymentDate.setValue(paymentDate || moment(new Date()).format('DD.MM.YYYY'))
            txtPaymentSum.setValue(paymentSum || '')
            txtPaymentPayer.setValue(paymentPayer || '')
        }

        !isOpen && clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const clearData = () => {
        fieldsError.setValue({})

        txtPaymentRefund.setValue(0)
        txtPaymentNumber.setValue('')
        txtPaymentDate.setValue(moment(new Date()).format('DD.MM.YYYY'))
        txtPaymentSum.setValue('')
        txtPaymentPayer.setValue('')
    }


    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                txtPaymentDate.setValue(stringDate)
                modalCalendar.setValue({isOpen: false})
            }
        })
    }


    /** Кнопка сохранить все данные */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'paymentNumber', value: txtPaymentNumber.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Номер платежа из выписки.'},
            {fieldName: 'paymentDate', value: txtPaymentDate.value, validType: 'date', isRequired: true, errorMessage: 'Дата платежа из выписки не указана или не корректна.'},
            {fieldName: 'paymentSum', value: txtPaymentSum.value.toString().trim().replace(',', '.'), validType: 'numberDecimal', isRequired: true, errorMessage: 'Сумма платежа из выписки не указана.'},
            {fieldName: 'paymentPayer', value: txtPaymentPayer.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Плательщик из выписки не указан.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnimation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            handlerButtonOk && handlerButtonOk(txtPaymentNumber.value, txtPaymentDate.value, Number(txtPaymentSum.value.toString().trim().replace(',', '.')), txtPaymentPayer.value, txtPaymentRefund.value)
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnimation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }


    const propsToComponent: ICardContractPayments = {
        isOpen, cardMode, 
        paymentNumber, paymentDate, paymentSum, paymentPayer, paymentRefund,

        fieldsError, txtPaymentRefund, txtPaymentNumber, txtPaymentDate, txtPaymentSum, txtPaymentPayer,
        handleOpenModalCalendar, handleBtnSave, handlerButtonCancel
    }

    return <>
        <CardContractPayments {...propsToComponent} />

        <ModalCalendar {...modalCalendar.value} />
    </>
}