import React, { memo, useEffect } from 'react'
import { HashLoader } from 'react-spinners'

import css from './index.module.scss'
import { LoaderType } from './type'


/** Компонент Загрузчика. 
* @param {boolean} isOpen - Состояние загрузчика, открытое или закрытое.
* @param {string} color - Цвет загрузчика.
* @param {string} title - Заголовок загрузчика.
* @param {boolean} backdrop - Фон загрузчика (true = absolute (при условии если родительские элемент position: relative), false = fixed).
* @param {boolean} bordered - Будет ли загрузчик с границей.
*/
export const Loader = memo(({ 
    isOpen, color, title, backdrop = false, borderEadius = '10px' 
}: LoaderType): React.JSX.Element => {
    useEffect(() => {
        switch (isOpen) {
            case true: document.body.style.overflow = 'hidden'; break
            case false: document.body.style.overflow = 'unset'; break
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${css.wrapper} ${isOpen ? css.open : css.close}`} style={{ position: `${backdrop ? 'absolute' : 'fixed'}`, borderRadius: `${borderEadius}` }}>
            <div className={css.content}>
                <HashLoader loading={isOpen} color={color} />
                {title && <span style={{ color: `${color}` }}>{title}</span>}
            </div>
        </div>
    )
})