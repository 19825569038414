import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { FinalExaminationJobs } from "./_interface"
import { IFinalExaminationJobs, IFinalExaminationJobsContainer } from "./_types"

import { useInput } from "hooks/useInput"

import { useFinalExamination } from "store/finalExamination/finalExamination.state"
import { IFinalExaminationJobsList } from "store/finalExamination/finalExamination.type"


/** Контейнер проверок итоговых работ */
export const FinalExaminationJobsContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: IFinalExaminationJobsContainer) => {
    // const notify = useContext(NotificationContext)

    const {isLoadingJobs, getJobsList} = useFinalExamination(useShallow((state) => ({
        isLoadingJobs: state.isLoadingJobs,
        getJobsList: state.actionGetFinalExaminationJobs,
    })))

    const jobsList = useInput<IFinalExaminationJobsList[]>([])


    useEffect(() => {
        if (isOpen) {
            getJobsList().then((res) => {
                if (res) jobsList.setValue(res)
            })
        }

        !isOpen && jobsList.setValue([]) // Обнуление данных
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Обработка клика по работе в таблице */
    const handlerOnClickTableRow = (lastName: string, linkToLK: string) => {
        const newWindow = window.open(linkToLK, '_blank')
        if (newWindow) {
            newWindow.focus()
        } else {
            console.error('Failed to open new window. Please check if pop-ups are blocked.')
        }
        
        handlerButtonOk && handlerButtonOk(lastName)
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IFinalExaminationJobs = {
        isOpen, isLoading: isLoadingJobs, jobsList,
        handlerOnClickTableRow,
        handlerButtonCancel
    }

    return <FinalExaminationJobs {...propsToComponent} />
}