import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { FinalExaminationArchive } from "./_interface"
import { IFinalExaminationArchive, IFinalExaminationArchiveContainer } from "./_types"

import { useInput } from "hooks/useInput"
import { useDebounce } from "hooks/useDebounce"

import { useFinalExamination } from "store/finalExamination/finalExamination.state"

import { CardContractContainer, ICardContractContainer } from "../cardContract"
import { CardListenerContainer, ICardListenerContainer } from "../cardListener"

import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"
import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Контейнер архива итоговых работ слушателей */
export const FinalExaminationArchiveContainer = ({isOpen, handlerButtonOk, handlerButtonCancel}: IFinalExaminationArchiveContainer) => {
    const notify = useContext(NotificationContext)

    const finalExaminationState = useFinalExamination(useShallow((state) => state))
    // const cardFinalExamination = useInput<ICardFinalExaminationContainer>({isOpen: false})
    // const finalExaminationArchive = useInput<IFinalExaminationArchiveContainer>({isOpen: false})
    const cardContract = useInput<ICardContractContainer>({isOpen: false})
    const cardListener = useInput<ICardListenerContainer>({isOpen: false})

    const loadingTitle = useInput<string>('Загрузка архива...')
    const modalConfirm = useInput<IModalConfirm>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')

    useEffect(() => {
        isOpen && loadDataListeners(txtSearch.value)

        if (!isOpen) {
            finalExaminationState.clearFinalExaminationArchive()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps
    
    // useEffect(() => {
    //     if (finalExaminationState.error) return notify && notifyOpen(finalExaminationState.error?.message, finalExaminationState.error?.type, 3000, notify)
    // }, [finalExaminationState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isOpen && loadDataListeners(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        finalExaminationState.isReloadArchive && loadDataListeners(txtSearch.value)
    }, [finalExaminationState.isReloadArchive]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция по получению списка слушателей */
    const loadDataListeners = (search?: string) => {
        finalExaminationState.actionGetFinalExaminationArchive(currentPageNumber.value, search || '')
    }



    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, actionName?: string, recordID?: number) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                loadingTitle.setValue('Возвращаю на обработку...')

                finalExaminationState.actionSetFinalExaminationWithArchive(Number(recordID)).then((res) => {
                    if (res === 200) {
                        loadDataListeners(txtSearch.value)
                        handlerButtonOk && handlerButtonOk()
                        return notify && notifyOpen('Анкета итоговой работы слушателя, успешно возвращена на обработку.', 'success', 1500, notify)
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка архива...') })

                modalConfirm.setValue({ isOpen: false })
            }
        })
    }
    /** Открытие/закрытие анкеты по договору */
    const handleOpenCardContract = (isOpen: boolean, contractID?: number) => {
        cardContract.setValue({
            isOpen, cardMode: 'edit', cardID: contractID,
            handlerButtonCancel() {cardContract.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardContract.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие анкеты по слушателю */
    const handleOpenCardListener = (isOpen: boolean, ListenerID?: number) => {
        cardListener.setValue({
            isOpen, cardMode: 'edit', cardID: ListenerID,
            handlerButtonCancel() {cardListener.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListener.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие карточки по итоговой работе */
    // const handleOpenCardFinalExamination = (isOpen: boolean, finalExaminationID?: number, fullNameFrom?: string) => {
    //     // cardFinalExamination.setValue({
    //     //     isOpen, cardID: finalExaminationID, fullNameFrom,
    //     //     handlerButtonCancel() {cardFinalExamination.setValue({isOpen: false})}, 
    //     //     handlerButtonOk() {
    //     //         cardFinalExamination.setValue({isOpen: false})
    //     //         loadDataListeners(txtSearch.value)
    //     //     }
    //     // })
    // }

    const debounceSearch = useDebounce(loadDataListeners, 1500) // Запуск загрузки заявок через полторы секунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IFinalExaminationArchive = {
        isOpen, isLoading: finalExaminationState.archive.isLoading,
        txtSearch, txtSearchOnChange, loadDataListeners,

        archiveCount: finalExaminationState.archive.archiveCount,
        countUnverifiedWorks: finalExaminationState.countUnverifiedWorks,

        loadingTitle, currentPageNumber, 
        totalCount: finalExaminationState.archive.totalCount, 
        pageSize: finalExaminationState.archive.pageSize,

        listenersList: finalExaminationState.archive.items,

        handlerOpenModalConfirm,
        handleOpenCardListener,
        handleOpenCardContract,
        handlerButtonCancel,
    }

    return <>
        <FinalExaminationArchive {...propsToComponent} />

        <ModalConfirm {...modalConfirm.value} />
        <CardListenerContainer {...cardListener.value} />
        <CardContractContainer {...cardContract.value} />
    </>
}