import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { 
    getListenerCard,
    getListenerCheckPhone, getListenerCheckSnils, getListenerFiles, getListeners,
    setListenerCardSave,
    setListenerDeleteFiles,
    setListenerUploadFiles
} from './listeners.api'
import {
    IListenersState, IListenersActions,
    IListenersList,
    IListenerCard,
    IListenerFormSendedData,
    IListenerFilesList
} from './listeners.types'


export const initialStateListeners: IListenersState = {
    error: null,
    isReloadListeners: false,
    isLoadingCard: false,
 
    listeners: {
        isLoading: false,

        items: [] as IListenersList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },
}

/** Хранилище страницы - Слушатели */
export const useListeners = create<IListenersState & IListenersActions>()(devtools((set) => ({
    ...initialStateListeners,

    clearListenersState: () => {set(initialStateListeners)},


    /** Проверка по номеру телефона, существование лушателя */
    actionGetListenerCheckPhone: async (listenerID: number, clientPhone: string) => {
        const res = await getListenerCheckPhone(listenerID, clientPhone)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            return res.data
        }
    },
    /** Проверка по СНИЛС, существование лушателя */
    actionGetListenerCheckSnils: async (listenerID: number, snils: string) => {
        const res = await getListenerCheckSnils(listenerID, snils)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            return res.data
        }
    },



    /** Получение списка слушателей */
    actionGetListeners: async (pageNumber: number, search: string) => {
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await getListeners(pageNumber, search)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadListeners: false})

            set((state) => ({
                listeners: {
                    ...state.listeners, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        set((state) => ({listeners: {...state.listeners, isLoading: false}}))
    },
    /** Получение списка файлов */
    actionGetListenerFiles: async (listenerID: number) => {
        let returnData: IListenerFilesList[] = []
        set({isLoadingCard: true})

        const res = await getListenerFiles(listenerID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data?.files
        }

        set({isLoadingCard: false})
        return returnData
    },



    /** Получение данных по карточке заявки */
    actionGetListenerCard: async (listenerID: number) => {
        let returnData: IListenerCard | null = null
        set({isLoadingCard: true})

        const res = await getListenerCard(listenerID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по анкете слушателя */
    actionSetListenerCard: async (listenerID: number, data: IListenerFormSendedData, files?: File[]) => {
        set({isLoadingCard: true})

        const res = await setListenerCardSave(listenerID, data, files)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },



    /** Удаление файлов слушателя */
    actionSetListenerDeleteFiles: async (listenerID: number, fileID: number) => {
        set({isLoadingCard: true})

        const res = await setListenerDeleteFiles(listenerID, fileID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadListeners: true})
        }
            
        set({isLoadingCard: false})
        // set({isReloadListeners: true})
    },
    /** Загрузка файлов слушателя */
    actionSetListenerUploadFiles: async (listenerID: number, files: File[]) => {
        set({isLoadingCard: true})

        const res = await setListenerUploadFiles(listenerID, files)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadListeners: true})
        }

        set({isLoadingCard: false})
    },

}), {name: 'listenersState'}))