import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { IPrintDiplomasActions, IPrintDiplomasCard, IPrintDiplomasList, IPrintDiplomasState } from './PrintingsDiplomas.type'
import { getPrintingDiplomasCard, getPrintingDiplomasList, setPrintingDiplomasCardSave, setPrintingDiplomasDelete } from './PrintingsDiplomas.api'



export const initialStatePrintingDiplomas: IPrintDiplomasState = {
    error: null,
    isReloadListeners: false,
    isLoadingCard: false,
 
    listeners: {
        isLoading: false,

        items: [] as IPrintDiplomasList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0,
    }
}

/** Хранилище страницы - Печать дипломов */
export const usePrintingsDiplomas = create<IPrintDiplomasState & IPrintDiplomasActions>()(devtools((set) => ({
    ...initialStatePrintingDiplomas,

    /** Очистка хранилища печати дипломов */
    clearPrintingDiplomasState: () => {set(initialStatePrintingDiplomas)},


    /** Получение списка анкет */
    actionGetPrintingDiplomasListeners: async (pageNumber: number, search: string) => {
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await getPrintingDiplomasList(pageNumber, search)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadListeners: false})

            set((state) => ({
                listeners: {
                    ...state.listeners, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage,
                    archiveCount: res.data!.archiveCount
                }
            }))
        }

        set((state) => ({listeners: {...state.listeners, isLoading: false}}))
    },
    /** Данные по анкете */
    actionGetPrintingDiplomasCard: async (printID: number) => {
        let returnData: IPrintDiplomasCard | null = null
        set({isLoadingCard: true})

        const res = await getPrintingDiplomasCard(printID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по анкете */
    actionSetPrintingDiplomasCardSave: async (printID: number, printStatusID: number, diplomRegNumber: string, diplomNumber: string, diplomDateIssue: string) => {
        set({isLoadingCard: true})

        const res = await setPrintingDiplomasCardSave(printID, printStatusID, diplomRegNumber, diplomNumber, diplomDateIssue)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },



    /** Удаление анкеты из раздела печати */
    actionSetPrintingDiplomasDelete: async (printID: number) => {
        set({isLoadingCard: true})
        set((state) => ({listeners: {...state.listeners, isLoading: !state.isReloadListeners}}))

        const res = await setPrintingDiplomasDelete(printID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

            if (res.message === 'OK') set({ error: null })

            set({isLoadingCard: false})
            set({isReloadListeners: false})
            set((state) => ({listeners: {...state.listeners, isLoading: false}}))
        return res.status
    }

}), {name: 'printingsDiplomasState'}))