import { useState } from "react"

export interface IUseInput<T> {
    value: T
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    setValue: React.Dispatch<React.SetStateAction<T>>
}

export const useInput = <T extends any>(initialValue: T): IUseInput<T> => {
    const [value, setValue] = useState<T>(initialValue)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value as T)
    }

    return {
        value, setValue, onChange
    }
}