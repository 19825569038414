import axios, { AxiosError } from 'axios'

// export const APP_API_URL='http://192.168.1.105:5006/api'
// export const APP_WEB_SOCKET='ws://192.168.1.105:5006/api'

// export const APP_API_URL='http://192.168.0.167:5006/api'
// export const APP_WEB_SOCKET='ws://192.168.0.167:5006/api'

export const APP_API_URL='https://crm.iktbox.ru/crm'
export const APP_WEB_SOCKET='wss://crm.iktbox.ru/ws'


axios.defaults.withCredentials = true
const api = axios.create({
    baseURL: APP_API_URL,
    withCredentials: true
})

api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('us_id')}`

    return config
}, (error) => {
    Promise.reject(error)
})

api.interceptors.response.use(async (config) => {
    return config
}, ( async (error) => {
    const originalRequest = error.config

    if ((error as AxiosError).code !== 'ERR_NETWORK') {
        if (error.response.status === 401) {
            try {
                const response = await axios.get(`${APP_API_URL}/auth/refresh`)

                if (response.data.token && response.data.tokenExpiration) {
                    localStorage.setItem('us_id', response.data.token)
                    localStorage.setItem('us_exp', response.data.tokenExpiration.toString())
                } else { 
                    localStorage.removeItem('us_id') 
                    localStorage.removeItem('us_exp')

                    window.location.href = '/'
                }

                return api.request(originalRequest)
            } catch (e) {
                localStorage.removeItem('us_id')
                localStorage.removeItem('us_exp')
                
                window.location.href = '/'
            }
        } else {
            return error.response
        }
    } else { 
        return {statusText: error.code}
    }
}))

export default api