export interface IValidFormData {
    
    /** Имя поля */
    fieldName: string
    /** Значение поля */
    value: string
    /** Обязательное для проверки */
    isRequired: boolean
    /** Сообщение которое необходимо вывести */
    errorMessage: string

    // Тип проверки
    validType: 
    | 'notEmpty' // Не пустое
    | 'phoneMask' // Номер телефона по маске
    | 'email' // Электронная почта
    | 'date' // Действительность даты
    | 'time' // Действительность времени
    | 'numberDecimal' // Целое или десятичное число через точку
    | 'number_1-9' // Целое число, первая цифра обязательно с 1 по 9
    | 'number_0-9' // Любые цифры
    | 'snils' // СНИЛС
    | 'inn' // ИНН
    | 'kpp' // КПП
}

export interface IValidFormDataReturnData {
    fieldName: string
    message: string
}

export const validFormData = (dataForValid: IValidFormData[]): IValidFormDataReturnData[] => {
    let returnData: IValidFormDataReturnData[] = []

    for (const item of dataForValid) {
        switch (item.validType) {
            case 'notEmpty': { // Не пустое
                if (item.isRequired && item.value.trim().length === 0) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'phoneMask': { // Номер телефона по маске
                if ((item.isRequired && phoneMask(item.value).error) || (!item.isRequired && item.value.length > 0 && phoneMask(item.value).error)) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'email': { // Электронная почта
                const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'date': { // Действительность даты
                if ((item.isRequired && !isValidDate(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidDate(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'time': { // Действительность времени
                if ((item.isRequired && !isValidTime(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidTime(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'numberDecimal': { // Целое или десятичное число через точку
                const re = /^[1-9]\d*(\.\d+)?$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'number_1-9': { // Целое число, первая цифра обязательно с 1 по 9
                const re = /^[1-9][0-9]*$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'number_0-9': { // Любые цифры
                const re = /^[0-9]+$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'snils': { // СНИЛС
                if ((item.isRequired && !isValidSnils(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidSnils(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'inn': { // ИНН
                if ((item.isRequired && !isValidINN(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidINN(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }
           
            case 'kpp': {
                const re = /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                }
                break
            } 
        }
    }

    return returnData
}


/** Проверяем дату на валидность и существование */
export const isValidDate = (dateString: string) => {
    // Парсим введенную строку в объект Date
    const parts = dateString.split('.')
    const day = parseInt(parts[0], 10)
    const month = parseInt(parts[1], 10) - 1 // Месяцы в JavaScript начинаются с 0
    const year = parseInt(parts[2], 10)
    const date = new Date(year, month, day)

    // Проверяем, корректная ли дата и не произошла ли ошибка при парсинге
    return !isNaN(date.getTime()) && date.getDate() === day && date.getMonth() === month && date.getFullYear() === year
}
/** Проверяем время на валидность и существование */
export const isValidTime = (timeValue: string) => {
    // Проверяем формат времени
    if (!/^(?:[0-9]{2}:){2}[0-9]{2}$/.test(timeValue)) {
        return false
    }

    // Разбиваем время на часы, минуты и секунды
    const [hours, minutes, seconds] = timeValue.split(":").map(Number)

    // Проверяем допустимые значения для часов (от 0 до 23), минут и секунд (от 0 до 59)
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
        return false
    }

    // Время прошло проверку
    return true
}

/** Проверка СНИЛС на валидность */
export const isValidSnils = (snils: string): boolean => {
    let result = false

    let varSnils = snils.match(/\d+/g) // Используем регулярное выражение для извлечения цифр
    snils = varSnils ? varSnils.join("") : "" // Объединяем извлеченные цифры в строку

    // if (typeof snils === 'number') {
    //     snils = snils.toString()
    // } else if (typeof snils !== 'string') {
    //     snils = ''
    // }

    if (!snils.length) {
        // error.code = 1
        // error.message = 'СНИЛС пуст'
    } else if (/[^0-9]/.test(snils)) {
        // error.code = 2
        // error.message = 'СНИЛС может состоять только из цифр'
    } else if (snils.length !== 11) {
        // error.code = 3
        // error.message = 'СНИЛС может состоять только из 11 цифр'
    } else {
        let sum = 0
        for (let i = 0; i < 9; i++) {
            sum += parseInt(snils[i]) * (9 - i)
        }

        let checkDigit = 0
        if (sum < 100) {
            checkDigit = sum
        } else if (sum > 101) {
            checkDigit = parseInt(String(sum % 101))
            if (checkDigit === 100) {
                checkDigit = 0
            }
        }

        if (checkDigit === parseInt(snils.slice(-2))) {
            result = true
        } else {
            // error.code = 4
            // error.message = 'Неправильное контрольное число'
        }
    }

    return result
}
/** Проверка ИНН на валидность */
export const isValidINN = (inn: string): boolean => {
    let result = false

    let varSnils = inn.match(/\d+/g) // Используем регулярное выражение для извлечения цифр
    inn = varSnils ? varSnils.join("") : "" // Объединяем извлеченные цифры в строку

    // if (typeof inn === 'number') {
    //     inn = inn.toString()
    // } else if (typeof inn !== 'string') {
    //     inn = ''
    // }

    if (!inn.length) {
        // error.code = 1
        // error.message = 'ИНН пуст'
        result = false
    } else if (/[^0-9]/.test(inn)) {
        // error.code = 2
        // error.message = 'ИНН может состоять только из цифр'
        result = false
    } else if (![10, 12].includes(inn.length)) {
        // error.code = 3
        // error.message = 'ИНН может состоять только из 10 или 12 цифр'
        result = false
    } else {
        const checkDigit = (inn: string, coefficients: number[]): number => {
            let n = 0
            for (let i in coefficients) {
                n += coefficients[i] * parseInt(inn[i])
            }
            return parseInt((n % 11 % 10).toString())
        }
        switch (inn.length) {
            case 10:
                const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
                if (n10 === parseInt(inn[9])) {
                    result = true
                }
                break
            case 12:
                const n11 = checkDigit(inn.slice(0, 10), [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
                const n12 = checkDigit(inn.slice(0, 11), [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
                if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
                    result = true
                }
                break
        }
        if (!result) {
            // error.code = 4
            // error.message = 'Неправильное контрольное число'
            result = false
        }
    }   

    return result
}



/** Функция по проверке номера телефона по маске */
export const phoneMask = (input: string) => {
    let matrix = '+###'

    if (input.length === 0) matrix = ''

    maskList.forEach(item => {
        let code = item.code.toString().replace(/[\s#]/g, '')
        let phone = `+${input.replace(/[\s#-)(]/g, '')}`

        if (phone.includes(code)) matrix = item.code
    })

    let i = 0, val = input.replace(/\D/g, '')

    input = matrix.toString().replace(/(?!\+)./g, function (a) {
        return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a
    })

    if (matrix.length <= 4 || input.length !== matrix.length) {
        return { error: true, data: input }
    } else {
        return { error: false, data: input }
    }
}
/** Список масок для проверки номера телефона */
const maskList = [
    { "code": "+1 ### ### ####" },
    { "code": "+1 242 ### ####" },
    { "code": "+1 246 ### ####" },
    { "code": "+1 264 ### ####" },
    { "code": "+1 268 ### ####" },
    { "code": "+1 284 ### ####" },
    { "code": "+1 340 ### ####" },
    { "code": "+1 345 ### ####" },
    { "code": "+1 441 ### ####" },
    { "code": "+1 473 ### ####" },
    { "code": "+1 649 ### ####" },
    { "code": "+1 664 ### ####" },
    { "code": "+1 670 ### ####" },
    { "code": "+1 671 ### ####" },
    { "code": "+1 684 ### ####" },
    { "code": "+1 721 ### ####" },
    { "code": "+1 758 ### ####" },
    { "code": "+1 767 ### ####" },
    { "code": "+1 784 ### ####" },
    { "code": "+1 809 ### ####" },
    { "code": "+1 829 ### ####" },
    { "code": "+1 849 ### ####" },
    { "code": "+1 868 ### ####" },
    { "code": "+1 869 ### ####" },
    { "code": "+1 876 ### ####" },
    { "code": "+20 ### ### ####" },
    { "code": "+211 ## ### ####" },
    { "code": "+212 ## #### ###" },
    { "code": "+213 ## ### ####" },
    { "code": "+216 ## ### ###" },
    { "code": "+218 ## ### ###" },
    { "code": "+218 21 ### ####" },
    { "code": "+220 ### ## ##" },
    { "code": "+221 ## ### ####" },
    { "code": "+222 ## ## ####" },
    { "code": "+223 ## ## ####" },
    { "code": "+224 ## ### ###" },
    { "code": "+225 ## ### ###" },
    { "code": "+226 ## ## ####" },
    { "code": "+227 ## ## ####" },
    { "code": "+228 ## ### ###" },
    { "code": "+229 ## ## ####" },
    { "code": "+230 ### ####" },
    { "code": "+231 ## ### ###" },
    { "code": "+232 ## ######" },
    { "code": "+233 ### ### ###" },
    { "code": "+234 ## ### ##" },
    { "code": "+234 ## ### ###" },
    { "code": "+234 ### ### ####" },
    { "code": "+234 ### ### ####" },
    { "code": "+235 ## ## ## ##" },
    { "code": "+236 ## ## ####" },
    { "code": "+237 #### ####" },
    { "code": "+238 ### ## ##" },
    { "code": "+239 ## #####" },
    { "code": "+240 ## ### ####" },
    { "code": "+241 # ## ## ##" },
    { "code": "+242 ## ### ####" },
    { "code": "+243 ### ### ###" },
    { "code": "+244 ### ### ###" },
    { "code": "+245 # ######" },
    { "code": "+246 ### ####" },
    { "code": "+247 ####" },
    { "code": "+248 # ### ###" },
    { "code": "+249 ## ### ####" },
    { "code": "+250 ### ### ###" },
    { "code": "+251 ## ### ####" },
    { "code": "+252 # ### ###" },
    { "code": "+252 # ### ###" },
    { "code": "+252 ## ### ###" },
    { "code": "+253 ## ## ## ##" },
    { "code": "+254 ### ######" },
    { "code": "+255 ## ### ####" },
    { "code": "+256 ### ### ###" },
    { "code": "+257 ## ## ####" },
    { "code": "+258 ## ### ###" },
    { "code": "+260 ## ### ####" },
    { "code": "+261 ## ## #####" },
    { "code": "+262 ##### ####" },
    { "code": "+262 ##### ####" },
    { "code": "+263 # ######" },
    { "code": "+264 ## ### ####" },
    { "code": "+265 # #### ####" },
    { "code": "+265 1 ### ###" },
    { "code": "+266 # ### ####" },
    { "code": "+267 ## ### ###" },
    { "code": "+268 ## ## ####" },
    { "code": "+269 ## #####" },
    { "code": "+27 ## ### ####" },
    { "code": "+290 ####" },
    { "code": "+290 ####" },
    { "code": "+291 # ### ###" },
    { "code": "+297 ### ####" },
    { "code": "+298 ### ###" },
    { "code": "+299 ## ## ##" },
    { "code": "+30 ### ### ####" },
    { "code": "+31 ## ### ####" },
    { "code": "+32 ### ### ###" },
    { "code": "+33 ### ### ###" },
    { "code": "+34 ### ### ###" },
    { "code": "+350 ### #####" },
    { "code": "+351 ## ### ####" },
    { "code": "+352 ### ### ###" },
    { "code": "+353 ### ### ###" },
    { "code": "+354 ### ####" },
    { "code": "+355 ### ### ###" },
    { "code": "+356 #### ####" },
    { "code": "+357 ## ### ###" },
    { "code": "+358 ### ### ## ##" },
    { "code": "+359 ### ### ###" },
    { "code": "+36 ### ### ###" },
    { "code": "+370 ### ## ###" },
    { "code": "+371 ## ### ###" },
    { "code": "+372 ### ####" },
    { "code": "+372 #### ####" },
    { "code": "+373 #### ####" },
    { "code": "+374 ## ### ###" },
    { "code": "+375 ## ### ## ##" },
    { "code": "+376 ### ###" },
    { "code": "+377 ## ### ###" },
    { "code": "+377 ### ### ###" },
    { "code": "+378 #### ######" },
    { "code": "+380 ## ### ## ##" },
    { "code": "+381 ## ### ####" },
    { "code": "+382 ## ### ###" },
    { "code": "+385 ## ### ###" },
    { "code": "+386 ## ### ###" },
    { "code": "+387 ## ####" },
    { "code": "+387 ## #####" },
    { "code": "+389 ## ### ###" },
    { "code": "+39 ### #### ###" },
    { "code": "+39 6 698 #####" },
    { "code": "+40 ## ### ####" },
    { "code": "+41 ## ### ####" },
    { "code": "+420 ### ### ###" },
    { "code": "+421 ### ### ###" },
    { "code": "+423 ### ### ####" },
    { "code": "+43 ### ### ####" },
    { "code": "+44 ## #### ####" },
    { "code": "+45 ## ## ## ##" },
    { "code": "+46 ## ### ####" },
    { "code": "+47 ### ## ###" },
    { "code": "+48 ### ### ###" },
    { "code": "+49 ### ## ##" },
    { "code": "+49 ### ## ###" },
    { "code": "+49 ### ## ####" },
    { "code": "+49 ### ### ####" },
    { "code": "+49 ### ###" },
    { "code": "+49 #### ### ####" },
    { "code": "+500 #####" },
    { "code": "+501 ### ####" },
    { "code": "+502 # ### ####" },
    { "code": "+503 ## ## ####" },
    { "code": "+504 #### ####" },
    { "code": "+505 #### ####" },
    { "code": "+506 #### ####" },
    { "code": "+507 ### ####" },
    { "code": "+508 ## ####" },
    { "code": "+509 ## ## ####" },
    { "code": "+51 ### ### ###" },
    { "code": "+52 ## ## ####" },
    { "code": "+52 ### ### ####" },
    { "code": "+53 # ### ####" },
    { "code": "+54 ### ### ####" },
    { "code": "+55 ## #### ####" },
    { "code": "+55 ## 7### ####" },
    { "code": "+55 ## 9#### ####" },
    { "code": "+56 # #### ####" },
    { "code": "+57 ### ### ####" },
    { "code": "+58 ### ### ####" },
    { "code": "+590 ### ### ###" },
    { "code": "+591 # ### ####" },
    { "code": "+592 ### ####" },
    { "code": "+593 # ### ####" },
    { "code": "+593 ## ### ####" },
    { "code": "+594 ##### ####" },
    { "code": "+595 ### ### ###" },
    { "code": "+596 ### ## ## ##" },
    { "code": "+597 ### ###" },
    { "code": "+597 ### ####" },
    { "code": "+598 # ### ## ##" },
    { "code": "+599 ### ####" },
    { "code": "+599 ### ####" },
    { "code": "+599 ### ####" },
    { "code": "+599 9### ####" },
    { "code": "+60 # ### ###" },
    { "code": "+60 ## ### ###" },
    { "code": "+60 ## ### ####" },
    { "code": "+60 ### ### ###" },
    { "code": "+61 # #### ####" },
    { "code": "+62 ## ### ##" },
    { "code": "+62 ## ### ###" },
    { "code": "+62 ## ### ####" },
    { "code": "+62 8## ### ## ###" },
    { "code": "+62 8## ### ###" },
    { "code": "+62 8## ### ####" },
    { "code": "+63 ### ### ####" },
    { "code": "+64 ## ### ###" },
    { "code": "+64 ### ### ###" },
    { "code": "+64 ### ### ####" },
    { "code": "+65 #### ####" },
    { "code": "+66 ## ### ###" },
    { "code": "+66 ## ### ####" },
    { "code": "+670 ### ####" },
    { "code": "+670 77# #####" },
    { "code": "+670 78# #####" },
    { "code": "+672 1## ###" },
    { "code": "+672 3## ###" },
    { "code": "+673 ### ####" },
    { "code": "+674 ### ####" },
    { "code": "+675 ### ## ###" },
    { "code": "+676 #####" },
    { "code": "+677 ### ####" },
    { "code": "+677 #####" },
    { "code": "+678 ## #####" },
    { "code": "+678 #####" },
    { "code": "+679 ## #####" },
    { "code": "+680 ### ####" },
    { "code": "+681 ## ####" },
    { "code": "+682 ## ###" },
    { "code": "+683 ####" },
    { "code": "+685 ## ####" },
    { "code": "+686 ## ###" },
    { "code": "+687 ## ####" },
    { "code": "+688 2####" },
    { "code": "+688 90####" },
    { "code": "+689 ## ## ##" },
    { "code": "+690 ####" },
    { "code": "+691 ### ####" },
    { "code": "+692 ### ####" },
    { "code": "+7 ### ### ## ##" },
    { "code": "+7 6## ### ## ##" },
    { "code": "+7 7## ### ## ##" },
    { "code": "+81 ## #### ####" },
    { "code": "+81 ### ### ###" },
    { "code": "+82 ## ### ####" },
    { "code": "+84 ## #### ###" },
    { "code": "+84 ### #### ###" },
    { "code": "+850 ## ### ###" },
    { "code": "+850 ### ###" },
    { "code": "+850 ### #### ###" },
    { "code": "+850 #### ####" },
    { "code": "+850 #### #############" },
    { "code": "+850 191 ### ####" },
    { "code": "+852 #### ####" },
    { "code": "+853 #### ####" },
    { "code": "+855 ## ### ###" },
    { "code": "+856 ## ### ###" },
    { "code": "+856 20## ### ###" },
    { "code": "+86 ## ##### #####" },
    { "code": "+86 ### #### ###" },
    { "code": "+86 ### #### ####" },
    { "code": "+880 ## ### ###" },
    { "code": "+886 # #### ####" },
    { "code": "+886 #### ####" },
    { "code": "+90 ### ### ####" },
    { "code": "+91 #### ### ###" },
    { "code": "+92 ### ### ####" },
    { "code": "+93 ## ### ####" },
    { "code": "+94 ## ### ####" },
    { "code": "+95 # ### ###" },
    { "code": "+95 ## ### ###" },
    { "code": "+95 ### ###" },
    { "code": "+960 ### ####" },
    { "code": "+961 # ### ###" },
    { "code": "+961 ## ### ###" },
    { "code": "+962 # #### ####" },
    { "code": "+963 ## #### ###" },
    { "code": "+964 ### ### ####" },
    { "code": "+965 #### ####" },
    { "code": "+966 # ### ####" },
    { "code": "+966 5 #### ####" },
    { "code": "+967 # ### ###" },
    { "code": "+967 ## ### ###" },
    { "code": "+967 ### ### ###" },
    { "code": "+968 ## ### ###" },
    { "code": "+970 ## ### ####" },
    { "code": "+971 # ### ####" },
    { "code": "+971 5# ### ####" },
    { "code": "+972 # ### ####" },
    { "code": "+972 5# ### ####" },
    { "code": "+973 #### ####" },
    { "code": "+974 #### ####" },
    { "code": "+975 # ### ###" },
    { "code": "+975 17 ### ###" },
    { "code": "+976 ## ## ####" },
    { "code": "+977 ## ### ###" },
    { "code": "+98 ### ### ####" },
    { "code": "+992 ## ### ####" },
    { "code": "+993 # ### ####" },
    { "code": "+994 ## ### ## ##" },
    { "code": "+995 ### ### ###" },
    { "code": "+996 ### ### ###" },
    { "code": "+998 ## ### ####" }
]