import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { IDeliveryFilter, IResponseDeliveryCard, IResponseDeliveryFiles, IResponseDeliveryList, IResponseDeliveryTrackNumbers } from "./delivery.type"


const route = '/delivery'


/** Основной список */
export const getDeliveryList = async (pageNumber: number, search?: string, filter?: IDeliveryFilter) => {
    let response: IResponseDeliveryList

    try {
        const res: AxiosResponse<IResponseDeliveryList, AxiosError> = await api.get(`${route}/getDeliveryList`, {params: {pageNumber, search, filter}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseDeliveryList
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}


/** Список файлов по анкете */
export const getDeliveryFilesList = async (contractID: number) => {
    let response: IResponseDeliveryFiles

    try {
        const res: AxiosResponse<IResponseDeliveryFiles, AxiosError> = await api.get(`${route}/getDeliveryFilesList?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseDeliveryFiles
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/**  Список трек-номеров по анкете */
export const getDeliveryTrackNumbersList = async (contractID: number) => {
    let response: IResponseDeliveryTrackNumbers

    try {
        const res: AxiosResponse<IResponseDeliveryTrackNumbers, AxiosError> = await api.get(`${route}/getDeliveryTrackNumbersList?contractID=${contractID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseDeliveryTrackNumbers
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}




/** Данные по анкете */
export const getDeliveryCardData = async (contractID: number) => {
    let response: IResponseDeliveryCard

    try {
        const res: AxiosResponse<IResponseDeliveryCard, AxiosError> = await api.get(`${route}/getDeliveryCardData`, {params: {contractID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseDeliveryCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по трек-номеру */
export const setDeliveryTrackNumbersSave = async (contractID: number, deliveryID: number, action: string, trackNumber: string, trackNumberComment: string) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setDeliveryTrackNumbersSave?contractID=${contractID}&deliveryID=${deliveryID}&action=${action}&trackNumber=${trackNumber}&trackNumberComment=${trackNumberComment}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете доставки */
export const setDeliveryCardDataSave = async (contractID: number, deliveryToWhom: string, deliveryAddress: string, myComment: string, isAddressConfirmed: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setDeliveryCardDataSave?contractID=${contractID}&deliveryToWhom=${deliveryToWhom}&deliveryAddress=${deliveryAddress}&myComment=${myComment}&isAddressConfirmed=${isAddressConfirmed}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}




/** Отправка информации в telegram канал (ИКТ - Сканы дипломов) */
export const setDeliverySendingFiles = async (contractListenerID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setDeliverySendingFiles?contractListenerID=${contractListenerID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Завершение обучения и отправка информации в telegram канал (ИКТ - Доставка) */
export const setDeliverySendingEndCloseContract = async (contractID: number, byTrackNumber: string) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/setDeliverySendingEndCloseContract?contractID=${contractID}&byTrackNumber=${byTrackNumber}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}