import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { nanoid } from 'nanoid'
import moment from 'moment'

import { FieldsErrorType, ICardCompany, ICardCompanyContainer, IFieldsError, IFilesList } from './_types'
import { CardCompany } from './_interface'

import CommonService from 'services/common.service'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { isValidINN, validFormData } from 'hooks/useForm'

import { useCompanies } from 'store/companies/companies.state'

import { IModalNotice, ModalNotice } from 'components/dumb/modal.notice'
import { IModalPreview, ModalPreview } from 'components/dumb/modal.preview'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'
// import fetchSuggestions from 'services/api.dadata.service'


/** Карточка по контрагенту - Контейнер */
export const CardCompanyContainer = ({isOpen, cardMode, cardID, handlerButtonOk, handlerButtonCancel}: ICardCompanyContainer) => {
    const notify = useContext(NotificationContext)

    const {
        isLoadingCard, getCardData, setCardData, checkINN, 
        checkKPP, deleteFiles, uploadFiles, getFiles
    } = useCompanies(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        getCardData: state.actionGetCompanyCard,
        setCardData: state.actionSetCompanyCard,
        checkINN: state.actionGetCompanyCheckINN,
        checkKPP: state.actionGetCompanyCheckKPP,
        deleteFiles: state.actionSetCompanyDeleteFiles,
        uploadFiles: state.actionSetCompanyUploadFiles,
        getFiles: state.actionGetCompanyFiles,
    })))

    const filesListSendToSrv = useInput<File[]>([]) // файлы для отпарвки на сервер, при создании
    const filesList = useInput<IFilesList[]>([]) // просто список файлов

    const modalNotice = useInput<IModalNotice>({isOpen: false})
    const modalPreview = useInput<IModalPreview>({isOpen: false})

    const tabActive = useInput<string>('primary')
    const fieldsError = useInput<IFieldsError>({})

    const companyNumber = useInput<number | ''>('')
    const companyDateCreate = useInput<string>('')
    const commpanyINNAuto = useInput<boolean>(false)
    const isReadOnlyKPP = useInput<boolean>(true)

    const txtINNBuff = useInput<string>('') // Буффер, оригинальный ИНН
    const txtINN = useInput<string>('')
    const txtKPPBuff = useInput<string>('') // Буффер, оригинальный КПП
    const txtKPP = useInput<string>('')
    const txtCompanyName = useInput<string>('')
    const txtCompanyNameShort = useInput<string>('')

    const txtManagerPositionBuff = useInput<string>('') // Буффер, оригинальная должность
    const txtManagerPosition = useInput<string>('')
    const txtActingOnBasisBuff = useInput<string>('') // Буффер, оригинальная действующего на основании
    const txtActingOnBasis = useInput<string>('')

    const txtLastName = useInput<string>('')
    const txtFirstName = useInput<string>('')
    const txtSecondName = useInput<string>('')
    const txtInPersonWhom = useInput<string>('')

    const txtAddress = useInput<string>('')
    const txtRequisites = useInput<string>('Банк: \nР/сч: \nК/сч: \nБИК: \nEmail: \nТелефон: ')
    
    const txtComment = useInput<string>('')
    // const suggestions = useInput<string[]>([])


    useEffect(() => {
        if (isOpen) {
            cardMode === 'edit' && getCardData(Number(cardID)).then((res) => {
                if (res) {
                    companyNumber.setValue(res.companyID)
                    companyDateCreate.setValue(moment(res.dateCreate).format('DD.MM.YYYY'))

                    commpanyINNAuto.setValue(res.companyINN.length > 12 ? true : false)

                    if (res.companyINN.length >= 12) {
                        isReadOnlyKPP.setValue(true)
                    } else {
                        isReadOnlyKPP.setValue(false)
                    }

                    txtINNBuff.setValue(res.companyINN || '')
                    txtINN.setValue(res.companyINN || '')
                    txtKPPBuff.setValue(res.companyKPP || '')
                    txtKPP.setValue(res.companyKPP || '')
                    txtCompanyName.setValue(res.companyName || '')

                    txtCompanyNameShort.setValue(res.companyNameShort || '')
                    txtLastName.setValue(res.lastName || '')
                    txtFirstName.setValue(res.firstName || '')
                    txtSecondName.setValue(res.secondName || '')
                    txtManagerPositionBuff.setValue(res.managerPosition || '')
                    txtManagerPosition.setValue(res.managerPosition || '')
                    txtActingOnBasisBuff.setValue(res.actingOnBasis || '')
                    txtActingOnBasis.setValue(res.actingOnBasis || '')

                    txtInPersonWhom.setValue(res.inPersonWhom || '')
                    txtAddress.setValue(res.address || '')
                    txtRequisites.setValue(res.requisites || 'Банк: \nР/сч: \nК/сч: \nБИК: \nEmail: \nТелефон: ')

                    txtComment.setValue(res.comment || '')

                    filesList.setValue(res.filesList.map((item) => ({
                        fileID: item.companyFileID,
                        fileName: item.fileName,
                        filePath: item.filePath,
                        fileSize: item.fileSize,
                        dateCreate: item.dateCreate,
                        isSelected: false
                    })))
                }
            })
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (cardMode === 'new' && (txtLastName.value || txtFirstName.value || txtSecondName.value)) {
            const lastName = txtLastName.value?.trim().charAt(0).toUpperCase() + txtLastName.value?.trim().toLowerCase().slice(1)
            const firstName = txtFirstName.value?.trim().charAt(0).toUpperCase() + txtFirstName.value?.trim().toLowerCase().slice(1)
            const secondName = txtSecondName.value?.trim().charAt(0).toUpperCase() + txtSecondName.value?.trim().toLowerCase().slice(1)

            let managerPosition: string = `${txtManagerPosition.value?.trim().charAt(0).toLowerCase() + txtManagerPosition.value?.trim().toLowerCase().slice(1)}`

            let newFullNameFrom = `${CommonService.declensionFullName(lastName, firstName, secondName, 'кого/чего')}`
            txtInPersonWhom.setValue(`${managerPosition} ${newFullNameFrom.trim()}`)
        }
    }, [txtLastName.value, txtFirstName.value, txtSecondName.value, txtManagerPosition.value,]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isReadOnlyKPP.value && !commpanyINNAuto.value) {
            txtManagerPosition.setValue('')
            txtActingOnBasis.setValue('')
            txtKPP.setValue('')
        } else {
            txtManagerPosition.setValue(txtManagerPositionBuff.value || '')
            txtActingOnBasis.setValue(txtActingOnBasisBuff.value || 'Устава')
            !commpanyINNAuto.value && txtKPP.setValue(txtKPPBuff.value || '')
        }
    }, [isReadOnlyKPP.value, commpanyINNAuto.value]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     fetchSuggestions(txtAddress.value).then((res) => {
    //         console.log(res)
    //         if (res?.suggestions) {
    //             let addressList: string[] = []
    //             for (const item of res?.suggestions) {
    //                 addressList.push(item.unrestricted_value)
    //             }
                
    //             suggestions.setValue(addressList)
    //         }
    //     })

    //     console.log(suggestions.value)
    // }, [txtAddress.value]) // eslint-disable-line react-hooks/exhaustive-deps
    

    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        tabActive.setValue('primary')
        fieldsError.setValue({})

        companyNumber.setValue('')
        companyDateCreate.setValue('')
        commpanyINNAuto.setValue(false)
        isReadOnlyKPP.setValue(true)

        txtINNBuff.setValue('') // Буффер, оригинальный ИНН
        txtINN.setValue('')
        txtKPPBuff.setValue('') // Буффер, оригинальный КПП
        txtKPP.setValue('')
        txtCompanyName.setValue('')

        txtCompanyNameShort.setValue('')
        txtLastName.setValue('')
        txtFirstName.setValue('')
        txtSecondName.setValue('')
        txtManagerPositionBuff.setValue('') // Буффер, оригинальная должность
        txtManagerPosition.setValue('')
        txtActingOnBasisBuff.setValue('') // Буффер, оригинальная действующего на основании
        txtActingOnBasis.setValue('')

        txtInPersonWhom.setValue('')
        txtAddress.setValue('')
        txtRequisites.setValue('Банк: \nР/сч: \nК/сч: \nБИК: \nEmail: \nТелефон: ')

        txtComment.setValue('')

        filesList.setValue([])
        filesListSendToSrv.setValue([])
    }
    /** Функция по генерации ИНН */
    const handlerINNAuto = (checked: boolean) => {
        commpanyINNAuto.setValue(checked)

        if (checked) {
            txtINN.setValue(nanoid())
            txtKPP.setValue('')
            isReadOnlyKPP.setValue(true)
        } else {
            txtINN.setValue(txtINNBuff.value || '')
            txtKPP.setValue(txtKPPBuff.value || '')

            if (txtINNBuff.value?.length === 10) {
                isReadOnlyKPP.setValue(false)
            }

            let innFromBuffer: string = ''
            if (txtINNBuff.value.length === 12) innFromBuffer = txtINNBuff.value

            txtINN.setValue(innFromBuffer || '')
        }
    }



    /** Проверка по ИНН, существование контрагента */
    const companyCheckINN = async (inn: string) => {
        const res = await checkINN(cardID || 0, inn)
        if (res?.result) handlerOpenModalNotice(true, 'Извещение...', `<b>Внимание!</b><br/>Контрагент с таким ИНН уже зарегистрирован, идентификатор анкеты контрагента: <b>${res.appID}</b>`)
    }
    /** Проверка по КПП, существование контрагента */
    const companyCheckKPP = async (kpp: string) => {
        const res = await checkKPP(cardID || 0, kpp)
        if (res?.result) handlerOpenModalNotice(true, 'Извещение...', `<b>Внимание!</b><br/>Контрагент с таким КПП уже зарегистрирован, идентификатор анкеты контрагента: <b>${res.appID}</b>`)
    }



    /** Открытие/закрытие окна извещения */
    const handlerOpenModalNotice = (isOpen: boolean, headerText: string, contentText: string) => {
        modalNotice.setValue({
            isOpen, headerText, contentText, 
            handlerButtonOk() {modalNotice.setValue({isOpen: false})}, 
        })
    }
    /** Открытие/закрытие окна предпросмотра документа */
    const handlerOpenModalPreview = (isOpen: boolean, fileUrl: string, fileName: string) => {
        modalPreview.setValue({
            isOpen, fileUrl, fileName, 
            handlerButtonCancel() {modalPreview.setValue({isOpen: false})}, 
        })
    }


    /** Функция по выделению файлов */
    const handlerSelectedFiles = (fileID: number) => {
        const newList = filesList.value.map(item => {
            if (item.fileID === fileID) {
                return { ...item, isSelected: !item.isSelected }
            }

            return item
        })

        filesList.setValue(newList)
    }
    /** Функция по выделению всех файлов */
    const handlerSelectedFilesAll = () => {
        const newList = filesList.value.map(item => {
            return { ...item, isSelected: !item.isSelected }
        })

        filesList.setValue(newList)
    }
    /** Функция по скачиванию файлов контрагента */
    const handlerDownloadFiles = (fileID?: number) => {
        let selectedFiles: IFilesList[] = []

        if (cardMode === 'new') return notify && notifyOpen(`Не возможно скачать файл или файлы, т.к. они еще не загружены на сервер.`, 'warning', 1500, notify)

        if (fileID) { selectedFiles = filesList.value.filter(file => file.fileID === fileID) } 
        else { selectedFiles = filesList.value.filter(file => file.isSelected) }

        for (const file of selectedFiles) {
            const link = document.createElement('a') // Создание ссылки и программное скачивание файла

            link.href = file.filePath
            link.download = file.fileName
            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)
        }
    }
    /** Функция по удалению файлов контрагента */
    const handlerDeleteFiles = async (fileID?: number) => {
        let selectedFiles: IFilesList[] = []

        if (fileID) { selectedFiles = filesList.value.filter(file => file.fileID === fileID) } 
        else { selectedFiles = filesList.value.filter(file => file.isSelected) }

        switch (cardMode) {
            case 'new': { // При создании контрагента               
                const fileNamesToDelete = selectedFiles.map(file => file.fileName) // Получаем имена файлов, которые нужно удалить
                
                // Обновляем filesList, удаляя выбранные файлы
                filesList.setValue(filesList.value.filter(file => !fileNamesToDelete.includes(file.fileName)))
                
                // Обновляем filesListSendToSrv, удаляя файлы, чьи имена находятся в fileNamesToDelete
                filesListSendToSrv.setValue(filesListSendToSrv.value.filter(file => !fileNamesToDelete.includes(file.name)));
                break
            }
            case 'edit': { // При редактировании контрагента
                for (const file of selectedFiles) {
                    await deleteFiles(Number(cardID) | 0, file.fileID).finally(() => {
                        getFiles(Number(cardID) | 0).then((files) => {
                            filesList.setValue(files.map((item) => ({
                                fileID: item.companyFileID,
                                fileName: item.fileName,
                                filePath: item.filePath,
                                fileSize: item.fileSize,
                                dateCreate: item.dateCreate,
                                isSelected: false
                            })))
                        })
                    })
                } break
            }
        }
    }



    /** Кнопка сохранить все данные во вкладке primary */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'inn', value: txtINN.value, validType: commpanyINNAuto.value ? 'notEmpty' : 'inn', isRequired: true, errorMessage: 'ИНН контрагента не корректный.'},
            {fieldName: 'kpp', value: txtKPP.value, validType: 'kpp', isRequired: isReadOnlyKPP.value ? false : true, errorMessage: 'КПП контрагента не корректный.'},

            {fieldName: 'companyName', value: txtCompanyName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Полное наименование контрагента, обязательно должно быть заполнено.'},
            {fieldName: 'companyNameShort', value: txtCompanyNameShort.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Краткое наименование контрагента, обязательно должно быть заполнено.'},
            
            {fieldName: 'managerPosition', value: txtManagerPosition.value, validType: 'notEmpty', isRequired: (isReadOnlyKPP.value && !commpanyINNAuto.value) ? false : true, errorMessage: 'Должность руководителя, обязательно должно быть заполнено.'},
            {fieldName: 'actingOnBasis', value: txtActingOnBasis.value, validType: 'notEmpty', isRequired: (isReadOnlyKPP.value && !commpanyINNAuto.value) ? false : true, errorMessage: 'Действующего на основании, обязательно должно быть заполнено.'},
            
            {fieldName: 'lastName', value: txtLastName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Фамилия руководителя, обязательно нужно заполнить.'},
            {fieldName: 'firstName', value: txtFirstName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Имя руководителя, обязательно нужно заполнить.'},
            
            {fieldName: 'inPersonWhom', value: txtInPersonWhom.value, validType: 'notEmpty', isRequired: true, errorMessage: 'В лице кого, обязательно нужно заполнить.'},
            {fieldName: 'address', value: txtAddress.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Юридический адрес, обязательно нужно заполнить.'},
            {fieldName: 'requisites', value: txtRequisites.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Платёжные реквизиты, обязательно нужно заполнить.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            setCardData(cardID || 0, {
                actionForm: cardMode || 'new',
            
                companyINN: txtINN.value,
                companyKPP: txtKPP.value,
                companyName: txtCompanyName.value,
                companyNameShort: txtCompanyNameShort.value,
            
                managerPosition: txtManagerPosition.value,
                lastName: txtLastName.value,
                firstName: txtFirstName.value,
                secondName: txtSecondName.value,
                actingOnBasis: txtActingOnBasis.value,
            
                inPersonWhom: txtInPersonWhom.value,
                address: txtAddress.value,
            
                requisites: txtRequisites.value,
            
                comment: txtComment.value,
                
                isForeignCompany: commpanyINNAuto.value ? 1 : 0
            }, filesListSendToSrv.value).then((res) => {
                if (res === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }



    /** Проверка ИНН на дубль */
    const debounceINN = useDebounce(companyCheckINN, 1500)
    const handleChangeINN = (value: string) => {
        txtINN.setValue(value)
        debounceINN(value)

        if (!commpanyINNAuto.value && isValidINN(value)) {
            if (value.length === 10) {
                isReadOnlyKPP.setValue(false)
            } else {
                isReadOnlyKPP.setValue(true)
            }
        } else { isReadOnlyKPP.setValue(true) }        
    }
    /** Проверка КПП на дубль */
    const debounceKPP = useDebounce(companyCheckKPP, 1500)
    const handleChangeKPP = (value: string) => {
        txtKPP.setValue(value)
        debounceKPP(value)
    }


    // Загрузка файлов на сервер
    const fileUploader = async (files: File[]) => {
        const maxSize = 10485760 // Максимально разрешенный размер файла для загрузки (10 MB)

        switch (cardMode) {
            case 'new': { // При создании контрагента
                const newFiles: IFilesList[] = files.reduce<IFilesList[]>((acc, item) => {
                    // Проверка на максимальный размер файла
                    if (item.size > maxSize) {
                        notify && notifyOpen(`Файл ${item.name}, превышает максимально допустимый размер в 10 мегабайт.`, 'error', 1500, notify)
                        return acc
                    }

                    // Проверка на уникальность имени файла
                    const isAlreadyAdded = filesList.value.some(existingFile => existingFile.fileName === item.name)
                    if (isAlreadyAdded) {
                        notify && notifyOpen(`Файл с именем ${item.name} уже добавлен в список.`, 'error', 1500, notify)
                        return acc
                    }

                    // Добавление нового файла
                    const newFile: IFilesList = {
                        fileID: Number(CommonService.generateDigits(10)),
                        fileName: item.name,
                        filePath: '',
                        fileSize: CommonService.getDefiningFileUnit(item.size),
                        dateCreate: new Date().toISOString(),
                        isSelected: false
                    }

                    acc.push(newFile)
                    return acc
                }, [])

                // Обновление списка файлов, добавляя новые файлы
                filesList.setValue([...filesList.value, ...newFiles])

                const newFilesOnly = newFiles.map(f => files.find(file => file.name === f.fileName)).filter(f => f) as File[]
                filesListSendToSrv.setValue([...filesListSendToSrv.value, ...newFilesOnly])
                break
            }

            case 'edit': { // При редактировании контрагента
                files.forEach((file) => { // Проверка файлов

                })

                await uploadFiles(Number(cardID) | 0, files).finally(() => {
                    getFiles(Number(cardID) | 0).then((files) => {
                        filesList.setValue(files.map((item) => ({
                            fileID: item.companyFileID,
                            fileName: item.fileName,
                            filePath: item.filePath,
                            fileSize: item.fileSize,
                            dateCreate: item.dateCreate,
                            isSelected: false
                        })))
                    })
                })
                break
            }
        }
    }



    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardCompany = {
        isOpen, cardMode, handlerButtonCancel,
        isLoading: isLoadingCard, tabActive, fieldsError,

        filesList, fileUploader,

        companyNumber, companyDateCreate, commpanyINNAuto, txtCompanyNameShort, txtCompanyName,
        txtINN, isReadOnlyKPP, txtKPP, txtLastName, txtFirstName, txtSecondName, txtManagerPosition, txtActingOnBasis,
        txtInPersonWhom, txtAddress, txtRequisites, txtComment,

        handleChangeINN, handleChangeKPP, handlerSelectedFiles, handlerSelectedFilesAll,
        handleBtnSave, handlerINNAuto, handlerDownloadFiles, handlerDeleteFiles, handlerOpenModalPreview,
    }

    return <>
        <CardCompany {...propsToComponent} />

        <ModalNotice {...modalNotice.value} />
        <ModalPreview {...modalPreview.value} />
    </>
}