import { useCallback, useRef } from 'react'


export const useDebounce = (callback: (value: any) => void, delay: number) => {
    const timer = useRef<NodeJS.Timeout | undefined>(undefined)

    const debouncedCallback = useCallback((args: any) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            callback(args)
        }, delay)
    }, [callback, delay])

    return debouncedCallback
}