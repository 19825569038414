import { ICardDelivery, ICardDeliveryTabPrimary } from "./_types"

import { CardDeliveryTabLogs } from "./_tab.logs"
import { CardDeliveryTabPrimary } from "./_tab.primary"

import { IconClearClose, IconDelivery, IconLogs, IconMainGrid } from "images/icons"
import { Flex, ToolTip, Loader, Drawer, Divider, Button, Tab } from "components/ui"


/** Анкета доставки */
export const CardDelivery = ({
    isOpen, isLoadingCard, tabActive, loadingTitle, fieldsError, contractNumber, contractDate, applicationID,
    txtDeliveryToWhom, txtDeliveryAddress, txtIsAddressConfirmed, txtComment,
    listFiles, listListeners, listTrackNumbers,
    handlerOpenModalConfirm, handlerOpenModalPreview, handlerDownloadFiles, 
    handlerSendingTrackNumbers, handlerSendingListenerFiles, handlerFileUploader,
    handlerOpenModalTrackNumber, handleBtnSave, handlerButtonCancel
}: ICardDelivery) => {
    /** Свойства передаваемые в основную вкладку */
    const propsToCardDeliveryTabPrimary: ICardDeliveryTabPrimary = {
        fieldsError,
        txtDeliveryToWhom, txtDeliveryAddress, txtIsAddressConfirmed, txtComment,
        listFiles, listListeners, listTrackNumbers,
        handlerOpenModalPreview, handlerDownloadFiles, handlerSendingTrackNumbers, 
        handlerSendingListenerFiles, handlerFileUploader, handlerOpenModalTrackNumber
    }

    /** Отображение страницы в зависимости от выбора активной вкладки */
    const getCardPage = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': return <CardDeliveryTabPrimary {...propsToCardDeliveryTabPrimary} />
            case 'logs': return <CardDeliveryTabLogs />
        }
    }
    
    /** Функция по получению кнопочной панели в низу анкеты, в зависимости от выбранной вкладки */
    const getCardPageBtnPanel = (tabActive: string) => {
        switch (tabActive) {
            case 'primary': {
                return <Flex gap='4px'>
                    <Divider />
                    <Flex direction='row' gap='4px' justifyContent='flex-end'>
                        <Flex direction='row' gap='4px'>
                            <Button variant='primaryDark' padding='0 10px' onClick={() => handlerOpenModalConfirm(true, 'Закрытие договора', 'Вы действительно хотите закрыть данный договор?', 'sendingOrCloseContract')}>
                                Закрыть договор
                            </Button>

                            <Divider type='vertical' margin='8px' />
                        </Flex>

                        <Button padding='0 10px' onClick={handleBtnSave}>Сохранить</Button>
                    </Flex>
                </Flex>
            }

            case 'logs': return ''
        }
    }

    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconDelivery color='#fff' width={20} height={20} />
                        <h4>Анкета доставки</h4>
                    </Flex>

                    <h5>№ {contractNumber.value} от {contractDate.value} (👉 {applicationID.value})</h5>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body_bottom_panel'>
                <Flex gap='6px' className='drawer_container-content' overflow={['files', 'listeners'].includes(tabActive.value) ? 'hidden' : 'scroll'} padding={['files', 'listeners'].includes(tabActive.value) ? '2px 0px 0px 0px' : '4px 8px 4px 4px'}>
                    {getCardPage(tabActive.value)}
                </Flex>

                {getCardPageBtnPanel(tabActive.value)}
            </Flex>

            <Tab.Container>
                <Tab.Item tabName='primary' tabNameActive={tabActive.value} tabToolTipPlace='top-start' tabToolTipTitle='Основные данные' onClick={tabActive.setValue}><IconMainGrid /></Tab.Item>
                <Tab.Item tabName='logs' tabNameActive={tabActive.value} tabToolTipPlace='top-end' tabToolTipTitle='Журнал изменений' onClick={tabActive.setValue}><IconLogs /></Tab.Item>
            </Tab.Container>

            <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadingTitle.value} backdrop />
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
    </Drawer>
}