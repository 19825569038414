import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { 
    getCompanyCheckINN, getCompanyCheckKPP,
    getCompanies, getCompanyFiles,
    getCompanyCard, setCompanyCardSave,
    setCompanyDeleteFiles, setCompanyUploadFiles
} from './companies.api'
import {
    ICompaniesState, ICompaniesActions,
    ICompaniesList, ICompaniesFilesList, 
    ICompanyCard, ICompanyFormSendedData,
} from './companies.types'


export const initialStateCompanies: ICompaniesState = {
    error: null,
    isReloadCompanies: false,
    isLoadingCard: false,
 
    companies: {
        isLoading: false,

        items: [] as ICompaniesList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },
}

/** Хранилище страницы - Контрагенты */
export const useCompanies = create<ICompaniesState & ICompaniesActions>()(devtools((set) => ({
    ...initialStateCompanies,

    clearCompaniesState: () => {set(initialStateCompanies)},


    /** Проверка по ИНН, существование контрагента */
    actionGetCompanyCheckINN: async (companyID: number, inn: string) => {
        const res = await getCompanyCheckINN(companyID, inn)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            return res.data
        }
    },
    /** Проверка по КПП, существование контрагента */
    actionGetCompanyCheckKPP: async (companyID: number, kpp: string) => {
        const res = await getCompanyCheckKPP(companyID, kpp)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            return res.data
        }
    },



    /** Получение списка контрагентов */
    actionGetCompanies: async (pageNumber: number, search: string) => {
        set((state) => ({companies: {...state.companies, isLoading: !state.isReloadCompanies}}))

        const res = await getCompanies(pageNumber, search)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadCompanies: false})

            set((state) => ({
                companies: {
                    ...state.companies, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        set((state) => ({companies: {...state.companies, isLoading: false}}))
    },
    /** Получение списка файлов контрагента */
    actionGetCompanyFiles: async (companyID: number) => {
        let returnData: ICompaniesFilesList[] = []
        set({isLoadingCard: true})

        const res = await getCompanyFiles(companyID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data?.files
        }

        set({isLoadingCard: false})
        return returnData
    },



    /** Получение данных по анкете контрагента */
    actionGetCompanyCard: async (companyID: number) => {
        let returnData: ICompanyCard | null = null
        set({isLoadingCard: true})

        const res = await getCompanyCard(companyID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по анкете контрагента */
    actionSetCompanyCard: async (companyID: number, data: ICompanyFormSendedData, files?: File[]) => {
        set({isLoadingCard: true})

        const res = await setCompanyCardSave(companyID, data, files)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })
            
        set({isLoadingCard: false})
        return res.status
    },



    /** Удаление файлов контрагента */
    actionSetCompanyDeleteFiles: async (companyID: number, fileID: number) => {
        set({isLoadingCard: true})

        const res = await setCompanyDeleteFiles(companyID, fileID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadCompanies: true})
        }
            
        set({isLoadingCard: false})
        set({isReloadCompanies: true})
    },
    /** Загрузка файлов контрагента */
    actionSetCompanyUploadFiles: async (companyID: number, files: File[]) => {
        set({isLoadingCard: true})

        const res = await setCompanyUploadFiles(companyID, files)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadCompanies: true})
        }

        set({isLoadingCard: false})
    },

}), {name: 'companiesState'}))