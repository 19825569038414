type DeclensionRules = Record<string, string>


/** Класс общих функций */
export default class CommonService {

    private static rules: Record<'кого/чего' | 'кому/чему', Record<string, DeclensionRules>> = {
        'кого/чего': {
            'lastName': {
                'ов': 'ова', 
                'ев': 'ева', 
                'ин': 'ина',
                'ко': 'ко', 
                'ий': 'ого',
                'ян': 'ян',
                'ук': 'ука',
                'ик': 'ика',
                'ан': 'ана',

                'ова': 'овой',
                'ева': 'евой',
                'ина': 'иной',
                'нко': 'нко',
                'кая': 'кой',
            },
            'firstName': {
                'ей': 'ея', 
                'ий': 'ия', 
                'ан': 'ана', 
                'др': 'дра', 
                'им': 'има', 
                'он': 'она', 
                'ил': 'ила', 
                'ир': 'ира', 
                'ав': 'ава', 
                'ат': 'ата', 
                'ис': 'иса', 
                'ём': 'ёма', 
                'ем': 'ема', 
                'та': 'ты', 
                'ек': 'ека', 
                'ья': 'ьи', 
                'ин': 'ина', 
                'ел': 'ла', 
                'лл': 'лла', 
                'ор': 'ора', 
                'ай': 'ая', 
                'ег': 'ега', 
                'ар': 'ара', 
                'рь': 'ря', 
                'ль': 'ля', 
                'ен': 'ена', 
                'ён': 'ёна', 
                'ур': 'ура', 
                'ам': 'ама', 
                'ла': 'лы', 
                'ид': 'ида', 
                'пп': 'ппа', 
                'ип': 'ипа', 

                'ина': 'ины', 
                'рия': 'рии', 
                'сия': 'сии', 
                'лия': 'лии', 
                'ена': 'ены', 
                'ана': 'аны', 
                'нна': 'нны', 
                'яна': 'яны', 
                'рья': 'рьи', 
                'ьга': 'ьги', 
                'ния': 'нии', 
                'дра': 'дры', 
                'лья': 'льи', 
                'жда': 'жды', 
                'ара': 'ары', 
                'ира': 'иры', 
                'ета': 'еты', 
                'ика': 'ики', 
                'еся': 'еси', 
                'ита': 'иты', 
                'ера': 'еры', 
                'фия': 'фии', 
                'ила': 'илы', 
                'овь': 'ови', 
                'иса': 'исы', 
            },
            'secondName': {
                'ич': 'ича', 
                'ли': 'ли', 
                'лы': 'лы', 
                
                'вна': 'вны', 
            }
        },

        'кому/чему': {
            'lastName': {
                'ов': 'ову', 
                'ев': 'еву', 
                'ин': 'ину',
                'ко': 'ко', 
                'ий': 'ому',
                'ян': 'ян',
                'ук': 'уку',
                'ик': 'ику',
                'ан': 'ану',

                'ова': 'овой',
                'ева': 'евой',
                'ина': 'иной',
                'нко': 'нко',
                'кая': 'кой',
            },
            'firstName': {
                'ей': 'ею', 
                'ий': 'ию', 
                'ан': 'ану', 
                'др': 'дру', 
                'им': 'иму', 
                'он': 'ону', 
                'ил': 'илу', 
                'ир': 'иру', 
                'ав': 'аву', 
                'ат': 'ату', 
                'ис': 'ису', 
                'ём': 'ёму', 
                'ем': 'ему', 
                'та': 'те', 
                'ек': 'еку', 
                'ья': 'ье',  
                'ин': 'ину', 
                'ел': 'лу', 
                'лл': 'ллу', 
                'ор': 'ору', 
                'ай': 'аю', 
                'ег': 'егу', 
                'ар': 'ару', 
                'рь': 'рю', 
                'ль': 'лю', 
                'ен': 'ену', 
                'ён': 'ёну', 
                'ур': 'уру', 
                'ам': 'аму', 
                'ла': 'лу', 
                'ид': 'иду', 
                'пп': 'ппу', 
                'ип': 'ипу', 

                'ина': 'ине', 
                'рия': 'рии', 
                'сия': 'сии', 
                'лия': 'лии', 
                'ена': 'ене', 
                'ана': 'ане', 
                'нна': 'нне', 
                'яна': 'яне', 
                'рья': 'рьи', 
                'ьга': 'ьге', 
                'ния': 'нии', 
                'дра': 'дре', 
                'лья': 'лье', 
                'жда': 'жде', 
                'ара': 'аре', 
                'ира': 'ире', 
                'ета': 'ете', 
                'ика': 'ике', 
                'еся': 'есе', 
                'ита': 'ите', 
                'ера': 'ере', 
                'фия': 'фие', 
                'ила': 'иле', 
                'овь': 'ови', 
                'иса': 'исе', 
            },
            'secondName': {
                'ич': 'ичу', 
                'ли': 'ли', 
                'лы': 'лы', 
                
                'вна': 'вне', 
            }
        }
    }


    
    /** Функция принимает число и возвращает строку, содержащую число и правильное склонение в зависимости от недели это или месяцы */
    static formatDuration = (value: number, isWeek: boolean = false): string => {
        const intPart = Math.floor(value)  // Целая часть числа
        let unit = isWeek ? "недел" : "месяц"  // Определяем единицу измерения
        let suffix = ""

        if (intPart !== value) {  // Обработка дробных значений
            if (isWeek) {
                suffix = "ь"  // Нет строгих правил, обычно "недель" для дробных значений
            } else {
                suffix = "а"  // Обычно для дробных значений месяцев используем "месяца"
            }
            return `${value} ${unit}${suffix}`
        }

        // Правила склонения в зависимости от числа
        if (intPart % 10 === 1 && intPart % 100 !== 11) {
            suffix = ""
        } else if ([2, 3, 4].includes(intPart % 10) && ![12, 13, 14].includes(intPart % 100)) {
            suffix = isWeek ? "и" : "а"
        } else {
            suffix = isWeek ? "ей" : "ев"
        }

        return `${value} ${unit}${suffix}`;
    }

    /** Функция принимает число и возвращает строку, содержащую число и правильное склонение слова "час" */
    static formatHours = (number: number): string => {
        const remainder10 = number % 10
        const remainder100 = number % 100

        if (remainder100 >= 11 && remainder100 <= 20) {
            return number + ' часов'
        } else if (remainder10 === 1) {
            return number + ' час'
        } else if (remainder10 >= 2 && remainder10 <= 4) {
            return number + ' часа'
        } else {
            return number + ' часов'
        }
    }    

    /** Функция по генерации случайного числа */
    static generateDigits = (count: number): string => {
        let digits = ''

        while (digits.length < count) {
            // Генерируем случайное число и преобразуем его в строку
            const part = Math.floor(Math.random() * 1_000_000_000).toString()
            // Добавляем сгенерированную часть к результату
            digits += part
        }
        // Обрезаем строку до нужной длины
        return digits.substring(0, count)
    }

    /** Функция принимает ФИО, а возвращает его в склоненном виде, в зависимости от выбранного падежа */
    static declensionFullName(lastName: string, firstName: string, secondName: string, padej: 'кого/чего' | 'кому/чему'): string {
        const applyDeclension = (name: string, type: 'lastName' | 'firstName' | 'secondName', padej: 'кого/чего' | 'кому/чему'): string => {
            const rules = this.rules[padej][type]

            for (const [suffix, replacement] of Object.entries(rules)) {
                if (name.endsWith(suffix)) {
                    return name.replace(new RegExp(suffix + '$'), replacement)
                }
            }
            return name
        }

        const newLastName = lastName ? applyDeclension(lastName.toString(), 'lastName', padej) : ''
        const newFirstName = firstName ? applyDeclension(firstName.toString(), 'firstName', padej) : ''
        const newSecondName = secondName ? applyDeclension(secondName.toString(), 'secondName', padej) : ''

        return `${newLastName} ${newFirstName} ${newSecondName}`
    }

    /** Определение единицы измерения файла */
    static getDefiningFileUnit = (fileSizeInBytes: number) => {
        let returnString = ''

        const fileSizeInKilobytes = Number((fileSizeInBytes / 1024).toFixed(1))
        const fileSizeInMegabytes = Number((fileSizeInKilobytes / 1024).toFixed(1))
        const fileSizeInGigabytes = Number((fileSizeInMegabytes / 1024).toFixed(1))

        if (fileSizeInBytes < 1024) {
            returnString = `${fileSizeInBytes} B`
        } else if (fileSizeInKilobytes < 1024) {
            returnString = `${fileSizeInKilobytes} KB`
        } else if (fileSizeInMegabytes < 1024) {
            returnString = `${fileSizeInMegabytes} MB`
        } else {
            returnString = `${fileSizeInGigabytes} GB`
        }

        return returnString
    }

}