import { useEffect, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { ICardListenerTabPrimary } from './_types'

import { IconCalendar, IconClearClose, IconMicrophone, IconSelectData, IconUpdate } from 'images/icons'
import { Flex, ToolTip, Textarea, Input, Divider, Checkbox, Button } from 'components/ui'
import { useInput } from 'hooks/useInput'


/** Основная вкладка в анкете слушателя */
export const CardListenerTabPrimary = ({
    fieldsError,  clientSnilsAuto,
    txtCitizenshipID, txtCitizenship, txtSnils, txtClientPhone, txtClientEmail,
    txtLastName, txtFirstName, txtSecondName, txtBirthDate, txtFullNameFrom,
    txtPassport, txtAddress, txtEducationLevelID, txtEducationLevel, txtLastNameDiplom, txtDiplom,
    txtComment, txtLogin, txtPassword, txtSex,
    handleOpenModalDict, handleOpenModalCalendar, handleChangePhone, handlerSnilsAuto, handleChangeSnils, handlerPhoneGenerate,
}: ICardListenerTabPrimary) => {
    const {resetTranscript, browserSupportsSpeechRecognition, finalTranscript, isMicrophoneAvailable} = useSpeechRecognition()
    const [isListening, setIsListening] = useState<boolean>(false)
    const fieldName = useInput<string>('')

    useEffect(() => {
        if (isListening && finalTranscript) {
            switch (fieldName.value) {
                case 'passport': txtPassport.setValue(txtPassport.value + finalTranscript); break
                case 'diplom': txtDiplom.setValue(txtDiplom.value + finalTranscript); break
            }
        }
    }, [finalTranscript, isListening, fieldName.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetTranscript()
    }, [finalTranscript]) // eslint-disable-line react-hooks/exhaustive-deps

    const startListening = (fName: string) => {
        if (isListening) {
            setIsListening(false)
            SpeechRecognition.stopListening()

            fieldName.setValue('')
        } else {
            setIsListening(true)
            SpeechRecognition.startListening({ continuous: true })

            fieldName.setValue(fName)
        }
    }

    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }

    const getBtnSelect = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    }

    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }

    return <>
        <Flex gap="20px">
            <Flex gap="5px">
                <Divider prefix={<h4>Основная информация</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.citizenship ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Гражданство слушателя <p>✸</p></Flex>
                            <Input placeholder="Гражданство слушателя" isReadOnly paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'citizenship', 'citizenship'))} {...txtCitizenship} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.snils ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">СНИЛС слушателя <p>✸</p></Flex>
                            <Input placeholder="СНИЛС слушателя" paddingRight={4} mask='999-999-999 99' maskChar="" 
                                value={txtSnils.value}
                                onChange={(e) => handleChangeSnils(e.target.value)}
                                type={(txtCitizenshipID.value === 185 && !clientSnilsAuto.value) ? 'mask' : 'text'}
                                isReadOnly={clientSnilsAuto.value}
                            />
                            <Checkbox id='toolTipSnils' isPositionAbsolute right='18px' top='8px' checked={clientSnilsAuto.value} onChange={(e) => handlerSnilsAuto(e.target.checked)} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.clientPhone ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Телефон слушателя <p>✸</p></Flex>
                            <Input placeholder="Телефон слушателя" paddingRight={4} suffix={<Button id="tooltipBtnPhone" size="sm" variant="icon" onClick={handlerPhoneGenerate}><IconUpdate /></Button>}
                                value={txtClientPhone.value} onChange={(e) => handleChangePhone(e.target.value)}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.clientEmail ? 'input_error' : 'input_error input_error-active'}>
                            <Flex direction="row" className="input_header">Email слушателя</Flex>
                            <Input placeholder="Email слушателя" paddingRight={4} suffix={txtClientEmail.value && getBtnClear(() => txtClientEmail.setValue(''))} {...txtClientEmail} />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.birthDate ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Дата рождения слушателя <p>✸</p></Flex>
                            <Input placeholder="Дата рождения слушателя" paddingRight={4} type='mask' mask='99.99.9999' maskChar="" {...txtBirthDate}
                                suffix={getBtnSelectCalendar(() => handleOpenModalCalendar(true, 'birthDate', txtBirthDate.value))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.lastName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Фамилия слушателя <p>✸</p></Flex>
                            <Input placeholder="Фамилия слушателя" paddingRight={4} suffix={txtLastName.value && getBtnClear(() => txtLastName.setValue(''))} {...txtLastName} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.firstName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Имя слушателя <p>✸</p></Flex>
                            <Input placeholder="Имя слушателя" paddingRight={4} suffix={txtFirstName.value && getBtnClear(() => txtFirstName.setValue(''))} {...txtFirstName} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex direction="row" className="input_header">Отчество слушателя</Flex>
                            <Input placeholder="Отчество слушателя" paddingRight={4} suffix={txtSecondName.value && getBtnClear(() => txtSecondName.setValue(''))} {...txtSecondName} />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.fullNameFrom ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Ф.И.О. слушателя (от кого) <p>✸</p></Flex>
                            <Input placeholder="Ф.И.О. слушателя (от кого)" paddingRight={4} suffix={txtFullNameFrom.value && getBtnClear(() => txtFullNameFrom.setValue(''))} {...txtFullNameFrom} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Удостоверение личности</h4>} suffix={
                    <Flex gap='5px' direction='row' className={!fieldsError.value.sex ? 'input_error' : 'input_error input_error-active'}>
                        <Button id='toolTipSexM' size='sm' padding='0 6px' variant={txtSex.value === 'Мужской' ? 'iconBg' : 'iconBr'} onClick={() => txtSex.setValue('Мужской')}>М</Button>
                        <Button id='toolTipSexJ' size='sm' padding='0 5.3px' variant={txtSex.value === 'Женский' ? 'iconBg' : 'iconBr'} onClick={() => txtSex.setValue('Женский')}>Ж</Button>
                    </Flex>
                    }
                />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap" className='input_error'>
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.passport ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Паспорт слушателя <p>✸</p></Flex>
                            <Textarea placeholder="ВНИМАНИЕ! Сначала введите серию и номер документа." rows={5} 
                                value={txtPassport.value} onChange={(e) => txtPassport.setValue(e.target.value)}
                            />
                        </Flex>

                        <div style={{position: 'absolute', right: '15px', bottom: '-14px'}}>
                            <Button size='sm' variant={isListening ? 'iconBg' : 'iconBr'} isDisabled={!browserSupportsSpeechRecognition || !isMicrophoneAvailable || (isListening && fieldName.value !== 'passport')} onClick={() => startListening('passport')}><IconMicrophone width={20} height={20} /></Button>
                        </div>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.address ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Адрес прописки слушателя <p>✸</p></Flex>
                            <Input placeholder="Адрес прописки слушателя" paddingRight={4} suffix={txtAddress.value && getBtnClear(() => txtAddress.setValue(''))} {...txtAddress} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Документ об образовании</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.educationLevel ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Уровень образования слушателя <p>✸</p></Flex>
                            <Input placeholder="Уровень образования слушателя" isReadOnly paddingRight={4} suffix={getBtnSelect(() => handleOpenModalDict(true, 'educationLevel', 'educationLevel'))} {...txtEducationLevel} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex direction="row" className="input_header">Фамилия слушателя в дипломе</Flex>
                            <Input placeholder="Фамилия слушателя в дипломе" paddingRight={4} suffix={txtLastNameDiplom.value && getBtnClear(() => txtLastNameDiplom.setValue(''))} {...txtLastNameDiplom} />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.diplom ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Документ об образовании слушателя <p>✸</p></Flex>
                            <Textarea placeholder="Документ об образовании слушателя" rows={5} value={txtDiplom.value} onChange={(e) => txtDiplom.setValue(e.target.value)} isDisabled={txtEducationLevelID.value === 1} />
                            
                            <div style={{position: 'absolute', right: '15px', bottom: '-14px'}}>
                                <Button size='sm' variant={isListening ? 'iconBg' : 'iconBr'} isDisabled={!browserSupportsSpeechRecognition || !isMicrophoneAvailable || (isListening && fieldName.value !== 'diplom')} onClick={() => startListening('diplom')}><IconMicrophone width={20} height={20} /></Button>
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="5px">
                <Divider prefix={<h4>Прочее</h4>} />

                <Flex gap='15px'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex direction="row" className="input_header">Комментарий</Flex>
                            <Textarea placeholder="Комментарий" rows={5} value={txtComment.value} onChange={(e) => txtComment.setValue(e.target.value)} />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.login ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Логин (личный кабинет) <p>✸</p></Flex>
                            <Input placeholder="Логин (личный кабинет)" paddingRight={4} suffix={txtLogin.value && getBtnClear(() => txtLogin.setValue(''))} {...txtLogin} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.password ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Пароль (личный кабинет)  <p>✸</p></Flex>
                            <Input placeholder="Пароль (личный кабинет)" paddingRight={4} suffix={txtPassword.value && getBtnClear(() => txtPassword.setValue(''))} {...txtPassword} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
        <ToolTip anchorSelect="#toolTipSnils" place="right">При установке галочки, СНИЛС сгенерируется автоматически.<br/>При снятии галочки, СНИЛС надо будет ввести вручную.</ToolTip>
        <ToolTip anchorSelect="#toolTipSexM" content='Мужской пол' place="top-end" />
        <ToolTip anchorSelect="#toolTipSexJ" content='Женский пол' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPhone" content='Сгенерировать телефон' place="bottom-end" />
    </>
}