import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment'

import { FieldsErrorType, ICardContract, ICardContractContainer, IFieldsError, IFilesList } from './_types'
import { CardModeType } from '../common.type'
import { CardContract } from './_interface'

import { useInput } from 'hooks/useInput'
import { validFormData } from 'hooks/useForm'

import { useContracts } from 'store/contracts/contracts.state'
import { IPaymentsList, IProgramsList } from 'store/contracts/contracts.types'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'

import { IModalPreview, ModalPreview } from 'components/dumb/modal.preview'
import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'
import { IModalConfirm, ModalConfirm } from 'components/dumb/modal.confirm'
import { IModalNotice, ModalNotice } from 'components/dumb/modal.notice'

import { SelectCompaniesContainer, ISelectCompanyContainer } from '../selectCompany'
import { SelectListenerContainer, ISelectListenerContainer } from '../selectListener'
import { SelectDictionaryContainer, ISelectDictionaryContainer, DictNameType } from '../selectDictionary'

import { CardContractUploadContainer, ICardContractUploadContainer } from '../cardContractUpload'
import { CardContractSendedContainer, ICardContractSendedContainer } from '../cardContractSended'
import { CardContractProgramContainer, ICardContractProgramContainer } from '../cardContractProgram'
import { CardContractPaymentsContainer, ICardContractPaymentsContainer } from '../cardContractPayments'
import { CardContractGenerateDocContainer, ICardContractGenerateDocContainer } from '../cardContractGenerateDoc'
import { CardContractProgramListenerContainer, ICardContractProgramListenerContainer } from '../cardContractProgramListener'


/** Анкета по договору - Контейнер */
export const CardContractContainer = ({isOpen, cardMode, cardID, handlerButtonOk, handlerButtonCancel}: ICardContractContainer) => {
    const notify = useContext(NotificationContext)

    const loadingTitle = useInput<string>('Загрузка анкеты...')

    const {
        isLoadingCard, saveCard, getCard, getPayments, getPrograms, getFiles, getNewContractNumber, 
        deleteProgram, addAndDeleteProgramListener, paymentSave, generateDocuments, deleteFiles, uploadFiles, 
        sendedContractDocuments, sendedContractStatementDelivery, createPrimaryAccess,
    } = useContracts(useShallow((state) => ({
        isLoadingCard: state.isLoadingCard,
        saveCard: state.actionSetContractCard,
        getCard: state.actionGetContractCard,
        getPayments: state.actionGetgetContractPayments,
        getPrograms: state.actionGetgetContractProgramsListeners,
        getFiles: state.actionGetContractFiles,
        getNewContractNumber: state.actionGetNewContractNumber,
        deleteProgram: state.actionSetContractCardProgramsSave,
        addAndDeleteProgramListener: state.actionSetContractCardProgramListenerSave,
        deleteFiles: state.actionSetContractFilesDelete,
        uploadFiles: state.actionSetContractUploadFiles,
        paymentSave: state.actionSetContractCardPaymentSave,
        generateDocuments: state.actionSetContractGenerateDocument,
        sendedContractDocuments: state.actionSetSendedContractDocuments,
        sendedContractStatementDelivery: state.actionSetSendedContractStatementDelivery,
        createPrimaryAccess: state.actionSetCreatePrimaryAccess,
    })))

    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalNotice = useInput<IModalNotice>({isOpen: false})
    const modalDict = useInput<ISelectDictionaryContainer>({isOpen: false})
    const modalPrograms = useInput<ICardContractProgramContainer>({isOpen: false})
    const modalCompanies = useInput<ISelectCompanyContainer>({isOpen: false})
    const modalListeners = useInput<ISelectListenerContainer>({isOpen: false})
    const modalListenerPropery = useInput<ICardContractProgramListenerContainer>({isOpen: false})
    const modalPayments = useInput<ICardContractPaymentsContainer>({isOpen: false})
    const modalContractsGenerateDoc = useInput<ICardContractGenerateDocContainer>({isOpen: false})
    const modalContractUpload = useInput<ICardContractUploadContainer>({isOpen: false})
    const modalContractSended = useInput<ICardContractSendedContainer>({isOpen: false})
    const modalPreview = useInput<IModalPreview>({isOpen: false})

    const tabActive = useInput<string>('primary')
    const fieldsError = useInput<IFieldsError>({})

    const dataBaseName = useInput<string>('')
    const contractsDateCreate = useInput<string>('')
    const summDuty = useInput<number>(0)

    const txtPostPayment = useInput<number | ''>(0)
    const txtOutsideMoodle = useInput<number | ''>(0)

    const txtApplicationID = useInput<number | ''>('')
    const txtContractDate = useInput<string>('')
    const txtContractNumber = useInput<string>('')
    const txtContractTypeID = useInput<number | ''>(1)
    const txtContractType = useInput<string>('Физическое лицо')
    const txtContractViewID = useInput<number | ''>(1)
    const txtContractView = useInput<string>('Стандарт')
    const txtContractStatusID = useInput<number | ''>(1)
    const txtContractStatus = useInput<string>('Новый')
    
    const txtCompanyNameID = useInput<number | ''>('')
    const txtCompanyName = useInput<string>('')
    const txtCompanyContactFIO = useInput<string>('')
    const txtCompanyContactPhone = useInput<string>('')
    const txtCompanyContactEmail = useInput<string>('')
    const txtComment = useInput<string>('')

    const deliveryToWhom = useInput<string>('')
    const deliveryAddress = useInput<string>('')
    const attestationTypeID = useInput<number | ''>('')

    const paymentsList = useInput<IPaymentsList[]>([])
    const programsList = useInput<IProgramsList[]>([])
    const filesList = useInput<IFilesList[]>([])


    useEffect(() => {
        if (isOpen) {
            cardMode === 'edit' && getCard(Number(cardID)).then((res) => {
                if (res) {
                    contractsDateCreate.setValue(moment(res.dateCreate).format('DD.MM.YYYY'))
    
                    dataBaseName.setValue(res.dataBase || '')
                    txtPostPayment.setValue(res.isPostPayment || 0)
                    txtOutsideMoodle.setValue(res.isLearningOutsideMoodle || 0)

                    txtApplicationID.setValue(res.applicationID || '')
                    txtContractDate.setValue(res.contractDate || '')
                    txtContractNumber.setValue(res.contractNumber || '')
                    txtContractTypeID.setValue(res.contractTypeID || '')
                    txtContractType.setValue(res.contractTypeName || '')
                    txtContractViewID.setValue(res.contractViewID || '')
                    txtContractView.setValue(res.contractViewName || '')
                    txtContractStatusID.setValue(res.contractStatusID || '')
                    txtContractStatus.setValue(res.contractStatusName || '')
                    
                    txtCompanyNameID.setValue(res.companyID || '')
                    txtCompanyName.setValue(res.companyNameShort || '')
                    txtCompanyContactFIO.setValue(res.companyContactFIO || '')
                    txtCompanyContactPhone.setValue(res.companyContactPhone || '')
                    txtCompanyContactEmail.setValue(res.companyContactEmail || '')
                    txtComment.setValue(res.comment || '')

                    summDuty.setValue(res.summDuty || 0)
                    paymentsList.setValue(res.paymentsList)
                    programsList.setValue(res.programsListenersList)

                    deliveryToWhom.setValue(res.deliveryToWhom || '')
                    deliveryAddress.setValue(res.deliveryAddress || '')
                    attestationTypeID.setValue(res.attestationTypeID || '')

                    filesList.setValue(res.filesList.map((item) => ({
                        fileID: item.contractFileID,
                        sectionName: item.contractSectionName,
                        fileName: item.fileName,
                        filePath: item.filePath,
                        fileSize: item.fileSize,
                        dateCreate: item.dateCreate,
                        isSelected: false
                    })))
                }
            })

            cardMode === 'new' && txtContractDate.setValue(moment(new Date().toISOString()).format('DD.MM.YYYY')) 
        }

        !isOpen && clearCard() // Обнуление данных по анкете
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция обнуления данных по карточке */
    const clearCard = () => {
        tabActive.setValue('primary')
        fieldsError.setValue({})

        dataBaseName.setValue('')
        contractsDateCreate.setValue('')
        summDuty.setValue(0)
    
        txtPostPayment.setValue(0)
        txtOutsideMoodle.setValue(0)

        txtApplicationID.setValue('')
        txtContractDate.setValue('')
        txtContractNumber.setValue('')
        txtContractTypeID.setValue(1)
        txtContractType.setValue('Физическое лицо')
        txtContractViewID.setValue(1)
        txtContractView.setValue('Стандарт')
        txtContractStatusID.setValue(1)
        txtContractStatus.setValue('Новый')
        
        txtCompanyNameID.setValue('')
        txtCompanyName.setValue('')
        txtCompanyContactFIO.setValue('')
        txtCompanyContactPhone.setValue('')
        txtCompanyContactEmail.setValue('')

        deliveryToWhom.setValue('')
        deliveryAddress.setValue('')
        attestationTypeID.setValue('')

        txtComment.setValue('')

        paymentsList.setValue([])
        programsList.setValue([])
        filesList.setValue([])
    }


    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'contractDate': txtContractDate.setValue(stringDate); break
                }

                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handleOpenModalDict = (isOpen: boolean, dictName: DictNameType, whereParam1?: string, whereParam2?: string) => {
        modalDict.setValue({
            isOpen, dictName, whereParam1, whereParam2,
            handlerButtonCancel() {modalDict.setValue({isOpen: false})}, 
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (dictName) {
                    case 'applicationsNumber': {
                        txtApplicationID.setValue(Number(id))
                        dataBaseName.setValue(param1 || '')
                        break
                    }
                    
                    case 'contractType': {
                        txtContractTypeID.setValue(Number(id))
                        txtContractType.setValue(name)
                        break
                    }
                    
                    case 'contractView': {
                        txtContractViewID.setValue(Number(id))
                        txtContractView.setValue(name)
                        break
                    }
                    
                    case 'contractStatus': {
                        txtContractStatusID.setValue(Number(id))
                        txtContractStatus.setValue(name)
                        break
                    }                 
                }

                modalDict.setValue({isOpen: false})
            }
        })
    }



    /** Открытие/закрытие окна выбора контрагента */
    const handlerOpenModalCompanies = (isOpen: boolean) => {
        modalCompanies.setValue({
            isOpen,
            handlerButtonCancel() {modalCompanies.setValue({isOpen: false})}, 
            handlerButtonOk(id, name, address) {
                txtCompanyNameID.setValue(id)
                txtCompanyName.setValue(name)

                deliveryToWhom.setValue(name)
                deliveryAddress.setValue(address)

                modalCompanies.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора слушателя */
    const handlerOpenModalListeners = (isOpen: boolean, contractProgramID: number, qualification: string) => {
        modalListeners.setValue({
            isOpen,
            handlerButtonCancel() {modalListeners.setValue({isOpen: false})}, 
            handlerButtonOk(id, name, address) {
                loadingTitle.setValue('Добавление слушателя...')

                deliveryToWhom.setValue(name)
                deliveryAddress.setValue(address)

                addAndDeleteProgramListener(0, {
                    actionForm: 'new',
                    contractID: Number(cardID),
                    contractProgramID: Number(contractProgramID),
                    listenerID: id,
                    attestationTypeID: Number(attestationTypeID.value),
                    qualification: qualification,

                    deliveryToWhom: name,
                    deliveryAddress: address
                }).then((res) => {
                    if (res === 200) {
                        notify && notifyOpen('Слушатель успешно добавлен.', 'success', 1500, notify)
                        
                        getPrograms(cardID || 0).then((res) => { if (res) programsList.setValue(res) })
                        getPayments(cardID || 0).then((res) => { 
                            if (res) {
                                summDuty.setValue(res.summDuty)
                                paymentsList.setValue(res.payments)
                            }
                        })
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })

                modalListeners.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна редактирования свойств слушателя по программе */
    const handlerOpenModalListenerProperty = (isOpen: boolean, contractProgramID: number, contractListenerID: number, documentTypeID: number, attestationTypeID: number, attestationTypeName: string, qualification: string) => {
        modalListenerPropery.setValue({
            isOpen, isAttestationTypeVisible: [1, 3].includes(documentTypeID), attestationTypeID, attestationTypeName, qualification,
            handlerButtonCancel() {modalListenerPropery.setValue({isOpen: false})}, 
            handlerButtonOk(id, qualificationName) {
                loadingTitle.setValue('Изменение свойств слушателя...')

                addAndDeleteProgramListener(contractListenerID, {
                    actionForm: 'edit',
                    contractID: 0,
                    contractProgramID: Number(contractProgramID),
                    listenerID: 0,
                    attestationTypeID: id,
                    qualification: qualificationName,

                    deliveryToWhom: '0',
                    deliveryAddress: '0'
                }).then((res) => {
                    if (res === 200) {
                        notify && notifyOpen('Свойства слушателя успешно изменены.', 'success', 1500, notify)
                        
                        getPrograms(cardID || 0).then((res) => { if (res) programsList.setValue(res) })
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })

                modalListenerPropery.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна создания/редактирования оплат по договору */
    const handlerOpenModalPayments = (isOpen: boolean, cardMode: CardModeType, contractPaymentID?: number, paymentNumber?: string, paymentDate?: string, paymentSum?: number, paymentPayer?: string, paymentRefund?: number) => {
        modalPayments.setValue({
            isOpen, cardMode, paymentNumber, paymentDate, paymentSum, paymentPayer, paymentRefund,
            handlerButtonCancel() {modalPayments.setValue({isOpen: false})}, 
            handlerButtonOk(paymentNumber, paymentDate, paymentSum, paymentPayer, paymentRefund) {
                loadingTitle.setValue(`${cardMode === 'new' ? 'Создание оплаты...' : 'Изменение оплаты...'}`)

                paymentSave(Number(cardID), Number(contractPaymentID), {
                    actionForm: cardMode,

                    paymentNumber: paymentNumber,
                    paymentDate: paymentDate,
                    paymentSum: Number(paymentSum),
                    paymentPayer: paymentPayer,
                    paymentRefund: paymentRefund
                }).then((res) => {
                    if (res === 200) {
                        notify && notifyOpen(`${cardMode === 'new' ? 'Оплата успешно добавлена' : 'Оплата успешно изменена'}`, 'success', 1500, notify)
                        
                        getPayments(cardID || 0).then((res) => { 
                            if (res) {
                                summDuty.setValue(res.summDuty)
                                paymentsList.setValue(res.payments)
                            }
                        })
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })

                modalPayments.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна по выбору документов для формирования */
    const handlerOpenModalContractsGenerateDoc = (isOpen: boolean) => {
        modalContractsGenerateDoc.setValue({
            isOpen, isCompany: txtContractTypeID.value === 2,
            handlerButtonCancel() {modalContractsGenerateDoc.setValue({isOpen: false})}, 
            handlerButtonOk(documents, sum) {
                loadingTitle.setValue(`Создаем документы...`)

                generateDocuments(Number(cardID), documents, sum).then((res) => {
                    if (res === 200) {
                        notify && notifyOpen(`Документы успешно созданы`, 'success', 1500, notify)
                        
                        getFiles(cardID || 0).then((res) => { 
                            if (res) {
                                filesList.setValue(res.map((item) => ({
                                    fileID: item.contractFileID,
                                    sectionName: item.contractSectionName,
                                    fileName: item.fileName,
                                    filePath: item.filePath,
                                    fileSize: item.fileSize,
                                    dateCreate: item.dateCreate,
                                    isSelected: false
                                })))
                            }
                        })
                    }
                }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })

                modalContractsGenerateDoc.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна загрузки файлов по договору */
    const handlerOpenModalContractUpload = (isOpen: boolean) => {
        modalContractUpload.setValue({
            isOpen, contractID: cardID,
            fileUploader(files, selectedSectionID) { fileUploader(files, selectedSectionID) },
            handlerButtonCancel() { modalContractUpload.setValue({isOpen: false}) }, 
        })
    }
    /** Открытие/закрытие окна отправки файлов по договору в telegram */
    const handlerOpenModalContractSended = (isOpen: boolean) => {
        if (filesList.value.length === 0) return notify && notifyOpen('Документы для отправки не найдены.', 'info', 2000, notify)

        modalContractSended.setValue({
            isOpen,
            handlerButtonCancel() { modalContractSended.setValue({isOpen: false}) }, 
            handlerButtonOk(selectedList) { 
                if (selectedList.filter((item) => item.type === 'sendedDocuments').length > 0) {
                    if (filesList.value.filter((item) => item.sectionName === 'На подпись').length === 0) return notify && notifyOpen('Документы для отправки на подпись не найдены.', 'info', 2000, notify)
                }
        
                loadingTitle.setValue('Отправляю документы, ожидайте...')

                for (const item of selectedList) {
                    switch (item.type) {
                        case 'sendedDocuments': {
                            modalContractSended.setValue({isOpen: false})
                            
                            sendedContractDocuments(Number(cardID)).then((res) => {
                                if (res === 200) notify && notifyOpen('Документы успешно отправлены', 'success', 1500, notify)
                            })
                            break
                        } // Отправка в Telegram канал (ИКТ - Оформление договора)

                        case 'statementDelivery': {
                            modalContractSended.setValue({isOpen: false})
                            
                            sendedContractStatementDelivery(Number(cardID)).then((res) => {
                                if (res === 200) notify && notifyOpen('Документы успешно отправлены', 'success', 1500, notify)
                            })
                            break
                        } // Отправка в Telegram канал (ИКТ - Заявление на доставку)
                    }
                }

                loadingTitle.setValue('Загрузка анкеты...')
            },
        })
    }



    /** Открытие/закрытие окна создания или редактирования программы по договору */
    const handlerOpenModalProgram = (isOpen: boolean, cardMode?: CardModeType, contractProgramID?: number) => {
        modalPrograms.setValue({
            isOpen, cardMode, contractID: cardID, contractProgramID, dbName: dataBaseName.value,
            handlerButtonCancel() {modalPrograms.setValue({isOpen: false})}, 
            handlerButtonOk() {
                modalPrograms.setValue({isOpen: false})

                getPrograms(cardID || 0).then((res) => { if (res) programsList.setValue(res) })
            }
        })
    }
    /** Кнопка удалить программу */
    const handleBtnDeleteProgram = (contractProgramID: number) => {
        loadingTitle.setValue('Удаление программы...')

        deleteProgram(cardID || 0, contractProgramID, {
            actionForm: 'delete',
            courseProgramID: 0, courseID: 0, courseName: '0', documentTypeID: 0,
            durationEducation: '0', volumeHours: 0, price: 0,
            sphere: '0', qualification: '0', requirementName: '0',
            dateEducationStart: '0', dateEducationEnd: '0'
        }).then((res) => { 
            if (res === 200) {notify && notifyOpen('Программа успешно удалена.', 'success', 1500, notify)}
            getPrograms(cardID || 0).then((res) => { if (res) programsList.setValue(res) })
        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })
    }




    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, actionName?: string, recordID?: number) => {
        if (actionName === 'createLogin') {
            let errorGenerate: boolean = false
        
            if (programsList.value.length > 0) {
                for (const item of programsList.value) if (item.listenersList.length === 0) errorGenerate = true 
            } else {errorGenerate = true}
            
            if (errorGenerate) return notify && notifyOpen('Слушатели отсутствуют или в договоре имеются программы, в которых отсутствуют слушатели.', 'info', 2000, notify)    
        }

        if (actionName === 'deleteProgram') {
            if (programsList.value.filter((item) => item.contractProgramID === recordID && item.listenersList.length > 0).length > 0) return notify && notifyOpen('Программа которую Вы пытаетесь удалить, содержит слушателей, для начала удалите всех слушателей из программы.', 'info', 2000, notify)
        }

        if (actionName === 'deleteListener') {}

        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                switch (actionName) {
                    case 'contractNumber': {
                        loadingTitle.setValue('Получаем номер договора...')

                        getNewContractNumber(Number(cardID), txtContractDate.value).then((res) => {
                            if (res)  {txtContractNumber.setValue(res)} 
                            else {return notify && notifyOpen('Странно, но не удалось сгенерировать номер договора. Необходимо обратится к разработчику системы.', 'info', 2000, notify)}
                        }).finally(() => {loadingTitle.setValue('Загрузка анкеты...')})
                        break
                    } // Создание и получение нового номера по договору

                    case 'deleteProgram': {
                        handleBtnDeleteProgram(recordID || 0)
                        break
                    } // Удаление программы по договору
                    
                    case 'deleteListener':{
                        loadingTitle.setValue('Удаление слушателя...')

                        addAndDeleteProgramListener(recordID || 0, {
                            actionForm: 'delete', contractID: 0, contractProgramID: 0, listenerID: 0, attestationTypeID: 0,
                            qualification: '0', deliveryToWhom: '0', deliveryAddress: '0'
                        }).then((res) => {
                            if (res === 200) {
                                notify && notifyOpen('Слушатель успешно удалён.', 'success', 1500, notify)
                                
                                getPrograms(cardID || 0).then((res) => { if (res) programsList.setValue(res) })
                                getPayments(cardID || 0).then((res) => { 
                                    if (res) {
                                        summDuty.setValue(res.summDuty)
                                        paymentsList.setValue(res.payments)
                                    }
                                })
                            }
                        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })
                        break
                    } // Удаление слушателя по договору

                    case 'deletePayment':{
                        loadingTitle.setValue('Удаление оплаты...')

                        paymentSave(Number(cardID), Number(recordID), {
                            actionForm: 'delete', paymentNumber: '0', paymentDate: '0', paymentSum: 0, paymentPayer: '0', paymentRefund: 0
                        }).then((res) => {
                            if (res === 200) {
                                notify && notifyOpen('Оплата успешно удалена', 'success', 1500, notify)
                                
                                getPayments(cardID || 0).then((res) => { 
                                    if (res) {
                                        summDuty.setValue(res.summDuty)
                                        paymentsList.setValue(res.payments)
                                    }
                                })
                            }
                        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })
                        break
                    } // Удаление оплаты по договору                    

                    case 'createLogin': {
                        loadingTitle.setValue('Оформляю допуск к обучению, ожидайте...')

                        createPrimaryAccess(Number(cardID), Number(txtOutsideMoodle.value)).then((res) => {
                            if (res === 200) {
                                txtContractStatusID.setValue(4)
                                txtContractStatus.setValue('Проходит обучение')
                                
                                notify && notifyOpen('Допуск к обучению, успешно предоставлен!', 'success', 1500, notify)
                            }
                        }).finally(() => { loadingTitle.setValue('Загрузка анкеты...') })
                        break
                    } // Допуск слушателя к обучению
                }

                modalConfirm.setValue({isOpen: false})
            }
        })
    }
    // /** Открытие/закрытие окна извещения */
    // const handlerOpenModalNotice = (isOpen: boolean, headerText: string, contentText: string) => {
    //     modalNotice.setValue({
    //         isOpen, headerText, contentText, 
    //         handlerButtonOk() { modalNotice.setValue({isOpen: false}) }, 
    //     })
    // }



    /** Формирование документов по договору */
    const handlerGenerateDocuments = () => {
        let errorGenerate: boolean = false
        
        if (programsList.value.length > 0) {
            for (const item of programsList.value) if (item.listenersList.length === 0) errorGenerate = true 
        } else {errorGenerate = true}
        
        if (errorGenerate) return notify && notifyOpen('Слушатели отсутствуют или в договоре имеются программы, в которых отсутствуют слушатели.', 'info', 2000, notify)

        handlerOpenModalContractsGenerateDoc(true)
    }
    /** Открытие/закрытие окна предпросмотра документа */
    const handlerOpenModalPreview = (isOpen: boolean, fileUrl: string, fileName: string) => {
        modalPreview.setValue({
            isOpen, fileUrl, fileName, 
            handlerButtonCancel() {modalPreview.setValue({isOpen: false})}, 
        })
    }
    /** Функция по выделению файлов */
    const handlerSelectedFiles = (fileID: number) => {
        const newList = filesList.value.map(item => {
            if (item.fileID === fileID) {
                return { ...item, isSelected: !item.isSelected }
            }

            return item
        })

        filesList.setValue(newList)
    }
    /** Функция по выделению всех файлов */
    const handlerSelectedFilesAll = () => {
        const newList = filesList.value.map(item => {
            return { ...item, isSelected: !item.isSelected }
        })

        filesList.setValue(newList)
    }
    /** Функция по скачиванию файлов договора */
    const handlerDownloadFiles = (fileID?: number) => {
        let selectedFiles: IFilesList[] = []

        if (cardMode === 'new') return notify && notifyOpen(`Не возможно скачать файл или файлы, т.к. они еще не загружены на сервер.`, 'warning', 1500, notify)

        if (fileID) { selectedFiles = filesList.value.filter(file => file.fileID === fileID) } 
        else { selectedFiles = filesList.value.filter(file => file.isSelected) }

        for (const file of selectedFiles) {
            const link = document.createElement('a') // Создание ссылки и программное скачивание файла

            link.href = file.filePath
            link.download = file.fileName
            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)
        }
    }
    /** Функция по удалению файлов договора */
    const handlerDeleteFiles = async (fileID?: number) => {
        let selectedFiles: IFilesList[] = []

        if (fileID) { selectedFiles = filesList.value.filter(file => file.fileID === fileID) } 
        else { selectedFiles = filesList.value.filter(file => file.isSelected) }

        for (const file of selectedFiles) {
            await deleteFiles(Number(cardID) | 0, file.fileID).then((res) => {
                if (res === 200) {
                    getFiles(Number(cardID) | 0).then((res) => {
                        filesList.setValue(res.map((item) => ({
                            fileID: item.contractFileID,
                            sectionName: item.contractSectionName,
                            fileName: item.fileName,
                            filePath: item.filePath,
                            fileSize: item.fileSize,
                            dateCreate: item.dateCreate,
                            isSelected: false
                        })))
                    })
                }
            })
        }
    }


    // Загрузка файлов на сервер
    const fileUploader = async (files: File[], contractSectionID: number) => {
        // const maxSize = 10485760 // Максимально разрешенный размер файла для загрузки (10 MB)

        // files.forEach((file) => { // Проверка файлов

        // })

        await uploadFiles(Number(cardID) | 0, contractSectionID, files).finally(() => {
            getFiles(Number(cardID) | 0).then((res) => {
                filesList.setValue(res.map((item) => ({
                    fileID: item.contractFileID,
                    sectionName: item.contractSectionName,
                    fileName: item.fileName,
                    filePath: item.filePath,
                    fileSize: item.fileSize,
                    dateCreate: item.dateCreate,
                    isSelected: false
                })))
            })
        })
    }


    /** Кнопка сохранить все данные во вкладке primary */
    const handleBtnSave = (isClosedModal: boolean) => {
        let newCompanyNameID: number | '' = txtCompanyNameID.value, 
            newCompanyName: string = txtCompanyName.value,
            newCompanyContactFIO: string = txtCompanyContactFIO.value,
            newCompanyContactPhone: string = txtCompanyContactPhone.value,
            newCompanyContactEmail: string = txtCompanyContactEmail.value

        if (txtContractTypeID.value === 1) {
            txtCompanyNameID.setValue(''); newCompanyNameID = ''
            txtCompanyName.setValue(''); newCompanyName = ''
            txtCompanyContactFIO.setValue(''); newCompanyContactFIO = ''
            txtCompanyContactPhone.setValue(''); newCompanyContactPhone = ''
            txtCompanyContactEmail.setValue(''); newCompanyContactEmail = ''
        } // Если физическое лицо
        
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'applicationID', value: txtApplicationID.value.toString(), validType: 'notEmpty', isRequired: true, errorMessage: 'Номер заявки не выбран.'},
            {fieldName: 'contractDate', value: txtContractDate.value, validType: 'date', isRequired: true, errorMessage: 'Дата договора не указана или не корректна.'},
            {fieldName: 'contractNumber', value: txtContractNumber.value, validType: 'notEmpty', isRequired: cardMode === 'edit', errorMessage: 'Номер договора не указан.'},

            {fieldName: 'contractType', value: txtContractType.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Тип договора не выбран.'},
            {fieldName: 'contractView', value: txtContractView.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Вид договора не выбран.'},
            {fieldName: 'contractStatus', value: txtContractStatus.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Статус договора не выбран.'},
            
            {fieldName: 'companyName', value: newCompanyName, validType: 'notEmpty', isRequired: txtContractTypeID.value === 2, errorMessage: 'Наименование контрагента не указан.'},
            {fieldName: 'companyContactFIO', value: newCompanyContactFIO, validType: 'notEmpty', isRequired: txtContractTypeID.value === 2, errorMessage: 'Ф.И.О. контактного лица не указано.'},
            {fieldName: 'companyContactPhone', value: newCompanyContactPhone, validType: 'notEmpty', isRequired: txtContractTypeID.value === 2, errorMessage: 'Телефон контактного лица не указан.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnimation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }
        
        if (validateData.length === 0) { // Сохраняем данные
            loadingTitle.setValue(cardMode === 'new' ? 'Создание договора...' : 'Сохранение данных...')

            saveCard(cardID || 0, {
                actionForm: cardMode || 'new',

                contractDate: txtContractDate.value,
                applicationID: Number(txtApplicationID.value),
                dataBaseName: dataBaseName.value,
            
                contractTypeID: Number(txtContractTypeID.value),
                contractViewID: Number(txtContractViewID.value),
                contractStatusID: Number(txtContractStatusID.value),
            
                companyID: Number(newCompanyNameID),
                companyContactFIO: newCompanyContactFIO,
                companyContactPhone: newCompanyContactPhone,
                companyContactEmail: newCompanyContactEmail,
            
                deliveryToWhom: deliveryToWhom.value,
                deliveryAddress: deliveryAddress.value,
                comment: txtComment.value,
            
                isPostPayment: Number(txtPostPayment.value),
                isLearningOutsideMoodle: Number(txtOutsideMoodle.value)
            }).then((res) => {
                if (res === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    if (isClosedModal || cardMode === 'new') handlerButtonOk && handlerButtonOk()
                }
            }).finally(() => {
                loadingTitle.setValue('Загрузка анкеты...')
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnimation = (field: FieldsErrorType) => {
        const interval = setInterval(() => {fieldsError.setValue(prevState => ({...prevState, [field]: !prevState[field]}))}, 400)
        setTimeout(() => {fieldsError.setValue({[field]: false}); clearInterval(interval)}, 8000)
    }



    /** Свойства передаваемые в компоненту */
    const propsToComponent: ICardContract = {
        loadingTitle,

        isOpen, cardMode, handlerButtonCancel, handleBtnSave,
        isLoading: isLoadingCard, tabActive, fieldsError, contractID: cardID,

        paymentsList, programsList, filesList,

        handleOpenModalCalendar, handlerOpenModalConfirm, handleOpenModalDict,
        handlerOpenModalProgram, handlerOpenModalCompanies, handlerOpenModalListeners,
        handlerSelectedFiles, handlerDownloadFiles, handlerSelectedFilesAll, handlerDeleteFiles, 
        handlerOpenModalPreview, handlerGenerateDocuments, handlerOpenModalListenerProperty, 
        handlerOpenModalPayments, handlerOpenModalContractUpload, handlerOpenModalContractSended,

        contractsDateCreate, summDuty, txtPostPayment, txtOutsideMoodle,
        txtApplicationID, txtContractDate, txtContractNumber, txtContractTypeID, txtContractType, txtContractView, txtContractStatus,
        txtCompanyName, txtCompanyContactFIO, txtCompanyContactPhone, txtCompanyContactEmail, txtComment,
    }

    return <>
        <CardContract {...propsToComponent} />

        <ModalNotice {...modalNotice.value} />
        <ModalPreview {...modalPreview.value} />
        <ModalConfirm {...modalConfirm.value} />
        <ModalCalendar {...modalCalendar.value} />
        
        <SelectDictionaryContainer {...modalDict.value} />
        <SelectListenerContainer {...modalListeners.value} />
        <SelectCompaniesContainer {...modalCompanies.value} />

        <CardContractProgramContainer {...modalPrograms.value} />
        <CardContractPaymentsContainer {...modalPayments.value} />
        <CardContractUploadContainer {...modalContractUpload.value} />
        <CardContractSendedContainer {...modalContractSended.value} />
        <CardContractProgramListenerContainer {...modalListenerPropery.value} />
        <CardContractGenerateDocContainer {...modalContractsGenerateDoc.value} />
    </>
}